import React, { useState } from 'react';
import { Cable, ChevronLeft, ChevronRight, Zap, Sparkles, Lightbulb, Trophy, TrendingUp, AlertCircle, Info } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';


import { HealthAnalysis, DailyData } from '../../../types';

interface CreativeInsight {
    message: string;
    category: string;
    icon: string;
    color: string;
}

interface OverviewTabProps {
    email: string;
    healthAnalysis: HealthAnalysis | null;
    dailyDataOverview: DailyData[] | null;
    connectedProviders: string[] | null;
    creativeInsights: CreativeInsight[];
}

const CreativeInsightsSection: React.FC<{ insights: CreativeInsight[] }> = ({ insights }) => {
    const getIconComponent = (iconName: string) => {
        switch (iconName) {
            case 'trophy':
                return <Trophy className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 mt-0.5" />;
            case 'trend':
                return <TrendingUp className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 mt-0.5" />;
            case 'warning':
                return <AlertCircle className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 mt-0.5" />;
            case 'info':
                return <Info className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 mt-0.5" />;
            case 'lightbulb':
            default:
                return <Lightbulb className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0 mt-0.5" />;
        }
    };

    const getColorClass = (color: string) => {
        switch (color) {
            case 'green':
                return 'text-green-500';
            case 'blue':
                return 'text-blue-500';
            case 'yellow':
                return 'text-yellow-500';
            case 'red':
                return 'text-red-500';
            default:
                return 'text-purple-500';
        }
    };

    return (
        <div className="space-y-2 sm:space-y-3" data-testid="health-insights">
            {insights && insights.length > 0 ? (
                insights.map((insight: CreativeInsight, index: number) => (
                    <div key={index} className="flex items-start gap-2">
                        <span className={getColorClass(insight.color)}>
                            {getIconComponent(insight.icon)}
                        </span>
                        <p className="text-sm sm:text-base text-gray-600">{insight.message}</p>
                    </div>
                ))
            ) : (
                <p className="text-sm sm:text-base text-gray-500 text-center">
                    Connect your health devices to get personalized insights!
                </p>
            )}
        </div>
    );
};

const OverviewTab: React.FC<OverviewTabProps> = ({ email, healthAnalysis, dailyDataOverview, connectedProviders, creativeInsights }) => {
    const [currentDate, setCurrentDate] = useState(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    });

    const getCurrentDayData = () => {
        if (!dailyDataOverview) return null;
        return dailyDataOverview.find(data => {
            const dataDate = new Date(data.metadata.start_time);
            dataDate.setHours(0, 0, 0, 0);
            return dataDate.getTime() === currentDate.getTime();
        });
    };

    const handlePrevDay = () => {
        setCurrentDate(prev => {
            const newDate = new Date(prev);
            newDate.setDate(prev.getDate() - 1);
            return newDate;
        });
    };

    const handleNextDay = () => {
        setCurrentDate(prev => {
            const newDate = new Date(prev);
            newDate.setDate(prev.getDate() + 1);
            return newDate;
        });
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const currentDayData = getCurrentDayData();

    const formattedDate = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric'
    });

    if (!healthAnalysis) return <p className="text-center font-light py-4">No health data analysis available. Connect your health devices to get started!</p>;

    return (
        <div className="space-y-6">
            {/* Today's Overview */}
            <DashboardCard
                title="Today's Overview"
                titleClass="text-lg font-light"
                icon={<Zap className="text-yellow-500" />}
                testId="daily-overview-card"
            >
                <div className="space-y-4">
                    <div className="flex items-center justify-center" data-testid="date-navigation">
                        <button
                            onClick={handlePrevDay}
                            className="p-1 hover:bg-gray-100 rounded-full disabled:opacity-50 mx-4"
                            disabled={!dailyDataOverview}
                        >
                            <ChevronLeft className="w-4 h-4" />
                        </button>
                        <span className="font-light">{formattedDate}</span>
                        <button
                            onClick={handleNextDay}
                            className="p-1 hover:bg-gray-100 rounded-full disabled:opacity-50 mx-4"
                            disabled={currentDate.getTime() >= today.getTime()}
                        >
                            <ChevronRight className="w-4 h-4" />
                        </button>
                    </div>
                    {currentDayData ? (
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-1 sm:gap-4" data-testid="daily-metrics">
                            <div className="text-center p-1.5 sm:p-3 bg-gray-50 rounded-lg">
                                <p className="text-gray-500 text-xs sm:text-sm mb-1">Steps</p>
                                <p className="text-lg sm:text-xl font-light">
                                    {(currentDayData.distance_data?.steps || 0).toLocaleString()}
                                </p>
                            </div>
                            <div className="text-center p-1.5 sm:p-3 bg-gray-50 rounded-lg">
                                <p className="text-gray-500 text-xs sm:text-sm mb-1">Calories Burned</p>
                                <p className="text-lg sm:text-xl font-light">
                                    {Math.round(currentDayData.calories_data?.total_burned_calories || 0).toLocaleString()}
                                </p>
                            </div>
                            <div className="text-center p-1.5 sm:p-3 bg-gray-50 rounded-lg">
                                <p className="text-gray-500 text-xs sm:text-sm mb-1">Distance Covered</p>
                                <p className="text-lg sm:text-xl font-light">
                                    {((currentDayData.distance_data?.distance_meters || 0) / 1000).toFixed(2)} km
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-24 bg-gray-50 rounded-lg p-4">
                            <p className="text-gray-500 font-light text-center">
                                No health data available. <br /> Connect your health apps to start tracking your progress.
                            </p>
                        </div>
                    )}
                </div>
            </DashboardCard>

            {/* Health Insights */}
            <DashboardCard
                title="Health Insights"
                icon={<Sparkles className="text-purple-500" />}
                testId="overview-insights-card"
            >
                <CreativeInsightsSection insights={creativeInsights} />
            </DashboardCard>

            {/* Tracking Overview */}
            <DashboardCard
                title="Integration Overview"
                icon={<Cable className="text-primary" />}
                testId="tracking-overview-card"
            >
                <div className="text-sm sm:text-base space-y-1 sm:space-y-2" data-testid="tracking-metrics">
                    <div className="text-gray-600 flex items-center flex-wrap gap-2">
                        {Array.isArray(connectedProviders) && Array.from(new Set(connectedProviders)).length > 0 ? (
                            <>
                                <span>Data Sources:</span>
                                {Array.isArray(connectedProviders) && Array.from(new Set(connectedProviders)).map(provider => (
                                    <span key={provider} className="px-2 sm:px-3 py-1 bg-blue-50 text-blue-600 rounded-full">
                                        {provider}
                                    </span>
                                ))}
                            </>
                        ) : (
                            <span className="text-gray-500">
                                No sources currently synced. Connect your health apps to start tracking your data.
                            </span>
                        )}
                    </div>
                    <div className="text-gray-600 flex items-center flex-wrap gap-2">
                        <span>Data Types:</span>
                        {Array.isArray(healthAnalysis?.data_types) && healthAnalysis.data_types.map(type => (
                            <span key={type} className="px-2 sm:px-3 py-1 bg-green-50 text-green-600 rounded-full">
                                {type.toUpperCase()}
                            </span>
                        ))}
                    </div>
                </div>
            </DashboardCard>
        </div>
    );
};

export default OverviewTab;