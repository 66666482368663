import { getAccessToken } from './utils';
import { FeedbackResponse, FeedbackError } from '../types/feedbackTypes';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';


export const feedbackApi = {
    submitFeedbackTherapist: async (feedbackContent: string): Promise<FeedbackResponse> => {
        try {
            const response = await fetch(`${API_URL}/user_feedback/therapist_feedback_submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ message: feedbackContent })
            });

            if (!response.ok) {
                const errorData: FeedbackError = await response.json();
                throw new Error(errorData.detail || 'Failed to submit feedback');
            }

            return await response.json();
        } catch (error) {
            console.error('Error submitting feedback:', error);
            throw error;
        }
    },
    submitFeedbackUser: async (feedbackContent: string): Promise<FeedbackResponse> => {
        try {
            const response = await fetch(`${API_URL}/user_feedback/user_feedback_submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ message: feedbackContent })
            });

            if (!response.ok) {
                const errorData: FeedbackError = await response.json();
                throw new Error(errorData.detail || 'Failed to submit feedback');
            }

            return await response.json();
        } catch (error) {
            console.error('Error submitting feedback:', error);
            throw error;
        }
    }
};