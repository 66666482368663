import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

interface FileUploadModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDrop: (acceptedFiles: File[]) => void;
    uploadedFile: File | null;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
    isOpen,
    onClose,
    onDrop,
    uploadedFile
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
            'application/pdf': ['.pdf'],
        },
        maxFiles: 1,
    });

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed inset-0 bg-black opacity-30" onClick={onClose}></div>
                
                <div className="relative bg-white rounded-lg w-full max-w-md p-6">
                    <button 
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    >
                        <IoMdClose size={24} />
                    </button>

                    <h3 className="text-lg font-normal text-gray-900 mb-4">
                        Upload Notes
                    </h3>

                    <div {...getRootProps()} 
                        className={`border-2 border-dashed rounded-lg p-8 
                                text-center cursor-pointer transition-all duration-200 
                                ${isDragActive ? 'border-primary bg-primary/5' : 'border-gray-200'}`}
                    >
                        <input {...getInputProps()} />
                        <FaUpload className="mx-auto text-gray-400 mb-4 text-2xl" />
                        <p className="text-gray-500 font-light mb-2">
                            Drag & drop a file here, or click to select
                        </p>
                        <p className="text-gray-400 text-sm">
                            Supported formats: PDF, JPEG, PNG, GIF
                        </p>
                    </div>

                    {uploadedFile && (
                        <div className="mt-4 bg-gray-50 p-3 rounded-lg">
                            <p className="text-sm text-gray-600 font-light">
                                Selected file: {uploadedFile.name}
                            </p>
                        </div>
                    )}

                    <div className="mt-6 flex justify-end gap-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-light text-gray-700 hover:bg-gray-50 
                                     rounded-lg border border-gray-200"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-light text-white bg-primary 
                                     hover:bg-primary/90 rounded-lg"
                        >
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileUploadModal; 