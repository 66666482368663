import React from 'react';
import { FiUser, FiPhone, FiMapPin, FiUpload, FiTrash, FiEdit, FiBriefcase } from 'react-icons/fi';
import { FaLinkedin, FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa';
import { therapyTypes, ageGroups, insurancePlans } from '../TherapistOnboarding/Constants';
import useTherapistProfile from '../../hooks/useTherapistProfile';
import { useMediaQuery } from 'react-responsive';
import { TherapistProfileData } from '../../types/usersTypes';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";

const defaultAvatar = require('../../assets/default-avatar.png');


interface ProfileItemProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  isEditing: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  min?: string;
}

interface ProfileSectionProps {
  title: string;
  items: string[] | string;
  isEditing: boolean;
  name: string;
  onChange: (name: string, value: string[] | string) => void;
  options?: string[];
}

// Add this formatting function near the top of the file
const formatPhoneNumber = (value: string | number | undefined): string => {
  if (!value) return '';

  // Convert to string if number
  const stringValue = value.toString();

  // Remove all non-numeric characters
  const phoneNumber = stringValue.replace(/[^\d]/g, '');

  // Format the number as (XXX) XXX-XXXX
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

// Add type for image error event
type ImgErrorEvent = React.SyntheticEvent<HTMLImageElement, Event>;

interface TherapistProfileProps {
  therapistProfile?: TherapistProfileData;
}

const TherapistProfile: React.FC<TherapistProfileProps> = ({ therapistProfile }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });  // Add this line
  const {
    profile,
    editedProfile,
    isEditing,
    error,
    loading,
    uploadingImage,
    handleEdit,
    handleCancel,
    handleSave,
    handleChange,
    handleArrayChange,
    handleImageUpload,
    handleRemoveImage,
    handleTogglePublic
  } = useTherapistProfile();

  if (loading) return <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
  </div>;
  if (error) return <div className="text-center py-4 text-red-500 font-light">Error: {error}</div>;
  if (!profile) return <div className="text-center py-4 font-light">No profile data available</div>;

  // Update the img error handler
  const handleImageError = (e: ImgErrorEvent) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = defaultAvatar;
  };

  return (
    <div className="bg-white rounded-lg p-6" data-testid="therapist-profile">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 mb-6">
        <h2 className="text-2xl font-normal text-primary">Therapist Profile</h2>

        {/* Updated visibility controls */}
        <div className={`flex items-center ${isMobile ? 'justify-between' : 'ml-auto'}`}>
          <span className="text-sm font-light text-gray-700 mr-2">
            {isMobile ? (
              profile.is_public ? 'Public Profile' : 'Private Profile'
            ) : (
              `Profile Status: ${profile.is_public ? 'Public' : 'Private'}`
            )}
          </span>
          <div className="flex items-center">
            <Switch
              checked={profile.is_public}
              onCheckedChange={handleTogglePublic}
              className="data-[state=checked]:bg-green-500"
            />
            {profile.is_public ? (
              <FaEye className="ml-2 text-green-500" />
            ) : (
              <FaEyeSlash className="ml-2 text-gray-500" />
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6" data-testid="profile-info">
        <div className="md:col-span-1">
          {/* Name and Email above avatar */}
          <div className="text-center mb-6">
            <h3 className="text-xl font-normal text-gray-800">
              {isEditing ? (
                <input
                  type="text"
                  name="full_name"
                  value={editedProfile?.full_name || ''}
                  onChange={handleChange}
                  className="w-full p-1 border rounded text-center font-light"
                />
              ) : (
                profile.full_name
              )}
            </h3>
            {/* Email is now under name and not editable */}
            <p className="text-gray-600 font-light mt-1">
              {profile.email}
            </p>
          </div>

          {/* Made avatar slightly smaller */}
          <div className="relative w-40 h-40 mx-auto mb-4">
            <img
              src={editedProfile?.profile_picture_url || defaultAvatar}
              alt="Profile"
              className="rounded-full w-full h-full object-cover border-4 border-primary"
              onError={handleImageError}
            />
            {isEditing && (
              <div className="absolute bottom-0 right-0 flex">
                <label htmlFor="profile-picture-upload" className="cursor-pointer bg-primary text-white rounded-full p-2 mr-2">
                  <FiUpload />
                </label>
                <input
                  id="profile-picture-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <button onClick={handleRemoveImage} className="bg-red-500 text-white rounded-full p-2">
                  <FiTrash />
                </button>
              </div>
            )}
          </div>
          {uploadingImage && <p className="text-center text-sm text-gray-500 font-light">Uploading...</p>}
          {profile.linkedin_url && (
            <a href={profile.linkedin_url} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center text-blue-600 hover:text-blue-800 mt-4 font-light">
              <FaLinkedin size={24} className="mr-2" /> LinkedIn Profile
            </a>
          )}
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* License moved to cards, email removed from cards */}
            <ProfileItem
              icon={<FiUser />}
              label="License"
              value={isEditing ? editedProfile?.license || '' : profile?.license || ''}
              isEditing={isEditing}
              name="license"
              onChange={handleChange}
            />
            <ProfileItem
              icon={<FiBriefcase />}
              label="Experience"
              value={isEditing ? editedProfile?.years_of_experience : `${profile?.years_of_experience} years`}
              isEditing={isEditing}
              name="years_of_experience"
              onChange={handleChange}
              type="number"
              min="0"
            />
            <ProfileItem
              icon={<FiPhone />}
              label="Phone"
              value={isEditing ? editedProfile?.phone_number : profile?.phone_number}
              isEditing={isEditing}
              name="phone_number"
              onChange={handleChange}
            />
            <ProfileItem
              icon={<FiMapPin />}
              label="Address"
              value={isEditing ? editedProfile?.address : profile?.address}
              isEditing={isEditing}
              name="address"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-6 bg-gray-100 p-4 rounded-lg" data-testid="professional-info">
        <h3 className="text-xl font-normal text-primary mb-2">Professional Information</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <ProfileSection
            title="Therapy Types"
            items={isEditing ? editedProfile?.therapy_types || [] : profile?.therapy_types || []}
            isEditing={isEditing}
            name="therapy_types"
            onChange={handleArrayChange}
            options={therapyTypes}
          />
          <ProfileSection
            title="Specializations"
            items={isEditing ? editedProfile?.specializations : profile?.specializations}
            isEditing={isEditing}
            name="specializations"
            onChange={handleArrayChange}
          />
          <ProfileSection
            title="Languages"
            items={isEditing ? editedProfile?.languages : profile?.languages}
            isEditing={isEditing}
            name="languages"
            onChange={handleArrayChange}
          />
          <ProfileSection
            title="Age Groups Served"
            items={isEditing ? editedProfile?.age_groups_served : profile?.age_groups_served}
            isEditing={isEditing}
            name="age_groups_served"
            onChange={handleArrayChange}
            options={ageGroups}
          />
          <ProfileSection
            title="Education"
            items={isEditing ? editedProfile?.education : profile?.education}
            isEditing={isEditing}
            name="education"
            onChange={handleArrayChange}
          />
          <ProfileSection
            title="Accepted Insurance Plans"
            items={isEditing ? editedProfile?.insurance_accepted : profile?.insurance_accepted}
            isEditing={isEditing}
            name="insurance_accepted"
            onChange={handleArrayChange}
            options={insurancePlans}
          />
        </div>
      </div>

      <div className="mt-6" data-testid="professional-bio">
        <h3 className="text-xl font-normal text-primary mb-2">Professional Bio</h3>
        {isEditing ? (
          <Textarea
            name="professional_bio"
            value={editedProfile?.professional_bio || ''}
            onChange={handleChange}
            className="w-full font-light"
            rows={4}
          />
        ) : (
          <p className="text-gray-700 whitespace-pre-wrap font-light">{profile?.professional_bio}</p>
        )}
      </div>

      <div className="mt-6" data-testid="therapy-approach">
        <h3 className="text-xl font-normal text-primary mb-2">Therapy Approach</h3>
        {isEditing ? (
          <Textarea
            name="therapy_approach"
            value={editedProfile?.therapy_approach || ''}
            onChange={handleChange}
            className="w-full font-light"
            rows={4}
          />
        ) : (
          <p className="text-gray-700 whitespace-pre-wrap font-light">{profile?.therapy_approach}</p>
        )}
      </div>

      <div className="mt-6 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
        {isEditing ? (
          <>
            <Button
              onClick={handleCancel}
              variant="outline"
              className="w-full sm:w-auto font-light rounded-full"
              data-testid="delete-session"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className="w-full sm:w-auto font-light rounded-full"
              data-testid="save-profile-button"
            >
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            onClick={handleEdit}
            variant="outline"
            className="flex items-center w-full sm:w-auto justify-center font-light rounded-full"
            data-testid="edit-profile-button"
          >
            <FiEdit className="mr-2" /> Edit Profile
          </Button>
        )}
      </div>
    </div>
  );
};

// Update ProfileItem component with lighter fonts and phone formatting
const ProfileItem: React.FC<ProfileItemProps> = ({
  icon,
  label,
  value,
  isEditing,
  name,
  onChange,
  type = "text",
  min
}) => (
  <div className="bg-gray-50 p-3 rounded-lg">
    <div className="flex items-center mb-2">
      <div className="text-primary mr-3 text-xl flex-shrink-0">{icon}</div>
      <span className="font-normal text-gray-700">{label}</span>
    </div>
    <div className="pl-9">
      {isEditing ? (
        <Input
          type={type}
          name={name}
          value={name === "phone_number" ? formatPhoneNumber(value) : value || ''}
          onChange={(e) => {
            if (name === "phone_number") {
              const syntheticEvent = {
                target: {
                  name,
                  value: formatPhoneNumber(e.target.value)
                }
              } as React.ChangeEvent<HTMLInputElement>;
              onChange(syntheticEvent);
            } else {
              onChange(e);
            }
          }}
          className="w-full font-light"
          min={min}
          maxLength={name === "phone_number" ? 14 : undefined}
        />
      ) : (
        <p className="text-gray-900 break-words font-light">
          {name === "phone_number" ? formatPhoneNumber(value) : value || 'N/A'}
        </p>
      )}
    </div>
  </div>
);

// Update ProfileSection component with lighter fonts
const ProfileSection: React.FC<ProfileSectionProps> = ({
  title,
  items,
  isEditing,
  name,
  onChange,
  options
}) => (
  <div>
    <h4 className="font-normal mb-2">{title}</h4>
    {isEditing ? (
      options ? (
        <div className="border rounded p-2 max-h-40 overflow-y-auto">
          {options.map((option) => (
            <label key={option} className="flex items-center space-x-2 p-1 hover:bg-gray-100 cursor-pointer">
              <Checkbox
                checked={Array.isArray(items) && items.includes(option)}
                onCheckedChange={(checked) => {
                  if (Array.isArray(items)) {
                    const newItems = checked
                      ? [...items, option]
                      : items.filter(item => item !== option);
                    onChange(name, newItems);
                  }
                }}
              />
              <span className="break-words font-light">{option}</span>
            </label>
          ))}
        </div>
      ) : (
        <Input
          type="text"
          name={name}
          value={Array.isArray(items) ? items.join(', ') : items || ''}
          onChange={(e) => {
            onChange(name, e.target.value);
          }}
          onBlur={(e) => {
            const newValue = e.target.value
              .split(',')
              .map(item => item.trim())
              .filter(item => item);
            onChange(name, newValue);
          }}
          className="w-full p-2 border rounded font-light"
          placeholder="Enter values separated by commas"
        />
      )
    ) : (
      <div className="flex flex-wrap gap-2">
        {items && (Array.isArray(items) ? items : [items]).map((item, index) => (
          <span
            key={index}
            className="px-3 py-1.5 bg-white text-primary rounded-lg text-sm font-light
                     border border-primary/30 hover:border-primary/50 hover:bg-primary/5 
                     transition-all duration-200"
          >
            {item}
          </span>
        ))}
      </div>
    )}
  </div>
);

export default TherapistProfile;
