import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { api } from '../services/api';
import { useTherapistDashboard } from '../hooks/useTherapistDashboard';
import { Patient, Session, SessionCreate } from '../types';
import { TreatmentPlan } from '../types/therapistDashboardTypes';
import { toast } from 'react-toastify';
import { sessionsApi } from '../services/sessionsApi';

interface PatientTabContextType {
    // State
    selectedPatientId: string | null;
    selectedPatientDetails: Patient | null;
    pendingPatients: Patient[];
    approvedPatients: Patient[];
    patientNotes: Session[];
    expandedNoteId: string | null;
    searchTerm: string;
    editingNote: Session | null;
    currentPage: number;
    totalSessions: number;
    isLoading: boolean;
    isRegeneratingSummary: boolean;
    isSidebarCollapsed: boolean;
    allSessions: Session[];
    patientHealthOverview: any;
    isHealthDashboardVisible: boolean;
    isHealthDashboardLoading: boolean;
    isCreatePatientModalOpen: boolean;
    localPatients: Patient[];
    isLoadingPatient: boolean;
    filteredPatients: Patient[];
    sortedSessions: Session[];
    pageSize: number;
    totalPages: number;
    treatmentPlan: TreatmentPlan | null;
    isLoadingTreatmentPlan: boolean;
    healthData: any;
    isLoadingHealthData: boolean;
    sessionsWithWellbeingScore: Session[];
    isMobile: boolean;
    treatmentPlanDetails: {
        updated_at: string;
        // other treatment plan details
    };
    isEmptyState: boolean;
    downloadTreatmentPlanPdf: () => Promise<void>;

    // Actions
    setSelectedPatientId: (id: string | null) => void;
    setSearchTerm: (term: string) => void;
    handlePatientClick: (patient: Patient) => void;
    handlePageChange: (page: number) => void;
    handlePatientAction: (patientId: string, action: 'approve' | 'deny') => () => Promise<void>;
    toggleNoteExpansion: (noteId: string) => void;
    toggleSidebar: () => void;
    toggleHealthDashboard: () => Promise<void>;
    handleEditClick: (session: Session) => void;
    handleEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleEditSubmit: (e: React.FormEvent) => Promise<void>;
    handleRegenerateSummary: (format: string) => Promise<void>;
    handleDeleteClick: (sessionId: string) => Promise<void>;
    downloadPDF: (session: Session) => Promise<void>;
    openCreatePatientModal: () => void;
    closeCreatePatientModal: () => void;
    handlePatientCreated: (newPatient: Patient) => Promise<void>;
    setEditingNote: React.Dispatch<React.SetStateAction<Session | null>>;
    handleUpdateTreatmentPlan: (planContent: string) => Promise<void>;
    handleUploadTreatmentPlan: (file: File) => Promise<void>;
    handleGenerateTreatmentPlan: () => Promise<void>;
    handleGenerateTreatmentPlanWithInstructions: (instructions: string) => Promise<void>;
    handleDeleteTreatmentPlan: () => Promise<void>;
    handleGetTreatmentPlan: () => Promise<void>;
    createPatient: (formData: any) => Promise<Patient>;
    handleSaveClick: () => Promise<void>;
    handleCreateDemoPatient: () => Promise<void>;
}

interface PatientTabProviderProps {
    children: React.ReactNode;
    initialPatients: Patient[];
    onPatientAction: (patientId: string, action: 'approve' | 'deny') => Promise<void>;
}

const PatientTabContext = createContext<PatientTabContextType | undefined>(undefined);

export const PatientTabProvider: React.FC<PatientTabProviderProps> = ({ children, initialPatients, onPatientAction }) => {
    // State
    const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
    const [selectedPatientDetails, setSelectedPatientDetails] = useState<Patient | null>(null);
    const [pendingPatients, setPendingPatients] = useState<Patient[]>([]);
    const [approvedPatients, setApprovedPatients] = useState<Patient[]>([]);
    const [patientNotes, setPatientNotes] = useState<Session[]>([]);
    const [expandedNoteId, setExpandedNoteId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [editingNote, setEditingNote] = useState<Session | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalSessions, setTotalSessions] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRegeneratingSummary, setIsRegeneratingSummary] = useState<boolean>(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
    const [allSessions, setAllSessions] = useState<Session[]>([]);
    const [patientHealthOverview, setPatientHealthOverview] = useState<any>(null);
    const [isHealthDashboardVisible, setIsHealthDashboardVisible] = useState<boolean>(false);
    const [isHealthDashboardLoading, setIsHealthDashboardLoading] = useState<boolean>(false);
    const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] = useState<boolean>(false);
    const [localPatients, setLocalPatients] = useState<Patient[]>(initialPatients);
    const [isLoadingPatient, setIsLoadingPatient] = useState<boolean>(false);
    const [healthData, setHealthData] = useState<any>(null);
    const [isLoadingHealthData, setIsLoadingHealthData] = useState<boolean>(false);
    const [treatmentPlanDetails, setTreatmentPlanDetails] = useState<{
        updated_at: string;
    }>({ updated_at: '' });

    const {
        treatmentPlans,
        isLoadingTreatmentPlan,
        generateTreatmentPlan,
        uploadTreatmentPlan,
        updateTreatmentPlan,
        getTreatmentPlan,
        deleteTreatmentPlan,
        generateTreatmentPlanWithInstructions,
        createPatient,
        fetchPatientDetails,
        updateSession,
        regenerateSessionSummary,
        deleteSession,
        generateSessionPDF,
        isMobile,
    } = useTherapistDashboard();

    // Computed values
    const pageSize = 10;
    const totalPages = Math.ceil(totalSessions / pageSize);
    const treatmentPlan = selectedPatientId ? treatmentPlans[selectedPatientId] : undefined;

    const filteredPatients = useCallback(() => {
        const searchRegex = new RegExp(searchTerm, 'i');
        const filtered = localPatients.filter(patient =>
            searchRegex.test(patient.full_name) || searchRegex.test(patient.email || '')
        );

        // Sort patients: pending first, then alphabetically within each group
        return filtered.sort((a, b) => {
            // If status is different, pending comes first
            if (a.status !== b.status) {
                return a.status === 'pending' ? -1 : 1;
            }
            // If status is the same, sort alphabetically
            return a.full_name.localeCompare(b.full_name);
        });
    }, [localPatients, searchTerm]);

    const sortedSessions = useCallback(() => {
        return [...patientNotes].sort((a, b) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        );
    }, [patientNotes]);

    // Compute empty state
    const isEmptyState = useMemo(() => localPatients.length === 0, [localPatients]);

    // Handlers
    const handlePatientClick = useCallback(async (patient: Patient) => {
        setIsLoadingPatient(true);
        setSelectedPatientId(patient.id);
        try {
            const details = await fetchPatientDetails(patient.id, currentPage, pageSize);
            setSelectedPatientDetails(details.patientDetails as Patient);
            setAllSessions(details.allSessions);
            setPatientNotes(details.paginatedSessions);
            setTotalSessions(details.totalSessions);
            setPatientHealthOverview(details.healthOverview);

            // Auto-fetch treatment plan
            await getTreatmentPlan(patient.id);
        } catch (error) {
            console.error('Error fetching patient details:', error);
        } finally {
            setIsLoadingPatient(false);
        }
    }, [currentPage, pageSize, fetchPatientDetails, getTreatmentPlan]);

    const handlePageChange = (page: number): void => {
        setCurrentPage(page);
        const start = (page - 1) * pageSize;
        const end = start + pageSize;
        setPatientNotes(allSessions.slice(start, end));
    };

    const handlePatientAction = (patientId: string, action: 'approve' | 'deny') => async (): Promise<void> => {
        await onPatientAction(patientId, action);
        setLocalPatients(prevPatients =>
            action === 'deny'
                ? prevPatients.filter(p => p.id !== patientId)
                : prevPatients.map(p => p.id === patientId ? { ...p, status: 'approved' } : p)
        );
    };

    const toggleNoteExpansion = (noteId: string): void => {
        setExpandedNoteId(expandedNoteId === noteId ? null : noteId);
    };

    const toggleSidebar = (): void => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const toggleHealthDashboard = async (): Promise<void> => {
        if (!isHealthDashboardVisible && selectedPatientId) {
            setIsHealthDashboardLoading(true);
            try {
                const data = await api.therapists.getPatientHealthData(selectedPatientId);
                setHealthData(data);
            } catch (error) {
                console.error('Error fetching health data:', error);
            } finally {
                setIsHealthDashboardLoading(false);
            }
        }
        setIsHealthDashboardVisible(!isHealthDashboardVisible);
    };

    const handleEditClick = (session: Session): void => {
        setEditingNote(session);
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
        if (editingNote) {
            setEditingNote({
                ...editingNote,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleEditSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        if (editingNote) {
            try {
                const updatedSession = await updateSession(editingNote.id, editingNote);
                setPatientNotes(notes =>
                    notes.map(note =>
                        note.id === updatedSession.id ? updatedSession : note
                    )
                );
                setEditingNote(null);
            } catch (error) {
                console.error('Error updating session:', error);
            }
        }
    };

    const handleRegenerateSummary = async (format: string): Promise<void> => {
        if (!editingNote || !editingNote.content) return;

        try {
            setIsRegeneratingSummary(true);
            const updatedSession = await regenerateSessionSummary(editingNote.id, editingNote.content, format);

            if (updatedSession) {
                setEditingNote(prev => prev ? { ...prev, summary: updatedSession.summary } : null);
                // Refresh sessions list
                await fetchPatientDetails(selectedPatientId!, currentPage, pageSize);
            }
        } catch (error) {
            console.error('Error regenerating summary:', error);
            toast.error('Failed to regenerate summary');
        } finally {
            setIsRegeneratingSummary(false);
        }
    };

    const handleDeleteClick = async (sessionId: string): Promise<void> => {
        const confirmDelete = window.confirm('Are you sure you want to delete this session?');
        if (confirmDelete) {
            try {
                await deleteSession(sessionId);
                setPatientNotes(notes => notes.filter(note => note.id !== sessionId));
                setAllSessions(sessions => sessions.filter(session => session.id !== sessionId));
                setTotalSessions(prev => prev - 1);
            } catch (error) {
                console.error('Error deleting session:', error);
            }
        }
    };

    const downloadPDF = async (session: Session): Promise<void> => {
        try {
            const pdfBlob = await generateSessionPDF(session.id);
            const url = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `session-${session.session_number}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const openCreatePatientModal = (): void => {
        setIsCreatePatientModalOpen(true);
    };

    const closeCreatePatientModal = (): void => {
        setIsCreatePatientModalOpen(false);
    };

    const handlePatientCreated = async (newPatient: Patient): Promise<void> => {
        setLocalPatients(prevPatients => [...prevPatients, newPatient]);
        setApprovedPatients(prevApproved => [...prevApproved, newPatient]);
    };

    // Treatment Plan handlers
    const handleUpdateTreatmentPlan = async (planContent: string): Promise<void> => {
        if (selectedPatientId) {
            await updateTreatmentPlan(selectedPatientId, planContent);
        }
    };

    const handleUploadTreatmentPlan = async (file: File): Promise<void> => {
        if (selectedPatientId) {
            await uploadTreatmentPlan(selectedPatientId, file);
        }
    };

    const handleGenerateTreatmentPlan = async (): Promise<void> => {
        if (selectedPatientId) {
            await generateTreatmentPlan(selectedPatientId);
        }
    };

    const handleGenerateTreatmentPlanWithInstructions = async (instructions: string): Promise<void> => {
        if (selectedPatientId) {
            await generateTreatmentPlanWithInstructions(selectedPatientId, instructions);
        }
    };

    const handleDeleteTreatmentPlan = async (): Promise<void> => {
        if (selectedPatientId) {
            await deleteTreatmentPlan(selectedPatientId);
        }
    };

    const handleGetTreatmentPlan = async (): Promise<void> => {
        if (selectedPatientId) {
            await getTreatmentPlan(selectedPatientId);
        }
    };

    const handleSaveClick = async (): Promise<void> => {
        if (!editingNote) return;
        try {
            await handleEditSubmit(new Event('submit') as any);
            setEditingNote(null);
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    const handleCreateDemoPatient = async (): Promise<void> => {
        try {
            const demoData = await api.therapists.createDemoPatient();

            // Update local state with demo data
            setLocalPatients(prevPatients => [...prevPatients, demoData.patient]);
            setApprovedPatients(prevApproved => [...prevApproved, demoData.patient]);

            // Store demo data in context or relevant stores
            setAllSessions(demoData.sessions);
            setPatientHealthOverview(demoData.health_data.health_analysis);

            // You might want to store the treatment plan and health data
            // in your application state management system

            toast.success('Demo patient created successfully!');
        } catch (error) {
            console.error('Error creating demo patient:', error);
            toast.error('Failed to create demo patient');
        }
    };

    const downloadTreatmentPlanPdf = async (): Promise<void> => {
        if (!selectedPatientId) return;

        try {
            await api.therapists.downloadTreatmentPlanPdf(selectedPatientId);
            toast.success('Treatment plan downloaded successfully');
        } catch (error) {
            console.error('Error downloading treatment plan:', error);
            toast.error('Failed to download treatment plan');
        }
    };

    const value: PatientTabContextType = {
        // State
        selectedPatientId,
        selectedPatientDetails,
        pendingPatients,
        approvedPatients,
        patientNotes,
        expandedNoteId,
        searchTerm,
        editingNote,
        currentPage,
        totalSessions,
        isLoading,
        isRegeneratingSummary,
        isSidebarCollapsed,
        allSessions,
        patientHealthOverview,
        isHealthDashboardVisible,
        isHealthDashboardLoading,
        isCreatePatientModalOpen,
        localPatients,
        isLoadingPatient,
        filteredPatients: filteredPatients(),
        sortedSessions: sortedSessions(),
        pageSize,
        totalPages,
        treatmentPlan: treatmentPlan ? {
            content: treatmentPlan,
            updated_at: new Date().toISOString(),
            id: selectedPatientId || ''
        } : null,
        isLoadingTreatmentPlan,
        healthData,
        isLoadingHealthData,
        sessionsWithWellbeingScore: allSessions.filter(session => session.wellbeing_score !== null),
        isMobile,
        treatmentPlanDetails: {
            updated_at: '',
            // other treatment plan details
        },
        isEmptyState,

        // Actions
        setSelectedPatientId,
        setSearchTerm,
        handlePatientClick,
        handlePageChange,
        handlePatientAction,
        toggleNoteExpansion,
        toggleSidebar,
        toggleHealthDashboard,
        handleEditClick,
        handleEditChange,
        handleEditSubmit,
        handleRegenerateSummary,
        handleDeleteClick,
        downloadPDF,
        openCreatePatientModal,
        closeCreatePatientModal,
        handlePatientCreated,
        setEditingNote,
        handleUpdateTreatmentPlan,
        handleUploadTreatmentPlan,
        handleGenerateTreatmentPlan,
        handleGenerateTreatmentPlanWithInstructions,
        handleDeleteTreatmentPlan,
        handleGetTreatmentPlan,
        createPatient,
        handleSaveClick,
        handleCreateDemoPatient,
        downloadTreatmentPlanPdf,
    };

    return (
        <PatientTabContext.Provider value={value}>
            {children}
        </PatientTabContext.Provider>
    );
};

export const usePatientTab = () => {
    const context = useContext(PatientTabContext);
    if (context === undefined) {
        throw new Error('usePatientTab must be used within a PatientTabProvider');
    }
    return context;
};
