import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { supabase } from '../services/supabaseClient';
import { useAuthContext } from '../contexts/AuthContext';

interface TherapistProfile {
    id: string;
    full_name: string;
    profile_picture_url: string | null;
    is_public: boolean;
    [key: string]: any; 
}

interface UseTherapistProfileReturn {
    profile: TherapistProfile | null;
    editedProfile: TherapistProfile | null;
    isEditing: boolean;
    error: string | null;
    loading: boolean;
    uploadingImage: boolean;
    handleEdit: () => void;
    handleCancel: () => void;
    handleSave: () => Promise<void>;
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleArrayChange: (name: string, value: string[] | string) => void;
    handleImageUpload: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
    handleRemoveImage: () => void;
    handleTogglePublic: () => Promise<void>;
}

const useTherapistProfile = (): UseTherapistProfileReturn => {
    const [profile, setProfile] = useState<TherapistProfile | null>(null);
    const [editedProfile, setEditedProfile] = useState<TherapistProfile | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [uploadingImage, setUploadingImage] = useState<boolean>(false);
    const { user } = useAuthContext();

    const fetchProfile = useCallback(async () => {
        try {
            if (!user?.id) return;
            setLoading(true);
            const { data, error } = await supabase
                .from('therapists')
                .select('*')
                .eq('id', user.id)
                .single();

            if (error) throw error;

            setProfile(data);
            setEditedProfile(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching profile:', error);
            setError('Failed to load profile');
        } finally {
            setLoading(false);
        }
    }, [user?.id]);

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    const handleEdit = () => setIsEditing(true);

    const handleCancel = () => {
        setIsEditing(false);
        setEditedProfile(profile);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEditedProfile(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleArrayChange = (name: string, value: string[] | string) => {
        setEditedProfile(prev => prev ? ({
            ...prev,
            [name]: value
        }) : null);
    };

    const handleSave = async () => {
        try {
            if (!user?.id || !editedProfile) return;
            setLoading(true);

            // Update therapist profile
            const { error: profileError } = await supabase
                .from('therapists')
                .update(editedProfile)
                .eq('id', user.id);

            if (profileError) throw profileError;

            // Update auth user metadata
            const { error: authError } = await supabase.auth.updateUser({
                data: {
                    full_name: editedProfile.full_name
                }
            });

            if (authError) throw authError;

            // Fetch updated profile
            const { data: updatedProfile, error: fetchError } = await supabase
                .from('therapists')
                .select('*')
                .eq('id', user.id)
                .single();

            if (fetchError) throw fetchError;

            setProfile(updatedProfile);
            setIsEditing(false);
            setError(null);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            if (!event.target.files || !event.target.files[0] || !profile) return;
            setUploadingImage(true);
            const file = event.target.files[0];
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `${profile.id}/${fileName}`;

            let { error: uploadError } = await supabase.storage
                .from('therapist_profile_pictures')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            const { data } = supabase.storage
                .from('therapist_profile_pictures')
                .getPublicUrl(filePath);

            setEditedProfile(prev => prev ? ({ ...prev, profile_picture_url: data.publicUrl }) : null);
        } catch (error) {
            console.error('Error uploading image:', error);
            setError(`Failed to upload image: ${error instanceof Error ? error.message : 'Unknown error'}`);
        } finally {
            setUploadingImage(false);
        }
    };

    const handleRemoveImage = () => {
        setEditedProfile(prev => prev ? ({ ...prev, profile_picture_url: null }) : null);
    };

    const handleTogglePublic = async () => {
        try {
            if (!profile) return;
            const updatedIsPublic = !profile.is_public;
            const { error } = await supabase
                .from('therapists')
                .update({ is_public: updatedIsPublic })
                .eq('id', profile.id)
                .single();

            if (error) throw error;

            setProfile(prev => prev ? ({ ...prev, is_public: updatedIsPublic }) : null);
            setEditedProfile(prev => prev ? ({ ...prev, is_public: updatedIsPublic }) : null);
        } catch (error) {
            console.error('Error updating profile visibility:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        }
    };

    return {
        profile,
        editedProfile,
        isEditing,
        error,
        loading,
        uploadingImage,
        handleEdit,
        handleCancel,
        handleSave,
        handleChange,
        handleArrayChange,
        handleImageUpload,
        handleRemoveImage,
        handleTogglePublic
    };
};

export default useTherapistProfile;
