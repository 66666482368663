import React, { useState, useEffect } from 'react';
import { BarChart2, Activity, Heart, Calendar } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { api } from '../../../services/api';
import { MoodEntry, MoodStats, MoodEntryData } from '../../../types/patientDataTypes';
import MoodEntryForm from './MoodEntryForm';
import MoodList from './MoodList';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "@/components/ui/pagination";
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";

interface WellnessTabProps {
    userEmail: string;
}

const WellnessTab: React.FC<WellnessTabProps> = ({ userEmail }) => {
    const [showEntryForm, setShowEntryForm] = useState(false);
    const [moodEntries, setMoodEntries] = useState<MoodEntry[]>([]);
    const [moodStats, setMoodStats] = useState<MoodStats | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editingEntry, setEditingEntry] = useState<MoodEntry | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const PER_PAGE = 4;

    const fetchMoodData = async () => {
        setIsLoading(true);
        try {
            const [entriesResponse, stats] = await Promise.all([
                api.patients.getMoodEntries(userEmail, { page, per_page: PER_PAGE }),
                api.patients.getMoodStats(userEmail)
            ]);
            setMoodEntries(entriesResponse.data);
            setTotalEntries(entriesResponse.total);
            setMoodStats(stats);
        } catch (error) {
            console.error('Error fetching mood data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMoodData();
    }, [userEmail, page]);

    const handleMoodSubmit = async (entry: MoodEntryData) => {
        try {
            if (editingEntry) {
                await api.patients.updateMoodEntry(userEmail, editingEntry.id, entry);
            } else {
                await api.patients.addMoodEntry(userEmail, entry);
            }

            // Immediately fetch updated data
            const [entriesResponse, statsResponse] = await Promise.all([
                api.patients.getMoodEntries(userEmail, { page: 1, per_page: PER_PAGE }),
                api.patients.getMoodStats(userEmail)
            ]);

            // Update all states with new data
            setMoodEntries(entriesResponse.data);
            setTotalEntries(entriesResponse.total);
            setMoodStats(statsResponse);
            setPage(1);
            setShowEntryForm(false);
            setEditingEntry(undefined);
        } catch (error) {
            console.error('Error saving mood entry:', error);
        }
    };

    const handleDeleteEntry = async (entryId: string) => {
        try {
            await api.patients.deleteMoodEntry(userEmail, entryId);

            // Calculate if we need to go to the previous page
            const isLastItemOnPage = moodEntries.length === 1 && page > 1;
            const newPage = isLastItemOnPage ? page - 1 : page;

            // Fetch new data with adjusted page
            const [entriesResponse, statsResponse] = await Promise.all([
                api.patients.getMoodEntries(userEmail, { page: newPage, per_page: PER_PAGE }),
                api.patients.getMoodStats(userEmail)
            ]);

            setMoodEntries(entriesResponse.data);
            setTotalEntries(entriesResponse.total);
            setMoodStats(statsResponse);
            setPage(newPage);
        } catch (error) {
            console.error('Error deleting mood entry:', error);
        }
    };

    const handleEditEntry = (entry: MoodEntry) => {
        setEditingEntry(entry);
        setShowEntryForm(true);
    };

    const handlePageChange = async (newPage: number) => {
        setPage(newPage);
        setIsLoading(true);
        try {
            const entriesResponse = await api.patients.getMoodEntries(userEmail, {
                page: newPage,
                per_page: PER_PAGE
            });
            setMoodEntries(entriesResponse.data);
            setTotalEntries(entriesResponse.total);
        } catch (error) {
            console.error('Error fetching mood entries:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading && !moodStats) {
        return (
            <div className="space-y-6">
                {/* Title Skeleton */}
                <div className="flex items-center justify-between">
                    <Skeleton className="h-8 w-48" />
                </div>

                {/* Welcoming Header Section Skeleton */}
                <div className="bg-primary/5 rounded-xl p-6 shadow-sm border border-gray-100">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                        <div className="space-y-2">
                            <Skeleton className="h-8 w-64" />
                            <Skeleton className="h-5 w-96" />
                        </div>
                        <Skeleton className="h-12 w-48" />
                    </div>
                </div>

                {/* Main Content Grid Skeleton */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Mood Insights Card Skeleton */}
                    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                        <div className="flex items-center gap-2 mb-4">
                            <Skeleton className="h-6 w-6" />
                            <Skeleton className="h-6 w-48" />
                        </div>
                        <div className="space-y-6 mt-4">
                            <div className="grid grid-cols-2 gap-4">
                                <Skeleton className="h-24 w-full rounded-xl" />
                                <Skeleton className="h-24 w-full rounded-xl" />
                            </div>
                            <div className="space-y-3">
                                <Skeleton className="h-5 w-64" />
                                <div className="flex flex-wrap gap-2">
                                    {[1, 2, 3].map((i) => (
                                        <Skeleton key={i} className="h-8 w-24 rounded-full" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Mood Trends Chart Skeleton */}
                    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                        <div className="flex items-center gap-2 mb-4">
                            <Skeleton className="h-6 w-6" />
                            <Skeleton className="h-6 w-48" />
                        </div>
                        <Skeleton className="h-[300px] w-full mt-4" />
                    </div>
                </div>

                {/* Recent Entries Skeleton */}
                <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                    <div className="flex items-center gap-2 mb-4">
                        <Skeleton className="h-6 w-6" />
                        <Skeleton className="h-6 w-48" />
                    </div>
                    <div className="space-y-4">
                        {[1, 2, 3, 4].map((i) => (
                            <Skeleton key={i} className="h-24 w-full rounded-xl" />
                        ))}
                    </div>
                    <div className="mt-4 flex justify-center">
                        <Skeleton className="h-10 w-64" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            {/* Title */}
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-normal text-primary">Wellness Overview</h2>
            </div>

            {/* Welcoming Header Section */}
            <div className="bg-primary/5 rounded-xl p-6 shadow-sm border border-gray-100">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                    <div className="space-y-2">
                        <h3 className="text-2xl font-normal text-gray-800">Your Wellness Journey</h3>
                        <p className="text-gray-600 font-light">
                            {totalEntries > 0
                                ? `You've shared ${totalEntries} moments with us so far. How are you feeling today?`
                                : "Start tracking your wellness journey today"}
                        </p>
                    </div>
                    <button
                        onClick={() => setShowEntryForm(true)}
                        className="flex items-center gap-2 bg-primary hover:bg-primary/90 font-light text-white px-6 py-3 rounded-xl transition-all duration-200 shadow-sm hover:shadow-md"
                    >
                        <Heart className="w-5 h-5" />
                        <span>How are you feeling?</span>
                    </button>
                </div>
            </div>

            {/* Main Content Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Mood Insights Card */}
                <DashboardCard
                    title="Your Mood Pattern"
                    icon={<Activity className="text-purple-500" />}
                    titleClass="text-gray-800"
                >
                    <div className="space-y-6 mt-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="bg-purple-50 rounded-xl p-4 text-center">
                                <p className="text-gray-600 text-sm mb-1">Average Mood</p>
                                <p className="text-2xl font-light text-purple-600">
                                    {moodStats?.average_mood ? moodStats.average_mood.toFixed(1) : '-'}
                                </p>
                            </div>
                            <div className="bg-blue-50 rounded-xl p-4 text-center">
                                <p className="text-gray-600 text-sm mb-1">Entries This Week</p>
                                <p className="text-2xl font-light text-blue-600">
                                    {moodStats?.entries_this_week || '-'}
                                </p>
                            </div>
                        </div>

                        <div className="space-y-3">
                            <p className="text-sm text-gray-600">Your most commonly logged activities:</p>
                            <div className="flex flex-wrap gap-2">
                                {moodStats?.common_activities.map(({ activity, count }) => (
                                    <span
                                        key={activity}
                                        className="px-3 py-1.5 bg-gray-50 text-gray-700 rounded-full text-sm flex items-center gap-2"
                                    >
                                        <span>{activity}</span>
                                        <span className="bg-gray-200 text-gray-600 px-1.5 py-0.5 rounded-full text-xs">
                                            {count}
                                        </span>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </DashboardCard>

                {/* Mood Trends Chart */}
                <DashboardCard
                    title="Your Mood Journey"
                    icon={<BarChart2 className="text-purple-500" />}
                    titleClass="text-gray-800"
                >
                    {moodStats && moodStats.mood_trends && moodStats.mood_trends.length > 0 ? (
                        <div className="h-[300px] mt-4">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={moodStats.mood_trends}>
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                        stroke="#9CA3AF"
                                        reversed={true}
                                    />
                                    <YAxis domain={[1, 5]} stroke="#9CA3AF" ticks={[1, 2, 3, 4, 5]} />
                                    <Tooltip
                                        contentStyle={{
                                            backgroundColor: 'white',
                                            border: '1px solid #E5E7EB',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                                        }}
                                        labelFormatter={(date) => format(new Date(date), 'MMMM d, yyyy')}
                                        formatter={(value) => [`Mood: ${value}`, '']}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="score"
                                        stroke="#8B5CF6"
                                        strokeWidth={3}
                                        dot={false}
                                        activeDot={{ r: 8, fill: "#8B5CF6" }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    ) : (
                        <div className="h-[300px] flex items-center justify-center">
                            <p className="text-gray-500 font-light">Start logging to see your mood journey</p>
                        </div>
                    )}
                </DashboardCard>
            </div>

            {/* Recent Entries with Calendar View */}
            <DashboardCard
                title="Your Recent Check-ins"
                icon={<Calendar className="text-primary" />}
                titleClass="text-gray-800"
            >
                <MoodList
                    entries={moodEntries}
                    onDelete={handleDeleteEntry}
                    onEdit={handleEditEntry}
                    total={totalEntries}
                    page={page}
                    perPage={PER_PAGE}
                    onPageChange={handlePageChange}
                    isLoading={isLoading}
                    renderPagination={(currentPage, totalPages) => (
                        <Pagination className="mt-4">
                            <PaginationContent>
                                <PaginationItem>
                                    <PaginationPrevious
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        className={cn(
                                            "font-light",
                                            currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"
                                        )}
                                    />
                                </PaginationItem>

                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                                    <PaginationItem key={pageNum}>
                                        <PaginationLink
                                            onClick={() => handlePageChange(pageNum)}
                                            isActive={currentPage === pageNum}
                                            className={cn(
                                                "cursor-pointer font-light",
                                                currentPage === pageNum ? "font-normal" : "font-light"
                                            )}
                                        >
                                            {pageNum}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}

                                <PaginationItem>
                                    <PaginationNext
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        className={cn(
                                            "font-light",
                                            currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"
                                        )}
                                    />
                                </PaginationItem>
                            </PaginationContent>
                        </Pagination>
                    )}
                />
            </DashboardCard>

            {/* Keep Modal Form the same */}
            {showEntryForm && (
                <MoodEntryForm
                    onSubmit={handleMoodSubmit}
                    onClose={() => {
                        setShowEntryForm(false);
                        setEditingEntry(undefined);
                    }}
                    initialData={editingEntry}
                />
            )}
        </div>
    );
};

export default WellnessTab; 