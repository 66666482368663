import React from 'react';
import { Link } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import { StatCardProps, QuickActionButtonProps, ResourceCardProps } from '../../../types/patientDataTypes';

export const StatCard: React.FC<StatCardProps> = ({
    icon, title, value, description, color, link, comingSoon
}) => (
    <div className="bg-white p-6 rounded-lg shadow-md relative group">
        <div className="flex items-center justify-between">
            <div className={`text-2xl ${color}`}>{icon}</div>
            {link && !comingSoon && (
                <Link to={link} className="text-primary hover:underline text-sm">
                    View All
                </Link>
            )}
        </div>
        <h3 className="text-lg font-normal mt-2">{title}</h3>
        <p className="text-3xl font-light text-gray-800 mt-2">{value}</p>
        <p className="text-sm text-gray-500 font-light mt-1">{description}</p>
        {comingSoon && (
            <div className="absolute inset-0 bg-gray-50/80 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <div className="flex items-center text-gray-500 font-light">
                    <FiLock className="mr-2" /> Coming Soon
                </div>
            </div>
        )}
    </div>
);

export const QuickActionButton: React.FC<QuickActionButtonProps> = ({
    icon, label, onClick, count, comingSoon = false
}) => (
    <button
        onClick={onClick}
        disabled={comingSoon}
        className="relative flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200 disabled:cursor-not-allowed group"
    >
        <div className="relative">
            <span className="text-2xl text-primary">{icon}</span>
            {count && count > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {count}
                </span>
            )}
        </div>
        <span className="mt-2 text-sm font-light text-gray-700">{label}</span>
        {comingSoon && (
            <div className="absolute inset-0 bg-gray-50/80 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <div className="flex items-center text-gray-500 font-light">
                    <FiLock className="mr-2" /> Coming Soon
                </div>
            </div>
        )}
    </button>
);

export const ResourceCard: React.FC<ResourceCardProps> = ({
    title, description, icon, link
}) => (
    <div className="relative group">
        <a
            href={link}
            className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-200"
        >
            <div className="flex items-center mb-2">
                <span className="text-primary mr-2">{icon}</span>
                <h4 className="font-normal">{title}</h4>
            </div>
            <p className="text-sm text-gray-600 font-light">{description}</p>
        </a>
        <div className="absolute inset-0 bg-gray-50/90 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <div className="flex items-center text-gray-500 font-light">
                <FiLock className="mr-2" /> Coming Soon
            </div>
        </div>
    </div>
);