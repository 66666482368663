import React, { useState } from 'react';
import { format, parseISO, differenceInDays, eachDayOfInterval, isWithinInterval } from 'date-fns';
import { Droplet, Calendar, Moon, Sparkles, Heart } from 'lucide-react';
import { DashboardCard } from './TherapistHealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { MenstruationData } from '../../../../../types';

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

interface MenstruationTabProps {
    menstruationData: MenstruationData[];
}

// Add NoDataDisplay component
const NoDataDisplay = () => (
    <div className="h-64 flex items-center justify-center text-gray-500 font-light">
        No data available
    </div>
);

// Add CustomTooltip component
const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                <p className="font-light text-gray-600 mb-1">{label}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} className="text-sm font-light" style={{ color: entry.color }}>
                        {entry.name}: {entry.value} days
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

// Add these interfaces at the top with other interfaces
interface CyclePhase {
    phase: string;
    daysLeft: number;
    color: string;
}

interface FertilityWindow {
    status: string;
    detail: string;
    color: string;
}

const MenstruationTab: React.FC<MenstruationTabProps> = ({ menstruationData }) => {
    if (!menstruationData || menstruationData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No menstruation data available.</p>;
    }

    // Get latest data
    const latestData = menstruationData[0]?.menstruation_data || {};

    // Calculate stats
    const calculateStats = () => {
        const validCycles = menstruationData.filter(entry =>
            entry.menstruation_data?.cycle_length_days &&
            entry.menstruation_data.cycle_length_days > 0
        );
        const validPeriods = menstruationData.filter(entry =>
            entry.menstruation_data?.period_length_days &&
            entry.menstruation_data.period_length_days > 0
        );

        return {
            avgCycleLength: validCycles.length > 0
                ? Math.round(validCycles.reduce((sum, entry) =>
                    sum + (entry.menstruation_data.cycle_length_days || 0), 0) / validCycles.length)
                : null,
            avgPeriodLength: validPeriods.length > 0
                ? Math.round(validPeriods.reduce((sum, entry) =>
                    sum + (entry.menstruation_data.period_length_days || 0), 0) / validPeriods.length)
                : null,
            totalCycles: validCycles.length,
            daysUntilNext: latestData.period_start_date && latestData.predicted_cycle_length_days
                ? differenceInDays(
                    new Date(parseISO(latestData.period_start_date).setDate(
                        parseISO(latestData.period_start_date).getDate() +
                        (latestData.predicted_cycle_length_days || 0)
                    )),
                    new Date()
                )
                : null
        };
    };

    const stats = calculateStats();

    // Add calculateCyclePhase function
    const calculateCyclePhase = (dayInCycle: number, avgCycleLength: number): CyclePhase | null => {
        if (!dayInCycle || !avgCycleLength) return null;

        const menstrualPhase = 5;
        const follicularPhase = 10;
        const ovulatoryPhase = 3;
        const lutealPhase = avgCycleLength - (menstrualPhase + follicularPhase + ovulatoryPhase);

        if (dayInCycle <= menstrualPhase) {
            return {
                phase: 'Menstrual',
                daysLeft: menstrualPhase - dayInCycle + 1,
                color: 'text-pink-500'
            };
        } else if (dayInCycle <= menstrualPhase + follicularPhase) {
            return {
                phase: 'Follicular',
                daysLeft: (menstrualPhase + follicularPhase) - dayInCycle + 1,
                color: 'text-pink-500'
            };
        } else if (dayInCycle <= menstrualPhase + follicularPhase + ovulatoryPhase) {
            return {
                phase: 'Ovulatory',
                daysLeft: (menstrualPhase + follicularPhase + ovulatoryPhase) - dayInCycle + 1,
                color: 'text-pink-500'
            };
        } else {
            return {
                phase: 'Luteal',
                daysLeft: avgCycleLength - dayInCycle + 1,
                color: 'text-pink-500'
            };
        }
    };

    // Add calculateFertilityWindow function
    const calculateFertilityWindow = (dayInCycle: number, avgCycleLength: number): FertilityWindow | null => {
        if (!dayInCycle || !avgCycleLength) return null;

        const fertileStart = 11;
        const fertileEnd = 17;
        const ovulationDay = 14;

        if (dayInCycle >= fertileStart && dayInCycle <= fertileEnd) {
            return {
                status: 'Fertile Window',
                detail: dayInCycle === ovulationDay ? 'Ovulation Day' :
                    dayInCycle < ovulationDay ? `${ovulationDay - dayInCycle} days until ovulation` :
                        `${dayInCycle - ovulationDay} days past ovulation`,
                color: 'text-pink-500'
            };
        }

        const daysUntilFertile = dayInCycle < fertileStart ?
            fertileStart - dayInCycle :
            (avgCycleLength - dayInCycle) + fertileStart;

        return {
            status: 'Low Fertility',
            detail: `${daysUntilFertile} days until fertile window`,
            color: 'text-gray-500'
        };
    };

    // Process monthly period length data for the bar chart
    const getMonthlyPeriodData = () => {
        const monthlyData = new Array(12).fill(null).map((_, index) => {
            const month = new Date();
            month.setMonth(month.getMonth() - (11 - index)); // Get last 12 months

            const monthData = menstruationData.filter(entry => {
                const entryDate = parseISO(entry.metadata.start_time);
                return entryDate.getMonth() === month.getMonth() &&
                    entryDate.getFullYear() === month.getFullYear();
            });

            return {
                month: format(month, 'MMM'),
                periodLength: monthData.length > 0
                    ? Math.round(monthData.reduce((acc, curr) =>
                        acc + (curr.menstruation_data.period_length_days || 0), 0) / monthData.length)
                    : 0
            };
        });

        return monthlyData;
    };

    // Add CyclePhaseTimeline component
    const CyclePhaseTimeline: React.FC<{ menstruationData: MenstruationData[] }> = ({ menstruationData }) => {
        if (!menstruationData || menstruationData.length === 0) {
            return <NoDataDisplay />;
        }

        const entry = menstruationData[0];
        const cycleStartDate = parseISO(entry.menstruation_data.period_start_date || entry.metadata.start_time);
        const cycleEndDate = new Date(cycleStartDate);
        cycleEndDate.setDate(cycleStartDate.getDate() + (entry.menstruation_data.cycle_length_days || 28));

        const days = eachDayOfInterval({ start: cycleStartDate, end: cycleEndDate });

        const phaseConfig = {
            menstruation: {
                color: 'bg-pink-500',
                hoverColor: 'hover:bg-pink-600',
                label: 'Menstruation',
                height: 'h-16'
            },
            fertility: {
                color: 'bg-blue-400',
                hoverColor: 'hover:bg-blue-500',
                label: 'Fertility Window',
                height: 'h-12'
            },
            ovulation: {
                color: 'bg-emerald-400',
                hoverColor: 'hover:bg-emerald-500',
                label: 'Ovulation Day',
                height: 'h-20'
            },
            regular: {
                color: 'bg-gray-200',
                hoverColor: 'hover:bg-gray-300',
                label: 'Regular Phase',
                height: 'h-8'
            }
        };

        return (
            <div className="flex flex-col h-full">
                {/* Legend - Made more compact */}
                <div className="flex flex-wrap gap-2 justify-center mb-2">
                    {Object.entries(phaseConfig).map(([phase, config]) => (
                        <div key={phase} className="flex items-center gap-1">
                            <div className={`w-2 h-2 rounded-full ${config.color}`} />
                            <span className="text-xs text-gray-600 font-light">{config.label}</span>
                        </div>
                    ))}
                </div>

                {/* Timeline - Adjusted container and spacing */}
                <div className="relative flex-1 px-2">
                    <div className="grid grid-cols-[repeat(28,1fr)] gap-0.5 min-h-[180px]">
                        {days.map((date, index) => {
                            let phase: keyof typeof phaseConfig = 'regular';

                            // Determine phase
                            if (entry.menstruation_data.period_end_date &&
                                isWithinInterval(date, {
                                    start: cycleStartDate,
                                    end: parseISO(entry.menstruation_data.period_end_date)
                                })) {
                                phase = 'menstruation';
                            }

                            if (entry.menstruation_data.fertility_window_start &&
                                entry.menstruation_data.fertility_window_end &&
                                isWithinInterval(date, {
                                    start: parseISO(entry.menstruation_data.fertility_window_start),
                                    end: parseISO(entry.menstruation_data.fertility_window_end)
                                })) {
                                phase = 'fertility';
                            }

                            if (entry.menstruation_data.ovulation_day &&
                                format(date, 'yyyy-MM-dd') === format(parseISO(entry.menstruation_data.ovulation_day), 'yyyy-MM-dd')) {
                                phase = 'ovulation';
                            }

                            const config = phaseConfig[phase];

                            return (
                                <div key={index} className="relative group">
                                    <div className={`
                                        ${config.color} 
                                        ${config.hoverColor} 
                                        h-full
                                        rounded 
                                        transition-all 
                                        duration-200
                                        transform
                                        group-hover:scale-105
                                    `}>
                                        {/* Tooltip */}
                                        <div className="opacity-0 group-hover:opacity-100 absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 bg-white text-gray-800 text-xs rounded py-1 px-2 shadow-lg border border-gray-100 whitespace-nowrap z-10">
                                            <p className="text-xs">{format(date, 'MMM dd')}</p>
                                            <p className="text-xs text-gray-500">{config.label}</p>
                                        </div>
                                    </div>
                                    {/* Date labels - Show fewer labels */}
                                    {index % 14 === 0 && (
                                        <div className="absolute top-full mt-1 left-0 text-[10px] text-gray-500 transform -translate-x-1/2">
                                            {format(date, 'MMM dd')}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-12 gap-4">
                {/* Cycle Phase Information */}
                <div className="col-span-4">
                    <DashboardCard
                        title="Current Cycle Phase"
                        titleClass="text-lg font-light"
                        icon={<Moon className="text-pink-500" />}
                    >
                        <div className="h-64 flex flex-col justify-center items-center space-y-6">
                            {latestData.day_in_cycle && stats.avgCycleLength ? (
                                <>
                                    <div className="text-center">
                                        <p className="text-3xl font-light mb-2">
                                            Day {latestData.day_in_cycle}
                                        </p>
                                        <p className="text-sm text-gray-500 font-light">
                                            of {stats.avgCycleLength} day cycle
                                        </p>
                                    </div>

                                    {(() => {
                                        const phase = calculateCyclePhase(
                                            latestData.day_in_cycle,
                                            stats.avgCycleLength
                                        );
                                        return phase && (
                                            <div className="text-center">
                                                <p className={`text-xl ${phase.color} font-light mb-1`}>
                                                    {phase.phase} Phase
                                                </p>
                                                <p className="text-sm text-gray-500 font-light">
                                                    {phase.daysLeft} days remaining
                                                </p>
                                            </div>
                                        );
                                    })()}

                                    {(() => {
                                        const fertility = calculateFertilityWindow(
                                            latestData.day_in_cycle,
                                            stats.avgCycleLength
                                        );
                                        return fertility && (
                                            <div className="text-center">
                                                <p className={`text-lg ${fertility.color} font-light mb-1`}>
                                                    {fertility.status}
                                                </p>
                                                <p className="text-sm text-gray-500 font-light">
                                                    {fertility.detail}
                                                </p>
                                            </div>
                                        );
                                    })()}
                                </>
                            ) : <NoDataDisplay />}
                        </div>
                    </DashboardCard>
                </div>

                {/* Replace Period Length History with Cycle Timeline */}
                <div className="col-span-4">
                    <DashboardCard
                        title="Cycle Phases"
                        titleClass="text-lg font-light"
                        icon={<Calendar className="text-pink-500" />}
                    >
                        <div className="h-[250px]"> {/* Adjusted fixed height */}
                            <CyclePhaseTimeline menstruationData={menstruationData} />
                        </div>
                    </DashboardCard>
                </div>

                {/* Summary Stats Cards */}
                <div className="col-span-4 grid grid-cols-2 gap-4 h-64">
                    {[
                        ['Cycle Length', 'Period Length'],
                        ['Next Period', 'Total Cycles']
                    ].map((row, rowIndex) => (
                        row.map((metric, colIndex) => {
                            let value;
                            let suffix = 'd';
                            let periodLabel = 'Average';

                            const validPeriods = menstruationData.filter(entry =>
                                entry.menstruation_data?.period_length_days != null &&
                                entry.menstruation_data?.period_length_days > 0
                            );

                            const validCycles = menstruationData.filter(entry =>
                                entry.menstruation_data?.cycle_length_days != null &&
                                entry.menstruation_data?.cycle_length_days > 0
                            );

                            switch (metric) {
                                case 'Cycle Length':
                                    value = validCycles.length > 0
                                        ? Math.round(validCycles.reduce((sum, entry) =>
                                            sum + (entry.menstruation_data?.cycle_length_days || 0), 0) / validCycles.length)
                                        : null;
                                    break;
                                case 'Period Length':
                                    value = validPeriods.length > 0
                                        ? Math.round(validPeriods.reduce((sum, entry) =>
                                            sum + (entry.menstruation_data?.period_length_days || 0), 0) / validPeriods.length)
                                        : null;
                                    break;
                                case 'Next Period':
                                    value = stats.daysUntilNext;
                                    periodLabel = 'Days Until';
                                    break;
                                case 'Total Cycles':
                                    value = validCycles.length;
                                    suffix = '';
                                    periodLabel = 'Recorded';
                                    break;
                            }

                            return (
                                <div key={metric}
                                    className="bg-white rounded-lg p-4 shadow-sm border border-gray-100 
                                             flex flex-col justify-center">
                                    <h3 className="text-gray-500 text-sm font-light">{metric}</h3>
                                    <p className={`text-xl font-light mt-1 ${value === null ? 'text-gray-400' : 'text-gray-900'}`}>
                                        {value !== null ? `${value}${suffix}` : 'N/A'}
                                    </p>
                                    <p className="text-xs text-gray-400 mt-1">{periodLabel}</p>
                                </div>
                            );
                        })
                    ))}
                </div>
            </div>

            {/* Add Cycle Information Summary below */}
            <DashboardCard
                title="Cycle Information"
                titleClass="text-lg font-light"
                icon={<Heart className="text-pink-500" />}
            >
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                    <div className="text-center p-2" data-testid="current-cycle-day">
                        <p className="text-sm text-gray-500 font-light mb-1">Current Cycle Day</p>
                        <p className="text-xl sm:text-2xl font-light text-pink-500">
                            {latestData?.day_in_cycle || 'N/A'}
                        </p>
                    </div>
                    <div className="text-center p-2">
                        <p className="text-sm text-gray-500 font-light mb-1">Avg. Cycle Length</p>
                        <p className="text-xl sm:text-2xl font-light text-pink-500">
                            {stats.avgCycleLength ? `${stats.avgCycleLength}d` : 'N/A'}
                        </p>
                    </div>
                    <div className="text-center p-2">
                        <p className="text-sm text-gray-500 font-light mb-1">Avg. Period Length</p>
                        <p className="text-xl sm:text-2xl font-light text-pink-500">
                            {stats.avgPeriodLength ? `${stats.avgPeriodLength}d` : 'N/A'}
                        </p>
                    </div>
                    <div className="text-center p-2">
                        <p className="text-sm text-gray-500 font-light mb-1">Days Until Next</p>
                        <p className="text-xl sm:text-2xl font-light text-pink-500">
                            {stats.daysUntilNext ? `${stats.daysUntilNext}d` : 'N/A'}
                        </p>
                    </div>
                    <div className="text-center p-2">
                        <p className="text-sm text-gray-500 font-light mb-1">Total Cycles</p>
                        <p className="text-xl sm:text-2xl font-light text-pink-500">
                            {stats.totalCycles || 'N/A'}
                        </p>
                    </div>
                </div>
            </DashboardCard>
        </div>
    );
};

export default MenstruationTab;
