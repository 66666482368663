import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePageMobile from './pages/HomePageMobile';
import HomePageDesktop from './pages/HomePageDesktop';
import GetStartedPage from './pages/GetStartedPage';
import AdminDashboard from './pages/AdminDashboard';
import TherapistDashboard from './pages/TherapistDashboard';
import ProfessionalInfo from './components/TherapistOnboarding/ProfessionalInfo';
import PracticeDetails from './components/TherapistOnboarding/PracticeDetails';
import BioAndApproach from './components/TherapistOnboarding/BioAndApproach';
import VerificationSubmission from './components/TherapistOnboarding/VerificationSubmission';
import PendingApproval from './components/TherapistOnboarding/PendingApproval';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Navbar from './components/Shared/Navbar';
import Login from './pages/Login';
import ContactUsMobile from './components/HomePageMobile/ContactUsMobile';
import ContactUsDesktop from './components/HomePageDesktop/ContactUs';
import CompleteProfile from './components/PatientOnboarding/CompleteProfile';
import SignUp from './pages/SignUp';
import PatientDashboard from './pages/PatientDashboard';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import AuthCallback from './components/Shared/AuthCallback';
import PrivacyPolicy from './components/HomePageMobile/PrivacyPolicyMobile';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </QueryClientProvider>
  );
}


function AppContent() {
  const { session, user, isLoading, isAdmin, fetchUserData } = useAuthContext();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (session && user) {
      fetchUserData();
    }
  }, [session, user, fetchUserData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={isDesktop ? <HomePageDesktop /> : <HomePageMobile />} />
        <Route path="/get-started" element={<GetStartedPage />} />
        <Route path="/patient-signup" element={<SignUp role="patient" />} />
        <Route path="/therapist-signup" element={<SignUp role="therapist" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/contact" element={isDesktop ? <ContactUsDesktop /> : <ContactUsMobile />} />
        <Route path="/complete-profile" element={
          session && !user?.user_metadata?.profile_completed
            ? <CompleteProfile />
            : <Navigate to="/dashboard" replace />
        } />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            session
              ? isAdmin
                ? <Navigate to="/admin-dashboard" replace />
                : <PatientDashboard userEmail={user?.email} />
              : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/admin-dashboard"
          element={session && isAdmin ? <AdminDashboard /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/therapist"
          element={
            session && user?.user_metadata?.role === 'therapist'
              ? <TherapistDashboard />
              : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/therapist-onboarding/*"
          element={
            session && user?.user_metadata?.role === 'therapist' ?
              <Routes>
                <Route path="professional-info" element={<ProfessionalInfo />} />
                <Route path="practice-details" element={<PracticeDetails />} />
                <Route path="bio-and-approach" element={<BioAndApproach />} />
                <Route path="verification-submission" element={<VerificationSubmission />} />
                <Route path="pending-approval" element={<PendingApproval />} />
              </Routes> :
              <Navigate to="/login" replace />
          }
        />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
