import React from 'react';
import { FiUser, FiCalendar, FiPhone, FiHeart, FiWind, FiCoffee } from 'react-icons/fi';
import { AdminProfile } from '../../types/adminTypes';

const defaultAvatar = require('../../assets/default-avatar.png');


interface ProfileModalProps {
    profile: AdminProfile;
    onClose: () => void;
    isTherapist: boolean;
}

interface ProfileItemProps {
    icon: React.ReactNode;
    label: string;
    value?: string | number;
}

interface ProfileSectionProps {
    title: string;
    items?: string[];
}

// Format phone number function remains the same
const formatPhoneNumber = (value?: string): string => {
    if (!value) return value || '';

    const phoneNumber = value.replace(/[^\d]/g, '');

    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

// Component definitions with types
const ProfileModal: React.FC<ProfileModalProps> = ({ profile, onClose, isTherapist }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    {/* Changed: Lighter font for title */}
                    <h2 className="text-2xl font-normal text-primary">Profile Details</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 font-light"
                    >
                        Close
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Left Column - Basic Info and Avatar */}
                    <div className="md:col-span-1">
                        {/* Added: Name and Email above avatar */}
                        <div className="text-center mb-6">
                            {/* Changed: Lighter fonts for name and email */}
                            <h3 className="text-xl font-normal text-gray-800">
                                {profile.full_name}
                            </h3>
                            <p className="text-gray-600 font-light mt-1">
                                {profile.email}
                            </p>
                        </div>

                        {/* Made avatar slightly smaller */}
                        <div className="w-40 h-40 mx-auto mb-4">
                            <img
                                src={profile.profile_picture_url || defaultAvatar}
                                alt="Profile"
                                className="rounded-full w-full h-full object-cover border-4 border-primary"
                                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultAvatar;
                                }}
                            />
                        </div>
                    </div>

                    {/* Right Column - Profile Details */}
                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {!isTherapist && (
                                <>
                                    <ProfileItem icon={<FiCalendar />} label="Date of Birth" value={profile.dob} />
                                    <ProfileItem icon={<FiUser />} label="Gender" value={profile.gender} />
                                </>
                            )}
                            <ProfileItem icon={<FiPhone />} label="Phone" value={profile.phone || profile.phone_number} />
                            {isTherapist && (
                                <>
                                    <ProfileItem icon={<FiUser />} label="License" value={profile.license} />
                                    <ProfileItem icon={<FiUser />} label="Experience" value={`${profile.years_of_experience} years`} />
                                    <ProfileItem icon={<FiUser />} label="Address" value={profile.address} />
                                </>
                            )}
                            {!isTherapist && (
                                <>
                                    <ProfileItem icon={<FiHeart />} label="Primary Reason" value={profile.primary_reason} />
                                    <ProfileItem icon={<FiWind />} label="Alcohol Consumption" value={profile.alcohol_consumption} />
                                    <ProfileItem icon={<FiCoffee />} label="Caffeine Consumption" value={profile.caffeine_consumption} />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {!isTherapist && (
                    <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                        {/* Changed: Lighter fonts for section titles */}
                        <h3 className="text-xl font-normal text-primary mb-2">Mental Health Information</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <h4 className="font-normal mb-2">Mental Health History</h4>
                                {/* Changed: Lighter font for content */}
                                <p className="text-gray-700 font-light">{profile.mental_health_history || 'Not provided'}</p>
                            </div>
                            <div>
                                <h4 className="font-normal mb-2">Current Medications</h4>
                                {/* Changed: Lighter font for content */}
                                <p className="text-gray-700 font-light">{profile.current_medications || 'Not provided'}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h4 className="font-normal mb-2">Mental Health Self-Rating</h4>
                            <div className="flex items-center">
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-2">
                                    <div className="bg-primary h-2.5 rounded-full" style={{ width: `${(profile.mental_health_rating ?? 0) / 5 * 100}%` }}></div>
                                </div>
                                {/* Changed: Lighter font for rating */}
                                <span className="font-light">{profile.mental_health_rating ?? 0}/5</span>
                            </div>
                        </div>
                    </div>
                )}

                {isTherapist && (
                    <>
                        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                            {/* Changed: Lighter fonts for professional info */}
                            <h3 className="text-xl font-normal text-primary mb-2">Professional Information</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <ProfileSection title="Therapy Types" items={profile.therapy_types} />
                                <ProfileSection title="Specializations" items={profile.specializations} />
                                <ProfileSection title="Languages" items={profile.languages} />
                                <ProfileSection title="Age Groups Served" items={profile.age_groups_served} />
                                <ProfileSection title="Education" items={profile.education} />
                                <ProfileSection title="Accepted Insurance Plans" items={profile.insurance_accepted} />
                            </div>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-xl font-normal text-primary mb-2">Professional Bio</h3>
                            {/* Changed: Lighter font for bio */}
                            <p className="text-gray-700 font-light">{profile.professional_bio}</p>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-xl font-normal text-primary mb-2">Therapy Approach</h3>
                            {/* Changed: Lighter font for approach */}
                            <p className="text-gray-700 font-light">{profile.therapy_approach}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const ProfileItem: React.FC<ProfileItemProps> = ({ icon, label, value }) => (
    <div className="flex items-start bg-gray-50 p-3 rounded-lg">
        <div className="text-primary mr-3 text-xl flex-shrink-0 mt-1">{icon}</div>
        <div className="flex-grow min-w-0">
            <span className="font-normal text-gray-700">{label}</span>
            <p className="mt-1 text-gray-900 break-words max-h-20 overflow-y-auto font-light">
                {/* Format phone number if this is a phone field */}
                {label === "Phone" ? formatPhoneNumber(value?.toString()) || 'N/A' : value || 'N/A'}
            </p>
        </div>
    </div>
);

const ProfileSection: React.FC<ProfileSectionProps> = ({ title, items }) => (
    <div>
        <h4 className="font-normal mb-2">{title}</h4>
        <div className="flex flex-wrap gap-2">
            {items && items.map((item, index) => (
                <span
                    key={index}
                    className="px-3 py-1.5 bg-white text-primary rounded-lg text-sm font-light
                     border border-primary/30 hover:border-primary/50 hover:bg-primary/5 
                     transition-all duration-200"
                >
                    {item}
                </span>
            ))}
        </div>
    </div>
);


export default ProfileModal; 