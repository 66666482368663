import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import { useAuthContext } from '../contexts/AuthContext';
import { SubmitButton } from "@/components/Shared/Buttons"

const OasysLogo = require('../assets/oasys-logo2.png');

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { verifyOtp, updatePassword, signOut } = useAuthContext();

  useEffect(() => {
    const verifyToken = async () => {
      const tokenHash = new URLSearchParams(location.search).get('token_hash');
      if (tokenHash) {
        const { error } = await verifyOtp(tokenHash);
        if (error) {
          console.error('Error:', error);
        } else {
          setShowForm(true);
        }
      } else {
        setError('No reset token found. Please request a new password reset link.');
      }
    };

    verifyToken();
  }, [location, verifyOtp]);

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    try {
      const { error } = await updatePassword(newPassword);
      if (error) throw error;

      setError('');
      setMessage('Password successfully reset. Redirecting to login...');
      await signOut();
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      console.error('Error resetting password:', err);
      setMessage('');
      setError('Failed to reset password. Please try again or request a new reset link.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-5xl flex overflow-hidden min-h-[600px]">
            {/* Left Half - Quote Section */}
            <div className="hidden md:flex md:w-1/2 bg-primary relative border-8 border-primary rounded-l-3xl">
                <img 
                    src={OasysLogo} 
                    alt="Oasys Logo" 
                    className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55%] h-auto"
                />
                <div className="absolute bottom-8 right-8 max-w-[80%]">
                    <blockquote className="text-white font-light text-lg">
                        "Innovation is seeing what everybody has seen and thinking what nobody has thought."
                    </blockquote>
                    <cite className="text-white/80 text-sm block mt-2 text-right">
                        – Dr. Albert Szent-Györgyi
                    </cite>
                </div>
            </div>

            {/* Right Half - Form */}
            <div className="w-full md:w-1/2 p-8 md:p-12 flex flex-col justify-center bg-white border-8 border-primary rounded-r-3xl">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-normal text-primary">Set New Password</h2>
                    <p className="text-gray-500 mt-2 font-light">Please enter your new password below.</p>
                </div>

                {showForm ? (
                    <form onSubmit={handleResetPassword} className="space-y-4">
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                        />
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                        />
                        <SubmitButton type="submit" className="bg-primary text-white hover:bg-primary/90 w-full">
                            Reset Password
                        </SubmitButton>
                    </form>
                ) : (
                    <div className="text-center font-light text-white">Loading...</div>
                )}
                {message && (
                    <div className="mt-4 p-3 bg-green-100/10 border border-green-400 text-green-200 rounded-lg font-light">
                        <p>{message}</p>
                    </div>
                )}
                {error && (
                    <div className="mt-4 p-3 bg-red-100/10 border border-red-400 text-red-200 rounded-lg font-light">
                        <p>{error}</p>
                    </div>
                )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default ResetPassword;
