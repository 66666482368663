import { getAccessToken } from './utils';
import { JitsiTokenResponse, ErrorResponse } from '../types/jitsiTypes';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';


export const jitsiApi = {
    getJwtToken: async (roomName: string): Promise<string> => {
        try {
            const response = await fetch(`${API_URL}/sessions/jitsi-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ room_name: roomName })
            });

            if (!response.ok) {
                const errorData = await response.json() as ErrorResponse;
                throw new Error(errorData.detail || 'Failed to get Jitsi JWT token');
            }

            const data = await response.json() as JitsiTokenResponse;
            return data.token;
        } catch (error) {
            console.error('Error getting Jitsi JWT token:', error);
            throw error;
        }
    }
};