import { supabase } from './supabaseClient';
import { FilesApi } from '../types/filesTypes';

export const filesApi: FilesApi = {
    uploadFile: async (userId: string, file: File): Promise<string> => {
        try {
            const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
            const filePath = `${userId}/${fileName}`;
            const { error } = await supabase.storage
                .from('notes')
                .upload(filePath, file);

            if (error) {
                console.error('Upload error:', error);
                throw error;
            }

            const { data: { publicUrl } } = supabase.storage
                .from('notes')
                .getPublicUrl(filePath);

            return publicUrl;
        } catch (error: unknown) {
            console.error('Upload error:', error);
            throw error;
        }
    }
};