import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../services/supabaseClient';
import { FiSearch } from 'react-icons/fi';
import { useAuthContext } from '../../contexts/AuthContext';
import ProfileModal from '../AdminDashboard/ProfileModal';
import { useMediaQuery } from 'react-responsive';
import { Therapist } from '../../types';

// Add type declarations for props
interface TherapistListProps {
  onTherapistSelect: (therapist: Therapist | null) => void;
  userTherapist?: Therapist | null;
}

const defaultAvatar = require('../../assets/default-avatar.png');

const TherapistList: React.FC<TherapistListProps> = ({ onTherapistSelect, userTherapist }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { getUser } = useAuthContext();
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedTherapist, setSelectedTherapist] = useState<Therapist | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Therapist[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [therapistToInvite, setTherapistToInvite] = useState<Therapist | null>(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [currentModalTherapist, setCurrentModalTherapist] = useState<Therapist | null>(null);
  const [showRetractModal, setShowRetractModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    if (therapists.length > 0) {
      setSearchResults(therapists.filter(t => t.id !== selectedTherapist?.id));
    }
  }, [therapists, selectedTherapist]);

  const fetchTherapists = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('therapists')
        .select(`
          id,
          full_name,
          email,
          license,
          years_of_experience,
          specializations,
          languages,
          education,
          therapy_types,
          age_groups_served,
          telehealth_available,
          insurance_accepted,
          professional_bio,
          therapy_approach,
          address,
          phone_number,
          profile_picture_url,
          status,
          is_public
        `)
        .eq('status', 'approved')
        .eq('is_public', true)  // Only fetch public profiles
        .order('full_name');

      if (error) throw error;
      setTherapists(data);
    } catch (err) {
      console.error('Error fetching therapists:', err);
      setError('Failed to fetch therapists. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserTherapist = useCallback(async () => {
    try {
      const currentUser = await getUser();
      if (!currentUser) throw new Error('No authenticated user found');

      // Fetch therapist_patients data
      const { data: therapistPatientData, error: therapistPatientError } = await supabase
        .from('therapist_patients')
        .select('*')
        .eq('patient_id', currentUser.id)
        .or('status.is.null,status.eq.true')
        .single();

      if (therapistPatientError) {
        if (therapistPatientError.code === 'PGRST116') {
          console.log('No pending or approved therapist found for this user');
          setSelectedTherapist(null);
          onTherapistSelect(null);
          return;
        }
        throw therapistPatientError;
      }

      if (therapistPatientData) {
        // Fetch therapist data
        const { data: therapistData, error: therapistError } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', therapistPatientData.therapist_id)
          .single();

        if (therapistError) throw therapistError;

        if (therapistData) {
          const combinedData = { ...therapistData, status: therapistPatientData.status };
          setSelectedTherapist(combinedData);
          onTherapistSelect(combinedData);
        } else {
          console.log('Therapist not found');
          setSelectedTherapist(null);
          onTherapistSelect(null);
        }
      } else {
        console.log('No pending or approved therapist found for this user');
        setSelectedTherapist(null);
        onTherapistSelect(null);
      }

    } catch (err) {
      console.error('Error fetching user therapist:', err);
      setError('Failed to fetch your therapist. Please try again.');
    }
  }, [getUser, onTherapistSelect]);

  useEffect(() => {
    fetchTherapists();
    fetchUserTherapist();
  }, [fetchTherapists, fetchUserTherapist]);

  const handleTherapistClick = (therapist: Therapist) => {
    setCurrentModalTherapist(therapist);
    setShowProfileModal(true);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false);
    setCurrentModalTherapist(null);
  };

  const handleSelectTherapist = (therapist: Therapist) => {
    if (!selectedTherapist) {
      setTherapistToInvite(therapist);
      setShowConfirmation(true);
    }
  };

  const handleConfirmInvite = async () => {
    try {
      const currentUser = await getUser();
      if (!currentUser) throw new Error('No authenticated user found');
      if (!therapistToInvite) throw new Error('No therapist selected');

      // Define the type for the upsert data
      type TherapistPatientData = {
        therapist_id: string;
        patient_id: string;
        status: null;
      };

      const upsertData: TherapistPatientData = {
        therapist_id: therapistToInvite.id,
        patient_id: currentUser.id,
        status: null
      };

      const { error } = await supabase
        .from('therapist_patients')
        .upsert(upsertData, {
          onConflict: 'therapist_id,patient_id'
        });

      if (error) throw error;

      // Create a new therapist object with the status
      const updatedTherapist: Therapist = {
        ...therapistToInvite,
        status: null
      };

      setSelectedTherapist(updatedTherapist);
      onTherapistSelect(updatedTherapist);
      setShowConfirmation(false);

      // Remove the selected therapist from the search results
      setSearchResults(prevResults =>
        prevResults.filter(t => t.id !== therapistToInvite.id)
      );
      setTherapists(prevTherapists =>
        prevTherapists.filter(t => t.id !== therapistToInvite.id)
      );
    } catch (err) {
      console.error('Failed to send therapist invite:', err);
      const error = err as Error;
      setError(`Failed to send therapist invite: ${error.message || 'Unknown error'}`);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    
    if (query.trim() === '') {
      // Show all therapists except selected one when no search
      setSearchResults(therapists.filter(t => t.id !== selectedTherapist?.id));
    } else {
      // Filter therapists but preserve the status from selectedTherapist if it exists
      const filteredTherapists = therapists.filter(therapist => {
        const matchesSearch = (
          (therapist.full_name && therapist.full_name.toLowerCase().includes(query.toLowerCase())) ||
          (therapist.specializations &&
            Array.isArray(therapist.specializations) &&
            therapist.specializations.some((spec: string) =>
              spec && spec.toLowerCase().includes(query.toLowerCase())
            ))
        );

        // If this is the selected therapist, use their current status
        if (matchesSearch && selectedTherapist && therapist.id === selectedTherapist.id) {
          return {
            ...therapist,
            status: selectedTherapist.status
          };
        }

        return matchesSearch ? therapist : false;
      });

      setSearchResults(filteredTherapists);
    }
  };

  const handleRetractInvite = async () => {
    try {
      const currentUser = await getUser();
      if (!currentUser || !selectedTherapist) throw new Error('Missing required data');

      // Delete the specific row where both IDs match and status is null
      const { error } = await supabase
        .from('therapist_patients')
        .delete()
        .eq('therapist_id', selectedTherapist.id)
        .eq('patient_id', currentUser.id)
        .is('status', null);

      if (error) throw error;

      // Remove the selected therapist status but keep their data
      const therapistWithoutStatus = {
        ...selectedTherapist,
        status: undefined
      };

      setSelectedTherapist(null);
      onTherapistSelect(null);
      setShowRetractModal(false);

      // Update lists without duplicating the therapist
      setTherapists(prev => {
        const filtered = prev.filter(t => t.id !== therapistWithoutStatus.id);
        return [...filtered, therapistWithoutStatus];
      });
      
      setSearchResults(prev => {
        const filtered = prev.filter(t => t.id !== therapistWithoutStatus.id);
        return [...filtered, therapistWithoutStatus];
      });

    } catch (err) {
      console.error('Failed to retract invite:', err);
      const error = err as Error;
      setError(`Failed to retract invite: ${error.message || 'Unknown error'}`);
    }
  };

  const handleRemoveTherapist = async () => {
    try {
      const currentUser = await getUser();
      if (!currentUser || !selectedTherapist) throw new Error('Missing required data');

      const { error } = await supabase
        .from('therapist_patients')
        .update({ status: false })
        .match({ 
          therapist_id: selectedTherapist.id,
          patient_id: currentUser.id 
        });

      if (error) throw error;

      setSelectedTherapist(null);
      onTherapistSelect(null);
      setShowRemoveModal(false);

      // Add therapist back to the available list
      setTherapists(prev => [...prev, selectedTherapist]);
      setSearchResults(prev => [...prev, selectedTherapist]);

    } catch (err) {
      console.error('Failed to remove therapist:', err);
      const error = err as Error;
      setError(`Failed to remove therapist: ${error.message || 'Unknown error'}`);
    }
  };

  const renderTherapistSummary = (therapist: Therapist, isMyTherapist = false) => {
    // If this is the selected therapist, use their status from selectedTherapist
    const displayTherapist = isMyTherapist && selectedTherapist ? 
      { ...therapist, status: selectedTherapist.status } : 
      therapist;

    return (
      <div className={`bg-gray-100 p-4 rounded-lg mb-4 ${isMyTherapist ? 'border-2 border-primary bg-primary/5' : ''}`}>
        <div className="flex flex-col sm:flex-row sm:items-start gap-4 mb-2">
          {/* Left side with image and details */}
          <div className="flex items-center gap-3 flex-grow">
            <div className="bg-primary text-white rounded-full p-1 flex-shrink-0">
              <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-white">
                <img
                  src={therapist.profile_picture_url || defaultAvatar}
                  alt={therapist.full_name}
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </div>
            <div className="flex-grow min-w-0">
              <div className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
                <h4 className="text-lg font-normal">
                  <button
                    onClick={() => handleTherapistClick(therapist)}
                    className="text-primary hover:underline focus:outline-none underline"
                  >
                    {therapist.full_name}
                  </button>
                </h4>
              </div>
              <p className="text-sm text-gray-600 font-light">
                {therapist.license || 'License not specified'}
              </p>
              {isMyTherapist && (
                <p className="text-sm font-normal mt-1">
                  {displayTherapist.status === true ? (
                    <span className="text-green-600">Approved</span>
                  ) : (
                    <div className="flex items-center gap-2">
                      <span className="text-yellow-600">Invited - Pending Response</span>
                      <button
                        onClick={() => setShowRetractModal(true)}
                        className="text-red-500 hover:text-red-600 text-sm font-normal 
                                  transition-colors duration-300 underline"
                      >
                        Retract
                      </button>
                    </div>
                  )}
                </p>
              )}
            </div>
          </div>

          {/* Right side content */}
          <div className="hidden sm:flex flex-shrink-0 items-center">
            {isMyTherapist && (
              <span className="inline-block bg-primary text-white px-3 py-1 rounded-full 
                             text-sm font-normal">
                My Therapist
              </span>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <p className="text-sm font-light">
            <strong className="font-normal">Specializations:</strong>{' '}
            {therapist.specializations && Array.isArray(therapist.specializations)
              ? therapist.specializations.slice(0, 3).join(', ')
              : 'Not specified'}
          </p>
          <p className="text-sm font-light">
            <strong className="font-normal">Experience:</strong>{' '}
            {therapist.years_of_experience ? `${therapist.years_of_experience} years` : 'Not specified'}
          </p>
        </div>

        <div className="mt-4 flex justify-between items-center">
          {!isMyTherapist && !selectedTherapist && (
            <button
              onClick={() => handleSelectTherapist(therapist)}
              className="bg-primary text-white px-4 py-1.5 rounded-md 
                        text-sm hover:bg-primary/90 transition-colors duration-300 font-normal"
            >
              Select
            </button>
          )}
          
          {isMyTherapist && displayTherapist.status === true && (
            <div className="ml-auto">
              <button
                onClick={() => setShowRemoveModal(true)}
                className="text-red-500 hover:text-red-600 text-sm font-normal 
                          transition-colors duration-300 underline"
              >
                Remove Connection
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h3 className="text-2xl font-normal text-primary mb-4">Oasys Therapists</h3>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search by name or specialization"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary font-light"
        />
        <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4 font-light">{error}</div>
      ) : (
        <div className="space-y-4">
          {/* Only show selected therapist when not searching */}
          {!searchQuery && selectedTherapist && renderTherapistSummary(selectedTherapist, true)}

          {/* Render search results */}
          {searchResults.map(therapist => (
            <div key={therapist.id}>
              {renderTherapistSummary(
                therapist, 
                selectedTherapist?.id === therapist.id
              )}
            </div>
          ))}

          {searchResults.length === 0 && !selectedTherapist && (
            <p className="text-gray-600 font-light">No therapists found matching your search.</p>
          )}
        </div>
      )}

      {showConfirmation && therapistToInvite && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-normal mb-4">Send Invite to Therapist</h3>
            <p className="mb-4 font-light">
              Do you want to send an invite to {therapistToInvite.full_name}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 font-normal"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmInvite}
                className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark font-normal"
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      )}

      {showProfileModal && currentModalTherapist && (
        <ProfileModal
          profile={currentModalTherapist}
          onClose={closeProfileModal}
          isTherapist={true}
        />
      )}

      {showRetractModal && selectedTherapist && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
            <h3 className="text-lg font-normal mb-4">Retract Invitation</h3>
            <p className="mb-4 font-light">
              Are you sure you want to retract your invitation to {selectedTherapist.full_name}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowRetractModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 font-normal"
              >
                Cancel
              </button>
              <button
                onClick={handleRetractInvite}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 font-normal"
              >
                Retract Invite
              </button>
            </div>
          </div>
        </div>
      )}

      {showRemoveModal && selectedTherapist && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
            <h3 className="text-lg font-normal mb-4">Remove Therapist Connection</h3>
            <p className="mb-4 font-light">
              Are you sure you want to end your connection with {selectedTherapist.full_name}? 
              This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowRemoveModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 font-normal"
              >
                Cancel
              </button>
              <button
                onClick={handleRemoveTherapist}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 font-normal"
              >
                Remove Connection
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TherapistList;
