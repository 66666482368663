import React, { useState } from 'react';
import { FileText, FileCheck, FilePlus, Shield, Lock, Download } from 'lucide-react';
import DocumentCard from './DocumentCard';
import TreatmentPlanModal from './TreatmentPlanModal';
import DocumentsModal from './DocumentsModal';
import { usePatientTab } from '../../../../../contexts/PatientTabContext';

const PatientDocuments: React.FC = () => {
  const {
    selectedPatientDetails: patient,
    treatmentPlan,
    downloadTreatmentPlanPdf
  } = usePatientTab();

  const [showTreatmentPlanModal, setShowTreatmentPlanModal] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const documents = [
    {
      id: 'treatment-plan',
      title: 'Treatment Plan',
      icon: <FileText className="w-5 h-5" />,
      status: treatmentPlan ? 'completed' as const : 'pending' as const,
      lastUpdated: treatmentPlan?.updated_at || null,
      onClick: () => setShowTreatmentPlanModal(true),
      comingSoon: false
    },
    {
      id: 'consent-form',
      title: 'Consent Form',
      icon: <FileCheck className="w-5 h-5" />,
      status: 'unsigned' as const,
      lastUpdated: patient?.consent_form_date || null,
      onClick: () => {/* Handle consent form click */ },
      comingSoon: true
    },
    {
      id: 'safety-plan',
      title: 'Safety Plan',
      icon: <Shield className="w-5 h-5" />,
      status: 'pending' as const,
      lastUpdated: null,
      onClick: () => {/* Handle safety plan click */ },
      comingSoon: true
    }
  ];

  return (
    <div className="space-y-6 min-w-0">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-normal text-primary">Documents & Resources</h2>
        <button
          onClick={() => setShowDocumentsModal(true)}
          className="flex items-center gap-2 px-4 py-2 text-sm font-light 
                   bg-gray-100 text-primary hover:bg-gray-200 rounded-lg transition-all duration-200"
        >
          <FilePlus className="w-4 h-4" />
          Add Document
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {documents.map(doc => (
          <div
            key={doc.id}
            className="relative"
            onMouseEnter={() => setHoveredId(doc.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <DocumentCard {...doc} />
            {doc.comingSoon && hoveredId === doc.id && (
              <div className="absolute inset-0 flex items-center justify-center bg-white/95 rounded-lg 
                            border border-gray-200 transition-opacity duration-200">
                <div className="flex flex-col items-center gap-2">
                  <Lock className="w-5 h-5 text-primary" />
                  <span className="text-sm font-light text-primary">Coming Soon</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {showTreatmentPlanModal && (
        <TreatmentPlanModal
          isOpen={showTreatmentPlanModal}
          onClose={() => setShowTreatmentPlanModal(false)}
          onDownload={downloadTreatmentPlanPdf}
          className="max-w-5xl"
        />
      )}

      <DocumentsModal
        isOpen={showDocumentsModal}
        onClose={() => setShowDocumentsModal(false)}
      />
    </div>
  );
};

export default PatientDocuments; 