import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import TherapistOnboardingProgress from '../components/TherapistOnboarding/TherapistOnboardingProgress';

// Add interfaces
interface SignUpProps {
    role: 'therapist' | 'patient';
}

interface FormData {
    email: string;
    password: string;
    confirmPassword: string;
}

const SignUp: React.FC<SignUpProps> = ({ role }) => {
    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [error, setError] = useState<string | null>(null);
    const [signupComplete, setSignupComplete] = useState(false);
    const { signUp, signOut } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const checkPasswordStrength = (password: string) => {
        const checks = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };

        return {
            isStrong: Object.values(checks).every(Boolean),
            checks
        };
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        const { isStrong, checks } = checkPasswordStrength(formData.password);
        
        if (!isStrong) {
            const errorMessages = [];
            if (!checks.length) errorMessages.push("at least 8 characters");
            if (!checks.uppercase) errorMessages.push("an uppercase letter");
            if (!checks.lowercase) errorMessages.push("a lowercase letter");
            if (!checks.number) errorMessages.push("a number");
            if (!checks.special) errorMessages.push("a special character");
            
            setError(`Password must contain ${errorMessages.join(", ")}.`);
            return;
        }

        const { error } = await signUp(formData.email, formData.password, role);

        if (error) {
            setError(error.message);
        } else {
            setSignupComplete(true);
            await signOut();
            setTimeout(() => navigate('/login'), 5000);
        }
    };

    const PasswordStrengthIndicator: React.FC<{ password: string }> = ({ password }) => {
        const { checks } = checkPasswordStrength(password);
        const requirements = [
            { label: "8+ characters", met: checks.length },
            { label: "Uppercase", met: checks.uppercase },
            { label: "Lowercase", met: checks.lowercase },
            { label: "Number", met: checks.number },
            { label: "Special character", met: checks.special },
        ];

        return (
            <div className="mt-2 space-y-2">
                <div className="text-xs text-gray-500 font-light">Password requirements:</div>
                <div className="grid grid-cols-2 gap-2">
                    {requirements.map(({ label, met }) => (
                        <div 
                            key={label} 
                            className={`text-xs flex items-center space-x-1 
                                ${met ? 'text-green-600' : 'text-gray-400'}`}
                        >
                            {met ? (
                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            ) : (
                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (signupComplete) {
        return (
            <div className="min-h-screen flex flex-col">
                <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                    <BackgroundAnimation />
                    <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
                        <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md min-h-[600px] p-8 md:p-12 flex flex-col justify-center">
                            <div className="text-center">
                                <h2 className="text-3xl font-normal text-primary mb-4">Account Created Successfully!</h2>
                                <p className="text-gray-600 font-light">
                                    Please check your email to verify your account.
                                </p>
                                <p className="text-gray-400 mt-4 font-light">
                                    You will be redirected to the login page shortly.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col">
            <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                <BackgroundAnimation />
                
                <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
                    <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md min-h-[600px] p-8 md:p-12 flex flex-col justify-center">
                        <div className="text-center mb-8">
                            <h2 className="text-3xl font-normal text-primary">
                                {role === 'therapist' ? 'Submit Your Application' : 'Create An Account'}
                            </h2>
                            <p className="text-gray-400 mt-2 font-light">Please enter your details to get started.</p>
                        </div>

                        {role === 'therapist' && <TherapistOnboardingProgress currentStep={`/${role}-signup`} />}

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="space-y-4">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                                />
                                <div>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                                    />
                                    {formData.password && <PasswordStrengthIndicator password={formData.password} />}
                                </div>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                                />
                            </div>

                            {error && (
                                <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-normal"
                            >
                                Next
                            </button>
                        </form>

                        <div className="text-center mt-6 text-sm text-gray-500 font-light">
                            Already registered?{' '}
                            <Link to="/login" className="text-primary hover:underline font-normal">
                                Log In
                            </Link>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default SignUp;
