import { getAccessToken } from './utils';
import { TherapistStatusUpdateResponse } from '../types/usersTypes';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const adminApi = {
    sendTherapistStatusUpdate: async (therapistId: string, status: string): Promise<TherapistStatusUpdateResponse> => {
        try {
            const response = await fetch(`${API_URL}/admin/therapist-status-update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ therapist_id: therapistId, status })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to send therapist status update');
            }

            return await response.json();
        } catch (error) {
            console.error('Error sending therapist status update:', error);
            throw error;
        }
    }
};