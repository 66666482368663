import React from 'react';
import { Dialog } from '@headlessui/react';
import { FaApple, FaArrowRight, FaMobileAlt } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

interface AppleHealthModalProps {
  isOpen: boolean;
  onClose: () => void;
  widgetUrl: string;
}

const AppleHealthModal: React.FC<AppleHealthModalProps> = ({
  isOpen,
  onClose,
  widgetUrl,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleWidgetClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!widgetUrl || !isMobile) return;
    
    try {
        // Force open in new tab
        const newWindow = window.open(widgetUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    } catch (error) {
        console.error('Error opening widget:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded-xl bg-white p-8 shadow-xl">
          <Dialog.Title className="text-xl font-light text-gray-900 flex items-center gap-2 mb-2">
            <FaApple className="h-6 w-6" />
            Connect Apple Health
          </Dialog.Title>
          <br />
          
          <div className="space-y-6">
            <div className="text-sm font-light text-gray-500 space-y-4">
              <p className="flex items-start gap-3">
                <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary/90 text-white text-xs shrink-0 mt-0.5">
                  1
                </span>
                <span>Download and install the Oasys Health app on your mobile device</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary/90 text-white text-xs shrink-0 mt-0.5">
                  2
                </span>
                <span>Open the app and follow the instructions to connect your Apple Health data</span>
              </p>
            </div>

            <div className="space-y-3 pt-2">
              <a
                href="https://apps.apple.com/us/app/oasys-health/id6737940488"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex items-center justify-center gap-2 px-4 py-2.5 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors font-light text-sm"
              >
                <FaApple className="h-5 w-5" />
                Download Oasys Health App
              </a>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default AppleHealthModal; 