import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiCheck, FiX, FiPlus } from 'react-icons/fi';
import { usePatientTab } from '../../../../contexts/PatientTabContext';

const PatientSidebar: React.FC = () => {
    const {
        isSidebarCollapsed,
        toggleSidebar,
        searchTerm,
        setSearchTerm,
        filteredPatients,
        selectedPatientId,
        handlePatientClick,
        handlePatientAction,
        openCreatePatientModal,
        isLoadingPatient,
        handleCreateDemoPatient,
        isEmptyState
    } = usePatientTab();

    return (
        <div className={`${isSidebarCollapsed ? 'w-16' : 'w-[290px]'} min-h-screen border-r border-gray-200 
                    overflow-y-auto transition-all duration-300 ease-in-out flex flex-col
                    flex-shrink-0`}>
            <div className="p-6 flex-grow">
                <div className="flex justify-between items-center mb-6">
                    {!isSidebarCollapsed && <h3 className="text-2xl font-normal text-primary">Patients</h3>}
                    <button
                        onClick={toggleSidebar}
                        className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    >
                        {isSidebarCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                    </button>
                </div>
                {!isSidebarCollapsed && (
                    <div data-testid="patient-list">
                        <div className="flex gap-2 mb-6">
                            <input
                                type="text"
                                placeholder="Search patients..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-lg 
                         focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent 
                         transition-all duration-200 font-light text-sm"
                                data-testid="patient-search"
                            />
                            <button
                                onClick={openCreatePatientModal}
                                className="group relative p-2.5 bg-primary hover:bg-primary/90 rounded-lg 
                         transition-colors duration-200 flex items-center justify-center min-w-[40px]
                         transform hover:scale-[1.02]"
                                title="Add New Patient"
                            >
                                <FiPlus className="h-5 w-5 text-white" />
                                <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block 
                               bg-gray-800 text-white text-xs rounded-lg py-1 px-2 whitespace-nowrap
                               font-light">
                                    Add New Patient
                                </span>
                            </button>
                        </div>
                        <ul className="space-y-2">
                            {filteredPatients.map(patient => (
                                <li
                                    key={patient.id}
                                    className={`p-3 rounded-md cursor-pointer transition-colors duration-150 ease-in-out 
                    ${selectedPatientId === patient.id
                                            ? 'bg-primary text-white shadow-sm'
                                            : 'hover:bg-gray-100'
                                        }
                    ${isLoadingPatient && selectedPatientId === patient.id ? 'opacity-50' : ''}
                  `}
                                    onClick={() => !isLoadingPatient && handlePatientClick(patient)}
                                >
                                    <div className="flex justify-between items-center">
                                        <span className="font-light">
                                            {patient.full_name}
                                            {isLoadingPatient && selectedPatientId === patient.id && (
                                                <span className="ml-2 inline-block animate-pulse">...</span>
                                            )}
                                        </span>
                                        {patient.status === 'pending' && (
                                            <div className="flex space-x-2">
                                                <button
                                                    onClick={(e) => { e.stopPropagation(); handlePatientAction(patient.id, 'approve')(); }}
                                                    className="group relative px-2 py-1 rounded-lg bg-green-50 hover:bg-green-100 
                                   transition-all duration-200 flex items-center gap-1
                                   border border-green-200"
                                                    title="Approve Patient"
                                                >
                                                    <FiCheck className="h-4 w-4 text-green-600" />
                                                </button>
                                                <button
                                                    onClick={(e) => { e.stopPropagation(); handlePatientAction(patient.id, 'deny')(); }}
                                                    className="group relative px-2 py-1 rounded-lg bg-red-50 hover:bg-red-100 
                                   transition-all duration-200 flex items-center gap-1
                                   border border-red-200"
                                                    title="Deny Patient"
                                                >
                                                    <FiX className="h-4 w-4 text-red-600" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {isEmptyState && (
                <button
                    onClick={handleCreateDemoPatient}
                    className="w-full px-4 py-2 text-sm text-primary hover:bg-gray-50 transition-colors"
                >
                    Try Demo Patient
                </button>
            )}
        </div>
    );
};

export default PatientSidebar;
