import { getAccessToken } from './utils';
import { ChatMessage, ChatResponse } from '../types/chatDataTypes';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';


export const chatApi = {
  sendMessage: async (
    userEmail: string, 
    message: string, 
    chatHistory: ChatMessage[]
  ): Promise<ChatResponse> => {
    if (!userEmail) {
      throw new Error('User email is required');
    }

    try {
      const response = await fetch(`${API_URL}/chat/patient/${userEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessToken()}`
        },
        body: JSON.stringify({ message, chatHistory })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to get chat response');
      }

      const data = await response.json();
      return data as ChatResponse;
    } catch (error) {
      console.error('Error in chat API:', error);
      throw error;
    }
  },

  streamChat: async (email: string, message: string, chatHistory: Array<{ role: string; content: string; }>) => {
    try {
      const response = await fetch(
        `${API_URL}/chat/${email}`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${await getAccessToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            message, 
            chatHistory: chatHistory.map(msg => ({
              ...msg,
              content: msg.content.replace(/\r\n/g, '\n') // Normalize line endings
            }))
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get chat response');
      }

      return response;
    } catch (error) {
      console.error('Error in chat:', error);
      throw error;
    }
  }
};