import { getAccessToken } from './utils';
import { HealthInsightsResponse, MoodEntry, MoodStats, MoodEntryData, UpdateMoodEntry, PaginatedResponse, MoodListParams, HealthSummary} from '../types/patientDataTypes';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const patientsApi = {
  getHealthInsights: async (userEmail: string): Promise<HealthInsightsResponse> => {
    if (!userEmail) {
      throw new Error('User email is required');
    }

    try {
      const response = await fetch(`${API_URL}/patients/${userEmail}/insights`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessToken()}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Health insights error response:', {
          status: response.status,
          statusText: response.statusText,
          errorData,
          url: `${API_URL}/patients/${userEmail}/insights`
        });
        
        return {
          insights: [
            {
              type: "info",
              message: "Unable to load insights at the moment. Please try again later.",
              category: "general",
              priority: 1
            }
          ]
        };
      }

      const data = await response.json();
      return data as HealthInsightsResponse;
    } catch (error) {
      console.error('Error in patients API:', error);
      return {
        insights: [
          {
            type: "info",
            message: "Unable to load insights at the moment. Please try again later.",
            category: "general",
            priority: 1
          }
        ]
      };
    }
  },

  getMoodEntries: async (userEmail: string, params: MoodListParams): Promise<PaginatedResponse<MoodEntry>> => {
    const queryParams = new URLSearchParams({
      page: params.page.toString(),
      per_page: params.per_page.toString()
    });
    
    const response = await fetch(`${API_URL}/patients/${userEmail}/moods?${queryParams}`, {
      headers: {
        'Authorization': `Bearer ${await getAccessToken()}`
      }
    });
    if (!response.ok) throw new Error('Failed to fetch mood entries');
    return response.json();
  },

  getMoodStats: async (userEmail: string): Promise<MoodStats> => {
    try {
        const response = await fetch(`${API_URL}/patients/${userEmail}/moods/stats`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to fetch mood stats');
        }
        
        return response.json();
    } catch (error) {
        console.error('Error fetching mood stats:', error);
        throw error;
    }
  },

  addMoodEntry: async (
    userEmail: string, 
    entry: MoodEntryData
  ): Promise<MoodEntry> => {
    const response = await fetch(`${API_URL}/patients/${userEmail}/moods`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await getAccessToken()}`
      },
      body: JSON.stringify({
        ...entry,
        created_at: entry.created_at
      })
    });
    if (!response.ok) throw new Error('Failed to add mood entry');
    return response.json();
  },

  deleteMoodEntry: async (userEmail: string, entryId: string): Promise<void> => {
    const response = await fetch(`${API_URL}/patients/${userEmail}/moods/${entryId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${await getAccessToken()}`
      }
    });
    if (!response.ok) throw new Error('Failed to delete mood entry');
  },

  updateMoodEntry: async (
    userEmail: string, 
    entryId: string, 
    entry: UpdateMoodEntry
  ): Promise<MoodEntry> => {
    const response = await fetch(`${API_URL}/patients/${userEmail}/moods/${entryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await getAccessToken()}`
      },
      body: JSON.stringify(entry)
    });
    if (!response.ok) throw new Error('Failed to update mood entry');
    return response.json();
  },

  getCreativeInsights: async (userEmail: string): Promise<{ insights: Array<{
    message: string;
    category: string;
    icon: string;
    color: string;
  }> }> => {
    try {
      const response = await fetch(`${API_URL}/patients/${userEmail}/creative-insights`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${await getAccessToken()}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to get creative insights');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching creative insights:', error);
      throw error;
    }
  },

  getHealthSummary: async (userEmail: string): Promise<HealthSummary> => {
    try {
      const response = await fetch(`${API_URL}/patients/${userEmail}/health-summary`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${await getAccessToken()}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to get health summary');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching health summary:', error);
      return {
        text: "Unable to load health summary at the moment. Please try again later.",
        highlights: [
          {
            text: "Connect your health devices to see your personalized summary",
            icon: "info"
          }
        ]
      };
    }
  },
}; 