import React, { useState } from 'react';
import SessionDetails from './SessionDetails';
import SessionEditForm from './SessionEditForm';
import { FiEdit, FiTrash2, FiDownload, FiSave, FiX, FiRefreshCw, FiXCircle, FiFileText } from 'react-icons/fi';
import { Session } from '../../../../types/sessionsTypes';
import { usePatientTab } from '../../../../contexts/PatientTabContext';

interface SessionDetailsModalProps {
    session: Session;
    onClose: () => void;
}

const hasUploadedFile = (content?: string): boolean => {
    if (!content) return false;
    
    try {
        const url = new URL(content);
        return url.hostname.includes('supabase.co') && 
               url.pathname.includes('/storage/v1/object/public/');
    } catch {
        return false;
    }
};

const SessionDetailsModal: React.FC<SessionDetailsModalProps> = ({ session, onClose }) => {
    const [showFormatDialog, setShowFormatDialog] = useState(false);

    const {
        handleEditClick,
        handleDeleteClick,
        downloadPDF,
        editingNote,
        handleEditChange,
        handleEditSubmit,
        handleRegenerateSummary,
        isRegeneratingSummary,
        setEditingNote,
        handleSaveClick
    } = usePatientTab();

    const handleRegenerateClick = () => {
        setShowFormatDialog(true);
    };

    const handleFormatSelect = async (format: string) => {
        setShowFormatDialog(false);
        await handleRegenerateSummary(format);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col">
                {/* Sticky Header */}
                <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 rounded-t-lg z-10">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-normal text-primary">Session Details</h2>
                        <div className="flex space-x-2">
                            {editingNote && editingNote.id === session.id ? (
                                <>
                                    <button
                                        onClick={handleSaveClick}
                                        className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                 transition-all duration-200"
                                        title="Save Changes"
                                    >
                                        <FiSave className="h-5 w-5 text-primary" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                       group-hover:block bg-gray-800 text-white text-xs 
                                                       rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                              style={{ 
                                                transform: 'translate(-50%, -4px)', 
                                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                              }}>
                                            Save Changes
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => setEditingNote(null)}
                                        className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                 transition-all duration-200"
                                    >
                                        <FiXCircle className="h-5 w-5 text-red-500" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                       group-hover:block bg-gray-800 text-white text-xs 
                                                       rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                              style={{ 
                                                transform: 'translate(-50%, -4px)', 
                                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                              }}>
                                            Cancel Changes
                                        </span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() => handleEditClick(session)}
                                        className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                 transition-all duration-200"
                                    >
                                        <FiEdit className="h-5 w-5 text-primary" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                       group-hover:block bg-gray-800 text-white text-xs 
                                                       rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                              style={{ 
                                                transform: 'translate(-50%, -4px)', 
                                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                              }}>
                                            Edit Session
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => handleDeleteClick(session.id)}
                                        className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                 transition-all duration-200"
                                    >
                                        <FiTrash2 className="h-5 w-5 text-red-500" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                       group-hover:block bg-gray-800 text-white text-xs 
                                                       rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                              style={{ 
                                                transform: 'translate(-50%, -4px)', 
                                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                              }}>
                                            Delete Session
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => downloadPDF(session)}
                                        className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                 transition-all duration-200"
                                    >
                                        <FiDownload className="h-5 w-5 text-primary" />
                                        <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                       group-hover:block bg-gray-800 text-white text-xs 
                                                       rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                              style={{ 
                                                transform: 'translate(-50%, -4px)', 
                                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                              }}>
                                            Download PDF
                                        </span>
                                    </button>
                                    {!editingNote && session?.content && hasUploadedFile(session.content) && (
                                        <button
                                            onClick={() => window.open(session.content, '_blank')}
                                            className="group relative p-2 rounded-lg hover:bg-gray-100 
                                                       transition-all duration-200"
                                        >
                                            <FiFileText className="h-5 w-5 text-primary" />
                                            <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                                           group-hover:block bg-gray-800 text-white text-xs 
                                                           rounded-lg py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                                                  style={{ 
                                                    transform: 'translate(-50%, -4px)', 
                                                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                                                  }}>
                                            View Uploaded File
                                        </span>
                                        </button>
                                    )}
                                </>
                            )}
                            <button
                                onClick={onClose}
                                className="p-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
                            >
                                <FiX className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Scrollable Content */}
                <div className="flex-1 overflow-y-auto p-6">
                    {editingNote && editingNote.id === session.id ? (
                        <SessionEditForm
                            editingNote={editingNote}
                            handleEditChange={handleEditChange}
                            handleEditSubmit={(e) => {
                                handleEditSubmit(e);
                                onClose();
                            }}
                            setEditingNote={(note) => {
                                setEditingNote(note);
                                if (!note) onClose();
                            }}
                            handleRegenerateSummary={handleRegenerateSummary}
                            isRegeneratingSummary={isRegeneratingSummary}
                            stickyButtons={true}
                        />
                    ) : (
                        <SessionDetails
                            session={session}
                            handleEditClick={handleEditClick}
                            handleDeleteClick={handleDeleteClick}
                            downloadPDF={downloadPDF}
                            stickyButtons={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionDetailsModal;
