import React from 'react';

interface Step {
  path: string;
}

const steps: Step[] = [
  { path: '/therapist-signup' },
  { path: '/therapist-onboarding/professional-info' },
  { path: '/therapist-onboarding/practice-details' },
  { path: '/therapist-onboarding/bio-and-approach' },
  { path: '/therapist-onboarding/verification-submission' }
];

interface TherapistOnboardingProgressProps {
  currentStep: string;
}

const TherapistOnboardingProgress: React.FC<TherapistOnboardingProgressProps> = ({ currentStep }) => {
  const currentIndex = steps.findIndex(step => step.path === currentStep);

  return (
    <div className="mb-8">
      <div className="flex justify-between">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`w-1/5 h-4 rounded-full ${index <= currentIndex ? 'bg-primary' : 'bg-gray-300'
              }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default TherapistOnboardingProgress;
