export const therapyTypes = [
  "Individual",
  "Cognitive Behavioral Therapy (CBT)",
  "Dialectical Behavior Therapy (DBT)",
  "Family",
  "Group",
  "Couples",
  "Psychoanalysis",
  "Solution-Focused Brief Therapy (SFBT)",
  "Psychodynamic Therapy",
  "Humanistic Therapy",
  "Eye Movement Desensitization and Reprocessing (EMDR)",
  "Mindfulness-based Therapy",
  "Narrative Therapy",
  "Play/Art Therapy",
  "Exposure Therapy",
  "Acceptance and Commitment Therapy (ACT)",
  "Interpersonal Therapy",
  "Gestalt Therapy",
  "Existential Therapy",
  "Rational Emotive Behavior Therapy (REBT)",
  "Other"
];

export const ageGroups = [
  "Children (3-12)",
  "Adolescents (13-17)",
  "Young Adults (18-25)",
  "Adults (26-64)",
  "Seniors (65+)"
];

export const insurancePlans = [
  "All",
  "Aetna",
  "Blue Cross Blue Shield",
  "Cigna",
  "UnitedHealthcare",
  "Medicare",
  "Medicaid",
  "Humana",
  "Kaiser Permanente",
  "Anthem",
  "Centene",
  "Molina Healthcare",
  "WellCare",
  "Highmark",
  "Health Net",
  "Tricare",
  "Out-of-Network",
  "Sliding Scale",
  "Other"
];

export const licenseTypes = [
  { value: "LMFT", label: "Licensed Marriage and Family Therapist (LMFT)" },
  { value: "LCSW", label: "Licensed Clinical Social Worker (LCSW)" },
  { value: "LPC", label: "Licensed Professional Counselor (LPC)" },
  { value: "PhD", label: "Doctor of Philosophy in Psychology (PhD)" },
  { value: "PsyD", label: "Doctor of Psychology (PsyD)" },
  { value: "MD", label: "Medical Doctor - Psychiatrist (MD)" },
  { value: "LMHC", label: "Licensed Mental Health Counselor (LMHC)" },
  { value: "LPCC", label: "Licensed Professional Clinical Counselor (LPCC)" },
  { value: "LCPC", label: "Licensed Clinical Professional Counselor (LCPC)" },
  { value: "NCC", label: "National Certified Counselor (NCC)" },
  { value: "PMHNP", label: "Psychiatric Mental Health Nurse Practitioner (PMHNP)" },
  { value: "LICSW", label: "Licensed Independent Clinical Social Worker (LICSW)" },
  { value: "LPA", label: "Licensed Psychological Associate (LPA)" },
  { value: "LCMHC", label: "Licensed Clinical Mental Health Counselor (LCMHC)" },
  { value: "Other", label: "Other License Type" }
];

export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "DC", label: "District of Columbia" }
].sort((a, b) => a.label.localeCompare(b.label));

// Make the interface exportable
export interface HealthProvider {
  id: string;
  name: string;
  logo: string;
  available: boolean;
}

export const healthProviders: HealthProvider[] = [
  {
    id: 'APPLE',
    name: 'Apple Health',
    logo: require('../../assets/HealthProviders/apple-health.png'),
    available: true
  },
  {
    id: 'google_fit',
    name: 'Google Fit',
    logo: require('../../assets/HealthProviders/google-fit.png'),
    available: true
  },
  {
    id: 'samsung_health',
    name: 'Samsung Health',
    logo: require('../../assets/HealthProviders/samsung.png'),
    available: false
  },
  {
    id: 'garmin',
    name: 'Garmin',
    logo: require('../../assets/HealthProviders/garmin.png'),
    available: true
  },
  {
    id: 'oura',
    name: 'Oura Ring',
    logo: require('../../assets/HealthProviders/oura.png'),
    available: true
  },
  {
    id: 'whoop',
    name: 'Whoop',
    logo: require('../../assets/HealthProviders/whoop.png'),
    available: true
  },
  {
    id: 'fitbit',
    name: 'Fitbit',
    logo: require('../../assets/HealthProviders/fitbit.png'),
    available: true
  },
  {
    id: 'flo',
    name: 'Flo',
    logo: require('../../assets/HealthProviders/flo.png'),
    available: true
  },
  {
    id: 'strava',
    name: 'Strava',
    logo: require('../../assets/HealthProviders/strava.png'),
    available: false
  },
  {
    id: 'myfitnesspal',
    name: 'MyFitnessPal',
    logo: require('../../assets/HealthProviders/myfitnesspal.png'),
    available: true
  },
  {
    id: 'dexcom',
    name: 'Dexcom',
    logo: require('../../assets/HealthProviders/dexcom.png'),
    available: false
  },
  {
    id: 'eight_sleep',
    name: 'Eight Sleep',
    logo: require('../../assets/HealthProviders/eightsleep.png'),
    available: true
  },
  {
    id: 'peloton',
    name: 'Peloton',
    logo: require('../../assets/HealthProviders/peloton.png'),
    available: true
  },
  {
    id: 'zwift',
    name: 'Zwift',
    logo: require('../../assets/HealthProviders/zwift.png'),
    available: true
  },
  {
    id: 'technogym',
    name: 'Technogym',
    logo: require('../../assets/HealthProviders/technogym.png'),
    available: true
  },
  {
    id: 'macrosfirst',
    name: 'MacrosFirst',
    logo: require('../../assets/HealthProviders/macrosfirst.png'),
    available: true
  }
];

export const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Portuguese",
  "Russian",
  "Mandarin Chinese",
  "Cantonese Chinese",
  "Japanese",
  "Korean",
  "Vietnamese",
  "Arabic",
  "Hindi",
  "Bengali",
  "Urdu",
  "Turkish",
  "Persian/Farsi",
  "Thai",
  "Greek",
  "Polish",
  "Ukrainian",
  "Dutch",
  "Swedish",
  "Norwegian",
  "Danish",
  "Finnish",
  "Hebrew",
  "Tamil",
  "Telugu",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Gujarati",
  "Punjabi",
  "Tagalog/Filipino",
  "Indonesian",
  "Malay",
  "Swahili",
  "Amharic",
  "Somali",
  "Romanian",
  "Bulgarian",
  "Czech",
  "Slovak",
  "Hungarian",
  "Croatian",
  "Serbian",
  "Bosnian",
  "Albanian",
  "Latvian",
  "Lithuanian",
  "Estonian",
  "Icelandic",
  "Mongolian",
  "Khmer",
  "Lao",
  "Burmese",
  "Nepali",
  "Sinhala",
  "Tibetan",
  "Other"
].sort();

export const titles = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Mx.", label: "Mx." }
];


// Update the try-catch block with proper type handling
try {
  healthProviders.forEach((provider: HealthProvider) => {
    try {
      provider.logo = require(`../../assets/HealthProviders/${provider.id}.png`);
    } catch (e) {
      // Keep the default public path if asset import fails
    }
  });
} catch (e) {
  // Ignore any import errors
}
