import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { useMediaQuery } from 'react-responsive';
import { api } from '../services/api';
import { 
    Session, 
    SessionCreate 
} from '../types/sessionsTypes';
import { TherapistDashboardReturn, TreatmentPlans, PatientFormData, PatientDetails } from '../types/therapistDashboardTypes';
import { Patient } from '../types/usersTypes';

interface RegenerateSummaryResponse {
    summary: string;
}

const COLLAPSE_SIDEBAR_SECTIONS = ['patients', 'sessions']; 

export const useTherapistDashboard = (): TherapistDashboardReturn => {
    const { user, getSession } = useAuthContext();
    const [fullName, setFullName] = useState<string>('');
    const [activeSection, setActiveSection] = useState<string>('home');
    const [patients, setPatients] = useState<Patient[]>([]);
    const [approvedPatients, setApprovedPatients] = useState<Patient[]>([]);
    const location = useLocation();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [treatmentPlans, setTreatmentPlans] = useState<TreatmentPlans>({});
    const [isLoadingTreatmentPlan, setIsLoadingTreatmentPlan] = useState<boolean>(false);

    const fetchPatients = useCallback(async () => {
        if (!user?.id) {
            console.error('User profile or ID is missing');
            return;
        }

        try {
            const processedPatients = await api.therapists.fetchTherapistPatients(user.id);
            setPatients(processedPatients);
            setApprovedPatients(processedPatients.filter(patient => patient.status === 'approved'));
        } catch (err) {
            console.error('Error fetching patients:', err);
        }
    }, [user]);

    useEffect(() => {
        if (user?.id) {
            fetchPatients();
        }
    }, [fetchPatients, user]);

    const handleTabChange = (newSection: string): void => {
        if (activeSection === 'sessions' && newSection !== 'sessions') {
            const hasActiveSession = document.querySelector('[data-testid="session-container"]');
            if (hasActiveSession) {
                const confirmChange = window.confirm('You have an active session. Are you sure you want to leave? Your session will be saved but unsaved changes may be lost.');
                if (!confirmChange) {
                    return;
                }
            }
        }

        setActiveSection(newSection);
        if (COLLAPSE_SIDEBAR_SECTIONS.includes(newSection)) {
            setIsSidebarCollapsed(true);
        }
    };

    const handlePatientAction = async (patientId: string, action: 'approve' | 'deny'): Promise<void> => {
        if (action === 'deny') {
            const confirmDeny = window.confirm("Are you sure you want to deny this patient? They will be removed from your patient list.");
            if (!confirmDeny) return;
        }

        try {
            const session = await getSession();
            if (!session) {
                throw new Error('No active session');
            }

            let updateData;
            if (action === 'approve') {
                updateData = { status: true };
            } else if (action === 'deny') {
                updateData = { status: false };
            } else {
                return;
            }

            await api.therapists.updatePatientApproval(session.user.id, patientId, updateData);

            if (action === 'deny') {
                setPatients(prevPatients => prevPatients.filter(patient => patient.id !== patientId));
            } else {
                setPatients(prevPatients =>
                    prevPatients.map(patient =>
                        patient.id === patientId
                            ? { ...patient, status: 'approved' }
                            : patient
                    )
                );
            }

            console.log(`Patient ${action}d successfully`);
            await fetchPatients();

        } catch (err) {
            console.error('Error handling patient action:', err);
        }
    };

    useEffect(() => {
        if (location.state && location.state.showProfile) {
            setActiveSection('profile');
        }
    }, [location]);

    const toggleSidebar = (): void => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    useEffect(() => {
        const fetchTherapistDetails = async () => {
            if (user?.id) {
                try {
                    const data = await api.therapists.getTherapistDetails(user.id);
                    if (data) {
                        setFullName(data.full_name);
                    }
                } catch (error) {
                    console.error('Error fetching therapist details:', error);
                }
            }
        };

        fetchTherapistDetails();
    }, [user]);

    const generateTreatmentPlan = async (patientId: string): Promise<void> => {
        setIsLoadingTreatmentPlan(true);
        try {
            const response = await api.therapists.generateTreatmentPlan(patientId);
            setTreatmentPlans(prev => ({
                ...prev,
                [patientId]: response.treatment_plan
            }));
        } catch (error) {
            console.error('Error generating treatment plan:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const uploadTreatmentPlan = async (patientId: string, file: File): Promise<void> => {
        setIsLoadingTreatmentPlan(true);
        try {
            const response = await api.therapists.uploadTreatmentPlan(patientId, file);
            setTreatmentPlans(prev => ({
                ...prev,
                [patientId]: response.treatment_plan
            }));
        } catch (error) {
            console.error('Error uploading treatment plan:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const updateTreatmentPlan = async (patientId: string, planContent: string): Promise<void> => {
        setIsLoadingTreatmentPlan(true);
        try {
            const response = await api.therapists.updateTreatmentPlan(patientId, planContent);
            setTreatmentPlans(prev => ({
                ...prev,
                [patientId]: response.treatment_plan
            }));
        } catch (error) {
            console.error('Error updating treatment plan:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const getTreatmentPlan = async (patientId: string): Promise<Session> => {
        setIsLoadingTreatmentPlan(true);
        try {
            const response = await api.therapists.getTreatmentPlan(patientId);
            if (response && response.treatment_plan) {
                setTreatmentPlans(prev => ({
                    ...prev,
                    [patientId]: response.treatment_plan
                }));
                return response;
            }
            throw new Error('No treatment plan found');
        } catch (error) {
            console.error('Error getting treatment plan:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const deleteTreatmentPlan = async (patientId: string): Promise<void> => {
        setIsLoadingTreatmentPlan(true);
        try {
            await api.therapists.deleteTreatmentPlan(patientId);
            setTreatmentPlans(prev => {
                const newPlans = { ...prev };
                delete newPlans[patientId];
                return newPlans;
            });
        } catch (error) {
            console.error('Error deleting treatment plan:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const generateTreatmentPlanWithInstructions = async (patientId: string, instructions: string): Promise<void> => {
        setIsLoadingTreatmentPlan(true);
        try {
            const response = await api.therapists.generateTreatmentPlanWithInstructions(patientId, instructions);
            setTreatmentPlans(prev => ({
                ...prev,
                [patientId]: response.treatment_plan
            }));
        } catch (error) {
            console.error('Error generating treatment plan with instructions:', error);
            throw error;
        } finally {
            setIsLoadingTreatmentPlan(false);
        }
    };

    const createPatient = async (formData: PatientFormData): Promise<Patient> => {
        try {
            console.log('Creating patient with data:', formData);
            const newPatient = await api.therapists.createPatient(formData);
            console.log('New patient created:', newPatient);

            // Create complete patient object with status
            const patientWithStatus = {
                id: newPatient.user_id,
                user_id: newPatient.user_id,
                status: 'approved',
                full_name: formData.full_name,
                email: formData.email
            };

            // Update local state
            setPatients(prevPatients => [...prevPatients, patientWithStatus]);
            setApprovedPatients(prevApproved => [...prevApproved, patientWithStatus]);

            return patientWithStatus;
        } catch (error) {
            console.error('Failed to create patient:', error);
            throw error;
        }
    };

    const fetchPatientDetails = async (patientId: string, page: number, pageSize: number): Promise<PatientDetails> => {
        try {
            const { userData, therapistPatientData, allSessionsData } = await api.therapists.fetchPatientDetails(patientId);
            const healthOverviewResponse = await api.therapists.getPatientHealthDataOverview(patientId);

            return {
                patientDetails: { ...userData, ...therapistPatientData },
                allSessions: allSessionsData,
                paginatedSessions: allSessionsData.slice((page - 1) * pageSize, page * pageSize),
                totalSessions: allSessionsData.length,
                healthOverview: healthOverviewResponse?.general_observations || null
            };
        } catch (error) {
            console.error('Error fetching patient details:', error);
            throw error;
        }
    };

    const updateSession = async (sessionId: string, sessionPayload: SessionCreate): Promise<Session> => {
        try {
            return await api.sessions.updateSession(sessionId, sessionPayload);
        } catch (error) {
            console.error('Error updating session:', error);
            throw error;
        }
    };

    const regenerateSessionSummary = async (
        sessionId: string, 
        content: string, 
        format: string,
        fileUrl: string = ''
    ): Promise<RegenerateSummaryResponse> => {
        try {
            const response = await api.sessions.regenerateSummary(
                sessionId, 
                { 
                    content, 
                    format,
                    fileUrl
                }
            );
            return { summary: response.summary || '' };
        } catch (error) {
            console.error('Error regenerating summary:', error);
            throw error;
        }
    };

    const deleteSession = async (sessionId: string): Promise<void> => {
        try {
            await api.sessions.deleteSession(sessionId);
        } catch (error) {
            console.error('Error deleting session:', error);
            throw error;
        }
    };

    const generateSessionPDF = async (sessionId: string): Promise<Blob> => {
        try {
            return await api.sessions.generatePDF(sessionId);
        } catch (error) {
            console.error('Error generating PDF:', error);
            throw error;
        }
    };

    return {
        user,
        fullName,
        activeSection,
        patients,
        approvedPatients,
        isSidebarCollapsed,
        isMobile,
        handleTabChange,
        handlePatientAction,
        toggleSidebar,
        treatmentPlans,
        isLoadingTreatmentPlan,
        generateTreatmentPlan,
        uploadTreatmentPlan,
        updateTreatmentPlan,
        getTreatmentPlan,
        deleteTreatmentPlan,
        generateTreatmentPlanWithInstructions,
        createPatient,
        fetchPatientDetails,
        updateSession,
        regenerateSessionSummary,
        deleteSession,
        generateSessionPDF,
        userData: user
    };
};
