import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

interface FAQItemProps {
    question: string;
    answer: string;
}

interface FAQ {
    question: string;
    answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="border-b border-gray-200 dark:border-gray-800 py-8">
            <button
                className="flex justify-between items-center w-full text-left group"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-2xl md:text-3xl font-light text-primary/90 group-hover:text-primary">
                    {question}
                </span>
                <motion.div
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <FiChevronDown className="text-2xl text-primary" />
                </motion.div>
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <p className="mt-6 text-lg text-foreground/70 font-light max-w-4xl">
                            {answer}
                        </p>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const FAQ: React.FC = () => {
    const faqs: FAQ[] = [
        {
            question: "What kind of insights does Oasys offer?",
            answer: "Our AI analyzes patterns across health data, wearables, and clinical records to provide actionable insights about patient well-being, treatment effectiveness, and potential health risks."
        },
        {
            question: "What kind of data can Oasys track and display?",
            answer: "Oasys provides data on sleep, activity, heart rate, continuous glucose monitoring (CGM), blood work, menstrual cycles, and genetics, along with other physiological metrics that can be relevant to mental health care, depending on the wearables and sources integrated."
        },
        {
            question: "What types of wearable devices are compatible with Oasys?",
            answer: "Oasys integrates with a wide range of popular wearables, including Apple Watch, Oura Ring, Fitbit, and more, ensuring accessibility to data from devices clients may already be using."
        },
        {
            question: "What kind of training and support do you provide?",
            answer: "We offer comprehensive onboarding, including personalized training sessions, ongoing technical support, and regular check-ins with our clinical success team. We ensure your entire staff is confident using the platform to its full potential."
        },
        {
            question: "What security measures are in place?",
            answer: "We maintain the highest level of security with HIPAA-compliant infrastructure, end-to-end encryption, and strict access controls to ensure your data remains private and secure."
        }
    ];

    return (
        <div className="w-full">
            <div className="space-y-2">
                {faqs.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    );
};

export default FAQ; 