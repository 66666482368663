import { getAccessToken } from './utils';
import { supabase } from './supabaseClient';
import { PatientData, Patient, UpdateData, TherapistDetails } from '../types/usersTypes';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const therapistsApi = {
    fetchPatientDetails: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient/${patientId}/details`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch patient details');
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching patient details:', error);
            throw error;
        }
    },

    fetchPatients: async () => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patients`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch patients');
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching patients:', error);
            throw error;
        }
    },

    updatePatientStatus: async (patientId: string, status: boolean) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient/${patientId}/status`,
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ status }),
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to update patient status');
            }
            return response.json();
        } catch (error) {
            console.error('Error updating patient status:', error);
            throw error;
        }
    },

    getPatientHealthData: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-health-data/${patientId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 404) {
                    return { error: 'No health data found for this patient.' };
                }
                throw new Error(
                    errorData.detail || 'Failed to fetch patient health data'
                );
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching patient health data:', error);
            throw error;
        }
    },

    getPatientHealthDataOverview: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-health-data-overview/${patientId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 404) {
                    return { general_observations: null };
                }
                throw new Error(
                    errorData.detail || 'Failed to fetch patient health data overview'
                );
            }
            const data = await response.json();
            return {
                general_observations: data.general_observations || null
            };
        } catch (error) {
            console.error('Error fetching patient health data overview:', error);
            throw error;
        }
    },

    chatAboutHealthData: async (patientId: string, message: string, chatHistory: Array<{ role: string; content: string; }>) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-health-chat/${patientId}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        message, 
                        chat_history: chatHistory.map(msg => ({
                            ...msg,
                            content: msg.content.replace(/\r\n/g, '\n') // Normalize line endings
                        }))
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to get chat response');
            }

            return response;
        } catch (error) {
            console.error('Error in health data chat:', error);
            throw error;
        }
    },

    getTreatmentPlan: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/get`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to get treatment plan');
            }
            return response.json();
        } catch (error) {
            console.error('Error getting treatment plan:', error);
            throw error;
        }
    },

    generateTreatmentPlan: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/generate`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to generate treatment plan');
            }
            return response.json();
        } catch (error) {
            console.error('Error generating treatment plan:', error);
            throw error;
        }
    },

    uploadTreatmentPlan: async (patientId: string, file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/upload`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                    },
                    body: formData,
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to upload treatment plan');
            }
            return response.json();
        } catch (error) {
            console.error('Error uploading treatment plan:', error);
            throw error;
        }
    },

    updateTreatmentPlan: async (patientId: string, planContent: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/update`,
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ treatment_plan: planContent }),
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to update treatment plan');
            }
            return response.json();
        } catch (error) {
            console.error('Error updating treatment plan:', error);
            throw error;
        }
    },

    deleteTreatmentPlan: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/delete`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to delete treatment plan');
            }
            return response.json();
        } catch (error) {
            console.error('Error deleting treatment plan:', error);
            throw error;
        }
    },

    generateTreatmentPlanWithInstructions: async (patientId: string, instructions: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/generate-with-instructions`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ instructions }),
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to generate treatment plan with instructions');
            }
            return response.json();
        } catch (error) {
            console.error('Error generating treatment plan with instructions:', error);
            throw error;
        }
    },

    createPatient: async (patientData: PatientData) => {
        const response = await fetch(`${API_URL}/therapists/create-patient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken()}`
            },
            body: JSON.stringify(patientData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to create patient');
        }

        const newPatient = await response.json();
        console.log('New patient data:', newPatient);
        return newPatient;
    },

    fetchTherapistPatients: async (therapistId: string): Promise<Patient[]> => {
        try {
            const { data: patientsData, error } = await supabase
                .from('therapist_patients')
                .select(`
                    patient_id,
                    status,
                    users:patient_id (id, full_name, email)
                `)
                .eq('therapist_id', therapistId);

            if (error) throw error;

            return patientsData.reduce((acc: Patient[], patient: any) => {
                if (patient.users) {
                    acc.push({
                        id: patient.patient_id,
                        full_name: patient.users.full_name,
                        email: patient.users.email,
                        status: patient.status === true ? "approved" : (patient.status === false ? "denied" : "pending")
                    });
                }
                return acc;
            }, []);
        } catch (error) {
            console.error('Error fetching patients:', error);
            throw error;
        }
    },

    updatePatientApproval: async (therapistId: string, patientId: string, updateData: UpdateData) => {
        try {
            const { error } = await supabase
                .from('therapist_patients')
                .update(updateData)
                .eq('therapist_id', therapistId)
                .eq('patient_id', patientId);

            if (error) throw error;
            return true;
        } catch (error) {
            console.error('Error updating patient approval:', error);
            throw error;
        }
    },

    getTherapistDetails: async (therapistId: string): Promise<TherapistDetails> => {
        try {
            const { data, error } = await supabase
                .from('therapists')
                .select('full_name')
                .eq('id', therapistId)
                .single();

            if (error) throw error;
            return data;
        } catch (error) {
            console.error('Error fetching therapist details:', error);
            throw error;
        }
    },

    createDemoPatient: async () => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/create-demo-patient`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to create demo patient');
            }
            return response.json();
        } catch (error) {
            console.error('Error creating demo patient:', error);
            throw error;
        }
    },

    downloadTreatmentPlanPdf: async (patientId: string) => {
        try {
            const response = await fetch(
                `${API_URL}/therapists/patient-treatment-plan/${patientId}/pdf`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to download treatment plan');
            }
            
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `treatment_plan.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading treatment plan:', error);
            throw error;
        }
    },
};