import { createPortal } from 'react-dom';
import React, { useState, useEffect, useRef } from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactElement;
  position?: 'right' | 'top';
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);

  const updatePosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();

      if (position === 'right') {
        setTooltipPosition({
          x: rect.right + 8,
          y: rect.top + rect.height / 2
        });
      } else {
        setTooltipPosition({
          x: rect.left + rect.width / 2,
          y: rect.top
        });
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      updatePosition();
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);
    }
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isVisible]);

  const getTooltipStyles = () => {
    if (position === 'right') {
      return {
        left: tooltipPosition.x,
        top: tooltipPosition.y,
        transform: 'translateY(-50%)'
      };
    }
    return {
      left: tooltipPosition.x,
      top: tooltipPosition.y - 8,
      transform: 'translate(-50%, -100%)'
    };
  };

  // Don't show tooltip if there's no text
  if (!text) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>

      {isVisible && createPortal(
        <div
          className="fixed bg-gray-800 text-white text-xs rounded-lg py-1 px-2 
                     whitespace-nowrap font-light pointer-events-none z-[9999]"
          style={getTooltipStyles()}
        >
          {text}
        </div>,
        document.body
      )}
    </>
  );
};

export default Tooltip; 