import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FiSend } from 'react-icons/fi';
import { api } from '../../../../../services/api';
import { ChatMessage } from '../../../../../types/chatDataTypes';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import breaks from 'remark-breaks';


interface HealthOverviewChatProps {
    patientId: string;
    onMessageSent: (messages: ChatMessage[]) => void;
    showInput?: boolean;
    inputOnly?: boolean;
    showMessagesInInput?: boolean;
    messages: ChatMessage[];
    setMessages: (messages: ChatMessage[]) => void;
    isTyping: boolean;
    setIsTyping: (isTyping: boolean) => void;
}

const HealthOverviewChat: React.FC<HealthOverviewChatProps> = ({
    patientId,
    onMessageSent,
    showInput = true,
    inputOnly = false,
    showMessagesInInput = true,
    messages,
    setMessages,
    isTyping,
    setIsTyping
}) => {
    const [chatInput, setChatInput] = useState('');
    const [currentPlaceholder, setCurrentPlaceholder] = useState('');
    const [displayedPlaceholder, setDisplayedPlaceholder] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isPlaceholderTyping, setIsPlaceholderTyping] = useState(true);
    const [streamedResponse, setStreamedResponse] = useState('');

    const placeholderPrompts = [
        "How has the patient's sleep quality been trending?",
        "What patterns do you notice in their sleep schedule?",
        "Analyze their sleep duration variations",
        "What's their weekly activity pattern?",
        "How consistent are they with exercise?",
        "Compare their activity levels month over month",
        "Analyze their eating patterns",
        "What nutritional improvements could be suggested?",
        "How balanced is their diet?",
        "What trends do you see in their session scores?",
        "How do their sessions correlate with wellbeing?",
        "What factors seem to affect their wellbeing most?",
        "Provide a comprehensive health analysis",
        "What are the key health concerns?",
        "Suggest potential lifestyle improvements"
    ];

    // Handle the typing animation for placeholder
    useEffect(() => {
        if (!isPlaceholderTyping) return;

        if (displayedPlaceholder.length < currentPlaceholder.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedPlaceholder(currentPlaceholder.slice(0, displayedPlaceholder.length + 1));
            }, 50);
            return () => clearTimeout(timeoutId);
        } else {
            const timeoutId = setTimeout(() => {
                setIsPlaceholderTyping(false);
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [displayedPlaceholder, currentPlaceholder, isPlaceholderTyping]);

    // Handle the erasing animation
    useEffect(() => {
        if (isPlaceholderTyping) return;

        if (displayedPlaceholder.length > 0) {
            const timeoutId = setTimeout(() => {
                setDisplayedPlaceholder(displayedPlaceholder.slice(0, -1));
            }, 30);
            return () => clearTimeout(timeoutId);
        } else {
            const timeoutId = setTimeout(() => {
                const randomIndex = Math.floor(Math.random() * placeholderPrompts.length);
                setCurrentPlaceholder(placeholderPrompts[randomIndex]);
                setIsPlaceholderTyping(true);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [displayedPlaceholder, isPlaceholderTyping, placeholderPrompts]);

    // Initialize first placeholder
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * placeholderPrompts.length);
        setCurrentPlaceholder(placeholderPrompts[randomIndex]);
    }, []);

    const handleSendMessage = async () => {
        if (!chatInput.trim()) return;

        const newUserMessage: ChatMessage = {
            role: 'user',
            content: chatInput
        };
        const newMessages: ChatMessage[] = [...messages, newUserMessage];

        const placeholderAssistantMessage: ChatMessage = {
            role: 'assistant',
            content: ''
        };
        const messagesWithPlaceholder: ChatMessage[] = [...newMessages, placeholderAssistantMessage];

        setMessages(messagesWithPlaceholder);
        setChatInput('');
        setIsTyping(true);

        onMessageSent(messagesWithPlaceholder);

        try {
            const response = await api.therapists.chatAboutHealthData(patientId, chatInput, messages);
            if (!response) throw new Error('No response available');

            const reader = response.body?.getReader();
            if (!reader) throw new Error('No reader available');

            let accumulatedResponse = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = new TextDecoder().decode(value);
                accumulatedResponse += chunk;

                const updatedMessages = messagesWithPlaceholder.map((msg, index) => {
                    if (index === messagesWithPlaceholder.length - 1) {
                        return {
                            ...msg,
                            content: accumulatedResponse.trim()
                        };
                    }
                    return msg;
                });
                setMessages(updatedMessages);
            }

            setIsTyping(false);
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage: ChatMessage = {
                role: 'assistant',
                content: 'Sorry, I encountered an error. Please try again.'
            };
            setMessages([...newMessages, errorMessage]);
        } finally {
            setIsTyping(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    if (inputOnly) {
        return (
            <div className="flex items-center space-x-2 bg-white p-4 rounded-lg">
                <input
                    type="text"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={isFocused ? '' : displayedPlaceholder}
                    className="flex-grow px-6 py-2 border border-gray-200 rounded-full 
                             focus:outline-none focus:ring-2 focus:ring-primary/50 
                             focus:border-transparent font-light"
                />
                <button
                    onClick={handleSendMessage}
                    className="p-2 text-primary hover:text-primary/80 transition-colors duration-200"
                >
                    <FiSend className="w-5 h-5" />
                </button>
            </div>
        );
    }

    if (!showMessagesInInput) {
        return (
            <div className="space-y-3">
                {messages.map((message, index) => (
                    <div key={index}
                        className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                        <div className={`max-w-[70%] p-3 rounded-lg ${message.role === 'assistant'
                            ? 'bg-gray-50 text-gray-800'
                            : 'bg-primary text-white'
                            } font-light`}>
                            {isTyping && index === messages.length - 1 && !message.content ? (
                                <div className="flex items-center space-x-2">
                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                                </div>
                            ) : (
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm, breaks]}
                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                    components={{
                                        p: ({ children }) => (
                                            <p className="mb-3 font-light whitespace-pre-wrap leading-7">{children}</p>
                                        ),
                                        h1: ({ children }) => (
                                            <h1 className="text-2xl font-normal my-3 text-primary">{children}</h1>
                                        ),
                                        h2: ({ children }) => (
                                            <h2 className="text-xl font-normal my-2.5 text-primary">{children}</h2>
                                        ),
                                        h3: ({ children }) => (
                                            <h3 className="text-lg font-normal my-2 text-primary">{children}</h3>
                                        ),
                                        ul: ({ children }) => (
                                            <ul className="list-disc pl-5 my-2 space-y-1 font-light">{children}</ul>
                                        ),
                                        ol: ({ children }) => (
                                            <ol className="list-decimal pl-5 my-2 space-y-1 font-light">{children}</ol>
                                        ),
                                        li: ({ children }) => (
                                            <li className="font-light leading-relaxed">{children}</li>
                                        ),
                                    }}
                                >
                                    {message.content}
                                </ReactMarkdown>
                            )}
                        </div>
                    </div>
                ))}
                {isTyping && streamedResponse && (
                    <div className="flex justify-start">
                        <div className="max-w-[70%] p-3 rounded-lg bg-gray-50 text-gray-800 font-light">
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm, breaks]}
                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                components={{
                                    p: ({ children }) => (
                                        <p className="mb-2 font-light whitespace-pre-wrap leading-relaxed">{children}</p>
                                    ),
                                    h1: ({ children }) => (
                                        <h1 className="text-2xl font-normal my-3 text-primary">{children}</h1>
                                    ),
                                    h2: ({ children }) => (
                                        <h2 className="text-xl font-normal my-2.5 text-primary">{children}</h2>
                                    ),
                                    h3: ({ children }) => (
                                        <h3 className="text-lg font-normal my-2 text-primary">{children}</h3>
                                    ),
                                    ul: ({ children }) => (
                                        <ul className="list-disc pl-5 my-2 space-y-1 font-light">{children}</ul>
                                    ),
                                    ol: ({ children }) => (
                                        <ol className="list-decimal pl-5 my-2 space-y-1 font-light">{children}</ol>
                                    ),
                                    li: ({ children }) => (
                                        <li className="font-light leading-relaxed">{children}</li>
                                    ),
                                }}
                            >
                                {streamedResponse}
                            </ReactMarkdown>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return null;
};

export default HealthOverviewChat;