import { getAccessToken } from './utils';
import {
  HealthAnalysis,
  NutritionEntry,
  SleepData,
  ActivityData,
  MenstruationData,
  DailyData,
  BodyData,
} from '../types/healthDataTypes';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const terraApi = {
    generateWidgetSession: async (userEmail: string, provider?: string): Promise<{ url: string }> => {
        const response = await fetch(`${API_URL}/terra/generate-widget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken()}`
            },
            body: JSON.stringify({
                userEmail,
                provider: provider || undefined
            })
        });
        if (!response.ok) {
            throw new Error('Failed to generate Terra widget session');
        }
        return response.json();
    },
    disconnectProvider: async (userEmail: string, providerId: string): Promise<any> => {
        const response = await fetch(`${API_URL}/terra/disconnect/${userEmail}/${providerId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to disconnect provider');
        }
        return response.json();
    },
    getNutritionData: async (userEmail: string): Promise<NutritionEntry[]> => {
        const response = await fetch(`${API_URL}/terra/nutrition-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch nutrition data');
        }
        return await response.json();
    },
    getSleepData: async (userEmail: string): Promise<SleepData[]> => {
        const response = await fetch(`${API_URL}/terra/sleep-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch sleep data');
        }
        return await response.json();
    },
    getActivityData: async (userEmail: string): Promise<ActivityData[]> => {
        const response = await fetch(`${API_URL}/terra/activity-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch activity data');
        }
        return await response.json();
    },
    getConnectedProviders: async (userEmail: string): Promise<string[]> => {
        const response = await fetch(`${API_URL}/terra/connected-providers/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch connected providers');
        }
        const data = await response.json();
        return data.connected_providers;
    },
    getMenstruationData: async (userEmail: string): Promise<MenstruationData[]> => {
        const response = await fetch(`${API_URL}/terra/menstruation-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch menstruation data');
        }
        return await response.json();
    },
    getDailyData: async (userEmail: string): Promise<DailyData[]> => {
        const response = await fetch(`${API_URL}/terra/daily-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch daily data');
        }
        return await response.json();
    },
    getBodyData: async (userEmail: string): Promise<BodyData[]> => {
        const response = await fetch(`${API_URL}/terra/body-data/${userEmail}`, {
            headers: {
                'Authorization': `Bearer ${await getAccessToken()}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch body data');
        }
        return await response.json();
    },
    getCombinedHealthData: async (userEmail: string, forceRefresh: boolean = false): Promise<{
        health_analysis: HealthAnalysis;
        nutrition_data: NutritionEntry[];
        sleep_data: SleepData[];
        activity_data: ActivityData[];
        menstruation_data: MenstruationData[];
        daily_data: DailyData[];
        body_data: BodyData[];
    }> => {
        const response = await fetch(
            `${API_URL}/terra/combined-health-data/${userEmail}?force_refresh=${forceRefresh}`, 
            {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            }
        );
        
        // Add better error handling
        if (!response.ok) {
            // If it's a 404 or if there are no providers connected, return empty data
            if (response.status === 404 || response.status === 500) {
                return {
                    health_analysis: {} as HealthAnalysis,
                    nutrition_data: [],
                    sleep_data: [],
                    activity_data: [],
                    menstruation_data: [],
                    daily_data: [],
                    body_data: [],
                };
            }
            throw new Error('Failed to fetch combined health data');
        }
        return await response.json();
    },
    getCombinedHealthDataUpdated: async (userEmail: string): Promise<{
        health_analysis: HealthAnalysis;
        nutrition_data: NutritionEntry[];
        sleep_data: SleepData[];
        activity_data: ActivityData[];
        menstruation_data: MenstruationData[];
        daily_data: DailyData[];
        body_data: BodyData[];
    }> => {
        const response = await fetch(
            `${API_URL}/terra/combined-health-data-updated/${userEmail}`,
            {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            }
        );
        
        if (!response.ok) {
            if (response.status === 404 || response.status === 500) {
                return {
                    health_analysis: {} as HealthAnalysis,
                    nutrition_data: [],
                    sleep_data: [],
                    activity_data: [],
                    menstruation_data: [],
                    daily_data: [],
                    body_data: [],
                };
            }
            throw new Error('Failed to fetch updated combined health data');
        }
        return await response.json();
    },
};