import React, { useState } from 'react';
import { ClipboardList, FileSpreadsheet, FileSignature, X, Lock } from 'lucide-react';

interface DocumentsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DocumentsModal: React.FC<DocumentsModalProps> = ({ isOpen, onClose }) => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const additionalDocuments = [
    {
      id: 'assessment',
      title: 'Initial Assessment',
      description: 'Comprehensive patient evaluation',
      icon: <ClipboardList className="w-5 h-5" />,
    },
    {
      id: 'release-form',
      title: 'Release of Information',
      description: 'Authorization to share information',
      icon: <FileSignature className="w-5 h-5" />,
    },
    {
      id: 'insurance',
      title: 'Insurance Information',
      description: 'Coverage and billing details',
      icon: <FileSpreadsheet className="w-5 h-5" />,
    }
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-normal text-primary">Add Document</h3>
            <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-lg">
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {additionalDocuments.map(doc => (
              <div 
                key={doc.id} 
                className="relative p-4 border rounded-lg cursor-pointer transition-all duration-200"
                onMouseEnter={() => setHoveredId(doc.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <div className="flex items-start gap-4">
                  <div className="p-2 rounded-lg bg-primary/5">
                    {doc.icon}
                  </div>
                  <div>
                    <h4 className="text-base font-normal">{doc.title}</h4>
                    <p className="text-sm text-gray-500 font-light">{doc.description}</p>
                  </div>
                </div>
                {hoveredId === doc.id && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white/95 rounded-lg 
                                transition-opacity duration-200">
                    <div className="flex flex-col items-center gap-2">
                      <Lock className="w-5 h-5 text-primary" />
                      <span className="text-sm font-light text-primary">Coming Soon</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsModal;