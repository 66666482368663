import { chatApi } from './chatApi';
import { therapistsApi } from './therapistsApi';
import { filesApi } from './filesApi';
import { sessionsApi } from './sessionsApi';
import { terraApi } from './terraApi';
import { jitsiApi } from './jitsiApi';
import { feedbackApi } from './feedbackApi';
import { contactApi } from './contactApi';
import { adminApi } from './adminApi';
import { therapistSignUpApi } from './therapistSignUpApi';
import { patientsApi } from './patientsApi';

export const api = {
    chat: chatApi,
    therapists: therapistsApi,
    files: filesApi,
    sessions: sessionsApi,
    terra: terraApi,
    jitsi: jitsiApi,
    feedback: feedbackApi,
    contact: contactApi,
    admin: adminApi,
    therapistSignUp: therapistSignUpApi,
    patients: patientsApi,
};
