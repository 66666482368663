import React from 'react';
import { FiActivity, FiCalendar, FiHeart, FiBook, FiExternalLink, FiTrendingUp, FiSun, FiMoon, FiTarget, FiAward, FiAlertCircle } from 'react-icons/fi';
import {
    BarChart2,
    Plug2,
    UserCog,
    Calendar,
    Book,
    Flower2,
    Loader,
    AlertTriangle
} from 'lucide-react';
import { PatientOverviewProps, HealthSummaryHighlight } from '../../../types/patientDataTypes';
import { StatCard, QuickActionButton, ResourceCard } from './PatientOverviewComponents';

const PatientOverview: React.FC<PatientOverviewProps> = ({
    patientName = "Patient",
    connectedProviders = [],
    upcomingSessions = [],
    onTabChange,
    isProvidersLoading,
    healthSummary,
    isHealthSummaryLoading = false,
}) => {
    const calculateHealthScore = () => {
        return 85;
    };

    return (
        <div className="space-y-4">
            <h2 className="text-2xl font-normal text-primary mb-2">
                Welcome, {patientName}!
            </h2>
            <p className="text-gray-600 font-light">
                Here's an overview of your health journey and upcoming activities.
            </p>

            {/* Statistics Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <StatCard
                    icon={<FiHeart />}
                    title="Health Score"
                    value={`${calculateHealthScore()}%`}
                    description="Overall wellness"
                    color="text-red-600"
                    comingSoon={true}
                />
                <StatCard
                    icon={<FiActivity />}
                    title="Active Providers"
                    value={isProvidersLoading ? "..." : connectedProviders.length}
                    description="Connected services"
                    color="text-blue-600"
                />
                <StatCard
                    icon={<FiCalendar />}
                    title="Sessions"
                    value={upcomingSessions.length}
                    description="This month"
                    color="text-green-600"
                />
                <StatCard
                    icon={<FiTrendingUp />}
                    title="Progress"
                    value="7 days"
                    description="Streak maintained"
                    color="text-purple-600"
                    comingSoon={true}
                />
            </div>

            {/* Quick Actions Section */}
            <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="text-lg font-normal text-primary mb-4">Quick Actions</h3>
                <div className="grid grid-cols-6 gap-4">
                    <QuickActionButton
                        icon={<Flower2 className="w-5 h-5" />}
                        label="Log Mood"
                        onClick={() => onTabChange('wellness')}
                    />
                    <QuickActionButton
                        icon={<BarChart2 className="w-5 h-5" />}
                        label="Health Stats"
                        onClick={() => onTabChange('dashboard')}
                    />
                    <QuickActionButton
                        icon={<Plug2 className="w-5 h-5" />}
                        label="Connect Data"
                        onClick={() => onTabChange('connectHealth')}
                    />
                    <QuickActionButton
                        icon={<UserCog className="w-5 h-5" />}
                        label="My Therapist"
                        onClick={() => onTabChange('myTherapist')}
                    />
                    <QuickActionButton
                        icon={<Calendar className="w-5 h-5" />}
                        label="Book Session"
                        onClick={() => { }}
                        comingSoon={true}
                    />
                    <QuickActionButton
                        icon={<Book className="w-5 h-5" />}
                        label="Health Report"
                        onClick={() => { }}
                        comingSoon={true}
                    />
                </div>
            </div>

            {/* Health Summary Section */}
            <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="text-lg font-normal text-primary mb-4">
                    Health Summary
                </h3>

                {isHealthSummaryLoading ? (
                    <div className="flex justify-center items-center py-8">
                        <Loader className="w-8 h-8 animate-spin text-primary" />
                    </div>
                ) : healthSummary ? (
                    <>
                        <p className="text-gray-600 font-light mb-6 leading-relaxed">
                            {healthSummary.text}
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {healthSummary.highlights.map((highlight: HealthSummaryHighlight, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-start space-x-3 p-3 bg-gray-50 rounded-lg"
                                >
                                    {highlight.icon === 'sun' && <FiSun className="text-primary" />}
                                    {highlight.icon === 'moon' && <FiMoon className="text-primary" />}
                                    {highlight.icon === 'target' && <FiTarget className="text-primary" />}
                                    {highlight.icon === 'award' && <FiAward className="text-primary" />}
                                    {highlight.icon === 'warning' && <AlertTriangle className="text-primary" />}
                                    {highlight.icon === 'alert' && <FiAlertCircle className="text-primary" />}
                                    <p className="text-sm text-gray-600 font-light">
                                        {highlight.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <p className="text-gray-500 text-center py-4">
                        Connect your health devices to see your personalized summary
                    </p>
                )}
            </div>

            {/* Resource Section */}
            <div className="bg-white rounded-lg p-6 shadow-md">
                <h3 className="text-lg font-normal text-primary mb-4">
                    Wellness Resources
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ResourceCard
                        title="Health Tracking Guide"
                        description="Learn to effectively track your health data"
                        icon={<FiBook />}
                        link="#"
                    />
                    <ResourceCard
                        title="Meditation Library"
                        description="Access guided meditation sessions"
                        icon={<FiBook />}
                        link="#"
                    />
                    <ResourceCard
                        title="Exercise Database"
                        description="Browse recommended exercises"
                        icon={<FiBook />}
                        link="#"
                    />
                    <ResourceCard
                        title="Wellness Articles"
                        description="Read latest health insights"
                        icon={<FiExternalLink />}
                        link="#"
                    />
                </div>
            </div>

        </div>
    );
};

export default PatientOverview;