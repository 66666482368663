import React from 'react';
import ReactMarkdown from 'react-markdown';
import { BiLoaderAlt } from 'react-icons/bi';

interface SessionPrepNotesModalProps {
    isOpen: boolean;
    onClose: () => void;
    prepNotes: string;
    isLoading: boolean;
    isStreaming?: boolean;
}

const LoadingIndicator = () => (
    <div className="flex justify-center items-center py-8">
        <div className="flex space-x-2">
            <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                style={{ animationDelay: '0ms' }}></div>
            <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                style={{ animationDelay: '150ms' }}></div>
            <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                style={{ animationDelay: '300ms' }}></div>
        </div>
    </div>
);

const SessionPrepNotesModal: React.FC<SessionPrepNotesModalProps> = ({
    isOpen,
    onClose,
    prepNotes,
    isLoading,
    isStreaming = false
}) => {
    if (!isOpen) return null;

    const cleanHtmlFromContent = (content: string) => {
        return content
            .replace(/<div[^>]*>/g, '')
            .replace(/<\/div>/g, '\n')
            .replace(/<h3[^>]*>/g, '### ')
            .replace(/<\/h3>/g, '\n')
            .replace(/<p[^>]*>/g, '')
            .replace(/<\/p>/g, '\n')
            .replace(/<ul[^>]*>/g, '')
            .replace(/<\/ul>/g, '\n')
            .replace(/<li[^>]*>/g, '- ')
            .replace(/<\/li>/g, '\n')
            .replace(/<[^>]+>/g, '')
            .replace(/\n\s*\n/g, '\n\n')
            .trim();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto m-4">
                <div className="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-end items-center">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-700 hover:text-gray-900 font-medium bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                    >
                        Close
                    </button>
                </div>

                <div className="p-6">
                    {(isLoading && !prepNotes) || (isStreaming && !prepNotes) ? (
                        <LoadingIndicator />
                    ) : (
                        <ReactMarkdown
                            components={{
                                p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                                h1: ({ children }) => <h1 className="text-2xl font-normal mb-2">{children}</h1>,
                                h2: ({ children }) => <h2 className="text-xl font-normal mb-2">{children}</h2>,
                                h3: ({ children }) => <h3 className="text-lg font-normal mb-2">{children}</h3>,
                                h4: ({ children }) => <h4 className="text-md font-normal mb-2">{children}</h4>,
                                h5: ({ children }) => <h5 className="text-sm font-normal mb-2">{children}</h5>,
                                h6: ({ children }) => <h6 className="text-xs font-normal mb-2">{children}</h6>,
                                ul: ({ children }) => <ul className="list-disc pl-5 mb-4">{children}</ul>,
                                ol: ({ children }) => <ol className="list-decimal pl-5 mb-4">{children}</ol>,
                                li: ({ children }) => <li className="mb-1">{children}</li>,
                                a: ({ href, children }) => (
                                    <a
                                        href={href}
                                        className="text-blue-500 hover:underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {children}
                                    </a>
                                ),
                            }}
                        >
                            {cleanHtmlFromContent(prepNotes)}
                        </ReactMarkdown>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionPrepNotesModal;