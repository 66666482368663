import React, { useState, useEffect, useRef } from 'react';
import { usePatientTab } from '../../../../../contexts/PatientTabContext';
import { FileText, Upload, Wand2, Info, Download } from 'lucide-react';
import { FiEdit, FiTrash2, FiX, FiCheck, FiSave } from 'react-icons/fi';
import { FaBold, FaItalic, FaHeading, FaListUl, FaListOl, FaColumns } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { marked } from 'marked';
import TurndownService from 'turndown';
import DOMPurify from 'dompurify';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import Tooltip from '../../../../Shared/Tooltip';

interface TreatmentPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  onDownload: () => void;
}

type ModalView = 'menu' | 'instructions' | 'view' | 'upload';

const TreatmentPlanModal: React.FC<TreatmentPlanModalProps> = ({ isOpen, onClose, className, onDownload }) => {
  const {
    treatmentPlan,
    handleGenerateTreatmentPlanWithInstructions,
    handleUploadTreatmentPlan,
    handleDeleteTreatmentPlan,
    handleUpdateTreatmentPlan,
    selectedPatientId,
    isLoadingTreatmentPlan,
  } = usePatientTab();

  const [currentView, setCurrentView] = useState<ModalView>(treatmentPlan ? 'view' : 'menu');
  const [instructions, setInstructions] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [canGenerate, setCanGenerate] = useState<{
    can_generate: boolean;
    session_count: number;
    minimum_required: number;
  } | null>(null);
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const [duration, setDuration] = useState('short');
  const [otherFocusArea, setOtherFocusArea] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedPlan, setEditedPlan] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const editorRef = useRef<HTMLTextAreaElement>(null);

  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced'
  });

  useEffect(() => {
    const checkGenerationCapability = async () => {
      if (!selectedPatientId) return;

      try {
        const response = await fetch(`/api/patient-treatment-plan/${selectedPatientId}/can-generate`);
        const data = await response.json();
        setCanGenerate(data);
      } catch (error) {
        console.error('Error checking generation capability:', error);
      }
    };

    if (currentView === 'menu') {
      checkGenerationCapability();
    }
  }, [currentView, selectedPatientId]);

  // Prevent background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    if (treatmentPlan) {
      setEditedPlan(treatmentPlan.content || '');
      setIsEditing(false);
    } else {
      setEditedPlan('');
    }
  }, [treatmentPlan]);

  if (!isOpen) return null;

  const handleGenerate = async (withInstructions: string) => {
    try {
      await handleGenerateTreatmentPlanWithInstructions(withInstructions);
      setCurrentView('view');
    } catch (error) {
      console.error('Error generating treatment plan:', error);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        await handleUploadTreatmentPlan(file);
        setCurrentView('view');
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const renderInstructionsView = () => (
    <div className="p-6 space-y-6">
      <h3 className="text-xl font-normal text-primary mb-4">Generate Treatment Plan</h3>

      <button
        onClick={() => setShowInfoModal(true)}
        className="text-primary hover:text-primary/80 text-sm font-light underline underline-offset-2 flex items-center gap-1"
      >
        <Info className="w-4 h-4" />
        Learn more about the treatment plan
      </button>

      {showInfoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-normal text-primary">Treatment Plan Generation</h3>
              <button
                onClick={() => setShowInfoModal(false)}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                <FiX className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4 text-gray-600">
              <p>
                Our treatment plan generation system combines multiple data sources to create comprehensive,
                personalized treatment plans:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Patient session history and progress notes</li>
                <li>Physiological and behavioral data patterns</li>
                <li>Evidence-based therapeutic approaches</li>
                <li>Current academic research and clinical guidelines</li>
                <li>Professional psychiatric and psychological textbooks</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Treatment Focus Areas
        </label>
        <div className="grid grid-cols-2 gap-2">
          {['Depression', 'Anxiety', 'PTSD', 'Substance Use', 'Relationship Issues', 'Trauma', 'Stress Management', 'Other'].map((area) => (
            <label key={area} className="flex items-center p-3 border rounded-lg hover:bg-gray-50 cursor-pointer">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-primary focus:ring-primary"
                checked={area === 'Other' ? showOtherInput : selectedAreas.includes(area)}
                onChange={(e) => {
                  const selected = e.target.checked;
                  if (area === 'Other') {
                    setShowOtherInput(selected);
                    if (!selected) {
                      setOtherFocusArea('');
                      setSelectedAreas(prev => prev.filter(a => a !== otherFocusArea));
                    }
                  } else {
                    setSelectedAreas(prev => selected
                      ? [...prev, area]
                      : prev.filter(a => a !== area)
                    );
                  }
                }}
              />
              <span className="ml-2 text-sm text-gray-600">{area}</span>
            </label>
          ))}
        </div>
      </div>

      {showOtherInput && (
        <div className="mt-2">
          <input
            type="text"
            value={otherFocusArea}
            onChange={(e) => {
              setOtherFocusArea(e.target.value);
              setSelectedAreas(prev => {
                const filtered = prev.filter(a => !prev.includes(otherFocusArea));
                return e.target.value ? [...filtered, e.target.value] : filtered;
              });
            }}
            placeholder="Please specify..."
            className="w-full p-3 text-sm border border-gray-200 rounded-lg 
                     focus:ring-2 focus:ring-primary focus:border-transparent 
                     transition-all duration-200"
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Treatment Duration
        </label>
        <select
          className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          onChange={(e) => setDuration(e.target.value)}
        >
          <option value="short">Short-term (8-12 sessions)</option>
          <option value="medium">Medium-term (3-6 months)</option>
          <option value="long">Long-term (6+ months)</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Additional Instructions (Optional)
        </label>
        <textarea
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          placeholder="Add any specific requirements or focus areas..."
          className="w-full h-32 p-4 border border-gray-200 rounded-lg 
                   focus:outline-none focus:ring-2 focus:ring-primary 
                   focus:border-transparent font-light transition-all duration-200"
        />
      </div>
      <div className="flex justify-end gap-3 pt-4">
        <button
          onClick={() => setCurrentView('menu')}
          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-all duration-200"
        >
          Back
        </button>
        <button
          onClick={() => {
            const fullInstructions = `Generate a treatment plan for the following areas: ${selectedAreas.join(', ')}. 
              Duration: ${duration}. Additional instructions: ${instructions}`;
            handleGenerate(fullInstructions);
          }}
          disabled={selectedAreas.length === 0 || isLoadingTreatmentPlan}
          className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 
                   transition-all duration-200 disabled:opacity-50"
        >
          {isLoadingTreatmentPlan ? 'Generating...' : 'Generate Plan'}
        </button>
      </div>
    </div>
  );

  const renderUploadView = () => (
    <div className="p-6 space-y-4">
      <h3 className="text-xl font-normal text-primary mb-4">Upload Treatment Plan</h3>
      <div className="text-center p-8 border-2 border-dashed border-gray-200 rounded-lg">
        {isUploading ? (
          <div className="flex flex-col items-center gap-2">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            <p className="text-gray-600 font-light">Analyzing document...</p>
          </div>
        ) : (
          <>
            <input
              type="file"
              id="treatment-plan-upload"
              className="hidden"
              accept=".pdf,.doc,.docx,.txt,.md"
              onChange={handleFileUpload}
            />
            <label
              htmlFor="treatment-plan-upload"
              className="flex flex-col items-center gap-2 cursor-pointer"
            >
              <Upload className="w-8 h-8 text-gray-400" />
              <p className="text-gray-600 font-light">Click to upload or drag and drop</p>
              <p className="text-sm text-gray-500">PDF, DOC, DOCX, TXT, or MD</p>
            </label>
          </>
        )}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => setCurrentView('menu')}
          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-all duration-200"
        >
          Back
        </button>
      </div>
    </div>
  );

  const insertMarkdown = (prefix: string, suffix = '') => {
    if (!editorRef.current) return;

    const start = editorRef.current.selectionStart;
    const end = editorRef.current.selectionEnd;
    const text = editorRef.current.value;
    const selectedText = text.substring(start, end);

    const beforeText = text.substring(0, start);
    const afterText = text.substring(end);

    const newText = `${beforeText}${prefix}${selectedText}${suffix}${afterText}`;
    setEditedPlan(newText);

    setTimeout(() => {
      if (!editorRef.current) return;
      editorRef.current.focus();
      editorRef.current.setSelectionRange(
        start + prefix.length,
        end + prefix.length
      );
    }, 0);
  };

  const toolbarActions = [
    {
      icon: <FaBold className="h-4 w-4" />,
      label: 'Bold',
      action: () => insertMarkdown('**', '**')
    },
    {
      icon: <FaItalic className="h-4 w-4" />,
      label: 'Italic',
      action: () => insertMarkdown('*', '*')
    },
    {
      icon: <FaHeading className="h-4 w-4" />,
      label: 'Heading',
      action: () => insertMarkdown('### ')
    },
    {
      icon: <FaListUl className="h-4 w-4" />,
      label: 'List',
      action: () => insertMarkdown('- ')
    },
    {
      icon: <FaListOl className="h-4 w-4" />,
      label: 'Numbered List',
      action: () => insertMarkdown('1. ')
    }
  ];

  const ToolbarButton: React.FC<{
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    active?: boolean;
  }> = ({ icon, label, onClick, active }) => (
    <Tooltip text={label}>
      <button
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className={`p-2 rounded-lg transition-colors duration-200
                  ${active
            ? 'bg-primary/10 text-primary'
            : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'}`}
      >
        {icon}
      </button>
    </Tooltip>
  );

  const renderEditor = () => (
    <div className="p-6">
      <div className="flex items-center justify-between gap-2 border-b border-gray-200 pb-4">
        <div className="flex items-center gap-1">
          {toolbarActions.map((action, index) => (
            <ToolbarButton
              key={index}
              icon={action.icon}
              label={action.label}
              onClick={action.action}
            />
          ))}
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={addExampleContent}
            className="text-sm text-primary hover:text-primary/80 underline"
          >
            Add Example Content
          </button>
          <div className="h-6 w-px bg-gray-200" />
          <ToolbarButton
            icon={<FaColumns className="h-5 w-5" />}
            label="Toggle Preview"
            onClick={() => setShowPreview(!showPreview)}
            active={showPreview}
          />
          <div className="h-6 w-px bg-gray-200" />
          <Tooltip text="Cancel Changes">
            <button
              onClick={() => {
                setIsEditing(false);
                setEditedPlan(treatmentPlan?.content || '');
              }}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:bg-gray-100 
                       rounded-lg transition-all duration-200"
            >
              <FiX className="w-4 h-4" />
            </button>
          </Tooltip>
          <Tooltip text="Save Changes">
            <button
              onClick={handleSave}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-white 
                       rounded-lg hover:bg-primary/90 transition-all duration-200"
            >
              <FiSave className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
      </div>

      <div className={`${showPreview ? 'grid grid-cols-2 gap-6' : 'block'} mt-4`}>
        <div className={showPreview ? 'space-y-2' : ''}>
          <textarea
            ref={editorRef}
            value={editedPlan}
            onChange={(e) => setEditedPlan(e.target.value)}
            className="w-full min-h-[400px] px-4 py-3 font-mono text-sm
                     border border-gray-200 rounded-lg resize-none
                     focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
                     transition-all duration-200"
            placeholder="# Treatment Plan

## Patient Goals
- List goals here...

## Treatment Approach
1. First approach
2. Second approach

## Timeline
Describe timeline here..."
          />
          {!showPreview && (
            <div className="mt-2 space-y-2 text-sm text-gray-500">
              <p>Markdown Formatting Guide:</p>
              <ul className="list-disc pl-5 space-y-1">
                <li><code># Heading 1</code> - Main heading</li>
                <li><code>## Heading 2</code> - Section heading</li>
                <li><code>- Item</code> - Bullet point</li>
                <li><code>1. Item</code> - Numbered list</li>
                <li><code>**bold**</code> - Bold text</li>
                <li><code>*italic*</code> - Italic text</li>
              </ul>
            </div>
          )}
        </div>
        {showPreview && (
          <div className="border-l pl-6">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              components={{
                p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                h1: ({ children }) => <h1 className="text-2xl font-normal mb-4 text-primary">{children}</h1>,
                h2: ({ children }) => <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>,
                h3: ({ children }) => <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>,
                ul: ({ children }) => <ul className="list-disc pl-5 mb-4 font-light">{children}</ul>,
                ol: ({ children }) => <ol className="list-decimal pl-5 mb-4 font-light">{children}</ol>,
                li: ({ children }) => <li className="mb-1 font-light">{children}</li>,
              }}
            >
              {editedPlan}
            </ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );

  const renderTreatmentPlan = () => (
    <div className="p-6">
      {treatmentPlan ? (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
          components={{
            p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
            h2: ({ children }) => <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>,
            h3: ({ children }) => <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>,
            ul: ({ children }) => <ul className="list-disc pl-5 mb-4 font-light">{children}</ul>,
            ol: ({ children }) => <ol className="list-decimal pl-5 mb-4 font-light">{children}</ol>,
            li: ({ children }) => <li className="mb-1 font-light">{children}</li>,
          }}
        >
          {treatmentPlan.content || 'No treatment plan available.'}
        </ReactMarkdown>
      ) : (
        <p className="text-gray-500 font-light">No treatment plan available.</p>
      )}
    </div>
  );

  const renderContent = () => {
    switch (currentView) {
      case 'menu':
        return renderMenu();
      case 'instructions':
        return renderInstructionsView();
      case 'view':
        return isEditing ? renderEditor() : renderTreatmentPlan();
      case 'upload':
        return renderUploadView();
    }
  };

  const renderMenu = () => (
    <div className="space-y-6 p-6">

      <button
        onClick={() => setCurrentView('instructions')}
        className="w-full flex items-center gap-3 p-4 rounded-lg border border-gray-200 
                 hover:border-primary/20 hover:bg-gray-50 transition-all duration-200"
      >
        <Wand2 className="w-5 h-5 text-primary" />
        <div className="text-left">
          <h4 className="text-base font-normal">Generate Treatment Plan</h4>
          <p className="text-sm text-gray-500 font-light">
            Generate a plan using AI with your specific instructions
          </p>
        </div>
      </button>

      <button
        onClick={() => setCurrentView('upload')}
        className="w-full flex items-center gap-3 p-4 rounded-lg border border-gray-200 
                 hover:border-primary/20 hover:bg-gray-50 transition-all duration-200"
      >
        <Upload className="w-5 h-5 text-primary" />
        <div className="text-left">
          <h4 className="text-base font-normal">Upload Existing Plan</h4>
          <p className="text-sm text-gray-500 font-light">
            Upload a treatment plan document (.pdf, .docx, .txt)
          </p>
        </div>
      </button>
    </div>
  );

  const handleSave = async (): Promise<void> => {
    try {
      await handleUpdateTreatmentPlan(editedPlan);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving treatment plan:', error);
    }
  };

  const addExampleContent = () => {
    const example = `# Treatment Plan

## Patient Goals
- Reduce anxiety symptoms
- Improve sleep quality
- Develop better coping mechanisms

## Treatment Approach
1. Cognitive Behavioral Therapy (CBT)
2. Mindfulness techniques
3. Sleep hygiene education

## Timeline
Weekly sessions for 3 months, then reassess progress.

## Interventions
* Progressive muscle relaxation
* Thought recording exercises
* Sleep diary monitoring
`;
    setEditedPlan(example);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto py-4">
      <div className={`bg-white rounded-lg w-full mx-4 max-h-[90vh] flex flex-col ${className || 'max-w-4xl'}`}>
        <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 z-10">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-normal text-primary">Treatment Plan</h2>
            <div className="flex gap-2">
              {treatmentPlan && currentView === 'view' && !isEditing ? (
                <>
                  <button
                    onClick={onDownload}
                    className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                    title="Download PDF"
                  >
                    <Download className="h-5 w-5 text-gray-600" />
                  </button>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                    title="Edit Plan"
                  >
                    <FiEdit className="h-5 w-5 text-gray-600" />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this treatment plan? This action cannot be undone.')) {
                        handleDeleteTreatmentPlan();
                        onClose();
                      }
                    }}
                    className="p-2 rounded-lg hover:bg-red-50 transition-all duration-200"
                    title="Delete Plan"
                  >
                    <FiTrash2 className="h-5 w-5 text-red-500" />
                  </button>
                </>
              ) : null}
              {!isEditing && (
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                >
                  <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-y-auto flex-1">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanModal; 