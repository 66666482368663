import React, { useState, useEffect } from 'react';
import { X, Calendar, Frown, MehIcon, Smile, Laugh, Dumbbell, Briefcase, Users, Home, Coffee, Palette, Moon, Flower2, BookOpen, Trees, Plane, Film } from 'lucide-react';
import { FrownIcon } from 'lucide-react';
import { format } from 'date-fns';
import { MoodEntry, MoodEntryData } from '../../../types/patientDataTypes';
import Tooltip from '../../Shared/Tooltip';
import { Calendar as ShadcnCalendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CalendarIcon, Clock } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

interface MoodEntryFormProps {
    onSubmit: (entry: MoodEntryData) => Promise<void>;
    onClose: () => void;
    initialData?: MoodEntry;
}

const MOOD_LEVELS = [
    { score: 1, label: 'Very Low', icon: FrownIcon, description: 'Feeling down or overwhelmed' },
    { score: 2, label: 'Low', icon: Frown, description: 'Not great, but hanging in there' },
    { score: 3, label: 'Neutral', icon: MehIcon, description: 'Neither good nor bad' },
    { score: 4, label: 'Good', icon: Smile, description: 'Pretty good, feeling positive' },
    { score: 5, label: 'Excellent', icon: Laugh, description: 'Feeling amazing!' },
];

const ACTIVITY_ICONS: Record<string, React.ElementType> = {
    Exercise: Dumbbell,
    Work: Briefcase,
    Social: Users,
    Family: Home,
    Relaxation: Coffee,
    Hobbies: Palette,
    Sleep: Moon,
    Meditation: Flower2,
    Reading: BookOpen,
    Nature: Trees,
    Travel: Plane,
    Entertainment: Film
};

const getMoodColor = (score: number): string => {
    switch (score) {
        case 1: return 'bg-rose-500 hover:bg-rose-600';
        case 2: return 'bg-orange-500 hover:bg-orange-600';
        case 3: return 'bg-amber-500 hover:bg-amber-600';
        case 4: return 'bg-emerald-500 hover:bg-emerald-600';
        case 5: return 'bg-green-500 hover:bg-green-600';
        default: return 'bg-gray-500 hover:bg-gray-600';
    }
};

const TooltipWrapper: React.FC<{
    text: string;
    children: React.ReactElement
}> = ({ text, children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) return children;
    return <Tooltip text={text}>{children}</Tooltip>;
};

// Helper function to format time in 12-hour format
const formatTimeForDisplay = (hour: number, minute: number): string => {
  const date = new Date();
  date.setHours(hour, minute);
  return format(date, 'h:mm a'); // This will format like "3:30 PM"
};

// Helper function to generate time options
const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      options.push({
        value: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
        label: formatTimeForDisplay(hour, minute)
      });
    }
  }
  return options;
};

const MoodEntryForm: React.FC<MoodEntryFormProps> = ({ onSubmit, onClose, initialData }) => {
    const [moodScore, setMoodScore] = useState<number>(initialData?.mood_score || 3);
    const [notes, setNotes] = useState(initialData?.notes || '');
    const [activities, setActivities] = useState<string[]>(initialData?.activities || []);

    // Get current time rounded to nearest 15 minutes
    const roundToNearest15Minutes = (date: Date) => {
        const minutes = date.getMinutes();
        const remainder = minutes % 15;
        const roundedMinutes = remainder < 8 ? minutes - remainder : minutes + (15 - remainder);
        date.setMinutes(roundedMinutes);
        return date;
    };

    const [entryDate, setEntryDate] = useState<string>(() => {
        if (initialData) {
            return format(new Date(initialData.created_at), "yyyy-MM-dd'T'HH:mm");
        }
        return format(roundToNearest15Minutes(new Date()), "yyyy-MM-dd'T'HH:mm");
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSubmit({
            mood_score: moodScore,
            notes,
            activities,
            created_at: new Date(entryDate).toISOString(),
        });
    };

    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl p-4 sm:p-6 w-full max-w-md sm:max-w-xl md:max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg sm:text-xl font-normal">
                        {initialData ? 'Edit Mood Entry' : 'New Mood Entry'}
                    </h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-600"
                    >
                        <X className="w-5 h-5 sm:w-6 sm:h-6" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                    {/* Mood Score Selection */}
                    <div>
                        <label className="block text-sm font-light text-gray-700 mb-2 sm:mb-3">
                            How are you feeling?
                        </label>
                        <div className="grid grid-cols-5 gap-1 sm:gap-2">
                            {MOOD_LEVELS.map(({ score, label, icon: Icon, description }) => (
                                <TooltipWrapper key={score} text={description}>
                                    <button
                                        type="button"
                                        onClick={() => setMoodScore(score)}
                                        className={`flex flex-col items-center justify-center w-16 h-16 sm:w-20 sm:h-20 p-2 sm:p-3 rounded-lg sm:rounded-xl transition-all duration-200 ${moodScore === score
                                                ? `${getMoodColor(score)} text-white shadow-lg scale-105`
                                                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                                            }`}
                                    >
                                        <Icon className="w-8 h-8" strokeWidth={1.5} />
                                        <span className="text-[10px] sm:text-xs font-medium hidden sm:block mt-1">{label}</span>
                                    </button>
                                </TooltipWrapper>
                            ))}
                        </div>
                    </div>

                    {/* Activities */}
                    <div>
                        <label className="block text-sm font-light text-gray-700 mb-2 sm:mb-3">
                            What have you been up to?
                        </label>
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-1.5 sm:gap-2">
                            {Object.entries(ACTIVITY_ICONS).map(([activity, Icon]) => (
                                <button
                                    key={activity}
                                    type="button"
                                    onClick={() => {
                                        setActivities(prev =>
                                            prev.includes(activity)
                                                ? prev.filter(a => a !== activity)
                                                : [...prev, activity]
                                        );
                                    }}
                                    className={`flex items-center gap-1.5 sm:gap-2 px-2 sm:px-4 py-1.5 sm:py-2 rounded-lg sm:rounded-xl text-xs sm:text-sm transition-all duration-200 ${
                                        activities.includes(activity)
                                            ? 'bg-primary text-white shadow-md scale-105 font-light'
                                            : 'bg-gray-50 text-gray-600 hover:bg-gray-100 font-light'
                                    }`}
                                >
                                    <Icon className="w-4 h-4 sm:w-5 sm:h-5" />
                                    <span className="truncate">{activity}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Date/Time Selection */}
                    <div className="space-y-2">
                        <label className="block text-sm font-light text-gray-700">
                            Date and Time
                        </label>
                        <div className="flex gap-2">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant="outline"
                                        className={cn(
                                            "w-[280px] justify-start text-left font-light",
                                            !entryDate && "text-muted-foreground"
                                        )}
                                    >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {entryDate ? format(new Date(entryDate), "PPP") : <span>Pick a date</span>}
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0" align="start">
                                    <ShadcnCalendar
                                        mode="single"
                                        selected={new Date(entryDate)}
                                        onSelect={(date) => {
                                            if (date) {
                                                const currentTime = new Date(entryDate);
                                                date.setHours(currentTime.getHours());
                                                date.setMinutes(currentTime.getMinutes());
                                                setEntryDate(date.toISOString().slice(0, 16));
                                            }
                                        }}
                                        disabled={(date) =>
                                            date > new Date() || date < new Date("1900-01-01")
                                        }
                                        initialFocus
                                    />
                                </PopoverContent>
                            </Popover>

                            <Select
                                value={format(new Date(entryDate), "HH:mm")}
                                onValueChange={(time) => {
                                    const [hours, minutes] = time.split(':');
                                    const newDate = new Date(entryDate);
                                    newDate.setHours(parseInt(hours), parseInt(minutes));
                                    setEntryDate(newDate.toISOString().slice(0, 16));
                                }}
                            >
                                <SelectTrigger className="w-[130px] font-light">
                                    <SelectValue>
                                        {formatTimeForDisplay(
                                            new Date(entryDate).getHours(),
                                            new Date(entryDate).getMinutes()
                                        )}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {generateTimeOptions().map(({ value, label }) => (
                                        <SelectItem key={value} value={value}>
                                            {label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    {/* Notes */}
                    <div>
                        <label className="block text-sm font-light text-gray-700 mb-2">
                            Notes
                        </label>
                        <textarea
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-primary focus:border-primary font-light"
                            rows={3}
                            placeholder="How are you feeling today? What's on your mind?"
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-primary text-white py-2.5 sm:py-3 rounded-lg sm:rounded-xl text-sm sm:text-base hover:bg-primary/90 transition-colors shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200 font-light"
                    >
                        {initialData ? 'Update Entry' : 'Save How You Feel'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MoodEntryForm; 