import { supabase } from './supabaseClient';
import { TherapistData, UserData } from '../types/usersTypes';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const therapistSignUpApi = {
    updateProfile: async (userId: string, data: TherapistData): Promise<void> => {
        const { error } = await supabase
            .from('therapists')
            .update(data)
            .eq('id', userId);

        if (error) throw error;
    },

    uploadFile: async (bucket: string, fileName: string, file: File): Promise<string> => {
        const { error } = await supabase.storage
            .from(bucket)
            .upload(fileName, file, { upsert: true });

        if (error) throw error;

        const { data } = supabase.storage
            .from(bucket)
            .getPublicUrl(fileName);

        return data.publicUrl;
    },

    updateAuthUser: async (userData: { full_name: string }): Promise<void> => {
        const { error } = await supabase.auth.updateUser({
            data: {
                profile_completed: true,
                full_name: userData.full_name
            }
        });

        if (error) throw error;
    },

    completeProfile: async (userId: string, userData: UserData): Promise<void> => {
        const { error: insertError } = await supabase
            .from('therapists')
            .upsert({
                id: userId,
                ...userData,
                dob: userData.dob ? userData.dob.toISOString().split('T')[0] : null,
            });

        if (insertError) throw insertError;
    },

    signOut: async (): Promise<{ error: Error | null }> => {
        return await supabase.auth.signOut();
    },

    getUser: async (userId: string) => {
        const { data, error } = await supabase
            .from('therapists')
            .select('full_name, email')
            .eq('id', userId)
            .single();

        if (error) throw error;
        return data;
    },
};