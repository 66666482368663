import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AuthCallback: React.FC = () => {
    const { getSession, getUser, handleAuthStateChange } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleCallback = async () => {
            const session = await getSession();
            if (session) {
                const user = await getUser();
                if (user) {
                    await handleAuthStateChange(user);
                } else {
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        handleCallback();
    }, [getSession, getUser, handleAuthStateChange, navigate]);

    return <div>Processing authentication...</div>;
};

export default AuthCallback;
