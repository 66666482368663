import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import useTherapistSignUp from '../../hooks/useTherapistSignUp';
import { useAuthContext } from '../../contexts/AuthContext';

const PendingApproval: React.FC = () => {
  const navigate = useNavigate();
  const { error, handlePendingApproval } = useTherapistSignUp();
  const { user } = useAuthContext();
  useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        navigate('/login');
      }
    };
    checkAuth();
  }, [navigate, user]);

  const handleReturnHome = async () => {
    try {
      if (user) {
        await handlePendingApproval(user.id);
      }
    } catch (error) {
      console.error('Error in handleReturnHome:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex items-center justify-center p-4 pt-24 md:pt-32 pb-52 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md mx-auto p-8 md:p-12">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-normal text-primary">Application Submitted</h2>
              <p className="text-gray-400 mt-2 font-light">Thank you for applying to join Oasys</p>
            </div>

            <div className="mb-8">
              <svg className="mx-auto h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>

            <div className="space-y-4 mb-16">
              <p className="text-gray-600 font-light">
                Your application is now pending review by a member of our team.
              </p>
              <p className="text-gray-600 font-light">
                We typically process applications within 48 hours. You will receive an email once your application has been reviewed.
              </p>
              <p className="text-gray-600 font-light">
                If you have any questions or need to update your application, please contact our support team.
              </p>
            </div>

            <button 
              onClick={handleReturnHome} 
              className="w-full bg-primary text-white py-3 px-4 rounded-lg font-normal hover:bg-primary-dark transition-colors"
            >
              Return to Home
            </button>

            {error && (
              <div className="p-3 bg-red-100 border-red-400 text-red-700 rounded-lg font-light mt-6">
                <span className="block sm:inline">{error}</span>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PendingApproval;
