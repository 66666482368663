import React, { useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import { useAuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { SubmitButton, LinkButton } from "@/components/Shared/Buttons"

const OasysLogo = require('../assets/oasys-logo2.png');

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { resetPasswordForEmail, signOut } = useAuthContext();
    const navigate = useNavigate();

    const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setMessage('');
        setError('');

        const { error } = await resetPasswordForEmail(email);

        if (error) {
            setError(error.message || 'Failed to send reset password email. Please try again.');
            console.error('Error:', error);
        } else {
            setMessage('Password reset link sent to your email. Please check your inbox.');
            setTimeout(async () => {
                await signOut();
                navigate('/login');
            }, 5000);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                <BackgroundAnimation />
                
                <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
                    <div className="bg-white rounded-3xl shadow-2xl w-full max-w-5xl flex overflow-hidden min-h-[600px]">
                        {/* Left Half - Quote Section */}
                        <div className="hidden md:flex md:w-1/2 bg-primary relative border-8 border-primary rounded-l-3xl">
                            <img 
                                src={OasysLogo} 
                                alt="Oasys Logo" 
                                className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55%] h-auto"
                            />
                            <div className="absolute bottom-8 right-8 max-w-[80%]">
                                <blockquote className="text-white font-light text-lg">
                                    "What gets measured gets managed."
                                </blockquote>
                                <cite className="text-white/80 text-sm block mt-2 text-right">
                                    – Peter Drucker
                                </cite>
                            </div>
                        </div>

                        {/* Right Half - Form */}
                        <div className="w-full md:w-1/2 p-8 md:p-12 flex flex-col justify-center bg-white border-8 border-primary rounded-r-3xl">
                            <div className="text-center mb-8">
                                <h2 className="text-3xl font-normal text-primary">Reset Password</h2>
                                <p className="text-gray-500 mt-2 font-light">Enter your email to receive a reset link.</p>
                            </div>

                            <form onSubmit={handleResetPassword} className="space-y-4">
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                                />
                                <SubmitButton type="submit" className="bg-primary text-white hover:bg-primary/90 w-full">
                                    Send Reset Link
                                </SubmitButton>
                            </form>
                            {message && (
                                <div className="mt-4 p-3 bg-green-50 border border-green-400 text-green-700 rounded-lg font-light">
                                    <p>{message}</p>
                                </div>
                            )}
                            {error && (
                                <div className="mt-4 p-3 bg-red-50 border border-red-400 text-red-700 rounded-lg font-light">
                                    <p>{error}</p>
                                </div>
                            )}
                            <div className="mt-4 text-center">
                                <LinkButton asChild className="text-primary hover:text-primary/80">
                                    <Link to="/login">
                                        Back to Login
                                    </Link>
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default ForgotPassword;
