import React from 'react';
import { PatientTabProvider } from '../../../../contexts/PatientTabContext';
import PatientSidebar from './PatientSidebar';
import PatientDetails from './PatientDetails';
import Pagination from './Pagination';
import SessionCard from './SessionCard';
import TherapistHealthDashboard from './TherapistHealthDashboard/TherapistHealthDashboard';
import CreatePatientModal from './CreatePatientModal';
import { usePatientTab } from '../../../../contexts/PatientTabContext';
import { Patient } from '../../../../types';
import { Heart } from 'lucide-react';
import PatientDocuments from './PatientDocuments/PatientDocuments';

interface PatientTabProps {
  patients: Patient[];
  onPatientAction: (patientId: string, action: 'approve' | 'deny') => Promise<void>;
}

const PatientTabContent: React.FC = () => {
  const {
    selectedPatientDetails,
    isLoadingPatient,
    isSidebarCollapsed,
    sortedSessions,
    selectedPatientId,
    isCreatePatientModalOpen,
    isHealthDashboardVisible,
    isHealthDashboardLoading,
    sessionsWithWellbeingScore,
    isMobile,
    toggleHealthDashboard,
    isEmptyState,
    handleCreateDemoPatient,
    openCreatePatientModal
  } = usePatientTab();

  return (
    <div className="bg-white rounded-lg min-h-[800px]">
      <div className="flex h-full">
        <PatientSidebar />
        <div className={`${isSidebarCollapsed ? 'w-[calc(100%-4rem)]' : 'w-3/4'} p-6 overflow-y-auto transition-all duration-300 ease-in-out`}>
          {isLoadingPatient ? (
            <div className="h-full flex items-center justify-center">
              <div className="flex flex-col items-center space-y-4">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                <p className="text-gray-500">Loading patient details...</p>
              </div>
            </div>
          ) : selectedPatientDetails ? (
            <div>
              <PatientDetails />

              <div className="mt-6 mb-6">
                <button
                  onClick={toggleHealthDashboard}
                  disabled={isHealthDashboardLoading}
                  className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200
                    ${isHealthDashboardVisible
                      ? 'bg-primary text-white hover:bg-primary/90'
                      : 'bg-gray-100 text-primary hover:bg-gray-200'
                    } ${isHealthDashboardLoading ? 'opacity-75 cursor-wait' : ''}`}
                >
                  {isHealthDashboardLoading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                      <span className="font-light">Loading Dashboard...</span>
                    </>
                  ) : (
                    <>
                      <Heart className="w-5 h-5" />
                      <span className="font-light">
                        {isHealthDashboardVisible ? 'Hide Health Dashboard' : 'Show Health Dashboard'}
                      </span>
                    </>
                  )}
                </button>
              </div>

              {isHealthDashboardVisible && selectedPatientId && (
                <TherapistHealthDashboard
                  patientId={selectedPatientId}
                  isMobile={isMobile}
                  sessionsWithWellbeingScore={sessionsWithWellbeingScore}
                />
              )}

              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-5 border-r pr-4 min-w-[300px]">
                  <h2 className="text-xl font-normal text-primary mb-4">Session List</h2>
                  {sortedSessions.length > 0 ? (
                    <>
                      <div className="space-y-4">
                        {sortedSessions.map((session) => (
                          <SessionCard key={session.id} session={session} />
                        ))}
                      </div>
                      <Pagination />
                    </>
                  ) : (
                    <p className="text-gray-500 font-light">No sessions available for this patient.</p>
                  )}
                </div>

                <div className="col-span-7 pl-4">
                  <PatientDocuments />
                </div>
              </div>
            </div>
          ) : isEmptyState ? (
            <div className="h-full flex flex-col items-center justify-center space-y-6">
              <div className="text-center space-y-4">
                <h2 className="text-2xl font-semibold text-gray-700">Welcome to Your Patient Dashboard</h2>
                <p className="text-gray-500 max-w-md">
                  You don't have any patients yet. Would you like to explore the platform features with a demo patient?
                </p>
              </div>
              <div className="flex gap-4">
                <button
                  onClick={handleCreateDemoPatient}
                  className="flex items-center gap-2 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
                >
                  <span className="font-light">Create Demo Patient</span>
                </button>
                <button
                  onClick={openCreatePatientModal}
                  className="flex items-center gap-2 px-6 py-3 bg-gray-100 text-primary rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <span className="font-light">Add Real Patient</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-gray-500 text-center text-lg font-light">Select a patient to view details</p>
            </div>
          )}
        </div>
      </div>

      {isCreatePatientModalOpen && <CreatePatientModal />}
    </div>
  );
};

const PatientTab: React.FC<PatientTabProps> = ({ patients, onPatientAction }) => {
  return (
    <PatientTabProvider initialPatients={patients} onPatientAction={onPatientAction}>
      <PatientTabContent />
    </PatientTabProvider>
  );
};

export default PatientTab;
