import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Dumbbell, Heart } from 'lucide-react';
import { DashboardCard } from './TherapistHealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { ActivityData, ActivitySummaryData, CustomTooltipProps } from '../../../../../types';

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

interface ActivityTabProps {
    activityData: ActivityData[];
    bodyData?: ActivityData[];
}

type TimeframeType = 'week' | 'month';

interface ExtendedActivitySummaryData extends ActivitySummaryData {
    rawDate: Date;
}

interface ActivityTooltipProps extends CustomTooltipProps<ActivitySummaryData> {
    chartType?: 'activity' | 'heartRate';
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-3 py-1 text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);

const ActivityTab: React.FC<ActivityTabProps> = ({ activityData, bodyData }) => {
    const [timeframe, setTimeframe] = useState<TimeframeType>('week');

    if (!activityData || activityData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No activity data available.</p>;
    }

    const timeframeDays: Record<TimeframeType, number> = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = (): Date[] => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process activity data with proper date range
    const activitySummaryData: ExtendedActivitySummaryData[] = generateDateRange().map(date => {
        const matchingActivityData = activityData.find(entry =>
            entry.metadata?.start_time ?
                format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
                : false
        );

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            activityMinutes: matchingActivityData?.active_durations_data?.activity_seconds
                ? Math.round(matchingActivityData.active_durations_data.activity_seconds / 60)
                : null,
            totalCalories: matchingActivityData?.calories_data?.total_burned_calories
                ? Math.round(matchingActivityData.calories_data.total_burned_calories)
                : null,
            steps: matchingActivityData?.distance_data?.summary?.steps || null,
            restingHR: matchingActivityData?.heart_rate_data?.summary?.resting_hr_bpm ||
                matchingActivityData?.heart_rate_data?.summary?.avg_hr_bpm || null,
            activityName: matchingActivityData?.metadata?.name || null,
            minHR: matchingActivityData?.heart_rate_data?.summary?.min_hr_bpm || null,
            maxHR: matchingActivityData?.heart_rate_data?.summary?.max_hr_bpm || null,
            avgPace: matchingActivityData?.movement_data?.avg_pace_minutes_per_kilometer || null,
            avgSpeed: matchingActivityData?.movement_data?.avg_speed_meters_per_second || null,
        };
    });

    const calculateAverages = (data: ExtendedActivitySummaryData[]) => {
        const validActivity = data.filter(d => d.activityMinutes !== null);
        const validCalories = data.filter(d => d.totalCalories !== null);
        const validSteps = data.filter(d => d.steps !== null);
        const validRHR = data.filter(d => d.restingHR !== null);

        return {
            avgActivityMinutes: validActivity.length > 0
                ? Math.round(validActivity.reduce((acc, curr) => acc + (curr.activityMinutes || 0), 0) / validActivity.length)
                : null,
            avgCalories: validCalories.length > 0
                ? Math.round(validCalories.reduce((acc, curr) => acc + (curr.totalCalories || 0), 0) / validCalories.length)
                : null,
            avgSteps: validSteps.length > 0
                ? Math.round(validSteps.reduce((acc, curr) => acc + (curr.steps || 0), 0) / validSteps.length)
                : null,
            avgRestingHR: validRHR.length > 0
                ? Math.round(validRHR.reduce((acc, curr) => acc + (curr.restingHR || 0), 0) / validRHR.length)
                : null
        };
    };

    const averages = calculateAverages(activitySummaryData);

    const CustomTooltip: React.FC<ActivityTooltipProps> = ({ active, payload, label, chartType }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload as ActivitySummaryData;
            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                    <p className="font-light text-gray-600 mb-2">{label}</p>
                    <div className="space-y-1">
                        {chartType === 'activity' ? (
                            <>
                                {data.activityName && (
                                    <p className="text-sm font-light">
                                        <span className="text-gray-600">Activity Name: </span>
                                        <span style={{ color: '#f97316' }}>{data.activityName}</span>
                                    </p>
                                )}
                                <p className="text-sm font-light">
                                    <span className="text-gray-600">Activity Duration: </span>
                                    <span style={{ color: '#f97316' }}>
                                        {data.activityMinutes != null ? `${Math.round(data.activityMinutes)} mins` : 'No data'}
                                    </span>
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="text-sm font-light">
                                    <span className="text-gray-600">{payload[0].name}: </span>
                                    <span style={{ color: '#f97316' }}>
                                        {payload[0].value != null ? `${Math.round(payload[0].value)} bpm` : 'No data'}
                                    </span>
                                </p>
                                {data.minHR != null && data.maxHR != null && (
                                    <p className="text-sm font-light">
                                        <span className="text-gray-600">HR Range: </span>
                                        <span style={{ color: '#f97316' }}>
                                            {Math.round(data.minHR)}-{Math.round(data.maxHR)} bpm
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    };

    const calculateActivityStreak = (data: ExtendedActivitySummaryData[]): number => {
        let streak = 0;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayTime = today.getTime();

        const sortedData = [...data].sort((a, b) => b.rawDate.getTime() - a.rawDate.getTime());

        for (let i = 0; i < sortedData.length; i++) {
            const currentDate = sortedData[i].rawDate.getTime();
            const previousDate = i > 0 ? sortedData[i - 1].rawDate.getTime() : null;

            const currentActivityMinutes = sortedData[i].activityMinutes ?? 0;

            if (currentActivityMinutes > 0) {
                if (streak === 0) {
                    const diffDays = Math.floor((todayTime - currentDate) / (1000 * 60 * 60 * 24));
                    if (diffDays <= 1) {
                        streak = 1;
                    } else {
                        break;
                    }
                } else {
                    if (previousDate) {
                        const diffDays = Math.floor((currentDate - previousDate) / (1000 * 60 * 60 * 24));
                        if (diffDays === 1) {
                            streak++;
                        } else {
                            break;
                        }
                    }
                }
            } else {
                break;
            }
        }
        return streak;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-4">
            <div className="flex justify-end space-x-2">
                <TimeframeButton active={timeframe === 'week'} onClick={() => setTimeframe('week')}>Week</TimeframeButton>
                <TimeframeButton active={timeframe === 'month'} onClick={() => setTimeframe('month')}>Month</TimeframeButton>
            </div>

            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-4">
                    <DashboardCard title="Activity Duration" titleClass="text-lg font-light" icon={<Dumbbell className="text-orange-500" />}>
                        {activitySummaryData.some(d => d.activityMinutes !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={activitySummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip chartType="activity" />} />
                                        <Bar dataKey="activityMinutes" name="Activity Minutes" fill="#f97316" radius={[4, 4, 0, 0]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                <div className="col-span-4">
                    <DashboardCard
                        title={activityData.some(d => d.heart_rate_data?.summary?.resting_hr_bpm) ?
                            "Resting Heart Rate" : "Average Heart Rate"}
                        titleClass="text-lg font-light"
                        icon={<Heart className="text-orange-500" />}
                    >
                        {activitySummaryData.some(d => d.restingHR !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip chartType="heartRate" />} />
                                        <Line
                                            type="monotone"
                                            dataKey="restingHR"
                                            name={activityData.some(d => d.heart_rate_data?.summary?.resting_hr_bpm) ?
                                                "Resting HR" : "Average HR"}
                                            stroke="#f97316"
                                            strokeWidth={2}
                                            dot={{ fill: '#f97316', strokeWidth: 2 }}
                                            connectNulls={true}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                <div className="col-span-4 grid grid-cols-2 gap-4 h-64">
                    {[
                        ['Activity Time', 'Calories Burned'],
                        ['Steps', 'Activity Streak']
                    ].map((row, rowIndex) => (
                        row.map((metric, colIndex) => {
                            let value;
                            const streak = calculateActivityStreak(activitySummaryData);

                            value = metric === 'Activity Time' ? averages.avgActivityMinutes :
                                metric === 'Calories Burned' ? averages.avgCalories :
                                    metric === 'Steps' ? averages.avgSteps :
                                        streak;

                            const displayValue = value !== null
                                ? `${value}${metric === 'Activity Time' ? ' mins' :
                                    metric === 'Activity Streak' ? ' days' :
                                        metric === 'Steps' ? '' : ' cal'}`
                                : 'N/A';

                            const periodLabel = timeframe === 'week' ? '7-day avg' : '30-day avg';

                            return (
                                <div key={metric}
                                    className="bg-white rounded-lg p-4 shadow-sm border border-gray-100 
                                              flex flex-col justify-center">
                                    <h3 className="text-gray-500 text-sm font-light">{metric}</h3>
                                    <p className={`text-xl font-light mt-1 ${value === null ? 'text-gray-400' : 'text-gray-900'}`}>
                                        {displayValue}
                                    </p>
                                    <p className="text-xs text-gray-400 mt-1">{periodLabel}</p>
                                </div>
                            );
                        })
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActivityTab;