import React, { createContext, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import { User, Session } from '@supabase/supabase-js';

interface UserData {
    id: string;
    email: string;
    [key: string]: any;
}

interface AuthContextType {
    session: Session | null;
    user: User | null;
    isLoading: boolean;
    userData: UserData | null;
    isAdmin: boolean;
    isTherapist: boolean;
    isProfileCompleted: boolean;
    signIn: (email: string, password: string) => Promise<any>;
    signUp: (email: string, password: string, role: 'therapist' | 'patient') => Promise<any>;
    signOut: () => Promise<void>;
    resendConfirmationEmail: (email: string) => Promise<{ error: Error | null }>;
    fetchUserData: () => Promise<void>;
    resetPasswordForEmail: (email: string) => Promise<{ error: Error | null }>;
    updatePassword: (newPassword: string) => Promise<{ error: Error | null }>;
    getSession: () => Promise<Session | null>;
    getUser: () => Promise<User | null>;
    verifyOtp: (tokenHash: string) => Promise<{ error: Error | null }>;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const auth = useAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};
