import React from 'react';

interface DashboardCardProps {
    title: string;
    titleClass?: string;
    icon: React.ReactNode;
    children: React.ReactNode;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({ title, icon, children, titleClass }) => (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex items-center mb-4">
            {icon}
            <h3 className={`text-base font-normal ml-2 ${titleClass || ''}`}>{title}</h3>
        </div>
        {children}
    </div>
);

interface Thresholds {
    good: number;
    average: number;
}

export const getStatusColor = (value: number, thresholds: Thresholds, reverse: boolean = false): string => {
    if (reverse) {
        if (value <= thresholds.good) return 'text-green-500';
        if (value <= thresholds.average) return 'text-yellow-500';
        return 'text-red-500';
    }
    if (value >= thresholds.good) return 'text-green-500';
    if (value >= thresholds.average) return 'text-yellow-500';
    return 'text-red-500';
};

export const round = (num: number): string => Number(num).toFixed(2); 