import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BackgroundAnimation from './BackgroundAnimationMobile';
import Footer from './FooterMobile';
import { api } from '../../services/api';
import { ContactFormData } from '../../types/feedbackTypes';
import { SubmitButton } from "@/components/Shared/Buttons"

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState<ContactFormData>({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        try {
            await api.contact.submitForm(formData);
            setIsSubmitted(true);
            setFormData({ name: '', email: '', message: '' });
            setTimeout(() => setIsSubmitted(false), 5000);
        } catch (error: any) {
            console.error('Error submitting form:', error);
            setError(`An error occurred while submitting the form: ${error.response?.data?.detail || error.message}`);
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-br from-background to-lightGray relative overflow-hidden">
            <BackgroundAnimation />
            <main className="flex-grow flex items-center justify-center px-4 py-12 md:py-20 relative z-10 mt-24">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white rounded-2xl shadow-2xl p-6 md:p-8 w-full max-w-md"
                >
                    <h2 className="text-2xl md:text-3xl font-normal text-primary mb-6 text-center">Contact Us</h2>

                    <AnimatePresence>
                        {isSubmitted && (
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -50 }}
                                className="absolute top-0 left-0 right-0 bg-green-500 text-white p-4 rounded-t-2xl text-center font-light"
                            >
                                Thank you! Your message has been submitted.
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative mb-4 font-light" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-light text-gray-700 mb-1">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-light text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                            />
                        </div>

                        <div>
                            <label htmlFor="message" className="block text-sm font-light text-gray-700 mb-1">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows={4}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light"
                            ></textarea>
                        </div>

                        <SubmitButton type="submit">
                            Send Message
                        </SubmitButton>
                    </form>
                </motion.div>
            </main>
            <Footer />
        </div>
    );
};

export default ContactUs; 