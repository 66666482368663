// frontend/src/components/TherapistDashboard/Sessions/VideoMeeting.tsx
import React, { useContext } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { SessionContext } from '../../../../contexts/SessionContext';

interface VideoMeetingProps {
    roomName: string;
    onMeetingEnd: () => void;
}

export const generateMeetingUrl = (roomName: string) => {
    return `https://8x8.vc/vpaas-magic-cookie-f247bd904ea34361943010b7c9278d61/${roomName}`;
};

const VideoMeeting: React.FC<VideoMeetingProps> = ({ roomName, onMeetingEnd }) => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error('VideoMeeting must be used within a SessionProvider');
    }

    const { currentUser } = context;

    return (
        <div style={{ height: '600px', width: '100%' }}>
            <JitsiMeeting
                domain="8x8.vc"
                roomName={`vpaas-magic-cookie-f247bd904ea34361943010b7c9278d61/${roomName}`}
                configOverwrite={{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: false,
                    enableEmailInStats: false,
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                }}
                userInfo={{
                    displayName: currentUser.full_name,
                    email: currentUser.email,
                }}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100%';
                }}
                onReadyToClose={onMeetingEnd}
            />
        </div>
    );
};

export default VideoMeeting;
