import React from 'react';

interface CodeOfEthicsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const CodeOfEthicsModal: React.FC<CodeOfEthicsModalProps> = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm overflow-y-auto h-full w-full z-50 flex items-center justify-center px-4">
      <div className="relative mx-auto w-full max-w-2xl bg-white rounded-3xl shadow-2xl">
        <div className="p-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl font-normal text-primary">Professional Code of Ethics</h3>
            <p className="text-gray-400 mt-2 font-light">Our commitment to ethical practice and client care</p>
          </div>

          <div className="space-y-6 text-left">
            <div>
              <h4 className="text-lg font-normal text-gray-800 mb-2">Core Ethical Principles</h4>
              <div className="space-y-4 font-light">
                <p className="text-gray-600">
                  <span className="text-primary font-normal">1. Professional Competence</span><br />
                  Maintain high standards of professional competence through continuous education and training. Practice only within areas of expertise and qualification.
                </p>

                <p className="text-gray-600">
                  <span className="text-primary font-normal">2. Client Confidentiality</span><br />
                  Protect client privacy and maintain strict confidentiality of all client information, except when required by law or when there is risk of serious harm.
                </p>

                <p className="text-gray-600">
                  <span className="text-primary font-normal">3. Informed Consent</span><br />
                  Ensure clients understand and agree to the therapeutic process, including its benefits, risks, and alternatives. Obtain explicit consent for all treatment procedures.
                </p>

                <p className="text-gray-600">
                  <span className="text-primary font-normal">4. Professional Boundaries</span><br />
                  Maintain appropriate professional boundaries with clients at all times. Avoid dual relationships that could compromise therapeutic effectiveness.
                </p>

                <p className="text-gray-600">
                  <span className="text-primary font-normal">5. Cultural Competence</span><br />
                  Respect and acknowledge cultural, individual, and role differences. Actively work to provide culturally informed and responsive therapy.
                </p>

                <p className="text-gray-600">
                  <span className="text-primary font-normal">6. Client Welfare</span><br />
                  Prioritize client wellbeing and act in the best interest of clients. Avoid any actions that could cause harm or exploitation.
                </p>
              </div>
            </div>

            <div>
              <h4 className="text-lg font-normal text-gray-800 mb-2">Professional Conduct</h4>
              <p className="text-gray-600 font-light">
                By agreeing to this code of ethics, you commit to maintaining the highest standards of professional conduct, 
                including regular supervision, ongoing professional development, and adherence to all applicable laws and 
                regulations governing mental health practice.
              </p>
            </div>
          </div>

          <div className="mt-8 flex justify-end">
            <button
              onClick={handleAccept}
              className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-light"
            >
              I Agree to the Code of Ethics
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeOfEthicsModal;