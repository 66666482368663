import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import { UserData } from '../types/usersTypes';
import { ProfessionalInfoFormData, PracticeDetailsData, BioAndApproachData, VerificationData } from '../types/therapistSignUpTypes';


const useTherapistSignUp = () => {
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleProfessionalInfo = async (userId: string, formData: ProfessionalInfoFormData): Promise<void> => {
        try {
            await api.therapistSignUp.updateProfile(userId, {
                full_name: formData.full_name,
                license: formData.license,
                years_of_experience: formData.years_of_experience,
                languages: formData.languages,
                education: formData.education
            });

            // Update auth metadata with the new name
            await api.therapistSignUp.updateAuthUser({ full_name: formData.full_name });

            navigate('/therapist-onboarding/practice-details');
        } catch (err) {
            console.error('Error in handleProfessionalInfo:', err);
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    const handlePracticeDetails = async (userId: string, data: PracticeDetailsData): Promise<void> => {
        try {
            await api.therapistSignUp.updateProfile(userId, {
                therapy_types: data.therapyTypes,
                age_groups_served: data.ageGroups,
                telehealth_available: data.telehealthAvailable,
                insurance_accepted: data.insurancePlans,
                address: data.address,
                specializations: data.specializations
            });
            navigate('/therapist-onboarding/bio-and-approach');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    const handleBioAndApproach = async (userId: string, data: BioAndApproachData): Promise<void> => {
        try {
            let profilePictureUrl = data.profilePictureUrl;

            if (data.profilePicture) {
                const fileExt = data.profilePicture.name.split('.').pop();
                const fileName = `${userId}_profile.${fileExt}`;
                profilePictureUrl = await api.therapistSignUp.uploadFile(
                    'therapist_profile_pictures', 
                    fileName, 
                    data.profilePicture
                );
            }

            await api.therapistSignUp.updateProfile(userId, {
                professional_bio: data.professionalBio,
                therapy_approach: data.therapyApproach,
                profile_picture_url: profilePictureUrl
            });
            navigate('/therapist-onboarding/verification-submission');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    const handleVerificationSubmission = async (userId: string, data: VerificationData): Promise<void> => {
        try {
            let fileName = null;

            if (data.licenseFile) {
                const fileExt = data.licenseFile.name.split('.').pop();
                fileName = `${userId}_license.${fileExt}`;
                await api.therapistSignUp.uploadFile('license_documents', fileName, data.licenseFile);
            }

            await api.therapistSignUp.updateProfile(userId, {
                license_document: fileName,
                agreed_to_ethics: data.agreeToEthics,
                agreed_to_background_check: data.agreeToBackgroundCheck,
                status: 'pending_review'
            });

            // Send admin notification
            const user = await api.therapistSignUp.getUser(userId);
            if (user) {
                await api.contact.notifyAdminTherapistSignup({
                    name: user.full_name,
                    email: user.email
                });
            }

            navigate('/therapist-onboarding/pending-approval');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    const handlePendingApproval = async (userId: string): Promise<void> => {
        try {
            await api.therapistSignUp.updateProfile(userId, { status: 'pending_review' });
            await api.therapistSignUp.signOut();
            navigate('/');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    const completeTherapistProfile = async (userId: string, userData: UserData): Promise<void> => {
        try {
            await api.therapistSignUp.completeProfile(userId, userData);
            // Only update auth user if full_name is provided
            if (userData.full_name) {
                await api.therapistSignUp.updateAuthUser({ full_name: userData.full_name });
            }
        } catch (err) {
            console.error('Error completing therapist profile:', err);
            throw err;
        }
    };

    return {
        error,
        handleProfessionalInfo,
        handlePracticeDetails,
        handleBioAndApproach,
        handleVerificationSubmission,
        handlePendingApproval,
        completeTherapistProfile
    };
};

export default useTherapistSignUp;
