import { getAccessToken } from './utils';
import axios from 'axios';
import { supabase } from './supabaseClient';
import { 
    Session, 
    SessionCreate, 
    ChatMessage, 
    SessionsResponse,
    PatientDetailsSessions
} from '../types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const sessionsApi = {
    getSessions: async (userId: string, page: number = 1, searchTerm: string = '', pageSize: number = 10): Promise<SessionsResponse> => {
        let query = supabase
            .from('sessions')
            .select('*, patient:users!patient_id(full_name), wellbeing_score', { count: 'exact' })
            .eq('therapist_id', userId)
            .order('date', { ascending: false })
            .order('time', { ascending: false });

        if (searchTerm) {
            query = query.ilike('patient.full_name', `%${searchTerm}%`);
        }

        const { data, error, count } = await query
            .range((page - 1) * pageSize, page * pageSize - 1);

        if (error) throw error;
        
        // Handle the null case for count
        return { 
            data: data || [], 
            count: count || 0 
        };
    },
    updateSession: async (sessionId: string, sessionData: SessionCreate): Promise<Session> => {
        try {
            const response = await fetch(`${API_URL}/sessions/${sessionId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sessionData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(JSON.stringify(errorData));
            }
            return response.json();
        } catch (error) {
            console.error('Error in updateSession:', error);
            throw error;
        }
    },
    deleteSession: async (sessionId: string): Promise<{ success: boolean; message: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/${sessionId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                },
            });

            if (response.status === 204) {
                return { success: true, message: 'Session deleted successfully' };
            }

            if (!response.ok) {
                const errorData = await response.json().catch(() => ({}));
                throw new Error(errorData.detail || 'Failed to delete session');
            }

            return await response.json();
        } catch (error) {
            console.error('Error in deleteSession:', error);
            throw error;
        }
    },
    summarize: async (data: { 
        content: string, 
        fileUrl: string, 
        format: string 
    }): Promise<{ summary: string }> => {
        const response = await axios.post(
            `${API_URL}/sessions/summarize`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    },
    regenerateSummary: async (
        sessionId: string, 
        data: { 
            content: string, 
            fileUrl: string, 
            format: string 
        }
    ): Promise<{ summary: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/${sessionId}/regenerate-summary`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Failed to regenerate summary');
            }

            return response.json();
        } catch (error) {
            console.error('Error regenerating summary:', error);
            throw error;
        }
    },
    createSession: async (sessionData: SessionCreate): Promise<Session> => {
        try {
            const response = await fetch(`${API_URL}/sessions/create-session`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sessionData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(JSON.stringify(errorData));
            }
            const data = await response.json();
            return data;  // Return the created session data
        } catch (error) {
            console.error('Error in createSession:', error);
            throw error;
        }
    },
    generatePDF: async (sessionId: string): Promise<Blob> => {
        try {
            const response = await fetch(`${API_URL}/sessions/${sessionId}/pdf`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to generate PDF');
            }

            return response.blob();
        } catch (error) {
            console.error('Error generating PDF:', error);
            throw error;
        }
    },
    getLastSessionNumber: async (therapistId: string, patientId: string): Promise<number> => {
        try {
            const response = await fetch(`${API_URL}/sessions/last-session-number`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ therapist_id: therapistId, patient_id: patientId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch last session number');
            }

            const data = await response.json();
            return data.lastSessionNumber;
        } catch (error) {
            console.error('Error fetching last session number:', error);
            throw error;
        }
    },
    getPatientSessionPrep: async (patientId: string): Promise<{ aiAnalysis: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/patient-prep/${patientId}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch patient session prep data');
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching patient session prep:', error);
            throw error;
        }
    },
    chatAboutSessionPrep: async (
        patientId: string, 
        message: string, 
        chatHistory: ChatMessage[], 
        aiAnalysis: string
    ): Promise<{ response: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/prep-chat/${patientId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message,
                    chatHistory,
                    aiAnalysis
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to get chat response about session prep');
            }

            return response.json();
        } catch (error) {
            console.error('Error in session prep chat:', error);
            throw error;
        }
    },
    getSessionPrepNotes: async (patientId: string): Promise<{ prepNotes: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/prep-notes/${patientId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch session prep notes');
            }

            return response.json();
        } catch (error) {
            console.error('Error fetching session prep notes:', error);
            throw error;
        }
    },
    getSessionPrepNotesStream: async (
        patientId: string,
        onChunk: (chunk: string) => void
    ): Promise<void> => {
        try {
            const response = await fetch(
                `${API_URL}/sessions/prep-notes/${patientId}/stream`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch session prep notes');
            }

            const reader = response.body?.getReader();
            if (!reader) throw new Error('No reader available');

            const decoder = new TextDecoder();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value);
                onChunk(chunk);
            }
        } catch (error) {
            console.error('Error fetching session prep notes stream:', error);
            throw error;
        }
    },
    getPatientDetails: async (patientId: string): Promise<PatientDetailsSessions> => {
        try {
            const response = await fetch(
                `${API_URL}/sessions/patient-details/${patientId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch patient details');
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching patient details:', error);
            throw error;
        }
    },
    createDemoSession: async (sessionData: SessionCreate): Promise<Session> => {
        try {
            const response = await fetch(`${API_URL}/sessions/create-demo-session`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sessionData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(JSON.stringify(errorData));
            }
            return response.json();
        } catch (error) {
            console.error('Error in createDemoSession:', error);
            throw error;
        }
    },
    sendMeetingInvite: async (patientId: string, meetingUrl: string): Promise<{ message: string }> => {
        try {
            const response = await fetch(`${API_URL}/sessions/send-meeting-invite/${patientId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ meeting_url: meetingUrl }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to send meeting invite');
            }

            return response.json();
        } catch (error) {
            console.error('Error sending meeting invite:', error);
            throw error;
        }
    },
    chatAboutSessionPrepStream: async (
        patientId: string,
        message: string,
        chatHistory: ChatMessage[],
        aiAnalysis: string,
        onChunk: (chunk: string) => void
    ): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/sessions/prep-chat/${patientId}/stream`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message,
                    chatHistory,
                    aiAnalysis
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to get streaming chat response');
            }

            const reader = response.body?.getReader();
            if (!reader) throw new Error('No reader available');

            const decoder = new TextDecoder();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value);
                onChunk(chunk);
            }
        } catch (error) {
            console.error('Error in session prep chat stream:', error);
            throw error;
        }
    },
};
