import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ProfileFormProps } from '../../types/patientOnboardingTypes';
import { Check, ChevronsUpDown, CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";

const ProfileForm: React.FC<ProfileFormProps> = ({ userData, handleInputChange, handleDateChange }) => {
    const [localNames, setLocalNames] = useState({
        firstName: userData.full_name?.split(' ')[0] || '',
        lastName: userData.full_name?.split(' ').slice(1).join(' ') || ''
    });
    const [openGender, setOpenGender] = useState(false);
    const [openReason, setOpenReason] = useState(false);

    const genderOptions = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "non-binary", label: "Non-binary" },
        { value: "prefer-not-to-say", label: "Prefer not to say" }
    ];

    const reasonOptions = [
        { value: "personal-growth", label: "Personal Growth & Self-Discovery" },
        { value: "life-goals", label: "Life Goals & Direction" },
        { value: "career-development", label: "Career Development" },
        { value: "relationship-growth", label: "Relationship Enhancement" },
        { value: "emotional-wellness", label: "Emotional Wellness" },
        { value: "mindfulness", label: "Mindfulness & Presence" },
        { value: "confidence", label: "Building Confidence" },
        { value: "life-balance", label: "Life Balance & Wellbeing" },
        { value: "stress-management", label: "Stress Management" },
        { value: "communication", label: "Communication Skills" },
        { value: "life-changes", label: "Life Transitions Support" },
        { value: "anxiety-management", label: "Anxiety Management" },
        { value: "mood-support", label: "Mood Support" },
        { value: "grief-support", label: "Grief Support" },
        { value: "trauma-healing", label: "Trauma Healing" },
        { value: "relationship-support", label: "Relationship Support" },
        { value: "family-dynamics", label: "Family Dynamics" },
        { value: "sleep-wellness", label: "Sleep Wellness" },
        { value: "identity-exploration", label: "Identity Exploration" },
        { value: "emotional-regulation", label: "Emotional Regulation" },
        { value: "other", label: "Other" }
    ];

    // Handle first and last name changes
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        // Update local state
        const newNames = {
            ...localNames,
            [name === 'first_name' ? 'firstName' : 'lastName']: value
        };
        setLocalNames(newNames);

        // Combine and update parent state
        const fullName = `${newNames.firstName} ${newNames.lastName}`.trim();
        handleInputChange({
            target: {
                name: 'full_name',
                value: fullName
            }
        });
    };

    // Helper function to handle date conversion
    const getSelectedDate = () => {
        if (!userData.dob) return undefined;
        const date = new Date(userData.dob);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    };

    return (
        <form className="space-y-6">
            {/* Names grid */}
            <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                    <Label htmlFor="first_name" className="text-sm text-gray-500 font-light">
                        First Name *
                    </Label>
                    <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={localNames.firstName}
                        onChange={handleNameChange}
                        className="font-light"
                        required
                    />
                </div>
                <div className="space-y-2">
                    <Label htmlFor="last_name" className="text-sm text-gray-500 font-light">
                        Last Name *
                    </Label>
                    <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={localNames.lastName}
                        onChange={handleNameChange}
                        className="font-light"
                        required
                    />
                </div>
            </div>

            {/* DOB and Gender grid */}
            <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                    <Label htmlFor="dob" className="text-sm text-gray-500 font-light">
                        Date of Birth *
                    </Label>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={cn(
                                    "w-full justify-start text-left font-light",
                                    !userData.dob && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {userData.dob ? format(new Date(userData.dob), "PPP") : <span>Pick a date</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                mode="single"
                                selected={getSelectedDate()}
                                onSelect={(date) => {
                                    if (date) {
                                        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                                        const adjustedDate = new Date(date.getTime() - userTimezoneOffset);
                                        handleDateChange(adjustedDate);
                                    }
                                }}
                                disabled={(date) =>
                                    date > new Date() || date < new Date("1900-01-01")
                                }
                                initialFocus
                                captionLayout="dropdown-buttons"
                                fromYear={1900}
                                toYear={new Date().getFullYear()}
                                ISOWeek
                                showOutsideDays={false}
                                classNames={{
                                    caption_dropdowns: "flex justify-between w-full px-2",
                                    dropdown: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                                    dropdown_month: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                                    dropdown_year: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                                    caption: "flex justify-center pt-1 relative items-center",
                                    caption_label: "hidden",
                                    nav: "hidden",
                                    nav_button: "hidden",
                                    nav_button_previous: "hidden",
                                    nav_button_next: "hidden"
                                }}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
                <div>
                    <Label htmlFor="gender" className="text-sm text-gray-500 font-light">
                        Gender *
                    </Label>
                    <div className="mt-2">
                        <Popover open={openGender} onOpenChange={setOpenGender}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    aria-expanded={openGender}
                                    className="w-full justify-between font-light"
                                >
                                    {userData.gender
                                        ? genderOptions.find((option) => option.value === userData.gender)?.label
                                        : "Select Gender"}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-full p-0" align="start">
                                <Command>
                                    <CommandInput placeholder="Search gender..." />
                                    <CommandList>
                                        <CommandEmpty>No gender found.</CommandEmpty>
                                        <CommandGroup>
                                            {genderOptions.map((option) => (
                                                <CommandItem
                                                    key={option.value}
                                                    value={option.label}
                                                    onSelect={() => {
                                                        handleInputChange({
                                                            target: { name: 'gender', value: option.value }
                                                        } as React.ChangeEvent<HTMLSelectElement>);
                                                        setOpenGender(false);
                                                    }}
                                                >
                                                    <Check
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            userData.gender === option.value ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {option.label}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>

            {/* Primary Reason */}
            <div>
                <Label htmlFor="primary_reason" className="text-sm text-gray-500 font-light">
                    Primary Reason *
                </Label>
                <div className="mt-2">
                    <Popover open={openReason} onOpenChange={setOpenReason}>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={openReason}
                                className="w-full justify-between font-light"
                            >
                                {userData.primary_reason
                                    ? reasonOptions.find((option) => option.value === userData.primary_reason)?.label
                                    : "What brings you here today?"}
                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0" align="start">
                            <Command>
                                <CommandInput placeholder="Search reasons..." />
                                <CommandList>
                                    <CommandEmpty>No reason found.</CommandEmpty>
                                    <CommandGroup>
                                        {reasonOptions.map((option) => (
                                            <CommandItem
                                                key={option.value}
                                                value={option.label}
                                                onSelect={() => {
                                                    handleInputChange({
                                                        target: { name: 'primary_reason', value: option.value }
                                                    } as React.ChangeEvent<HTMLSelectElement>);
                                                    setOpenReason(false);
                                                }}
                                            >
                                                <Check
                                                    className={cn(
                                                        "mr-2 h-4 w-4",
                                                        userData.primary_reason === option.value ? "opacity-100" : "opacity-0"
                                                    )}
                                                />
                                                {option.label}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>

            <div className="space-y-3">
                <p className="text-sm font-light text-gray-500">Have you been to therapy before? *</p>
                <div className="flex space-x-6">
                    {['yes', 'no'].map((option) => (
                        <label key={option} className="flex items-center">
                            <input
                                type="radio"
                                name="therapy_before"
                                value={option}
                                checked={userData.therapy_before === option}
                                onChange={handleInputChange}
                                required
                                className="mr-2 text-primary focus:ring-primary/30"
                            />
                            <span className="capitalize font-light text-gray-700">{option}</span>
                        </label>
                    ))}
                </div>
            </div>
            <div className="space-y-3">
                <p className="text-sm font-light text-gray-500">How would you rate your current mental health? *</p>
                <div className="flex justify-between items-center">
                    {['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'].map((label, index) => (
                        <button
                            key={label}
                            type="button"
                            onClick={() => handleInputChange({ target: { name: 'mental_health_rating', value: index + 1 } })}
                            className={`px-4 py-2 rounded-lg text-sm transition-all duration-200 ${
                                userData.mental_health_rating === index + 1
                                    ? 'bg-primary text-white font-light'
                                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100 font-light'
                            }`}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>
        </form>
    );
};

export default ProfileForm;
