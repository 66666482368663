import React, { useState, FormEvent } from 'react';
import { api } from '../../services/api';
import { FaPaperPlane, FaChartLine } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

interface FeedbackFormProps {
    isTherapist: boolean;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ isTherapist }) => {
    const [feedback, setFeedback] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage('');

        try {
            if (isTherapist) {
                await api.feedback.submitFeedbackTherapist(feedback);
            } else {
                await api.feedback.submitFeedbackUser(feedback);
            }
            setSuccessMessage('Feedback submitted successfully!');
            setFeedback('');
        } catch (err) {
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="bg-white rounded-lg p-6" data-testid="feedback-form">
            <h2 className="text-2xl font-normal text-primary mb-6">Provide Feedback</h2>
            <div className="space-y-8">
                <div className="bg-white text-primary rounded-lg p-6 md:p-8 border border-gray-200 shadow-sm">
                    <div className="flex items-center text-sm md:text-base text-gray-600 mb-4 font-light">
                        <span>Your insights help us improve</span>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="bg-gray-100 rounded-lg p-4">
                            <textarea
                                className="w-full bg-transparent text-gray-800 placeholder-gray-500 border-none focus:outline-none focus:ring-0 resize-none font-light"
                                rows={isMobile ? 4 : 6}
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder="Share your thoughts here..."
                                required
                                data-testid="feedback-textarea"
                            />
                        </div>
                        {error && <p className="text-red-500 font-light" role="alert">{error}</p>}
                        {successMessage && <p className="text-green-500 font-light">{successMessage}</p>}
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="px-6 py-3 bg-primary text-white rounded-full hover:bg-primary-dark transition duration-150 ease-in-out flex items-center font-normal"
                                disabled={isSubmitting}
                                data-testid="submit-feedback-button"
                            >
                                {isSubmitting ? (
                                    <>
                                        <FaPaperPlane className="mr-2 animate-pulse" />
                                        Sending...
                                    </>
                                ) : (
                                    <>
                                        <FaPaperPlane className="mr-2" />
                                        Submit Feedback
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm" data-testid="feedback-impact-section">
                    <h3 className="text-xl font-normal text-primary mb-4 flex items-center">
                        <FaChartLine className="mr-2" />
                        Feedback Impact
                    </h3>
                    <p className="text-gray-600 mb-4 font-light">
                        Your feedback helps us continuously improve our platform. Here's how your input makes a difference:
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-600 font-light">
                        <li>Enhances user experience for therapists and patients</li>
                        <li>Guides our product development roadmap</li>
                        <li>Helps us prioritize new features and improvements</li>
                        <li>Identifies and resolves potential issues quickly</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;
