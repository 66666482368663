import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FiSend } from 'react-icons/fi';
import { BiLoaderAlt } from 'react-icons/bi';
import { api } from '../../../../services/api';
import { SessionChatMessage, SessionPrepChatProps } from '../../../../types/sessionsTypes';

const LoadingIndicator = () => (
    <div className="flex justify-start">
        <div className="max-w-3/4 p-3 rounded-lg bg-gray-100">
            <div className="flex space-x-2">
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '0ms' }}></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '150ms' }}></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '300ms' }}></div>
            </div>
        </div>
    </div>
);

const SessionPrepChat: React.FC<SessionPrepChatProps> = ({
    patientID,
    aiAnalysis,
    chatMessages,
    setChatMessages
}) => {
    const [chatInput, setChatInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [streamingMessage, setStreamingMessage] = useState<string>('');
    const lastMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages, streamingMessage]);

    const handleSendMessage = async () => {
        if (!chatInput.trim()) return;

        const newUserMessage: SessionChatMessage = { role: 'user', content: chatInput };
        setChatMessages(prev => [...prev, newUserMessage]);
        setChatInput('');
        setIsLoading(true);
        setStreamingMessage('');

        try {
            let accumulatedMessage = '';

            await api.sessions.chatAboutSessionPrepStream(
                patientID,
                chatInput,
                chatMessages,
                aiAnalysis,
                (chunk: string) => {
                    accumulatedMessage += chunk;
                    setStreamingMessage(accumulatedMessage);
                }
            );

            setChatMessages(prev => [...prev, {
                role: 'assistant',
                content: accumulatedMessage
            }]);

        } catch (error) {
            console.error('Error sending message:', error);
            setChatMessages(prev => [
                ...prev,
                { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' }
            ]);
        } finally {
            setIsLoading(false);
            setStreamingMessage('');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const cleanHtmlFromContent = (content: string) => {
        return content
            .replace(/<div[^>]*>/g, '')
            .replace(/<\/div>/g, '\n')
            .replace(/<h3[^>]*>/g, '### ')
            .replace(/<\/h3>/g, '\n')
            .replace(/<p[^>]*>/g, '')
            .replace(/<\/p>/g, '\n')
            .replace(/<ul[^>]*>/g, '')
            .replace(/<\/ul>/g, '\n')
            .replace(/<li[^>]*>/g, '- ')
            .replace(/<\/li>/g, '\n')
            .replace(/<[^>]+>/g, '')
            .replace(/\n\s*\n/g, '\n\n')
            .trim();
    };

    return (
        <div className="flex flex-col h-full">
            <div
                className="flex-1 overflow-y-auto p-4 space-y-4"
                ref={lastMessageRef}
                style={{ scrollBehavior: 'smooth', maxHeight: 'calc(100vh - 16rem)' }}
            >
                {chatMessages.map((message: SessionChatMessage, index: number) => (
                    <div key={index} className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                        <div className={`max-w-3/4 p-3 rounded-lg ${message.role === 'assistant'
                            ? 'bg-white shadow-sm text-gray-800'
                            : 'bg-primary/90 text-white'
                            }`}>
                            <ReactMarkdown
                                components={{
                                    p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                                    h1: ({ children }) => <h1 className="text-2xl font-normal mb-2">{children}</h1>,
                                    h2: ({ children }) => <h2 className="text-xl font-normal mb-2">{children}</h2>,
                                    h3: ({ children }) => <h3 className="text-lg font-normal mb-2">{children}</h3>,
                                    h4: ({ children }) => <h4 className="text-md font-normal mb-2">{children}</h4>,
                                    h5: ({ children }) => <h5 className="text-sm font-normal mb-2">{children}</h5>,
                                    h6: ({ children }) => <h6 className="text-xs font-normal mb-2">{children}</h6>,
                                    ul: ({ children }) => <ul className="list-disc pl-5 mb-4">{children}</ul>,
                                    ol: ({ children }) => <ol className="list-decimal pl-5 mb-4">{children}</ol>,
                                    li: ({ children }) => <li className="mb-1">{children}</li>,
                                    a: ({ href, children }) => (
                                        <a
                                            href={href}
                                            className="text-blue-500 hover:underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {children}
                                        </a>
                                    ),
                                }}
                            >
                                {cleanHtmlFromContent(message.content)}
                            </ReactMarkdown>
                        </div>
                    </div>
                ))}
                {streamingMessage && (
                    <div className="flex justify-start">
                        <div className="max-w-3/4 p-3 rounded-lg bg-white shadow-sm text-gray-800">
                            <ReactMarkdown
                                components={{
                                    p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                                    h1: ({ children }) => <h1 className="text-2xl font-normal mb-2">{children}</h1>,
                                    h2: ({ children }) => <h2 className="text-xl font-normal mb-2">{children}</h2>,
                                    h3: ({ children }) => <h3 className="text-lg font-normal mb-2">{children}</h3>,
                                    h4: ({ children }) => <h4 className="text-md font-normal mb-2">{children}</h4>,
                                    h5: ({ children }) => <h5 className="text-sm font-normal mb-2">{children}</h5>,
                                    h6: ({ children }) => <h6 className="text-xs font-normal mb-2">{children}</h6>,
                                    ul: ({ children }) => <ul className="list-disc pl-5 mb-4">{children}</ul>,
                                    ol: ({ children }) => <ol className="list-decimal pl-5 mb-4">{children}</ol>,
                                    li: ({ children }) => <li className="mb-1">{children}</li>,
                                    a: ({ href, children }) => (
                                        <a
                                            href={href}
                                            className="text-blue-500 hover:underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {children}
                                        </a>
                                    ),
                                }}
                            >
                                {cleanHtmlFromContent(streamingMessage)}
                            </ReactMarkdown>
                        </div>
                    </div>
                )}
                {isLoading && !streamingMessage && <LoadingIndicator />}
            </div>
            <div className="p-4 border-t border-gray-200 bg-white">
                <div className="flex items-center space-x-2">
                    <input
                        type="text"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Ask about the patient..."
                        className="flex-grow px-4 py-2 border border-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-primary/50 focus:border-transparent font-light"
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={isLoading}
                        className="bg-primary hover:bg-primary/90 text-white p-3 rounded-full transition-colors duration-300 flex items-center justify-center disabled:opacity-50"
                        aria-label="Send message"
                    >
                        <FiSend className="text-lg" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SessionPrepChat;
