import React, { useEffect } from 'react';
import { FiActivity, FiHeart, FiShield, FiEdit3, FiLayout, FiClock, FiCalendar } from 'react-icons/fi';
import FAQ from '../components/HomePageMobile/FAQMobile';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { TextHoverEffect } from "@/components/ui/text-hover-effect";
import { FlipWords } from "@/components/ui/flip.words";
import BeamDemo from '../components/HomePageMobile/BeamDemoMobile';
import { MacbookScroll } from '@/components/ui/macbook-scroll';
import { motion } from 'framer-motion';
const yogaImage = require('../assets/Homepage/yoga.jpg');
const patientScreen = require('../assets/Homepage/PatientScreen.png');

// Add interfaces for props
interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  gradient?: string;
}

// Add new interface for feature chips
interface FeatureChipProps {
  icon: React.ReactNode;
  label: string;
}

const FeatureChip: React.FC<FeatureChipProps> = ({ icon, label }) => (
  <div className="flex items-center gap-2 px-6 py-4 rounded-2xl bg-white dark:bg-white/5 border border-gray-200 dark:border-gray-800 hover:border-primary transition-all duration-300 cursor-pointer group shadow-sm hover:shadow-md">
    <span className="text-primary/90 group-hover:text-primary">
      {icon}
    </span>
    <span className="text-sm font-light text-gray-800 dark:text-gray-200 group-hover:text-primary/90">
      {label}
    </span>
  </div>
);

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, gradient = "from-blue-500/20 to-violet-500/20" }) => (
  <div className="relative group rounded-3xl bg-white dark:bg-gray-800/50 hover:shadow-xl transition-all duration-500 overflow-hidden border border-gray-200 dark:border-gray-800">
    <div className={`absolute inset-0 bg-gradient-to-r ${gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
    <div className="relative p-8 transition duration-300 ease-in-out group-hover:translate-y-[-4px]">
      <div className="text-primary/80 mb-6">
        {icon}
      </div>
      <h3 className="text-xl md:text-2xl font-light text-primary mb-4">{title}</h3>
      <p className="text-base md:text-lg text-foreground/70 font-light leading-relaxed">{description}</p>
    </div>
  </div>
);

// First, add this new component for the stars
const StarRating = () => (
  <div className="flex gap-1 mb-4">
    {[...Array(5)].map((_, i) => (
      <svg key={i} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    ))}
  </div>
);

const HomePageMobile: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const careWords = [
    "data-driven care.",
    "preventive health.",
    "mental wellness.",
    "personalized care.",
    "holistic therapy."
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section with Background Animation */}
      <div className="relative min-h-[80vh] md:min-h-screen pb-8 md:pb-32">
        <BackgroundAnimation />
        <div className="relative z-10 pt-28 md:pt-24">
          <div className="container mx-auto px-4">
            <header className="text-center space-y-4 md:space-y-8">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-primary leading-tight">
                Predict. Prevent.
                {/* Mobile-friendly version */}
                <span className="hidden sm:block mt-2 md:mt-4">
                  <TextHoverEffect text="THRIVE" className="text-black/90 dark:text-white/90" />
                </span>
                {/* Enhanced mobile version */}
                <motion.span
                  className="block sm:hidden mt-6 mb-6 text-8xl animate-gradient-text"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                  whileHover={{
                    scale: 1.05,
                    transition: { duration: 0.2 }
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  THRIVE
                </motion.span>
              </h1>
              <p className="text-lg md:text-2xl text-foreground max-w-2xl mx-auto font-light">
                A new era for <FlipWords words={careWords} className="inline-block" />
              </p>
            </header>

            <div className="flex flex-col items-center mt-6 md:mt-12">
              <HoverBorderGradient
                as="a"
                href="https://calendly.com/hashemabdou/oasys-platform-demo"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-background text-foreground hover:text-foreground"
              >
                Book a Demo
              </HoverBorderGradient>

              {/* Show simplified version on mobile */}
              <div className="md:hidden mt-8">
                <img
                  src={patientScreen}
                  alt="Patient dashboard preview"
                  className="w-full max-w-sm mx-auto rounded-lg shadow-lg"
                />
              </div>

              {/* Hide MacbookScroll on mobile */}
              <div className="hidden md:block w-full mt-24">
                <MacbookScroll
                  showGradient={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="relative">
        <div className="relative bg-gray-50 dark:bg-gray-900/50 py-16 md:py-24">
          {/* Transform your practice section */}
          <div className="container mx-auto px-8 md:px-16">
            <div className="space-y-12 md:space-y-24 mb-8 md:mb-12 font-light max-w-7xl mx-auto">
              {/* Header section with two columns */}
              <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                <h2 className="text-2xl md:text-5xl font-normal text-primary">Supercharge your practice.</h2>
                <p className="text-lg md:text-xl text-foreground/80 font-light">
                  Transform your clinical and nonclinical operations with tools that drive better patient outcomes and operational efficiency.
                </p>
              </div>

              {/* Main content with feature chips and cards */}
              <div className="grid gap-12 md:gap-16 md:grid-cols-12">
                {/* Feature chips column */}
                <div className="md:col-span-3 flex flex-col justify-between h-full space-y-6">
                  <FeatureChip
                    icon={<FiEdit3 className="w-5 h-5" />}
                    label="Intelligent Notetaking"
                  />
                  <FeatureChip
                    icon={<FiLayout className="w-5 h-5" />}
                    label="Health Analytics Dashboard"
                  />
                  <FeatureChip
                    icon={<FiClock className="w-5 h-5" />}
                    label="Smart Session Prep"
                  />
                  <FeatureChip
                    icon={<FiCalendar className="w-5 h-5" />}
                    label="Automated Scheduling"
                  />
                </div>

                {/* Cards column */}
                <div className="md:col-span-9">
                  <div className="grid gap-8 md:grid-cols-1 lg:grid-cols-3">
                    <FeatureCard
                      icon={<FiActivity className="w-8 h-8 md:w-10 md:h-10" />}
                      title="Predictive Analytics"
                      description="Identify patterns and predict potential health concerns before they escalate."
                      gradient="from-blue-500/20 to-cyan-500/20"
                    />
                    <FeatureCard
                      icon={<FiHeart className="w-8 h-8 md:w-10 md:h-10" />}
                      title="Health Integration"
                      description="Aggregate data from wearables, genetic tests, and clinical records seamlessly."
                      gradient="from-violet-500/20 to-fuchsia-500/20"
                    />
                    <FeatureCard
                      icon={<FiShield className="w-8 h-8 md:w-10 md:h-10" />}
                      title="Enterprise Security"
                      description="HIPAA-compliant infrastructure with end-to-end encryption and access controls."
                      gradient="from-emerald-500/20 to-teal-500/20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Unified health intelligence section */}
        <section className="mt-12 md:mt-24 mb-8 md:mb-12 px-4">
          <div className="container mx-auto">
            <div className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 md:p-16">
              <div className="flex flex-col items-center text-center mb-12">
                <h2 className="text-3xl md:text-5xl font-normal text-primary mb-6">
                  Unified health intelligence.
                </h2>
                <p className="text-foreground max-w-3xl text-lg md:text-xl font-light">
                  Connect your entire practice ecosystem to unlock AI-powered insights and deliver unprecedented patient care.
                </p>
              </div>
              <div className="h-[300px] md:h-[400px] flex items-center justify-center">
                <BeamDemo />
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section */}
        <section className="py-16 md:py-32 bg-gray-50 dark:bg-gray-900/50">
          <div className="container mx-auto px-8 md:px-16">
            <div className="grid gap-16 items-center max-w-6xl mx-auto md:grid-cols-2">
              {/* Image Side */}
              <div className="flex justify-center">
                <img src={yogaImage} alt="Yoga Session" className="rounded-lg shadow-lg w-full max-w-sm" />
              </div>

              {/* Testimonial Side */}
              <div className="space-y-4">
                <StarRating />
                <blockquote className="text-3xl font-light leading-relaxed text-foreground/90">
                  "Oasys makes my job so much easier! Not having to do so much admin work means I can actually focus on building meaningful connections with my patients."
                </blockquote>
                <div className="mt-6">
                  <div className="text-xl font-medium text-primary">Sarah G.</div>
                  <div className="text-foreground/60 font-light">Therapist</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ section */}
        <section className="bg-white dark:bg-background py-16 md:py-32">
          <div className="container mx-auto px-8 md:px-16">
            <div className="max-w-[100rem] mx-auto">
              <div className="grid gap-8 items-start md:grid-cols-12">
                <div className="md:col-span-2 md:pt-8">
                  <h2 className="text-3xl md:text-4xl font-normal text-primary">
                    FAQs
                  </h2>
                </div>
                <div className="md:col-span-10">
                  <FAQ />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <div className="bg-background">
        <Footer />
      </div>
    </div>
  );
};

export default HomePageMobile;
