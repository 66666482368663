"use client";
import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";

export const FlipWords = ({
  words,
  duration = 3000,
  className,
}: {
  words: string[];
  duration?: number;
  className?: string;
}) => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const startAnimation = useCallback(() => {
    const word = words[words.indexOf(currentWord) + 1] || words[0];
    setCurrentWord(word);
    setIsAnimating(true);
  }, [currentWord, words]);

  useEffect(() => {
    if (!isAnimating)
      setTimeout(() => {
        startAnimation();
      }, duration);
  }, [isAnimating, duration, startAnimation]);

  return (
    <AnimatePresence mode="wait" onExitComplete={() => setIsAnimating(false)}>
      <motion.div
        className={cn(
          "z-10 inline-block relative text-left text-neutral-900 dark:text-neutral-100 px-1 md:px-2",
          className
        )}
        key={currentWord}
      >
        {Array.from(currentWord).map((char, charIndex, array) => (
          <motion.span
            key={charIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.05,
                delay: (array.length - 1 - charIndex) * 0.05,
                ease: "easeIn"
              }
            }}
            transition={{
              duration: 0.05,
              delay: charIndex * 0.05,
              ease: "easeOut",
            }}
            className="inline-block"
            style={{
              width: char === " " ? "0.3em" : "auto",
              whiteSpace: "pre"
            }}
          >
            {char}
          </motion.span>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};
