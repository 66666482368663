import React, { useContext, useEffect } from 'react';
import { SessionProvider, SessionContext } from '../../../contexts/SessionContext';
import PreviousSessionForm from './OldSession/PreviousSessionForm';
import SessionContainer from './Shared/SessionContainer';
import { useMediaQuery } from 'react-responsive';
import { User } from '../../../types/usersTypes';
import { Patient } from '../../../types';
import NewSessionForm from './NewSession/NewSessionForm';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CalendarDays, Clock, History, PlayCircle } from 'lucide-react';

interface SessionTabProps {
  user: User;
  patients: Patient[];
}

const SessionTabContent: React.FC = () => {
  const {
    sessionState: { activeView, sessionData, successMessage },
    changeActiveView,
    setSuccessMessage,
    patients
  } = useContext(SessionContext)!;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const showDemoNotice = patients.length === 0;

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, setSuccessMessage]);

  const renderDefaultView = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Start Current Session Card */}
      <Card className="hover:shadow-md transition-shadow duration-300">
        <CardHeader>
          <CardTitle className="text-xl font-normal text-primary flex items-center gap-2">
            <PlayCircle className="h-5 w-5" />
            Start Current Session
          </CardTitle>
          <CardDescription>
            Begin a new therapy session with real-time note-taking
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-start gap-3 text-gray-600">
              <Clock className="h-5 w-5 mt-1" />
              <div>
                <h4 className="font-normal">Real-time Tracking</h4>
                <p className="text-sm font-light">Automatically track session duration and take notes as you go</p>
              </div>
            </div>
            <div className="flex items-start gap-3 text-gray-600">
              <CalendarDays className="h-5 w-5 mt-1" />
              <div>
                <h4 className="font-normal">Immediate Documentation</h4>
                <p className="text-sm font-light">Document your sessions as they happen</p>
              </div>
            </div>
            <button
              onClick={() => changeActiveView('startCurrent')}
              className="w-full px-6 py-3 bg-primary text-white font-light rounded-full hover:bg-primary-dark transition-colors duration-300"
            >
              Start Session
            </button>
          </div>
        </CardContent>
      </Card>

      {/* Log Previous Session Card */}
      <Card className="hover:shadow-md transition-shadow duration-300">
        <CardHeader>
          <CardTitle className="text-xl font-normal text-primary flex items-center gap-2">
            <History className="h-5 w-5" />
            Log Previous Session
          </CardTitle>
          <CardDescription>
            Record details of a previously completed session
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-start gap-3 text-gray-600">
              <CalendarDays className="h-5 w-5 mt-1" />
              <div>
                <h4 className="font-normal">Flexible Dating</h4>
                <p className="text-sm font-light">Log sessions from any previous date</p>
              </div>
            </div>
            <div className="flex items-start gap-3 text-gray-600">
              <Clock className="h-5 w-5 mt-1" />
              <div>
                <h4 className="font-normal">Custom Time Entry</h4>
                <p className="text-sm font-light">Specify exact session start and end times</p>
              </div>
            </div>
            <button
              onClick={() => changeActiveView('logPrevious')}
              className="w-full px-6 py-3 bg-white text-primary font-light border-2 border-primary rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              Log Session
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderContent = () => {
    switch (activeView) {
      case 'startCurrent':
        return <NewSessionForm />;
      case 'logPrevious':
        return <PreviousSessionForm />;
      case 'currentSession':
      case 'completedSession':
        return sessionData ? <SessionContainer /> : null;
      default:
        return (
          <>
            {showDemoNotice && (
              <div className="mb-6 bg-blue-50 border-l-4 border-blue-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-blue-700">
                      No patients found. You can try out the platform features with our demo patient.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {renderDefaultView()}
          </>
        );
    }
  };

  return (
    <div className="bg-white rounded-lg p-6">
      <h2 className="text-2xl font-normal text-primary mb-6">Sessions</h2>
      {successMessage && (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6"
          role="alert"
        >
          <span>{successMessage}</span>
        </div>
      )}
      {renderContent()}
    </div>
  );
};

const SessionTab: React.FC<SessionTabProps> = ({ user, patients }) => {
  return (
    <SessionProvider currentUser={user} patients={patients}>
      <SessionTabContent />
    </SessionProvider>
  );
};

export default SessionTab;
