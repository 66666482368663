import React, { useState, useEffect, useRef } from 'react';
import { Moon, Utensils, Smile, Dumbbell, Droplet, MessageSquare, FileText } from 'lucide-react';
import { usePatientTab } from '../../../../../contexts/PatientTabContext';
import SleepTab from './TherapistSleepTab';
import ActivityTab from './TherapistActivityTab';
import NutritionTab from './TherapistNutritionTab';
import WellbeingScoreGraph from '../WellbeingScoreDesktop/WellbeingScoreGraph';
import MenstruationTab from './TherapistMenstruationTab';
import HealthOverviewChat from './HealthOverviewChat';
import { DashboardCard } from './TherapistHealthDashboardUtils';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import {
    HealthAnalysis,
    ActivityData,
    SleepData,
    NutritionEntry,
    MenstruationData,
    BodyData,
    ChatMessage,
    Session
} from '../../../../../types';
import ReactMarkdown from 'react-markdown';
import { api } from '../../../../../services/api';

interface TabButtonProps {
    selected: boolean;
    onClick: () => void;
    icon: React.ReactNode;
    children: React.ReactNode;
}

interface TherapistHealthDashboardProps {
    patientId: string;
    isMobile: boolean;
    sessionsWithWellbeingScore: Session[];
}

type TabType = 'wellbeing' | 'sleep' | 'activity' | 'nutrition' | 'menstruation' | 'assistant';

const TabButton: React.FC<TabButtonProps> = ({ selected, onClick, icon, children }) => (
    <button
        onClick={onClick}
        className={`flex items-center px-4 py-2 rounded-lg text-sm transition-all duration-200
            ${selected
                ? 'bg-white text-primary shadow-sm font-light'
                : 'text-gray-500 hover:bg-white/50 font-light'}`}
    >
        {icon}
        <span className="ml-2 hidden sm:inline">{children}</span>
    </button>
);

const TherapistHealthDashboard: React.FC<TherapistHealthDashboardProps> = ({ patientId, isMobile, sessionsWithWellbeingScore }) => {
    const [sleepData, setSleepData] = useState<SleepData[]>([]);
    const [activityData, setActivityData] = useState<ActivityData[]>([]);
    const [nutritionData, setNutritionData] = useState<NutritionEntry[]>([]);
    const [bodyData, setBodyData] = useState<BodyData[]>([]);
    const [menstruationData, setMenstruationData] = useState<MenstruationData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<TabType>('wellbeing');
    const [healthOverview, setHealthOverview] = useState<string | null>(null);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([{
        role: 'assistant',
        content: "Hi! I'm here to help analyze this patient's health data. What would you like to know?"
    }]);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const fetchHealthData = async () => {
            try {
                setIsLoading(true);
                // The backend will handle returning either real or demo data
                const response = await api.therapists.getPatientHealthData(patientId);
                if (response) {
                    setSleepData(response.sleep_data || []);
                    setActivityData(response.activity_data || []);
                    setNutritionData(response.nutrition_data || []);
                    setBodyData(response.body_data || []);
                    setMenstruationData(response.menstruation_data || []);
                    setHealthOverview(response.health_analysis || null);
                }
            } catch (error) {
                console.error('Error fetching health data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (patientId) {
            fetchHealthData();
        }
    }, [patientId]);

    function handleNewMessage(messages: ChatMessage[]): void {
        setSelectedTab('assistant');
        setChatMessages(messages);
    }

    // Auto scroll effect
    useEffect(() => {
        if (chatContainerRef.current && selectedTab === 'assistant') {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages, selectedTab]);

    const handleClearChat = (): void => {
        setChatMessages([{
            role: 'assistant',
            content: "Hi! I'm here to help analyze this patient's health data. What would you like to know?"
        }]);
    };



    const renderTabContent = (): JSX.Element | null => {
        switch (selectedTab) {
            case 'sleep':
                return <SleepTab sleepData={sleepData} />;
            case 'activity':
                return <ActivityTab activityData={activityData} bodyData={bodyData} />;
            case 'nutrition':
                return <NutritionTab nutritionData={nutritionData} />;
            case 'menstruation':
                return <MenstruationTab menstruationData={menstruationData} />;
            case 'assistant':
                return (
                    <div className="bg-white p-6 rounded-lg shadow-sm">
                        <div className="flex justify-end mb-2">
                            <button
                                onClick={handleClearChat}
                                className="text-xs text-gray-500 hover:text-primary transition-colors duration-200 font-light"
                            >
                                Clear chat
                            </button>
                        </div>
                        <div
                            ref={chatContainerRef}
                            className="h-[calc(100vh-42rem)] overflow-y-auto px-4"
                        >
                            <div className="space-y-4 pb-4">
                                {chatMessages.map((message, index) => (
                                    <div key={index}
                                        className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                                        <div className={`max-w-[70%] p-3 rounded-lg ${message.role === 'assistant'
                                            ? 'bg-gray-50 text-gray-800'
                                            : 'bg-primary text-white'
                                            } font-light`}>
                                            {isTyping && index === chatMessages.length - 1 && !message.content ? (
                                                <div className="flex items-center space-x-2">
                                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                                                    <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                                                </div>
                                            ) : (
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                    components={{
                                                        p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                                                        h1: ({ children }) => <h1 className="text-2xl font-normal mb-4 text-primary">{children}</h1>,
                                                        h2: ({ children }) => <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>,
                                                        h3: ({ children }) => <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>,
                                                        ul: ({ children }) => <ul className="list-disc pl-5 mb-4 font-light">{children}</ul>,
                                                        ol: ({ children }) => <ol className="list-decimal pl-5 mb-4 font-light">{children}</ol>,
                                                        li: ({ children }) => <li className="mb-1 font-light">{children}</li>,
                                                    }}
                                                >
                                                    {message.content}
                                                </ReactMarkdown>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderHealthAnalysis = (analysis: any) => {
        console.log('analysis');
        console.log(analysis);
        if (typeof analysis === 'string') {
            return analysis;
        }

        // If it's an object, use the analysis_text property or format it
        if (analysis?.analysis_text) {
            return analysis.analysis_text;
        }

        // Fallback for object format
        return `
## Health Overview Analysis

### Activity Summary
- Total Steps: ${analysis?.activity_summary?.total_steps || 'N/A'}
- Distance: ${analysis?.activity_summary?.total_distance_km || 'N/A'} km

### Sleep Summary
- Average Duration: ${analysis?.sleep_summary?.average_sleep_duration_hours || 'N/A'} hours
- Sleep Efficiency: ${analysis?.sleep_summary?.average_sleep_efficiency || 'N/A'}%

### Nutrition Summary
- Daily Calories: ${analysis?.nutrition_summary?.average_daily_calories || 'N/A'}
- Daily Protein: ${analysis?.nutrition_summary?.average_daily_protein_g || 'N/A'}g

### Connected Devices
${analysis?.connected_devices?.join('\n') || 'No devices connected'}
        `;
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="space-y-4 my-4">
            {/* Tab Selection */}
            <div className="bg-gray-100/50 p-2 rounded-xl">
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <div className="flex flex-wrap gap-2">
                        <TabButton
                            selected={selectedTab === 'wellbeing'}
                            onClick={() => setSelectedTab('wellbeing')}
                            icon={<Smile className="w-4 h-4" />}
                        >
                            Overview
                        </TabButton>
                        {sleepData.length > 0 && (
                            <TabButton
                                selected={selectedTab === 'sleep'}
                                onClick={() => setSelectedTab('sleep')}
                                icon={<Moon className="w-4 h-4" />}
                            >
                                Sleep
                            </TabButton>
                        )}
                        {activityData.length > 0 && (
                            <TabButton
                                selected={selectedTab === 'activity'}
                                onClick={() => setSelectedTab('activity')}
                                icon={<Dumbbell className="w-4 h-4" />}
                            >
                                Activity
                            </TabButton>
                        )}
                        {nutritionData.length > 0 && (
                            <TabButton
                                selected={selectedTab === 'nutrition'}
                                onClick={() => setSelectedTab('nutrition')}
                                icon={<Utensils className="w-4 h-4" />}
                            >
                                Nutrition
                            </TabButton>
                        )}
                        {menstruationData.length > 0 && (
                            <TabButton
                                selected={selectedTab === 'menstruation'}
                                onClick={() => setSelectedTab('menstruation')}
                                icon={<Droplet className="w-4 h-4" />}
                            >
                                Menstruation
                            </TabButton>
                        )}
                    </div>
                    <TabButton
                        selected={selectedTab === 'assistant'}
                        onClick={() => setSelectedTab('assistant')}
                        icon={<MessageSquare className="w-4 h-4" />}
                    >
                        Assistant
                    </TabButton>
                </div>
            </div>

            {/* Content Area + Chat Input Combined */}
            <div className="py-5 bg-primary/5 rounded-lg p-6">
                <div className="space-y-4">
                    {selectedTab === 'wellbeing' ? (
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <DashboardCard
                                    title="General Observations"
                                    titleClass="text-base font-light text-gray-800"
                                    icon={<FileText className="text-primary" />}
                                >
                                    <div className="h-64">
                                        {healthOverview ? (
                                            <div className="prose prose-sm max-w-none space-y-2 font-light text-gray-600 overflow-y-auto h-full">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                    components={{
                                                        p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                                                        h1: ({ children }) => <h1 className="text-2xl font-normal mb-4 text-primary">{children}</h1>,
                                                        h2: ({ children }) => <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>,
                                                        h3: ({ children }) => <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>,
                                                        ul: ({ children }) => <ul className="list-disc pl-5 mb-4 font-light">{children}</ul>,
                                                        ol: ({ children }) => <ol className="list-decimal pl-5 mb-4 font-light">{children}</ol>,
                                                        li: ({ children }) => <li className="mb-1 font-light">{children}</li>,
                                                    }}
                                                >
                                                    {renderHealthAnalysis(healthOverview)}
                                                </ReactMarkdown>
                                            </div>
                                        ) : (
                                            <div className="font-light text-gray-600">No observations available</div>
                                        )}
                                    </div>
                                </DashboardCard>
                            </div>
                            <div className="col-span-1">
                                {sessionsWithWellbeingScore &&
                                    Array.isArray(sessionsWithWellbeingScore) &&
                                    sessionsWithWellbeingScore.length >= 2 ? (
                                    <WellbeingScoreGraph sessions={sessionsWithWellbeingScore} />
                                ) : (
                                    <DashboardCard
                                        title="Notes Sentiment Analysis"
                                        titleClass="text-base font-light text-gray-800"
                                        icon={<FileText className="text-primary" />}
                                    >
                                        <div className="h-64 flex items-center justify-center">
                                            <p className="text-gray-500 font-light">
                                                No notes available yet
                                            </p>
                                        </div>
                                    </DashboardCard>
                                )}
                            </div>
                        </div>
                    ) : (
                        renderTabContent()
                    )}

                    {/* Chat Input - Inside the same container */}
                    <div className="bg-white rounded-lg border border-gray-100">
                        <HealthOverviewChat
                            patientId={patientId}
                            onMessageSent={handleNewMessage}
                            showMessagesInInput={false}
                            inputOnly={true}
                            messages={chatMessages}
                            setMessages={setChatMessages}
                            isTyping={isTyping}
                            setIsTyping={setIsTyping}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TherapistHealthDashboard;