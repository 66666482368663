import React, { useState } from 'react';
import { FiUsers, FiCalendar, FiClock, FiBell, FiBook, FiExternalLink, FiUserPlus, FiMessageSquare, FiLock, FiCheck, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { User } from '@supabase/supabase-js';
import { Patient, TherapistProfileData } from '../../../types';


interface TherapistOverviewProps {
  patients: Patient[];
  approvedPatients: Patient[];
  user: User | null;
  therapistProfile: TherapistProfileData;
  onTabChange: (tab: string) => void;
  onPatientAction: (patientId: string, action: 'approve' | 'deny') => Promise<void>;
}

interface StatCardProps {
  icon: React.ReactNode;
  title: string;
  value: string | number;
  description: string;
  color: string;
  link?: string;
  comingSoon?: boolean;
}

interface QuickActionButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  count?: number;
  comingSoon?: boolean;
}

interface AlertProps {
  type: 'info' | 'reminder' | 'update';
  message: string;
}

interface ResourceCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  link: string;
}

const TherapistOverview: React.FC<TherapistOverviewProps> = ({
  patients,
  approvedPatients,
  user,
  therapistProfile,
  onTabChange,
  onPatientAction
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const pendingPatients = patients.filter(patient => patient.status === 'pending');
  const approvedPatientsCount = approvedPatients.length;
  const pendingPatientsCount = pendingPatients.length;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Now you can safely use therapistProfile with proper typing
  const therapistName = therapistProfile.full_name || 'Therapist';

  return (
    <div className="bg-white rounded-lg p-6">
      {/* Welcome Message - moved outside cards and styled like other tabs */}
      <h2 className="text-2xl font-normal text-primary mb-2">
        Welcome back, {therapistName}!
      </h2>
      <p className="text-gray-600 font-light mb-6">
        Here's what's happening with your practice today.
      </p>

      <div className="space-y-6">
        {/* Key Statistics Cards - no changes */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatCard
            icon={<FiUsers />}
            title="Active Patients"
            value={approvedPatientsCount}
            description="Current patients"
            color="text-blue-600"
          />
          <StatCard
            icon={<FiClock />}
            title="Pending Requests"
            value={pendingPatientsCount}
            description="Awaiting approval"
            color="text-yellow-600"
          />
          <StatCard
            icon={<FiCalendar />}
            title="Upcoming Sessions"
            value="0"
            description="Next 7 days"
            color="text-green-600"
            comingSoon={true}
          />
          <StatCard
            icon={<FiMessageSquare />}
            title="Messages"
            value="0"
            description="Patient communications"
            color="text-purple-600"
            comingSoon={true}
          />
        </div>

        {/* Quick Actions */}
        <div className="bg-white rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-normal text-primary mb-4">Quick Actions</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <QuickActionButton
              icon={<FiUserPlus />}
              label="Review Requests"
              onClick={() => setIsModalOpen(true)}
              count={pendingPatientsCount > 0 ? pendingPatientsCount : undefined}
            />
            <QuickActionButton
              icon={<FiCalendar />}
              label="Schedule Session"
              onClick={() => { }}
              comingSoon={true}
            />
            <QuickActionButton
              icon={<FiMessageSquare />}
              label="Send Message"
              onClick={() => { }}
              comingSoon={true}
            />
            <QuickActionButton
              icon={<FiBook />}
              label="Create Note"
              onClick={() => onTabChange('sessions')}
            />
          </div>
        </div>

        {/* Notifications/Alerts */}
        <div className="bg-white rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-normal text-primary mb-4">
            <FiBell className="inline-block mr-2" />
            Recent Notifications
          </h3>
          <div className="space-y-3">
            {pendingPatientsCount > 0 && (
              <Alert
                type="info"
                message={`You have ${pendingPatientsCount} pending patient request${pendingPatientsCount > 1 ? 's' : ''}`}
              />
            )}
            <Alert
              type="reminder"
              message="Complete session notes for recent appointments"
            />
            <Alert
              type="update"
              message="New features available in the platform"
            />
          </div>
        </div>

        {/* Resource Center */}
        <div className="bg-white rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-normal text-primary mb-4">
            <FiBook className="inline-block mr-2" />
            Resource Center
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ResourceCard
              title="Documentation"
              description="Platform guides and best practices"
              icon={<FiBook />}
              link="#"
            />
            <ResourceCard
              title="Session Templates"
              description="Pre-made templates for notes"
              icon={<FiBook />}
              link="#"
            />
            <ResourceCard
              title="Health Data Guide"
              description="Understanding patient health metrics"
              icon={<FiBook />}
              link="#"
            />
            <ResourceCard
              title="Support Center"
              description="Get help with platform features"
              icon={<FiExternalLink />}
              link="#"
            />
          </div>
        </div>

        {/* New Patient Review Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-lg max-h-[80vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-normal text-primary">Pending Patient Requests</h3>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FiX className="text-xl" />
                </button>
              </div>

              {pendingPatients.length === 0 ? (
                <p className="text-gray-600 font-light">No pending requests</p>
              ) : (
                <div className="space-y-4">
                  {pendingPatients.map((patient) => (
                    <div key={patient.id} className="border rounded-lg p-4">
                      <div className="flex justify-between items-center">
                        <div className="flex-grow min-w-0 mr-4">
                          <h4 className="text-lg font-normal truncate">{patient.full_name}</h4>
                          <p className="text-sm text-gray-600 font-light truncate">{patient.email}</p>
                        </div>
                        <div className="flex gap-2 flex-shrink-0">
                          <button
                            onClick={() => {
                              onPatientAction(patient.id, 'approve');
                              if (pendingPatients.length === 1) setIsModalOpen(false);
                            }}
                            className="bg-green-500 text-white p-2 sm:px-3 sm:py-1 rounded hover:bg-green-600 
                                     flex items-center transition-colors"
                            title="Approve"
                          >
                            <FiCheck className={isMobile ? "" : "mr-1"} />
                            {!isMobile && <span>Approve</span>}
                          </button>
                          <button
                            onClick={() => {
                              onPatientAction(patient.id, 'deny');
                              if (pendingPatients.length === 1) setIsModalOpen(false);
                            }}
                            className="bg-red-500 text-white p-2 sm:px-3 sm:py-1 rounded hover:bg-red-600 
                                     flex items-center transition-colors"
                            title="Deny"
                          >
                            <FiX className={isMobile ? "" : "mr-1"} />
                            {!isMobile && <span>Deny</span>}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Helper Components
const StatCard: React.FC<StatCardProps> = ({
  icon,
  title,
  value,
  description,
  color,
  link,
  comingSoon
}) => (
  <div className="bg-white p-6 rounded-lg shadow-md relative group">
    <div className="flex items-center justify-between">
      <div className={`text-2xl ${color}`}>{icon}</div>
      {link && !comingSoon ? (
        <Link to={link} className="text-primary hover:underline text-sm">View All</Link>
      ) : null}
    </div>
    <h3 className="text-lg font-normal mt-2">{title}</h3>
    <p className="text-3xl font-light text-gray-800 mt-2">{value}</p>
    <p className="text-sm text-gray-500 font-light mt-1">{description}</p>
    {comingSoon && (
      <div className="absolute inset-0 bg-gray-50/80 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <div className="flex items-center text-gray-500 font-light">
          <FiLock className="mr-2" /> Coming Soon
        </div>
      </div>
    )}
  </div>
);

const QuickActionButton: React.FC<QuickActionButtonProps> = ({
  icon,
  label,
  onClick,
  count,
  comingSoon
}) => (
  <button
    onClick={onClick}
    disabled={comingSoon}
    className="relative flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200 disabled:cursor-not-allowed group"
  >
    <div className="relative">
      <span className="text-2xl text-primary">{icon}</span>
      {count && count > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
          {count}
        </span>
      )}
    </div>
    <span className="mt-2 text-sm font-light text-gray-700">{label}</span>
    {comingSoon && (
      <div className="absolute inset-0 bg-gray-50/80 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <div className="flex items-center text-gray-500 font-light">
          <FiLock className="mr-2" /> Coming Soon
        </div>
      </div>
    )}
  </button>
);

const Alert: React.FC<AlertProps> = ({ type, message }) => {
  const colors = {
    info: 'bg-blue-50 text-blue-800',
    reminder: 'bg-yellow-50 text-yellow-800',
    update: 'bg-green-50 text-green-800',
  };

  return (
    <div className={`${colors[type]} p-3 rounded-md font-light`}>
      {message}
    </div>
  );
};

const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  description,
  icon,
  link
}) => (
  <div className="relative group">
    <a
      href={link}
      className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-200"
    >
      <div className="flex items-center mb-2">
        <span className="text-primary mr-2">{icon}</span>
        <h4 className="font-normal">{title}</h4>
      </div>
      <p className="text-sm text-gray-600 font-light">{description}</p>
    </a>
    {/* Overlay that appears on hover */}
    <div className="absolute inset-0 bg-gray-50/90 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
      <div className="flex items-center text-gray-500 font-light">
        <FiLock className="mr-2" /> Coming Soon
      </div>
    </div>
  </div>
);

export default TherapistOverview;
