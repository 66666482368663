import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import TherapistOnboardingProgress from './TherapistOnboardingProgress';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import useTherapistSignUp from '../../hooks/useTherapistSignUp';
import { useAuthContext } from '../../contexts/AuthContext';
import { licenseTypes, states, titles, languages } from './Constants';
import Select, { MultiValue, ActionMeta as SelectActionMeta } from 'react-select';
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface FormData {
  title: string;
  firstName: string;
  lastName: string;
  licenseTypes: string[];
  licenseNumber: string;
  licenseState: string;
  license: string;
  yearsOfExperience: string;
  languages: string;
  education: string;
}

interface ParsedData {
  title: string;
  firstName: string;
  lastName: string;
}

interface ProcessedFormData {
  full_name: string;
  license: string;
  years_of_experience: number;
  licenseTypes: string[];
  licenseNumber: string;
  licenseState: string;
  languages: string[];
  education: string[];
}

interface SelectOption {
  value: string;
  label: string;
}

interface ActionMeta {
  name?: string;
  target?: {
    name: string;
  };
}

const ProfessionalInfo: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    title: '',
    firstName: '',
    lastName: '',
    licenseTypes: [],
    licenseNumber: '',
    licenseState: '',
    license: '',
    yearsOfExperience: '',
    languages: '',
    education: ''
  });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { error: hookError, handleProfessionalInfo } = useTherapistSignUp();
  const { user } = useAuthContext();
  const [openTitle, setOpenTitle] = useState(false);
  const [openLanguages, setOpenLanguages] = useState(false);

  // First, create a function to handle the data parsing
  const parseStoredData = (data: { full_name?: string }): ParsedData => {
    let title = '', firstName = '', lastName = '';
    if (data.full_name) {
      const nameParts = data.full_name.trim().split(' ');
      if (nameParts[0] && nameParts[0].endsWith('.')) {
        title = nameParts[0];
        if (nameParts.length > 2) {
          lastName = nameParts[nameParts.length - 1];
          firstName = nameParts.slice(1, -1).join(' ');
        }
      } else {
        if (nameParts.length > 1) {
          lastName = nameParts[nameParts.length - 1];
          firstName = nameParts.slice(0, -1).join(' ');
        } else {
          firstName = nameParts[0] || '';
        }
      }
    }

    return {
      title: title || '',
      firstName: firstName || '',
      lastName: lastName || ''
    };
  };

  useEffect(() => {
    const fetchTherapistData = async () => {
      if (!user) {
        navigate('/login');
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          // Parse the full name into components
          const nameData = parseStoredData(data);
          setFormData({
            ...nameData,
            licenseTypes: data.licenseTypes || [],
            licenseNumber: data.licenseNumber || '',
            licenseState: data.licenseState || '',
            license: data.license || '',
            yearsOfExperience: data.years_of_experience?.toString() || '',
            languages: data.languages ? data.languages.join(', ') : '',
            education: data.education ? data.education.join(', ') : ''
          });
        }
      }
    };
    fetchTherapistData();
  }, [navigate, user]);

  const handleChange = (
    event: string | readonly SelectOption[] | React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | null,
    actionMeta?: ActionMeta
  ): void => {
    if (Array.isArray(event)) {
      // Handle React-Select multi-select
      setFormData(prevState => ({
        ...prevState,
        licenseTypes: [...event].map(option => option.value)
      }));
    } else if ((event as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)?.target) {
      // Handle regular input and select changes
      const { name, value } = (event as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>).target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (typeof event === 'string' && actionMeta?.target?.name) {
      // Handle string value changes with action meta
      const name = actionMeta.target.name;
      setFormData(prevState => ({
        ...prevState,
        [name]: event
      }));
    }
  };

  const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      const fullName = [
        formData.title || '',
        formData.firstName || '',
        formData.lastName || ''
      ]
        .map(part => part.trim())
        .filter(Boolean)
        .join(' ');

      const licenseString = formData.licenseTypes.length > 0 ?
        `${formData.licenseTypes.join(', ')}${formData.licenseState && formData.licenseNumber ?
          ` (${formData.licenseState} #${formData.licenseNumber})` :
          ''
        }` : '';

      const processedFormData: ProcessedFormData = {
        full_name: fullName,
        license: licenseString,
        years_of_experience: parseInt(formData.yearsOfExperience, 10) || 0,
        licenseTypes: formData.licenseTypes,
        licenseNumber: formData.licenseNumber,
        licenseState: formData.licenseState,
        languages: formData.languages.split(',').map(item => item.trim()).filter(Boolean),
        education: formData.education.split(',').map(item => item.trim()).filter(Boolean)
      };

      await handleProfessionalInfo(user!.id, processedFormData);
    } catch (error) {
      console.error('Submit error:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    }
  };

  // Add this new handler for regular input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex items-center justify-center p-4 pt-20 md:pt-24 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md mx-auto p-8 md:p-12">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-normal text-primary">Professional Information</h2>
              <p className="text-gray-400 mt-2 font-light">Please fill in your professional details.</p>
            </div>

            <TherapistOnboardingProgress currentStep="/therapist-onboarding/professional-info" />

            <form onSubmit={handleSubmit} className="space-y-6 mt-8">
              <div className="flex space-x-4">
                <div className="w-1/4">
                  <Label htmlFor="title" className="text-sm text-gray-500 font-light">
                    Title
                  </Label>
                  <div className="mt-2">
                    <Popover open={openTitle} onOpenChange={setOpenTitle}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openTitle}
                          className="w-full justify-between font-light text-gray-500"
                        >
                          {formData.title || "Select"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-full p-0" align="start">
                        <Command>
                          <CommandInput placeholder="Search title..." />
                          <CommandList>
                            <CommandEmpty>No title found.</CommandEmpty>
                            <CommandGroup>
                              {titles.map((title) => (
                                <CommandItem
                                  key={title.value}
                                  value={title.label}
                                  onSelect={() => {
                                    handleChange(title.value, { target: { name: 'title' } });
                                    setOpenTitle(false);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      formData.title === title.value ? "opacity-100" : "opacity-0"
                                    )}
                                  />
                                  {title.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div className="w-[37.5%]">
                  <Label htmlFor="firstName" className="text-sm text-gray-500 font-light">
                    First Name *
                  </Label>
                  <Input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="mt-2 font-light"
                  />
                </div>
                <div className="w-[37.5%]">
                  <Label htmlFor="lastName" className="text-sm text-gray-500 font-light">
                    Last Name *
                  </Label>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    className="mt-2 font-light"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label className="text-sm text-gray-500 font-light">
                  License Information
                </Label>
                <div className="flex flex-col space-y-4 mt-2">
                  <div className="w-full">
                    <Select
                      isMulti
                      name="licenseTypes"
                      options={licenseTypes}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select License Type"
                      onChange={(newValue: MultiValue<SelectOption>, actionMeta: SelectActionMeta<SelectOption>) =>
                        handleChange(newValue, { name: 'licenseTypes' })}
                      value={formData.licenseTypes
                        .map(type => licenseTypes.find(lt => lt.value === type))
                        .filter((option): option is SelectOption => option !== undefined)}
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontWeight: 300,
                        }),
                        option: (base) => ({
                          ...base,
                          fontWeight: 300,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          fontWeight: 300,
                          color: '#6B7280',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          fontWeight: 300,
                        }),
                        multiValue: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          fontWeight: 300,
                        }),
                      }}
                    />
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Input
                        type="text"
                        id="licenseNumber"
                        name="licenseNumber"
                        placeholder="License Number"
                        value={formData.licenseNumber}
                        onChange={(e) => handleChange(e.target.value, { target: e.target })}
                        className="font-light"
                      />
                    </div>
                    <div className="w-1/2">
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            className="w-full justify-between font-light text-gray-500"
                          >
                            {formData.licenseState
                              ? states.find((state) => state.value === formData.licenseState)?.label
                              : "Select State"}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0" align="start">
                          <Command>
                            <CommandInput placeholder="Search states..." />
                            <CommandList>
                              <CommandEmpty>No state found.</CommandEmpty>
                              <CommandGroup>
                                {states.map((state) => (
                                  <CommandItem
                                    key={state.value}
                                    value={state.label}
                                    onSelect={() => {
                                      handleChange(state.value, { target: { name: 'licenseState' } });
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        formData.licenseState === state.value ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                    {state.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Label htmlFor="yearsOfExperience" className="text-sm text-gray-500 font-light">
                  Years of Experience *
                </Label>
                <Input
                  type="number"
                  id="yearsOfExperience"
                  name="yearsOfExperience"
                  value={formData.yearsOfExperience}
                  onChange={(e) => handleChange(e.target.value, e)}
                  required
                  className="mt-2 font-light"
                />
              </div>
              <div>
                <Label htmlFor="languages" className="text-sm text-gray-500 font-light">
                  Languages *
                </Label>
                <div className="mt-2">
                  <Popover open={openLanguages} onOpenChange={setOpenLanguages}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openLanguages}
                        className="w-full justify-between font-light text-left text-gray-500"
                      >
                        {formData.languages || "Select Languages"}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0" align="start">
                      <Command>
                        <CommandInput placeholder="Search languages..." />
                        <CommandList>
                          <CommandEmpty>No language found.</CommandEmpty>
                          <CommandGroup>
                            {languages.map((language) => (
                              <CommandItem
                                key={language}
                                value={language}
                                onSelect={() => {
                                  const currentLanguages = formData.languages
                                    ? formData.languages.split(',').map(l => l.trim())
                                    : [];
                                  
                                  const updatedLanguages = currentLanguages.includes(language)
                                    ? currentLanguages.filter(l => l !== language)
                                    : [...currentLanguages, language];
                                  
                                  handleArrayChange({
                                    target: {
                                      name: 'languages',
                                      value: updatedLanguages.join(', ')
                                    }
                                  } as React.ChangeEvent<HTMLInputElement>);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    formData.languages?.split(',').map(l => l.trim()).includes(language)
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {language}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div>
                <Label htmlFor="education" className="text-sm text-gray-500 font-light">
                  Education (comma-separated) *
                </Label>
                <Input
                  type="text"
                  id="education"
                  name="education"
                  value={formData.education}
                  onChange={handleArrayChange}
                  placeholder="e.g. John's Hopkins University, NYU"
                  required
                  className="mt-2 font-light"
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-normal"
              >
                Next
              </button>
            </form>

            {hookError && (
              <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light mt-6">
                <span className="block sm:inline">{hookError}</span>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProfessionalInfo;
