import React from 'react';

interface WellbeingScoreBarProps {
    score: number | null | undefined;
    isMobile: boolean;
}

const WellbeingScoreBar: React.FC<WellbeingScoreBarProps> = ({ score, isMobile }) => {
    if (score === null || score === undefined) {
        return null;
    }

    const getColor = (score: number): string => {
        if (score >= 80) return '#10B981'; // green-500
        if (score >= 60) return '#FBBF24'; // yellow-400
        if (score >= 40) return '#F97316'; // orange-500
        return '#EF4444'; // red-500
    };

    return (
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-col items-end'}`}>
            <span className="text-xs font-normal text-gray-500 mb-1">Notes Sentiment</span>
            <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                    className="h-full rounded-full transition-all duration-500 ease-out"
                    style={{
                        width: `${score}%`,
                        backgroundColor: getColor(score),
                    }}
                />
            </div>
        </div>
    );
};

export default WellbeingScoreBar;
