import React from 'react';
import { MdEmail, MdPhone, MdCalendarToday } from 'react-icons/md';
import { RiMedicineBottleLine, RiMentalHealthLine, RiEmotionUnhappyLine } from 'react-icons/ri';
import { BsGenderAmbiguous } from 'react-icons/bs';
import { usePatientTab } from '../../../../contexts/PatientTabContext';

const defaultAvatar = require('../../../../assets/default-avatar-2.png');

interface InfoItemProps {
  icon: React.ReactNode;
  label: string;
  value?: string;
  defaultValue?: string;
}

const PatientDetails: React.FC = () => {
  const { selectedPatientDetails: patient } = usePatientTab();

  if (!patient) return null;

  const calculateAge = (dob?: string): number | null => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const age = calculateAge(patient.dob);
  const formatGender = (gender?: string): string => {
    return gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : 'Not specified';
  };

  return (
    <div className="flex flex-col lg:flex-row gap-16 w-full p-4 bg-white rounded-lg shadow-sm">
      {/* Patient Header Section */}
      <div className="flex flex-col items-center space-y-4">
        {/* Name and Email above avatar */}
        <div className="text-center">
          <h3 className="text-xl lg:text-2xl font-normal text-primary mb-1">{patient.full_name}</h3>
          <p className="text-sm text-gray-600 font-light">{patient.email}</p>
        </div>

        <img
          src={patient.profile_picture_url || defaultAvatar}
          alt="Avatar"
          className="w-24 h-24 lg:w-40 lg:h-40 rounded-full object-cover border-4 border-gray-100"
        />
      </div>

      {/* Info Grid Section */}
      <div className="flex-1 grid grid-cols-1 xl:grid-cols-2 gap-6">
        {/* Personal Information */}
        <div className="bg-gray-50 p-6 rounded-lg">
          <h4 className="text-lg font-normal text-primary mb-4">Personal Information</h4>
          <div className="space-y-4">
            <InfoItem
              icon={<BsGenderAmbiguous />}
              label="Gender"
              value={formatGender(patient.gender)}
            />
            <InfoItem
              icon={<MdCalendarToday />}
              label="Date of Birth"
              value={`${patient.dob}${age ? ` (${age} years)` : ''}`}
            />
            <InfoItem
              icon={<MdPhone />}
              label="Phone"
              value={patient.phone}
            />
          </div>
        </div>

        {/* Medical Information */}
        <div className="bg-gray-50 p-6 rounded-lg">
          <h4 className="text-lg font-normal text-primary mb-4">Medical Information</h4>
          <div className="space-y-4">
            <InfoItem
              icon={<RiMedicineBottleLine />}
              label="Current Medications"
              value={patient.current_medications}
              defaultValue="No current medications."
            />
            <InfoItem
              icon={<RiMentalHealthLine />}
              label="Past Diagnoses"
              value={patient.mental_health_history}
              defaultValue="No mental health history."
            />
            <InfoItem
              icon={<RiEmotionUnhappyLine />}
              label="Stress Triggers"
              value={patient.stress_triggers}
              defaultValue="No stress triggers."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoItem: React.FC<InfoItemProps> = ({ icon, label, value, defaultValue = 'N/A' }) => (
  <div className="flex items-start gap-3">
    <span className="group relative mt-1">
      <div className="text-gray-500 flex-shrink-0">
        {icon}
      </div>
      <span className="absolute bottom-full -translate-x-1/2 mb-2 hidden 
                     group-hover:block bg-gray-800 text-white text-xs 
                     rounded-lg py-1 px-2 whitespace-nowrap z-10 font-light">
        {label}
      </span>
    </span>
    <span className="text-sm lg:text-base text-gray-700 font-light break-words">
      {value || defaultValue}
    </span>
  </div>
);

export default PatientDetails;
