import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Moon } from 'lucide-react';
import { DashboardCard } from './TherapistHealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
import { SleepData } from '../../../../../types';  // Adjust the import path as needed

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

interface SleepSummaryDataPoint {
    date: string;
    rawDate: Date;
    totalSleep: number | null;
    deepSleep: number | null;
    efficiency: number | null;
    awakeningsCount: number | null;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface SleepTabProps {
    sleepData: SleepData[];
}

interface SleepTooltipProps extends CustomTooltipProps {
    chartType?: 'sleep' | 'efficiency';
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-3 py-1 text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);

const SleepTab: React.FC<SleepTabProps> = ({ sleepData }) => {
    const [timeframe, setTimeframe] = useState<'week' | 'month'>('week');

    if (!sleepData || sleepData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No sleep data available.</p>;
    }

    const timeframeDays: Record<'week' | 'month', number> = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = () => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process sleep data with averages for week and month
    const sleepSummaryData = generateDateRange().map(date => {
        const matchingSleepData = sleepData.find(entry => {
            const entryDate = parseISO(entry.metadata.end_time);
            return format(entryDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        });

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            totalSleep: matchingSleepData?.sleep_durations_data?.asleep?.duration_asleep_state_seconds
                ? Math.round(matchingSleepData.sleep_durations_data.asleep.duration_asleep_state_seconds / 3600 * 10) / 10
                : null,
            deepSleep: matchingSleepData?.sleep_durations_data?.asleep?.duration_deep_sleep_state_seconds
                ? Math.round(matchingSleepData.sleep_durations_data.asleep.duration_deep_sleep_state_seconds / 3600 * 10) / 10
                : null,
            lightSleep: matchingSleepData?.sleep_durations_data?.asleep?.duration_light_sleep_state_seconds
                ? Math.round(matchingSleepData.sleep_durations_data.asleep.duration_light_sleep_state_seconds / 3600 * 10) / 10
                : null,
            remSleep: matchingSleepData?.sleep_durations_data?.asleep?.duration_REM_sleep_state_seconds
                ? Math.round(matchingSleepData.sleep_durations_data.asleep.duration_REM_sleep_state_seconds / 3600 * 10) / 10
                : null,
            efficiency: matchingSleepData?.sleep_durations_data?.sleep_efficiency
                ? Math.round(matchingSleepData.sleep_durations_data.sleep_efficiency)
                : null,
            awakeningsCount: matchingSleepData?.sleep_durations_data?.awake?.num_wakeup_events ?? null
        };
    });

    // Calculate averages for the selected timeframe
    const calculateAverages = (data: SleepSummaryDataPoint[]) => {
        const validTotalSleep = data.filter(d => d.totalSleep !== null);
        const validDeepSleep = data.filter(d => d.deepSleep !== null);
        const validEfficiency = data.filter(d => d.efficiency !== null);
        const validAwakenings = data.filter(d => d.awakeningsCount !== null);

        return {
            avgTotalSleep: validTotalSleep.length > 0
                ? Math.round(validTotalSleep.reduce((acc, curr) => acc + (curr.totalSleep || 0), 0) / validTotalSleep.length * 10) / 10
                : null,
            avgDeepSleep: validDeepSleep.length > 0
                ? Math.round(validDeepSleep.reduce((acc, curr) => acc + (curr.deepSleep || 0), 0) / validDeepSleep.length * 10) / 10
                : null,
            avgEfficiency: validEfficiency.length > 0
                ? Math.round(validEfficiency.reduce((acc, curr) => acc + (curr.efficiency || 0), 0) / validEfficiency.length)
                : null,
            avgAwakenings: validAwakenings.length > 0
                ? Math.round(validAwakenings.reduce((acc, curr) => acc + (curr.awakeningsCount || 0), 0) / validAwakenings.length * 10) / 10
                : null
        };
    };

    const averages = calculateAverages(sleepSummaryData);

    // Add formatHoursAndMinutes helper
    const formatHoursAndMinutes = (hours: number | null) => {
        if (hours === null) return null;
        const h = Math.floor(hours);
        const m = Math.round((hours - h) * 60);
        return `${h}h ${m}m`;
    };

    // Update CustomTooltip to handle both chart types
    const CustomTooltip: React.FC<SleepTooltipProps> = ({ active, payload, label, chartType }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;

            if (chartType === 'sleep') {
                const pieData = [
                    { name: 'Light Sleep', value: data.lightSleep || 0, color: '#c4b5fd' },
                    { name: 'Deep Sleep', value: data.deepSleep || 0, color: '#8b5cf6' },
                    { name: 'REM Sleep', value: data.remSleep || 0, color: '#6d28d9' },
                ].filter(item => item.value > 0);

                return (
                    <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="font-light text-gray-600 mb-2">{label}</p>
                        {data.totalSleep ? (
                            <>
                                <div className="w-32 h-32 mx-auto">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart>
                                            <Pie
                                                data={pieData}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={25}
                                                outerRadius={45}
                                            >
                                                {pieData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="space-y-1 mt-2">
                                    <p className="text-sm font-light text-center" style={{ color: '#4c1d95' }}>
                                        Total Sleep: {formatHoursAndMinutes(data.totalSleep)}
                                    </p>
                                    {pieData.map((item, index) => (
                                        <div key={index} className="flex justify-between text-xs">
                                            <span style={{ color: item.color }}>{item.name}:</span>
                                            <span style={{ color: item.color }}>{formatHoursAndMinutes(item.value)}</span>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p className="text-sm font-light text-gray-500">No sleep data for this day</p>
                        )}
                    </div>
                );
            }

            if (chartType === 'efficiency') {
                return (
                    <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="font-light text-gray-600 mb-1">{label}</p>
                        {payload.map((entry, index) => (
                            <p key={index} className="text-sm font-light" style={{ color: entry.color }}>
                                {entry.name}: {entry.value !== null ?
                                    `${entry.value} ${entry.name.includes('Efficiency') ? '%' :
                                        entry.name.includes('Awakenings') ? 'times' : 'hrs'}` :
                                    'No data'}
                            </p>
                        ))}
                    </div>
                );
            }
        }
        return null;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-4">
            {/* Timeframe Filter */}
            <div className="flex justify-end space-x-2">
                <TimeframeButton
                    active={timeframe === 'week'}
                    onClick={() => setTimeframe('week')}
                >
                    Week
                </TimeframeButton>
                <TimeframeButton
                    active={timeframe === 'month'}
                    onClick={() => setTimeframe('month')}
                >
                    Month
                </TimeframeButton>
            </div>

            {/* Main Content Grid */}
            <div className="grid grid-cols-12 gap-4">
                {/* Sleep Duration Chart */}
                <div className="col-span-4">
                    <DashboardCard title="Sleep Duration" titleClass="text-lg font-light" icon={<Moon className="text-indigo-500" />}>
                        {sleepSummaryData.some(d => d.totalSleep !== null || d.deepSleep !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={sleepSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis
                                            dataKey="date"
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                            style={{ fontFamily: 'Inter' }}
                                        />
                                        <YAxis
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                            style={{ fontFamily: 'Inter' }}
                                        />
                                        <Tooltip content={<CustomTooltip chartType="sleep" />} />
                                        <Bar
                                            dataKey="remSleep"
                                            name="REM Sleep"
                                            stackId="sleep"
                                            fill="#6d28d9"
                                            radius={0}
                                        />
                                        <Bar
                                            dataKey="deepSleep"
                                            name="Deep Sleep"
                                            stackId="sleep"
                                            fill="#8b5cf6"
                                            radius={0}
                                        />
                                        <Bar
                                            dataKey="lightSleep"
                                            name="Light Sleep"
                                            stackId="sleep"
                                            fill="#c4b5fd"
                                            radius={[4, 4, 0, 0]}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Sleep Quality Chart */}
                <div className="col-span-4">
                    <DashboardCard title="Sleep Quality" titleClass="text-lg font-light" icon={<Moon className="text-indigo-500" />}>
                        {sleepSummaryData.some(d => d.efficiency !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={sleepSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis
                                            dataKey="date"
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                            style={{ fontFamily: 'Inter' }}
                                        />
                                        <YAxis
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                            style={{ fontFamily: 'Inter' }}
                                        />
                                        <Tooltip content={<CustomTooltip chartType="efficiency" />} />
                                        <Line type="monotone" dataKey="efficiency" name="Sleep Efficiency"
                                            stroke="#8b5cf6" strokeWidth={2} dot={{ fill: '#8b5cf6', strokeWidth: 2 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Summary Stats - 2x2 Grid */}
                <div className="col-span-4 grid grid-cols-2 gap-4 h-64">
                    {[
                        ['Total Sleep', 'Deep Sleep'],
                        ['Sleep Efficiency', 'Awakenings']
                    ].map((row, rowIndex) => (
                        row.map((metric, colIndex) => {
                            const value = metric === 'Sleep Efficiency' ? averages.avgEfficiency :
                                metric === 'Deep Sleep' ? averages.avgDeepSleep :
                                    metric === 'Awakenings' ? averages.avgAwakenings :
                                        averages.avgTotalSleep;

                            const displayValue = value !== null ?
                                `${value}${metric === 'Sleep Efficiency' ? '%' :
                                    metric === 'Awakenings' ? ' times' : ' hrs'}` :
                                'N/A';

                            const periodLabel = timeframe === 'week' ? '7-day avg' : '30-day avg';

                            return (
                                <div key={metric}
                                    className="bg-white rounded-lg p-4 shadow-sm border border-gray-100 
                                              flex flex-col justify-center">
                                    <h3 className="text-gray-500 text-sm font-light">{metric}</h3>
                                    <p className={`text-xl font-light mt-1 ${value === null ? 'text-gray-400' : 'text-gray-900'}`}>
                                        {displayValue}
                                    </p>
                                    <p className="text-xs text-gray-400 mt-1">{periodLabel}</p>
                                </div>
                            );
                        })
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SleepTab;