import React, { useState, useEffect } from 'react';
import { useSessionContext } from '../../../../contexts/SessionContext';
import { SessionCreate } from '../../../../types/sessionsTypes';
import { Check, ChevronsUpDown, CalendarIcon, Clock } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent } from "@/components/ui/card";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// Add this helper function to add one hour to a time string
const addOneHour = (timeString: string): string => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes);
  date.setTime(date.getTime() + 60 * 60 * 1000); // Add one hour in milliseconds
  return format(date, 'HH:mm');
};

const PreviousSessionForm: React.FC = () => {
  const { handleLogPreviousSession, changeActiveView, sessionState, patients, getLastSessionNumber } = useSessionContext();
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<Partial<SessionCreate>>({
    patient_id: '',
    session_number: undefined,
    date: new Date().toISOString().split('T')[0],
    start_time: '',
    end_time: '',
  });

  useEffect(() => {
    if (formData.patient_id) {
      getLastSessionNumber(formData.patient_id).then((nextSessionNumber: number) => {
        setFormData(prevData => ({ ...prevData, session_number: nextSessionNumber }));
      });
    }
  }, [formData.patient_id, getLastSessionNumber]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;

    if (name === 'session_number') {
      setFormData(prev => ({
        ...prev,
        [name]: value === '' ? undefined : Number(value)
      }));
    } else if (name === 'start_time') {
      // When start time is selected, set end time to start time + 1 hour
      setFormData(prev => ({
        ...prev,
        start_time: value,
        end_time: addOneHour(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.patient_id || !formData.session_number || !formData.date || !formData.start_time || !formData.end_time) {
      setError('Please fill in all required fields.');
      return;
    }

    try {
      await handleLogPreviousSession(formData as SessionCreate);
    } catch (error) {
      setError('Failed to create session. Please try again.');
    }
  };


  // Helper function to generate time options
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const date = new Date();
        date.setHours(hour, minute);
        options.push({
          value: format(date, 'HH:mm'),
          label: format(date, 'h:mm a')
        });
      }
    }
    return options;
  };

  const allPatients = patients.length === 0 
    ? [{
        id: "demo-patient-id",
        full_name: "Jane Doe (Demo Patient)",
        email: "demo.patient@example.com",
        role: "user"
      }]
    : patients;

  return (
    <form onSubmit={handleSubmit} className="space-y-6" data-testid="previous-session-form">
      {/* Error Message */}
      {error && (
        <Alert variant="destructive">
          <AlertDescription className="text-sm font-light">
            {error}
          </AlertDescription>
        </Alert>
      )}

      {/* Show demo notice only when no real patients exist */}
      {patients.length === 0 && formData.patient_id === "demo-patient-id" && (
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                You're using the demo patient. This is a great way to try out the platform's features. All actions will be simulated.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Form Fields - Reorganized Layout */}
      <div className="space-y-4">
        {/* Patient and Session Number Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Patient Selection */}
          <div>
            <Label htmlFor="patient_id" className="text-sm text-gray-700 font-normal">
              Patient
            </Label>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between font-light mt-2"
                  data-testid="patient-select"
                >
                  {formData.patient_id
                    ? allPatients.find((patient) => patient.id === formData.patient_id)?.full_name
                    : "Select a patient"}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0" align="start">
                <Command>
                  <CommandInput placeholder="Search patients..." />
                  <CommandList>
                    <CommandEmpty>No patient found.</CommandEmpty>
                    <CommandGroup>
                      {allPatients.map((patient) => (
                        <CommandItem
                          key={patient.id}
                          value={patient.full_name}
                          onSelect={() => {
                            handleInputChange({
                              target: { name: 'patient_id', value: patient.id }
                            } as React.ChangeEvent<HTMLSelectElement>);
                            setOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              formData.patient_id === patient.id ? "opacity-100" : "opacity-0"
                            )}
                          />
                          {patient.full_name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          {/* Session Number */}
          <div className="space-y-2">
            <Label htmlFor="session_number" className="text-sm text-gray-700 font-normal">
              Session Number
            </Label>
            <Input
              type="number"
              id="session_number"
              name="session_number"
              value={formData.session_number === undefined ? '' : formData.session_number}
              onChange={handleInputChange}
              required
              className="font-light"
              placeholder="Enter session number"
            />
          </div>
        </div>

        {/* Date and Time Row */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Date Picker */}
          <div className="space-y-2">
            <Label className="text-sm text-gray-700 font-normal">
              Date
            </Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-light",
                    !formData.date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {formData.date ? format(new Date(formData.date), "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={formData.date ? new Date(formData.date) : undefined}
                  onSelect={(date) => {
                    if (date) {
                      handleInputChange({
                        target: {
                          name: 'date',
                          value: format(date, 'yyyy-MM-dd')
                        }
                      } as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                  initialFocus
                  disabled={(date) =>
                    date > new Date() || date < new Date("1900-01-01")
                  }
                />
              </PopoverContent>
            </Popover>
          </div>

          {/* Start Time */}
          <div className="space-y-2">
            <Label className="text-sm text-gray-700 font-normal">
              Start Time
            </Label>
            <Select
              value={formData.start_time || ""}
              onValueChange={(value) => 
                handleInputChange({
                  target: { name: 'start_time', value }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <SelectTrigger className="w-full font-light">
                <SelectValue placeholder="Select start time">
                  {formData.start_time ? 
                    format(new Date(`2000-01-01T${formData.start_time}`), 'h:mm a')
                    : "Select start time"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <div className="max-h-[300px] overflow-y-auto">
                  {generateTimeOptions().map(({ value, label }) => (
                    <SelectItem key={value} value={value} className="font-light">
                      {label}
                    </SelectItem>
                  ))}
                </div>
              </SelectContent>
            </Select>
          </div>

          {/* End Time */}
          <div className="space-y-2">
            <Label className="text-sm text-gray-700 font-normal">
              End Time
            </Label>
            <Select
              value={formData.end_time || ""}
              onValueChange={(value) => 
                handleInputChange({
                  target: { name: 'end_time', value }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <SelectTrigger className="w-full font-light">
                <SelectValue placeholder="Select end time">
                  {formData.end_time ? 
                    format(new Date(`2000-01-01T${formData.end_time}`), 'h:mm a')
                    : "Select end time"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <div className="max-h-[300px] overflow-y-auto">
                  {generateTimeOptions().map(({ value, label }) => (
                    <SelectItem 
                      key={value} 
                      value={value} 
                      className={cn(
                        "font-light",
                        formData.start_time && value <= formData.start_time ? "text-muted-foreground" : ""
                      )}
                    >
                      {label}
                    </SelectItem>
                  ))}
                </div>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="outline"
          onClick={() => changeActiveView('default')}
          className="font-light rounded-full"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={sessionState.isLoading}
          className="font-light rounded-full"
        >
          {sessionState.isLoading ? 'Creating...' : 'Create Session'}
        </Button>
      </div>
    </form>
  );
};

export default PreviousSessionForm;
