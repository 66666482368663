import React from 'react';
import { format } from 'date-fns';

export interface DocumentCardProps {
  title: string;
  icon: React.ReactNode;
  status: 'signed' | 'unsigned' | 'completed' | 'pending';
  lastUpdated: string | null;
  onClick: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  icon,
  status,
  lastUpdated,
  onClick
}) => {
  const getStatusColor = () => {
    switch (status) {
      case 'signed':
      case 'completed':
        return 'bg-green-50 text-green-700 border-green-100';
      case 'unsigned':
        return 'bg-amber-50 text-amber-700 border-amber-100';
      case 'pending':
        return 'bg-gray-50 text-gray-700 border-gray-100';
    }
  };

  return (
    <button
      onClick={onClick}
      className="w-full p-3 rounded-lg border border-gray-200 hover:border-primary/20 
                 bg-white shadow-sm hover:bg-gray-50/80 transition-all duration-200 group"
    >
      <div className="flex flex-col items-center gap-2">
        <div className="p-2 rounded-lg bg-primary/5 text-primary group-hover:bg-primary/10 
                      transition-all duration-200">
          {icon}
        </div>
        
        <div className="w-full text-center space-y-2">
          <h3 className="text-sm font-normal text-gray-900">{title}</h3>
          
          <p className="text-xs text-gray-400">
            {lastUpdated 
              ? `Updated ${format(new Date(lastUpdated), 'MMM d, yyyy')}`
              : 'No deadline'
            }
          </p>
          
          <span className={`inline-block text-xs px-2 py-0.5 rounded-full ${getStatusColor()}`}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>
        </div>
      </div>
    </button>
  );
};

export default DocumentCard; 