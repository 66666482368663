import React from 'react';
import WellbeingScoreBar from './WellbeingScoreDesktop/WellbeingScoreBar';
import { Session } from '../../../../types/sessionsTypes';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

interface SessionDetailsProps {
    session: Session;
    handleEditClick: (session: Session) => void;
    handleDeleteClick: (sessionId: string) => void;
    downloadPDF: (session: Session) => Promise<void>;
    stickyButtons?: boolean;
}

const SessionDetails: React.FC<SessionDetailsProps> = ({
    session,
    handleEditClick,
    handleDeleteClick,
    downloadPDF,
    stickyButtons
}) => {
    const formatTime = (time: string | undefined): string => {
        if (!time) return '-';
        return new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const calculateDuration = (startTime: string | undefined, endTime: string | undefined): string => {
        if (!startTime || !endTime) return '-';

        const start = new Date(`2000-01-01T${startTime}`);
        const end = new Date(`2000-01-01T${endTime}`);
        const diff = end.getTime() - start.getTime();

        const minutes = Math.floor(diff / 60000);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return `${hours}h ${remainingMinutes}m`;
    };

    return (
        <div className="space-y-6">
            {/* Session Header Info */}
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h5 className="font-normal text-primary mb-4">Session Information</h5>
                        <div className="space-y-3">
                            <p className="text-sm">
                                <span className="font-normal text-gray-700">Session Number:</span>
                                <span className="font-light ml-2">{session.session_number}</span>
                            </p>
                            <p className="text-sm">
                                <span className="font-normal text-gray-700">Date:</span>
                                <span className="font-light ml-2">
                                    {new Date(session.date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </span>
                            </p>
                            <p className="text-sm">
                                <span className="font-normal text-gray-700">Start Time:</span>
                                <span className="font-light ml-2">{formatTime(session.start_time)}</span>
                            </p>
                            <p className="text-sm">
                                <span className="font-normal text-gray-700">End Time:</span>
                                <span className="font-light ml-2">{formatTime(session.end_time)}</span>
                            </p>
                            <p className="text-sm">
                                <span className="font-normal text-gray-700">Duration:</span>
                                <span className="font-light ml-2">
                                    {calculateDuration(session.start_time, session.end_time)}
                                </span>
                            </p>
                        </div>
                    </div>
                    {session.wellbeing_score !== null && (
                        <div>
                            <h5 className="font-normal text-primary mb-4">Notes Sentiment Score</h5>
                            <div className="text-sm">
                                <WellbeingScoreBar score={session.wellbeing_score} isMobile={false} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Enhanced Summary */}
            <div>
                <h5 className="font-normal text-primary mb-4">Enhanced Summary</h5>
                <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                        components={{
                            p: ({ children }) => <p className="mb-4 font-light">{children}</p>,
                            h1: ({ children }) => <h1 className="text-2xl font-normal mb-4 text-primary">{children}</h1>,
                            h2: ({ children }) => <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>,
                            h3: ({ children }) => <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>,
                            ul: ({ children }) => <ul className="list-disc pl-5 mb-4 font-light">{children}</ul>,
                            ol: ({ children }) => <ol className="list-decimal pl-5 mb-4 font-light">{children}</ol>,
                            li: ({ children }) => <li className="mb-1 font-light">{children}</li>,
                        }}
                    >
                        {session.summary || 'No summary available.'}
                    </ReactMarkdown>
                </div>
            </div>

            {/* Original Notes */}
            <div>
                <h5 className="font-normal text-primary mb-4">Original Notes</h5>
                <p className="text-sm bg-gray-50 p-6 rounded-lg border border-gray-200 font-light leading-relaxed">
                    {session.content}
                </p>
            </div>

            {/* File Display Section */}
            {session.file_url && (
                <div>
                    <h5 className="font-normal text-primary mb-4">Uploaded File</h5>
                    {session.file_url.toLowerCase().endsWith('.pdf') ? (
                        <embed
                            src={session.file_url}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                            className="border border-gray-200 rounded-lg"
                        />
                    ) : session.file_url.match(/\.(jpeg|jpg|gif|png)$/) ? (
                        <img
                            src={session.file_url}
                            alt="Uploaded file"
                            className="max-w-full h-auto rounded-lg border border-gray-200"
                        />
                    ) : (
                        <a
                            href={session.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:text-primary/80 font-light transition-colors duration-200"
                        >
                            View uploaded file
                        </a>
                    )}
                </div>
            )}
        </div>
    );
};

export default SessionDetails;
