'use client';

import { cn } from '@/lib/utils';
import React, { forwardRef, useRef } from 'react';
import { AnimatedBeam, Circle } from '@/components/ui/beam';
import { FiUser } from 'react-icons/fi';
const oasysLogo = require('../../assets/oasys-logo1.png');

// Import health provider logos
const fitbitLogo = require('../../assets/HealthProviders/fitbit-small.png');
const floLogo = require('../../assets/HealthProviders/flo-landing-page.png');
const ouraLogo = require('../../assets/HealthProviders/oura-landing-page.png');
const whoopLogo = require('../../assets/HealthProviders/whoop.png');
const appleLogo = require('../../assets/HealthProviders/applehealth.png');

const Icons = {
    fitbit: () => <img src={fitbitLogo} alt="Fitbit" className="w-8 h-8 object-contain" />,
    garmin: () => <img src={floLogo} alt="Garmin" className="w-8 h-8 object-contain" />,
    oura: () => <img src={ouraLogo} alt="Oura" className="w-8 h-8 object-contain" />,
    whoop: () => <img src={whoopLogo} alt="Whoop" className="w-8 h-8 object-contain" />,
    apple: () => <img src={appleLogo} alt="Apple Health" className="w-8 h-8 object-contain" />,
    server: () => <img src={oasysLogo} alt="Oasys Logo" className="w-16 h-16 object-contain" />,
    user: () => <FiUser className="w-6 h-6" />,
};

// Add this CSS class to your global CSS or add it inline
const spinAnimation = {
    animation: 'spin 10s linear infinite',
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    }
};

export default function BeamDemo() {
    const containerRef = useRef<HTMLDivElement>(null);
    const div1Ref = useRef<HTMLDivElement>(null);
    const div2Ref = useRef<HTMLDivElement>(null);
    const div3Ref = useRef<HTMLDivElement>(null);
    const div4Ref = useRef<HTMLDivElement>(null);
    const div5Ref = useRef<HTMLDivElement>(null);
    const div6Ref = useRef<HTMLDivElement>(null);
    const div7Ref = useRef<HTMLDivElement>(null);

    return (
        <div
            className='relative flex h-full w-full max-w-[64rem] mx-auto items-center justify-center overflow-hidden rounded-lg bg-background/50 backdrop-blur-sm p-8 '
            ref={containerRef}
        >
            <div className='flex h-full w-full flex-row items-stretch justify-between gap-16'>
                <div className='flex flex-col justify-center gap-4'>
                    <div className="flex flex-col items-center">
                        <Circle ref={div1Ref} className="h-16 w-16">
                            <Icons.fitbit />
                        </Circle>
                    </div>
                    <div className="flex flex-col items-center">
                        <Circle ref={div2Ref} className="h-16 w-16">
                            <Icons.garmin />
                        </Circle>
                    </div>
                    <div className="flex flex-col items-center">
                        <Circle ref={div3Ref} className="h-16 w-16">
                            <Icons.oura />
                        </Circle>
                    </div>
                    <div className="flex flex-col items-center">
                        <Circle ref={div4Ref} className="h-16 w-16">
                            <Icons.whoop />
                        </Circle>
                    </div>
                    <div className="flex flex-col items-center">
                        <Circle ref={div5Ref} className="h-16 w-16">
                            <Icons.apple />
                        </Circle>
                    </div>
                </div>
                <div className='flex flex-col justify-center'>
                    <Circle ref={div6Ref} className='h-24 w-24 p-2'>
                        <img
                            src={oasysLogo}
                            alt="Oasys Logo"
                            className="w-16 h-16 object-contain animate-spin-slow"
                            style={{ animationDuration: '2s' }}
                        />
                    </Circle>
                </div>
                <div className='flex flex-col justify-center'>
                    <Circle ref={div7Ref}>
                        <Icons.user />
                    </Circle>
                </div>
            </div>

            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div1Ref}
                toRef={div6Ref}
                dotted
                dotSpacing={6}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div2Ref}
                toRef={div6Ref}
                dotted
                gradientStartColor='#006ae3'
                gradientStopColor='#1194ff'
                dotSpacing={6}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div3Ref}
                toRef={div6Ref}
                gradientStartColor='#00ac47'
                gradientStopColor='#4fcc5d'
                dotted
                dotSpacing={6}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div4Ref}
                toRef={div6Ref}
                dotted
                gradientStartColor='#006ae3'
                gradientStopColor='#1194ff'
                dotSpacing={6}
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div5Ref}
                toRef={div6Ref}
                dotted
                dotSpacing={6}
                gradientStartColor='#d948ae'
                gradientStopColor='#5b60ff'
            />
            <AnimatedBeam
                containerRef={containerRef}
                fromRef={div6Ref}
                toRef={div7Ref}
                dotted
                dotSpacing={6}
            />
        </div>
    );
} 