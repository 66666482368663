import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FiUser, FiLogOut } from 'react-icons/fi';
import { useAuthContext } from '../../contexts/AuthContext';
import { PrimaryButton, OutlineButton } from "@/components/Shared/Buttons";

const oasysLogo = require('../../assets/oasys-logo.png');
const defaultAvatar = require('../../assets/default-avatar.png');
const defaultAvatar2 = require('../../assets/default-avatar-2.png');


const Navbar: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { session, user, signOut, userData } = useAuthContext();

    const isTherapist = user?.user_metadata?.role === 'therapist';
    const profilePicture = userData?.profile_picture_url || (isTherapist ? defaultAvatar : defaultAvatar2);
    const fullName = isTherapist ? userData?.full_name : (userData?.full_name || user?.user_metadata?.full_name || 'User');
    const isSignupPage = location.pathname === '/patient-signup' || location.pathname === '/therapist-signup';
    const isTherapistOnboardingPage = [
        '/therapist-onboarding/professional-info',
        '/therapist-onboarding/practice-details',
        '/therapist-onboarding/bio-and-approach',
        '/therapist-onboarding/verification-submission',
        '/therapist-onboarding/pending-approval'
    ].includes(location.pathname);

    const isAuthenticatedPage = location.pathname === '/dashboard' ||
        location.pathname === '/admin-dashboard' ||
        location.pathname === '/therapist';

    const isAuthenticated = !!session && isAuthenticatedPage;

    const handleLogoutClick = async () => {
        const confirmLogout = window.confirm("Are you sure you want to log out?");
        if (confirmLogout) {
            try {
                await signOut();
                navigate('/');
            } catch (error) {
                console.error('Error signing out:', error);
            }
        }
    };

    const handleLogoClick = () => {
        if (isAuthenticated) {
            // If user is logged in, refresh the current page
            window.location.reload();
        } else {
            // If user is not logged in, navigate to home page
            navigate('/');
        }
    };

    const toggleDropdown = () => setDropdownOpen(prev => !prev);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setDropdownOpen(false);
    }, [location]);

    const handleProfileClick = () => {
        if (isTherapist) {
            navigate('/therapist', { state: { showProfile: true } });
        } else {
            navigate('/dashboard', { state: { showProfile: true } });
        }
        setDropdownOpen(false);
    };

    return (
        <nav className="bg-white shadow-sm border-b border-gray-200 fixed top-0 left-0 right-0 z-50">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 relative">
                <div className="flex justify-between items-center h-20">
                    <div
                        onClick={handleLogoClick}
                        className="cursor-pointer flex items-center"
                    >
                        <img src={oasysLogo} alt="Oasys Logo" className="h-16 w-auto mr-1" />
                    </div>
                    {!isSignupPage && !isTherapistOnboardingPage && (
                        <div className="flex items-center">
                            {isAuthenticated ? (
                                <div className="relative" ref={dropdownRef}>
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={toggleDropdown}
                                    >
                                        <img
                                            src={profilePicture}
                                            alt="Profile Avatar"
                                            className="h-8 w-8 rounded-full mr-2 object-cover object-center"
                                        />
                                        <span className="text-gray-700 hover:text-primary transition-colors duration-300 hidden md:inline font-light">
                                            {fullName}
                                        </span>
                                    </div>
                                    {dropdownOpen && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                                            <button
                                                onClick={handleProfileClick}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center font-light"
                                            >
                                                <FiUser className="mr-2" /> Profile
                                            </button>
                                            <button
                                                onClick={handleLogoutClick}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center font-light"
                                            >
                                                <FiLogOut className="mr-2" /> Logout
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <PrimaryButton 
                                        asChild 
                                        className="mr-4 hidden sm:inline-flex"
                                    >
                                        <Link to="/get-started">
                                            Get Started
                                        </Link>
                                    </PrimaryButton>
                                    <OutlineButton asChild>
                                        <Link to="/login">
                                            Log In
                                        </Link>
                                    </OutlineButton>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
