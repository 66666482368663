import React, { useState } from 'react';
import { PatientFormData } from '../../../../types/therapistDashboardTypes';
import { usePatientTab } from '../../../../contexts/PatientTabContext';

const CreatePatientModal: React.FC = () => {
  const { closeCreatePatientModal, handlePatientCreated, createPatient } = usePatientTab();
  const [formData, setFormData] = useState<PatientFormData>({
    full_name: '',
    email: '',
    password: 'temporaryPassword123',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const newPatient = await createPatient(formData);
      await handlePatientCreated(newPatient);
      closeCreatePatientModal();
    } catch (err) {
      console.error('Failed to create patient:', err);
      setError('Failed to create patient. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-[480px] max-w-[90vw]">
        <h3 className="text-2xl font-normal text-primary mb-6">Create New Patient</h3>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-light mb-2">Full Name</label>
            <input
              type="text"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg 
                       focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent 
                       transition-all duration-200 font-light"
              placeholder="Enter patient's full name"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-light mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg 
                       focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent 
                       transition-all duration-200 font-light"
              placeholder="Enter patient's email"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm font-light">{error}</p>
          )}
          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={closeCreatePatientModal}
              className="px-6 py-3 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg 
                       transition-colors duration-200 font-light"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-6 py-3 bg-primary hover:bg-primary/90 text-white rounded-lg 
                       transition-colors duration-200 font-light disabled:opacity-50 
                       disabled:cursor-not-allowed transform hover:scale-[1.02]"
            >
              {isLoading ? 'Creating...' : 'Create Patient'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePatientModal;
