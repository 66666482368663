import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TherapistOnboardingProgress from './TherapistOnboardingProgress';
import CodeOfEthicsModal from './CodeOfEthicsModal';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import useTherapistSignUp from '../../hooks/useTherapistSignUp';
import { useAuthContext } from '../../contexts/AuthContext';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const VerificationSubmission: React.FC = () => {
  const [licenseFile, setLicenseFile] = useState<File | null>(null);
  const [agreeToEthics, setAgreeToEthics] = useState<boolean>(false);
  const [agreeToBackgroundCheck, setAgreeToBackgroundCheck] = useState<boolean>(false);
  const [isEthicsModalOpen, setIsEthicsModalOpen] = useState<boolean>(false);
  const [hasReadEthics, setHasReadEthics] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { error: hookError, handleVerificationSubmission } = useTherapistSignUp();

  useEffect(() => {
    const checkAuth = async (): Promise<void> => {
      if (!user) {
        navigate('/login');
      }
    };
    checkAuth();
  }, [navigate, user]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files[0]) {
      setLicenseFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (user?.id) {
      await handleVerificationSubmission(user.id, {
        licenseFile,
        agreeToEthics,
        agreeToBackgroundCheck
      });
    }
  };

  const handleBack = (): void => {
    navigate('/therapist-onboarding/bio-and-approach');
  };

  const handleEthicsAccept = () => {
    setHasReadEthics(true);
    setAgreeToEthics(true);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex items-center justify-center p-4 pt-24 pb-32 md:pt-32 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md mx-auto p-8 md:p-12 mb-40">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-normal text-primary">Verification and Submission</h2>
              <p className="text-gray-400 mt-2 font-light">Complete your application submission.</p>
            </div>

            <TherapistOnboardingProgress currentStep="/therapist-onboarding/verification-submission" />

            <form onSubmit={handleSubmit} className="space-y-8 mt-8">
              <div>
                <Label htmlFor="licenseFile" className="text-sm text-gray-500 font-light">
                  Upload License Document (Optional)
                </Label>
                <div className="mt-2">
                  <Input
                    type="file"
                    id="licenseFile"
                    onChange={handleFileChange}
                    className="font-light h-12 py-1.5 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-light file:bg-primary file:text-white hover:file:bg-primary-dark file:cursor-pointer cursor-pointer"
                  />
                </div>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="agreeToEthics"
                    checked={agreeToEthics}
                    onChange={(e) => {
                      if (!hasReadEthics) {
                        setIsEthicsModalOpen(true);
                        e.preventDefault();
                      } else {
                        setAgreeToEthics(e.target.checked);
                      }
                    }}
                    required
                    className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
                  />
                  <label htmlFor="agreeToEthics" className="text-sm font-light text-gray-700">
                    I agree to abide by the{' '}
                    <button
                      type="button"
                      onClick={() => setIsEthicsModalOpen(true)}
                      className="text-primary hover:underline focus:outline-none font-light"
                    >
                      code of ethics
                    </button>{' '}
                    *
                  </label>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="agreeToBackgroundCheck"
                    checked={agreeToBackgroundCheck}
                    onChange={(e) => setAgreeToBackgroundCheck(e.target.checked)}
                    required
                    className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
                  />
                  <label htmlFor="agreeToBackgroundCheck" className="text-sm font-light text-gray-700">
                    I consent to a background check *
                  </label>
                </div>
              </div>

              <div className="flex justify-between space-x-4 mt-20">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-4 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors font-normal"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-normal"
                >
                  Submit
                </button>
              </div>
            </form>

            {hookError && (
              <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light mt-6">
                <span className="block sm:inline">{hookError}</span>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
      <CodeOfEthicsModal 
        isOpen={isEthicsModalOpen} 
        onClose={() => setIsEthicsModalOpen(false)}
        onAccept={handleEthicsAccept}
      />
    </div>
  );
};

export default VerificationSubmission;
