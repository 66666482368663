import React, { useState } from 'react';
import { FiEdit, FiSave as FiSaveIcon, FiRefreshCw } from 'react-icons/fi';
import { FaBold, FaItalic, FaHeading } from 'react-icons/fa';
import { Session } from '../../../../types/sessionsTypes';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Tooltip from '../../../Shared/Tooltip';

interface SessionEditFormProps {
  editingNote: Session;
  handleEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleEditSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setEditingNote: React.Dispatch<React.SetStateAction<Session | null>>;
  handleRegenerateSummary: (format: string) => Promise<void>;
  isRegeneratingSummary: boolean;
  isMobile?: boolean;
  stickyButtons?: boolean;
}

const SessionEditForm: React.FC<SessionEditFormProps> = ({
  editingNote,
  handleEditChange,
  handleEditSubmit,
  setEditingNote,
  handleRegenerateSummary,
  isRegeneratingSummary,
  isMobile,
  stickyButtons
}) => {
  const [isEditingSummary, setIsEditingSummary] = useState<boolean>(false);
  const [editedSummary, setEditedSummary] = useState<string>(editingNote.summary || '');
  const [showFormatDialog, setShowFormatDialog] = useState<boolean>(false);

  const handleEditSummary = (): void => {
    setIsEditingSummary(true);
    setEditedSummary(editingNote.summary || '');
  };

  const handleSaveSummary = (): void => {
    const editor = document.getElementById('summary-editor');
    if (editor) {
      handleEditChange({
        target: { 
          name: 'summary', 
          value: editor.innerHTML 
        }
      } as React.ChangeEvent<HTMLTextAreaElement>);
    }
    setIsEditingSummary(false);
  };

  const handleContentChange = (e: React.FormEvent<HTMLDivElement>): void => {
    setEditedSummary(e.currentTarget.innerHTML);
  };

  const formatSummary = (text: string): string => {
    const sections = text.split('\n\n');
    return sections.map(section => {
      const lines = section.split('\n');
      const title = lines.shift() || '';
      const content = lines.join('\n');
      return `<h3 class="text-lg font-normal">${title}</h3><p class="mb-4 font-light">${content}</p>`;
    }).join('');
  };

  const handleRegenerateClick = (): void => {
    setShowFormatDialog(true);
  };

  const handleFormatSelect = async (format: string): Promise<void> => {
    setShowFormatDialog(false);
    await handleRegenerateSummary(format);
  };

  const applyStyle = (style: 'bold' | 'italic' | 'h3') => {
    document.execCommand('styleWithCSS', false);
    
    switch(style) {
      case 'bold':
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const span = document.createElement('span');
          span.style.fontWeight = '700';
          span.appendChild(range.extractContents());
          range.insertNode(span);
        }
        break;
      case 'italic':
        document.execCommand('italic', false);
        break;
      case 'h3':
        document.execCommand('formatBlock', false, 'h3');
        break;
    }
    
    // Update editedSummary with the new HTML content
    const editor = document.getElementById('summary-editor');
    if (editor) {
      setEditedSummary(editor.innerHTML);
    }
  };

  return (
    <>
      <form onSubmit={handleEditSubmit} className={`space-y-6 ${isMobile ? 'p-4' : ''}`}>
        <div className={`${isMobile ? 'space-y-6' : 'grid grid-cols-1 md:grid-cols-2 gap-6'}`}>
          <div>
            <label className="block text-sm text-gray-700 font-normal mb-2">Session Number</label>
            <input
              type="number"
              name="session_number"
              value={editingNote.session_number}
              onChange={handleEditChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none 
                           focus:ring-2 focus:ring-primary focus:border-transparent font-light
                           transition-all duration-200"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700 font-normal mb-2">Date</label>
            <input
              type="date"
              name="date"
              value={editingNote.date}
              onChange={handleEditChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none 
                           focus:ring-2 focus:ring-primary focus:border-transparent font-light
                           transition-all duration-200"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700 font-normal mb-2">Start Time</label>
            <input
              type="time"
              name="start_time"
              value={editingNote.start_time || ''}
              onChange={handleEditChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none 
                           focus:ring-2 focus:ring-primary focus:border-transparent font-light
                           transition-all duration-200"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-700 font-normal mb-2">End Time</label>
            <input
              type="time"
              name="end_time"
              value={editingNote.end_time || ''}
              onChange={handleEditChange}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none 
                           focus:ring-2 focus:ring-primary focus:border-transparent font-light
                           transition-all duration-200"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm text-gray-700 font-normal mb-2">Original Notes</label>
          <textarea
            name="content"
            value={editingNote.content || ''}
            onChange={handleEditChange}
            rows={4}
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none 
                         focus:ring-2 focus:ring-primary focus:border-transparent font-light
                         transition-all duration-200"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm text-gray-700 font-normal mb-2">Summary</label>
          <div className="flex justify-between items-center mb-2">
            {isEditingSummary ? (
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={handleSaveSummary}
                  className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                >
                  <FiSaveIcon className="h-5 w-5 text-primary" />
                  <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                 group-hover:block bg-gray-800 text-white text-xs rounded-lg 
                                 py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                        style={{ 
                          transform: 'translate(-50%, -4px)', 
                          filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                        }}>
                    Save Summary
                  </span>
                </button>
                <div className="h-6 w-px bg-gray-200 mx-2" />
                <div className="flex items-center gap-1">
                  <button
                    type="button"
                    onClick={() => applyStyle('bold')}
                    className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    <FaBold className="h-4 w-4 text-gray-600" />
                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                   group-hover:block bg-gray-800 text-white text-xs rounded-lg 
                                   py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                          style={{ 
                            transform: 'translate(-50%, -4px)', 
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                          }}>
                      Bold
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => applyStyle('italic')}
                    className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    <FaItalic className="h-4 w-4 text-gray-600" />
                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                   group-hover:block bg-gray-800 text-white text-xs rounded-lg 
                                   py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                          style={{ 
                            transform: 'translate(-50%, -4px)', 
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                          }}>
                      Italic
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => applyStyle('h3')}
                    className="group relative p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    <FaHeading className="h-4 w-4 text-gray-600" />
                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden 
                                   group-hover:block bg-gray-800 text-white text-xs rounded-lg 
                                   py-1 px-2 whitespace-nowrap font-light z-[9999] pointer-events-none"
                          style={{ 
                            transform: 'translate(-50%, -4px)', 
                            filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                          }}>
                      Heading
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <>
                <Tooltip text="Edit Summary">
                  <button
                    type="button"
                    onClick={handleEditSummary}
                    className="p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    <FiEdit className="h-5 w-5 text-primary" />
                  </button>
                </Tooltip>

                <Tooltip text="Regenerate Summary">
                  <button
                    type="button"
                    onClick={handleRegenerateClick}
                    className="p-2 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    <FiRefreshCw 
                      className={`h-5 w-5 text-primary ${isRegeneratingSummary ? 'animate-spin' : ''}`} 
                    />
                  </button>
                </Tooltip>
              </>
            )}
          </div>
          {isEditingSummary ? (
            <div
              id="summary-editor"
              contentEditable
              onInput={handleContentChange}
              dangerouslySetInnerHTML={{ __html: editedSummary }}
              className="w-full h-64 px-4 py-3 border border-gray-200 rounded-lg 
                        focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent 
                        font-light transition-all duration-200 overflow-y-auto 
                        [&_span]:font-bold [&_h3]:text-lg [&_h3]:font-normal"
              style={{ whiteSpace: 'pre-wrap' }}
            />
          ) : (
            <div
              className="bg-gray-50 p-6 rounded-lg border border-gray-200 text-gray-700 
                        prose prose-sm max-w-none font-light"
              dangerouslySetInnerHTML={{ __html: editingNote.summary || '' }}
            />
          )}
        </div>
      </form>

      {/* Format Selection Dialog */}
      <Transition appear show={showFormatDialog} as={Fragment}>
        <Dialog 
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto" 
          onClose={() => setShowFormatDialog(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title className="text-lg font-normal text-gray-900 mb-4">
                  Select Summary Format
                </Dialog.Title>

                <div className="space-y-3">
                  {['SOAP', 'DAP', 'BIRP'].map((format) => (
                    <button
                      key={format}
                      onClick={() => handleFormatSelect(format)}
                      className="w-full px-4 py-3 text-left rounded-lg hover:bg-gray-50 
                               transition-colors duration-200 focus:outline-none focus:ring-2 
                               focus:ring-primary"
                    >
                      {format}
                    </button>
                  ))}
                </div>

                <button
                  onClick={() => setShowFormatDialog(false)}
                  className="mt-4 w-full px-4 py-2 text-sm text-gray-600 hover:text-gray-900 
                           transition-colors duration-200"
                >
                  Cancel
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SessionEditForm;
