import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Heart, HeartPulse, Activity } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { BodyData, BodyStats, BodySummaryData, CustomTooltipProps } from '../../../types';

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-3 py-1 text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);


interface BodyTabProps {
    bodyData: BodyData[];
}

const BodyTab: React.FC<BodyTabProps> = ({ bodyData }) => {
    const [timeframe, setTimeframe] = useState<'week' | 'month'>('week');


    const timeframeDays: Record<string, number> = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = () => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process body data with proper date range
    const bodySummaryData = generateDateRange().map(date => {
        const matchingBodyData = bodyData.find(entry =>
            format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            heartRate: matchingBodyData?.heart_data?.heart_rate_data?.summary?.avg_hr_bpm || null,
            oxygen: matchingBodyData?.oxygen_data?.avg_saturation_percentage || null,
            vo2max: matchingBodyData?.oxygen_data?.vo2max_ml_per_min_per_kg || null
        };
    });

    const calculateStats = (data: BodySummaryData[]): BodyStats => {
        const validHR = data.filter(d => d.heartRate !== null);
        const validOxygen = data.filter(d => d.oxygen !== null);
        const validVo2 = data.filter(d => d.vo2max !== null);

        const lastVo2 = validVo2.length > 0 ? validVo2[validVo2.length - 1].vo2max : null;

        return {
            avgHeartRate: validHR.length > 0
                ? Math.round(validHR.reduce((acc, curr) => acc + (curr.heartRate || 0), 0) / validHR.length)
                : null,
            maxHeartRate: validHR.length > 0
                ? Math.round(Math.max(...validHR.map(d => d.heartRate || 0)))
                : null,
            minHeartRate: validHR.length > 0
                ? Math.round(Math.min(...validHR.map(d => d.heartRate || 0)))
                : null,
            avgOxygen: validOxygen.length > 0
                ? Number((validOxygen.reduce((acc, curr) => acc + (curr.oxygen || 0), 0) / validOxygen.length).toFixed(1))
                : null,
            vo2Max: lastVo2 !== null
                ? Number(lastVo2.toFixed(1))
                : null
        };
    };

    const stats = calculateStats(bodySummaryData);

    const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                    <p className="font-light text-gray-600 mb-1">{label}</p>
                    {payload.map((entry, index) => (
                        <p key={index} className="text-sm font-light" style={{ color: entry.color }}>
                            {entry.name}: {entry.value !== null ?
                                `${entry.name === 'Oxygen Saturation' ?
                                    Number(entry.value).toFixed(1) :
                                    Math.round(entry.value)}${entry.name === 'Heart Rate' ? ' bpm' : '%'}` :
                                'No data'}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-2">
            <div className="flex justify-end space-x-2 overflow-x-auto pb-2" data-testid="timeframe-selector">
                <TimeframeButton active={timeframe === 'week'} onClick={() => setTimeframe('week')}>Week</TimeframeButton>
                <TimeframeButton active={timeframe === 'month'} onClick={() => setTimeframe('month')}>Month</TimeframeButton>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                {/* Heart Rate Chart */}
                <div className="col-span-1 md:col-span-8">
                    <DashboardCard
                        title="Heart Rate"
                        titleClass="text-lg font-light"
                        icon={<Heart className="text-rose-500" />}
                        testId="dashboard-card-heart-rate"
                    >
                        <div className="h-64 min-h-[250px]">
                            <ResponsiveContainer width="100%" height="100%">
                                {bodySummaryData.some(d => d.heartRate !== null) ? (
                                    <LineChart data={bodySummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis yAxisId="left" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis yAxisId="right" orientation="right" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="heartRate"
                                            name="Heart Rate"
                                            stroke="#f43f5e"
                                            strokeWidth={2}
                                            dot={{ fill: '#f43f5e', strokeWidth: 2 }}
                                            connectNulls={true}
                                        />
                                    </LineChart>
                                ) : (
                                    <div className="h-full flex items-center justify-center text-gray-500 font-light">
                                        Connect your device to track heart rate
                                    </div>
                                )}
                            </ResponsiveContainer>
                        </div>
                    </DashboardCard>
                </div>

                {/* Oxygen Saturation Chart */}
                <div className="col-span-1 md:col-span-4">
                    <DashboardCard
                        title="Oxygen Saturation"
                        titleClass="text-lg font-light"
                        icon={<HeartPulse className="text-rose-500" />}
                        testId="dashboard-card-oxygen"
                    >
                        <div className="h-64 min-h-[250px]">
                            <ResponsiveContainer width="100%" height="100%">
                                {bodySummaryData.some(d => d.oxygen !== null) ? (
                                    <LineChart data={bodySummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis
                                            yAxisId="left"
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                            domain={[90, 100]}
                                            allowDataOverflow={true}
                                        />
                                        <YAxis yAxisId="right" orientation="right" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="oxygen"
                                            name="Oxygen Saturation"
                                            stroke="#f43f5e"
                                            strokeWidth={2}
                                            dot={{ fill: '#f43f5e', strokeWidth: 2 }}
                                        />
                                    </LineChart>
                                ) : (
                                    <div className="h-full flex items-center justify-center text-gray-500 font-light">
                                        Connect your device to track oxygen saturation
                                    </div>
                                )}
                            </ResponsiveContainer>
                        </div>
                    </DashboardCard>
                </div>

                {/* Summary Stats */}
                <div className="col-span-1 md:col-span-12">
                    <DashboardCard
                        title="Heart Health Summary"
                        titleClass="text-lg font-light"
                        icon={<Activity className="text-rose-500" />}
                    >
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                            <div className="text-center p-2" data-testid="avg-heart-rate">
                                <p className="text-sm text-gray-500 font-light mb-1">Avg. Heart Rate</p>
                                <p className="text-xl md:text-2xl font-light text-rose-500">
                                    {stats.avgHeartRate ?
                                        `${stats.avgHeartRate} bpm` :
                                        <span className="text-gray-400">--</span>}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-sm text-gray-500 font-light mb-1">Max Heart Rate</p>
                                <p className="text-xl md:text-2xl font-light text-rose-500">
                                    {stats.maxHeartRate ?
                                        `${stats.maxHeartRate} bpm` :
                                        <span className="text-gray-400">--</span>}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-sm text-gray-500 font-light mb-1">Min Heart Rate</p>
                                <p className="text-xl md:text-2xl font-light text-rose-500">
                                    {stats.minHeartRate ?
                                        `${stats.minHeartRate} bpm` :
                                        <span className="text-gray-400">--</span>}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-sm text-gray-500 font-light mb-1">Avg. Oxygen Saturation</p>
                                <p className="text-xl md:text-2xl font-light text-rose-500">
                                    {stats.avgOxygen ?
                                        `${stats.avgOxygen}%` :
                                        <span className="text-gray-400">--</span>}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-sm text-gray-500 font-light mb-1">VO2 Max</p>
                                <p className="text-xl md:text-2xl font-light text-rose-500">
                                    {stats.vo2Max ?
                                        `${stats.vo2Max} ml/min/kg` :
                                        <span className="text-gray-400">--</span>}
                                </p>
                            </div>
                        </div>
                    </DashboardCard>
                </div>
            </div>
        </div>
    );
};

export default BodyTab;