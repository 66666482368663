import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { FaPencilAlt, FaSave, FaBold, FaItalic, FaHeading, FaLink, FaListUl, FaColumns } from 'react-icons/fa';
import Tooltip from '../../../Shared/Tooltip';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

interface SessionSummaryModalProps {
    isOpen: boolean;
    onClose: () => void;
    summary: string;
    isEditing: boolean;
    onEdit: () => void;
    onSave: () => void;
    editedSummary: string;
    onSummaryChange: (markdown: string) => void;
    wellbeingScore?: number;
}

interface ToolbarButtonProps {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    active?: boolean;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({ icon, label, onClick, active }) => (
    <Tooltip text={label}>
        <button
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            className={`p-2 rounded-lg transition-colors duration-200
                      ${active
                    ? 'bg-primary/10 text-primary'
                    : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'}`}
        >
            {icon}
        </button>
    </Tooltip>
);

const SessionSummaryModal: React.FC<SessionSummaryModalProps> = ({
    isOpen,
    onClose,
    summary,
    isEditing,
    onEdit,
    onSave,
    editedSummary,
    onSummaryChange,
    wellbeingScore
}) => {
    const editorRef = React.useRef<HTMLTextAreaElement>(null);
    const [showPreview, setShowPreview] = useState(false);

    const insertMarkdown = (prefix: string, suffix = '') => {
        if (!editorRef.current) return;

        const start = editorRef.current.selectionStart;
        const end = editorRef.current.selectionEnd;
        const text = editorRef.current.value;
        const selectedText = text.substring(start, end);

        const beforeText = text.substring(0, start);
        const afterText = text.substring(end);

        const newText = `${beforeText}${prefix}${selectedText}${suffix}${afterText}`;
        onSummaryChange(newText);

        // Reset cursor position
        setTimeout(() => {
            if (!editorRef.current) return;
            editorRef.current.focus();
            editorRef.current.setSelectionRange(
                start + prefix.length,
                end + prefix.length
            );
        }, 0);
    };

    const toolbarActions = [
        {
            icon: <FaBold className="h-4 w-4" />,
            label: 'Bold',
            action: () => insertMarkdown('**', '**')
        },
        {
            icon: <FaItalic className="h-4 w-4" />,
            label: 'Italic',
            action: () => insertMarkdown('*', '*')
        },
        {
            icon: <FaHeading className="h-4 w-4" />,
            label: 'Heading',
            action: () => insertMarkdown('### ')
        },
        {
            icon: <FaLink className="h-4 w-4" />,
            label: 'Link',
            action: () => insertMarkdown('[', '](url)')
        },
        {
            icon: <FaListUl className="h-4 w-4" />,
            label: 'List',
            action: () => insertMarkdown('- ')
        }
    ];

    const MarkdownPreview = React.memo(({ content }: { content: string }) => (
        <div className="prose prose-sm max-w-none">
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                components={{
                    h1: ({ children }) => (
                        <h1 className="text-2xl font-normal mb-4 text-primary">{children}</h1>
                    ),
                    h2: ({ children }) => (
                        <h2 className="text-xl font-normal mb-3 text-primary">{children}</h2>
                    ),
                    h3: ({ children }) => (
                        <h3 className="text-lg font-normal mb-2 text-primary">{children}</h3>
                    ),
                    p: ({ children }) => (
                        <p className="mb-4 font-light leading-relaxed text-gray-700">{children}</p>
                    ),
                    ul: ({ children }) => (
                        <ul className="list-disc pl-5 mb-4 space-y-2">{children}</ul>
                    ),
                    ol: ({ children }) => (
                        <ol className="list-decimal pl-5 mb-4 space-y-2">{children}</ol>
                    ),
                    li: ({ children }) => (
                        <li className="font-light text-gray-700">{children}</li>
                    ),
                    strong: ({ children }) => (
                        <strong className="font-medium text-gray-900">{children}</strong>
                    ),
                    em: ({ children }) => (
                        <em className="italic text-gray-700">{children}</em>
                    ),
                }}
            >
                {content.trim()}
            </ReactMarkdown>
        </div>
    ));

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black/30">
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl shadow-xl">
                    <div className="flex justify-between items-center p-4 border-b">
                        <h3 className="text-lg font-normal text-primary">Enhanced Notes Summary</h3>
                        <div className="flex items-center gap-2">
                            {isEditing ? (
                                <>
                                    <ToolbarButton
                                        icon={<FaColumns className="h-5 w-5" />}
                                        label="Toggle Preview"
                                        onClick={() => setShowPreview(!showPreview)}
                                        active={showPreview}
                                    />
                                    <div className="h-6 w-px bg-gray-200 mx-2" />
                                    <div className="flex items-center gap-1">
                                        {toolbarActions.map((action, index) => (
                                            <ToolbarButton
                                                key={index}
                                                icon={action.icon}
                                                label={action.label}
                                                onClick={action.action}
                                            />
                                        ))}
                                    </div>
                                    <div className="h-6 w-px bg-gray-200 mx-2" />
                                    <ToolbarButton
                                        icon={<FaSave className="h-5 w-5 text-primary" />}
                                        label="Save Summary"
                                        onClick={onSave}
                                    />
                                </>
                            ) : (
                                <>
                                    <ToolbarButton
                                        icon={<FaPencilAlt className="h-5 w-5" />}
                                        label="Edit Summary"
                                        onClick={onEdit}
                                    />
                                    <ToolbarButton
                                        icon={<IoMdClose size={24} />}
                                        label="Close"
                                        onClick={onClose}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="p-6">
                        {isEditing ? (
                            <div className="relative">
                                <div className={`${showPreview ? 'grid grid-cols-2 gap-6' : 'block'}`}>
                                    <div className={showPreview ? 'space-y-2' : ''}>
                                        <textarea
                                            ref={editorRef}
                                            value={editedSummary}
                                            onChange={(e) => onSummaryChange(e.target.value)}
                                            className="w-full min-h-[400px] px-4 py-3 font-mono text-sm
                                                     border border-gray-200 rounded-lg resize-none
                                                     focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
                                                     transition-all duration-200"
                                            placeholder="Enter your markdown here..."
                                        />
                                        {!showPreview && (
                                            <div className="mt-2 text-sm text-gray-500">
                                                Use markdown syntax for formatting: **bold**, *italic*, ### heading, - for lists
                                            </div>
                                        )}
                                    </div>
                                    {showPreview && (
                                        <div className="border-l pl-6">
                                            <div className="prose prose-sm max-w-none">
                                                <MarkdownPreview content={editedSummary} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="prose prose-sm max-w-none">
                                <MarkdownPreview content={summary} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionSummaryModal;