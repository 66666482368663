import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiUser, FiHeart } from 'react-icons/fi';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import { PrimaryButton, OutlineButton, LinkButton } from "@/components/Shared/Buttons"

const OasysLogo = require('../assets/oasys-logo2.png');

const GetStartedPage: React.FC = () => {
  const [selectedRole, setSelectedRole] = useState<'user' | 'therapist'>('user');

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-5xl flex overflow-hidden min-h-[600px]">
            {/* Left Half - Quote Section */}
            <div className="hidden md:flex md:w-1/2 bg-primary relative">
              <img 
                src={OasysLogo} 
                alt="Oasys Logo" 
                className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55%] h-auto"
              />
              <div className="absolute bottom-8 right-8 max-w-[80%]">
                <blockquote className="text-white font-light text-lg">
                  "The part can never be well unless the whole is well."
                </blockquote>
                <cite className="text-white/80 text-sm block mt-2 text-right">
                  – Plato
                </cite>
              </div>
            </div>

            {/* Right Half - Get Started Options */}
            <div className="w-full md:w-1/2 p-8 md:p-12 flex flex-col justify-center border-8 border-primary rounded-r-3xl">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-normal text-primary">Get Started</h2>
                <p className="text-gray-400 mt-2 font-light">Choose how you'd like to join us.</p>
              </div>

              {/* Toggle Switch */}
              <div className="flex justify-center mb-8">
                <div className="bg-gray-100 rounded-lg p-1 flex">
                  <button
                    onClick={() => setSelectedRole('user')}
                    className={`flex items-center px-6 py-2 rounded-lg transition-all duration-200 ${
                      selectedRole === 'user'
                        ? 'bg-white shadow-md text-primary'
                        : 'text-gray-500 hover:text-primary'
                    }`}
                  >
                    <FiUser className="w-5 h-5 mr-2" />
                    <span className="font-light">User</span>
                  </button>
                  <button
                    onClick={() => setSelectedRole('therapist')}
                    className={`flex items-center px-6 py-2 rounded-lg transition-all duration-200 ${
                      selectedRole === 'therapist'
                        ? 'bg-white shadow-md text-primary'
                        : 'text-gray-500 hover:text-primary'
                    }`}
                  >
                    <FiHeart className="w-5 h-5 mr-2" />
                    <span className="font-light">Therapist</span>
                  </button>
                </div>
              </div>

              {/* Content based on selection */}
              <div className="max-w-sm mx-auto w-full">
                {selectedRole === 'user' ? (
                  <div className="space-y-6">
                    <p className="text-gray-600 text-center font-light">
                      Get personalized mental health support and insights tailored to your needs.
                    </p>
                    <PrimaryButton asChild className="w-full rounded-lg">
                      <Link to="/patient-signup">
                        Sign Up
                      </Link>
                    </PrimaryButton>
                  </div>
                ) : (
                  <div className="space-y-6">
                    <p className="text-gray-600 text-center font-light">
                      Join our platform to connect with clients and grow your practice in a meaningful way.
                    </p>
                    <PrimaryButton asChild className="w-full rounded-lg">
                      <Link to="/therapist-signup">
                        Apply Now
                      </Link>
                    </PrimaryButton>
                  </div>
                )}
              </div>

              <div className="text-center mt-8">
                <p className="text-gray-500 font-light">
                  Already have an account?{' '}
                  <LinkButton asChild className="p-0">
                    <Link to="/login">
                      Log in
                    </Link>
                  </LinkButton>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default GetStartedPage;
