import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Moon, Heart, BedDouble } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { SleepData, SleepSummaryDataPoint, CustomTooltipProps } from '../../../types';

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-2 sm:px-3 py-1 text-xs sm:text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);

interface SleepTooltipProps extends CustomTooltipProps<SleepSummaryDataPoint> {
    chartType?: 'sleep' | 'efficiency';
}

interface SleepTabProps {
    sleepData: SleepData[];
}

const SleepTab: React.FC<SleepTabProps> = ({ sleepData }) => {

    const [timeframe, setTimeframe] = useState<'week' | 'month'>('week');

    if (!sleepData || sleepData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No sleep data available.</p>;
    }

    const timeframeDays: Record<'week' | 'month', number> = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = () => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        endDate.setDate(endDate.getDate() - 1);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process sleep data with proper date range
    const sleepSummaryData = generateDateRange().map(date => {
        const matchingSleepData = sleepData.find(entry =>
            format(parseISO(entry.metadata.end_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            totalSleep: matchingSleepData ?
                Math.round((matchingSleepData.sleep_durations_data?.asleep?.duration_asleep_state_seconds || 0) / 3600 * 10) / 10 : null,
            deepSleep: matchingSleepData ?
                Math.round((matchingSleepData.sleep_durations_data?.asleep?.duration_deep_sleep_state_seconds || 0) / 3600 * 10) / 10 : null,
            efficiency: matchingSleepData ?
                Math.round(matchingSleepData.sleep_durations_data?.sleep_efficiency || 0) : null,
            awakenings: matchingSleepData?.sleep_durations_data?.awake?.num_wakeup_events || null,
            remSleep: matchingSleepData ?
                Math.round((matchingSleepData.sleep_durations_data?.asleep?.duration_REM_sleep_state_seconds || 0) / 3600 * 10) / 10 : null,
            lightSleep: matchingSleepData ?
                Math.round((matchingSleepData.sleep_durations_data?.asleep?.duration_light_sleep_state_seconds || 0) / 3600 * 10) / 10 : null,
            readiness: matchingSleepData?.readiness_data?.readiness || null,
            restingHR: matchingSleepData?.heart_rate_data?.summary?.resting_hr_bpm || null,
            avgBreathRate: matchingSleepData?.respiration_data?.breaths_data?.avg_breaths_per_min || null,
            avgOxygenSaturation: matchingSleepData?.respiration_data?.oxygen_saturation_data?.avg_saturation_percentage || null,
        };
    });

    const calculateAverages = (data: SleepSummaryDataPoint[]) => {
        const validSleep = data.filter((d: SleepSummaryDataPoint) => d.totalSleep !== null);
        const validAwakenings = data.filter((d: SleepSummaryDataPoint) => d.awakenings !== null);
        const validEfficiency = data.filter((d: SleepSummaryDataPoint) => d.efficiency !== null);

        return {
            avgTotalSleep: validSleep.length > 0
                ? Math.round(validSleep.reduce((acc: number, curr: SleepSummaryDataPoint) =>
                    acc + (curr.totalSleep || 0), 0) / validSleep.length * 10) / 10
                : null,
            avgAwakenings: validAwakenings.length > 0
                ? Math.round(validAwakenings.reduce((acc: number, curr: SleepSummaryDataPoint) =>
                    acc + (curr.awakenings || 0), 0) / validAwakenings.length * 10) / 10
                : null,
            avgEfficiency: validEfficiency.length > 0
                ? Math.round(validEfficiency.reduce((acc: number, curr: SleepSummaryDataPoint) =>
                    acc + (curr.efficiency || 0), 0) / validEfficiency.length)
                : null
        };
    };

    const averages = calculateAverages(sleepSummaryData);

    const formatHoursAndMinutes = (hours: number | null) => {
        if (hours === null) return null;
        const h = Math.floor(hours);
        const m = Math.round((hours - h) * 60);
        return `${h}h ${m}m`;
    };

    const CustomTooltip: React.FC<SleepTooltipProps> = ({ active, payload, label, chartType }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;

            if (chartType === 'sleep') {
                // Create pie chart data
                const pieData = [
                    { name: 'Light Sleep', value: data.lightSleep || 0, color: '#c4b5fd' },
                    { name: 'Deep Sleep', value: data.deepSleep || 0, color: '#8b5cf6' },
                    { name: 'REM Sleep', value: data.remSleep || 0, color: '#6d28d9' },
                ].filter(item => item.value > 0);

                return (
                    <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="font-light text-gray-600 mb-2">{label}</p>
                        {data.totalSleep ? (
                            <>
                                <div className="w-32 h-32 mx-auto">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart>
                                            <Pie
                                                data={pieData}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={25}
                                                outerRadius={45}
                                            >
                                                {pieData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="space-y-1 mt-2">
                                    <p className="text-sm font-light text-center" style={{ color: '#4c1d95' }}>
                                        Total Sleep: {formatHoursAndMinutes(data.totalSleep)}
                                    </p>
                                    {pieData.map((item, index) => (
                                        <div key={index} className="flex justify-between text-xs">
                                            <span style={{ color: item.color }}>{item.name}:</span>
                                            <span style={{ color: item.color }}>{formatHoursAndMinutes(item.value)}</span>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p className="text-sm font-light text-gray-500">No sleep data for this day</p>
                        )}
                    </div>
                );
            }

            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                    <p className="font-light text-gray-600 mb-2">{label}</p>
                    <p className="text-sm font-light" style={{ color: '#6d28d9' }}>
                        Efficiency: {data.efficiency}%
                    </p>
                </div>
            );
        }
        return null;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-4">
            <div className="flex justify-end space-x-1 sm:space-x-2 px-1 sm:px-0" data-testid="timeframe-selector">
                <TimeframeButton active={timeframe === 'week'} onClick={() => setTimeframe('week')}>Week</TimeframeButton>
                <TimeframeButton active={timeframe === 'month'} onClick={() => setTimeframe('month')}>Month</TimeframeButton>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-2 sm:gap-4">
                {/* Sleep Duration Chart */}
                <div className="col-span-1 lg:col-span-8">
                    <DashboardCard
                        title="Sleep Duration"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<Moon className="text-purple-500" />}
                        testId="dashboard-card-sleep-duration"
                    >
                        {sleepSummaryData.some(d => d.totalSleep !== null) ? (
                            <div className="h-48 sm:h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={sleepSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip chartType="sleep" />} />
                                        <Bar
                                            dataKey="remSleep"
                                            name="REM Sleep"
                                            stackId="sleep"
                                            fill="#6d28d9"
                                            radius={0}
                                        />
                                        <Bar
                                            dataKey="deepSleep"
                                            name="Deep Sleep"
                                            stackId="sleep"
                                            fill="#8b5cf6"
                                            radius={0}
                                        />
                                        <Bar
                                            dataKey="lightSleep"
                                            name="Light Sleep"
                                            stackId="sleep"
                                            fill="#c4b5fd"
                                            radius={[4, 4, 0, 0]}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Sleep Efficiency Chart */}
                <div className="col-span-1 lg:col-span-4">
                    <DashboardCard
                        title="Sleep Efficiency"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<Heart className="text-purple-500" />}
                        testId="dashboard-card-sleep-efficiency"
                    >
                        {sleepSummaryData.some(d => d.efficiency !== null && d.efficiency > 0) ? (
                            <div className="h-48 sm:h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={sleepSummaryData.map(d => ({
                                        ...d,
                                        efficiency: d.efficiency === 0 ? null : d.efficiency
                                    }))} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis
                                            domain={[60, 100]}
                                            tickFormatter={(value) => `${value}%`}
                                            tick={{ fontSize: 11, fontFamily: 'Inter' }}
                                        />
                                        <Tooltip content={<CustomTooltip chartType="efficiency" />} />
                                        <Line
                                            type="monotone"
                                            dataKey="efficiency"
                                            name="Sleep Efficiency"
                                            stroke="#8b5cf6"
                                            strokeWidth={2}
                                            dot={{ fill: '#8b5cf6', strokeWidth: 2 }}
                                            isAnimationActive={false}
                                            connectNulls={true}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Summary Stats */}
                <div className="col-span-1 lg:col-span-12">
                    <DashboardCard
                        title="Summary Statistics"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<BedDouble className="text-purple-500" />}
                        testId="summary-stats-card"
                    >
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4" data-testid="summary-stats">
                            <div className="text-center">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Avg. Total Sleep</p>
                                <p className="text-xl sm:text-2xl font-light text-purple-500">
                                    {averages.avgTotalSleep !== null ?
                                        formatHoursAndMinutes(averages.avgTotalSleep) :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Avg. Awakenings</p>
                                <p className="text-xl sm:text-2xl font-light text-purple-500">
                                    {averages.avgAwakenings !== null ?
                                        averages.avgAwakenings :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Avg. Efficiency</p>
                                <p className="text-xl sm:text-2xl font-light text-purple-500">
                                    {averages.avgEfficiency !== null && averages.avgEfficiency > 0 ?
                                        `${averages.avgEfficiency}%` :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Latest Sleep</p>
                                <p className="text-xl sm:text-2xl font-light text-purple-500">
                                    {sleepSummaryData[sleepSummaryData.length - 1]?.totalSleep ?
                                        formatHoursAndMinutes(sleepSummaryData[sleepSummaryData.length - 1].totalSleep) :
                                        'N/A'}
                                </p>
                            </div>
                        </div>
                    </DashboardCard>
                </div>
            </div>
        </div>
    );
};

export default SleepTab;