import React, { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import Draggable from 'react-draggable';

interface FloatingNotesProps {
    content: string;
    onChange: (content: string) => void;
    onClose: () => void;
}

const FloatingNotes: React.FC<FloatingNotesProps> = ({ content, onChange, onClose }) => {
    const nodeRef = useRef(null);

    return (
        <Draggable nodeRef={nodeRef} handle=".handle">
            <div 
                ref={nodeRef}
                className="fixed z-[9999] bg-white rounded-lg shadow-lg border border-gray-200 w-96"
                style={{ 
                    minHeight: '200px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="handle flex items-center justify-between p-2 bg-gray-50 
                               rounded-t-lg cursor-move">
                    <span className="text-sm font-light text-gray-600">Session Notes</span>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClose();
                        }}
                        className="p-1 hover:bg-gray-100 rounded text-gray-400 
                                 hover:text-gray-600 transition-colors duration-200"
                    >
                        <FaTimes size={12} />
                    </button>
                </div>
                <textarea
                    value={content}
                    onChange={(e) => onChange(e.target.value)}
                    className="w-full h-[300px] p-3 text-sm font-light resize-none 
                              focus:outline-none focus:ring-0"
                />
            </div>
        </Draggable>
    );
};

export default FloatingNotes; 