import React, { useState, useContext, useEffect } from 'react';
import NoteTaking from './NoteTaking';
import VideoMeeting, { generateMeetingUrl } from '../NewSession/VideoMeeting';
import { api } from '../../../../services/api';
import { SessionContext } from '../../../../contexts/SessionContext';
import { useMediaQuery } from 'react-responsive';
import SessionPrepChat from '../NewSession/SessionPrepChat';
import { SessionData, SessionChatMessage } from '../../../../types/sessionsTypes';
import { Patient } from '../../../../types/usersTypes';
import { FiClock, FiUser, FiFileText } from 'react-icons/fi';
import PatientProfileModal from './PatientProfileModal';
import SessionPrepNotesModal from '../NewSession/SessionPrepNotesModal';
import { PatientDetailsSessions } from '../../../../types/sessionsTypes';

const SessionContainer: React.FC = () => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error('SessionContainer must be used within SessionProvider');
    }

    const {
        sessionState: { sessionData, patientPrepData },
        handleEndSession,
        handleDeleteSession,
        handleUpdateSession,
        patients,
    } = context;

    const [showVideo, setShowVideo] = useState<boolean>(false);
    const [jwtToken, setJwtToken] = useState<string | null>(null);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showPrepChat, setShowPrepChat] = useState<boolean>(true);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [showPatientProfile, setShowPatientProfile] = useState<boolean>(false);
    const [showPrepNotesModal, setShowPrepNotesModal] = useState(false);
    const [prepNotes, setPrepNotes] = useState('');
    const [isLoadingPrepNotes, setIsLoadingPrepNotes] = useState(false);
    const [prepNotesCache, setPrepNotesCache] = useState<{ [key: string]: string }>({});
    const [chatMessages, setChatMessages] = useState<SessionChatMessage[]>([]);
    const [initialMessageSent, setInitialMessageSent] = useState<boolean>(false);
    const [patientDetails, setPatientDetails] = useState<PatientDetailsSessions | null>(null);
    const [isStreamingPrepNotes, setIsStreamingPrepNotes] = useState(false);

    const isCurrentSession = sessionData?.status === 'in_progress';
    const isDemoSession = sessionData?.patient_id === "demo-patient-id";

    useEffect(() => {
        if (isCurrentSession && sessionData?.start_time) {
            const timer = setInterval(() => {
                setElapsedTime(prev => prev + 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isCurrentSession, sessionData?.start_time]);

    useEffect(() => {
        if (!initialMessageSent && patientPrepData?.aiAnalysis) {
            setChatMessages([
                {
                    role: 'assistant',
                    content: "Anything you would like to know more about?"
                }
            ]);
            setInitialMessageSent(true);
        }
    }, [patientPrepData, initialMessageSent]);

    useEffect(() => {
        const fetchPatientDetails = async () => {
            if (sessionData?.patient_id) {
                try {
                    const details = await api.sessions.getPatientDetails(sessionData.patient_id);
                    setPatientDetails(details);
                } catch (error) {
                    console.error('Error fetching patient details:', error);
                }
            }
        };

        fetchPatientDetails();
    }, [sessionData?.patient_id]);

    const formatElapsedTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const handleStartMeeting = async () => {
        if (!sessionData?.videoRoomName) return;

        if (isMobile) {
            alert('Video calls are not supported on mobile devices. Please use a desktop computer to start a video call.');
            return;
        }

        try {
            const token = await api.jitsi.getJwtToken(sessionData.videoRoomName);
            setJwtToken(token);
            setShowVideo(true);
        } catch (error) {
            console.error('Error starting meeting:', error);
        }
    };

    const handleMeetingEnd = () => {
        setShowVideo(false);
        const now = new Date();
        const endTime = now.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).substring(0, 5);

        if (!sessionData) return;

        const updatedSessionData: Partial<SessionData> = {
            ...sessionData,
            end_time: endTime
        };

        handleUpdateSession(updatedSessionData);
    };

    const currentPatient = patients?.find((p: Patient) => p.id === sessionData?.patient_id) || {
        id: "demo-patient-id",
        full_name: "Jane Doe",
        email: "demo.patient@example.com",
        role: "user"
    };

    const handleViewPrepNotes = async () => {
        if (!sessionData?.patient_id) return;

        if (prepNotesCache[sessionData.patient_id]) {
            setPrepNotes(prepNotesCache[sessionData.patient_id]);
            setShowPrepNotesModal(true);
            return;
        }

        setIsLoadingPrepNotes(true);
        setShowPrepNotesModal(true);
        setPrepNotes('');
        setIsStreamingPrepNotes(true);

        try {
            await api.sessions.getSessionPrepNotesStream(
                sessionData.patient_id,
                (chunk: string) => {
                    setPrepNotes(prev => prev + chunk);
                }
            );

            // Cache the complete notes
            setPrepNotesCache(prev => ({
                ...prev,
                [sessionData.patient_id]: prepNotes
            }));
        } catch (error) {
            console.error('Error fetching prep notes:', error);
            setPrepNotes('Failed to load session preparation notes. Please try again.');
        } finally {
            setIsLoadingPrepNotes(false);
            setIsStreamingPrepNotes(false);
        }
    };

    const handleShareMeetingLink = async () => {
        if (!sessionData?.videoRoomName || !sessionData?.patient_id) return;

        const meetingUrl = generateMeetingUrl(sessionData.videoRoomName);

        try {
            const result = await api.sessions.sendMeetingInvite(sessionData.patient_id, meetingUrl);
            alert(result.message || 'Meeting invite sent successfully to patient\'s email!');
        } catch (error) {
            console.error('Error sending meeting invite:', error);
            alert(error instanceof Error ? error.message : 'Failed to send meeting invite. Please try again.');
        }
    };

    if (!sessionData) {
        return null;
    }

    return (
        <div className="flex flex-col h-full min-h-[calc(100vh-16rem)]">
            {isDemoSession && (
                <div className="mb-4 bg-blue-50 border-l-4 border-blue-400 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-blue-700">
                                Demo Session: Try out all features! Changes won't be saved.
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div className="space-y-4 flex-grow" data-testid="session-container">
                {/* Session Header */}
                <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
                    <div className="flex flex-col space-y-4">
                        {/* Top Row - Title, Patient Info, and Actions */}
                        <div className="flex justify-between items-center">
                            {/* Left side - Title and Patient */}
                            <div className="flex flex-col gap-2">
                                <h3 className="text-xl font-normal text-primary">
                                    Current: Session {sessionData?.session_number}
                                </h3>

                                <button
                                    onClick={() => setShowPatientProfile(true)}
                                    className="inline-flex items-center gap-2 bg-primary/5 px-3 py-1.5 rounded-lg 
                                             border border-primary/10 hover:bg-primary/10 transition-colors duration-200 w-fit"
                                >
                                    <FiUser className="text-primary" />
                                    <span className="font-medium text-primary">
                                        {currentPatient?.full_name}
                                        {isDemoSession && " (Demo)"}
                                    </span>
                                </button>

                                {isCurrentSession && (
                                    <div className="flex items-center gap-2 text-sm text-gray-600 font-light">
                                        <FiClock className="text-gray-400" />
                                        <span>Duration: {formatElapsedTime(elapsedTime)}</span>
                                    </div>
                                )}
                            </div>

                            {/* Right side - Actions */}
                            {(isCurrentSession && !isMobile) && (
                                <div className="flex flex-col gap-4">
                                    <div className="flex items-center gap-6">
                                        {!showVideo ? (
                                            <button
                                                onClick={handleStartMeeting}
                                                data-testid="start-video-meeting"
                                                className="flex items-center gap-2 px-4 py-2 bg-primary/10 
                                                        text-primary rounded-lg hover:bg-primary/20 
                                                        transition-colors duration-200 font-light"
                                            >
                                                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                </svg>
                                                Start Video Call
                                            </button>
                                        ) : (
                                            <button
                                                onClick={handleShareMeetingLink}
                                                className="flex items-center gap-2 px-4 py-2 bg-secondary/10 
                                                        text-secondary rounded-lg hover:bg-secondary/20 
                                                        transition-colors duration-200 font-light"
                                            >
                                                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                </svg>
                                                Send Meeting Link
                                            </button>
                                        )}

                                        <button
                                            onClick={handleViewPrepNotes}
                                            className="flex items-center gap-2 px-4 py-2 bg-secondary/10 
                                                    text-secondary rounded-lg hover:bg-secondary/20 
                                                    transition-colors duration-200 font-light"
                                        >
                                            <FiFileText className="text-lg" />
                                            Session Prep Notes
                                        </button>
                                    </div>
                                    {patientPrepData && (
                                        <div className="flex items-center gap-2 justify-end">
                                            <span className="text-sm font-light text-gray-700">
                                                Assistant
                                            </span>
                                            <button
                                                onClick={() => setShowPrepChat(!showPrepChat)}
                                                className={`relative inline-flex h-6 w-11 items-center rounded-full 
                                                         transition-colors focus:outline-none focus:ring-2 
                                                         focus:ring-primary focus:ring-offset-2 
                                                         ${showPrepChat ? 'bg-primary' : 'bg-gray-200'}`}
                                                role="switch"
                                                aria-checked={showPrepChat}
                                            >
                                                <span className={`${showPrepChat ? 'translate-x-6' : 'translate-x-1'}
                                                        inline-block h-4 w-4 transform rounded-full 
                                                        bg-white transition-transform`}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Patient Profile Modal */}
                {showPatientProfile && patientDetails && (
                    <PatientProfileModal
                        profile={patientDetails}
                        onClose={() => setShowPatientProfile(false)}
                    />
                )}

                {/* Video Meeting Section */}
                {showVideo && jwtToken && sessionData.videoRoomName && (
                    <VideoMeeting
                        roomName={sessionData.videoRoomName}
                        onMeetingEnd={handleMeetingEnd}
                        data-testid="video-meeting"
                    />
                )}

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 flex-grow">
                    {/* Left Column - NoteTaking */}
                    <div className={`flex flex-col ${showPrepChat && isCurrentSession && patientPrepData ? 'lg:col-span-7' : 'lg:col-span-12'}`}>
                        <NoteTaking
                            initialData={sessionData}
                            isPreviousSession={!isCurrentSession}
                            onEndSession={handleEndSession}
                            handleDeleteSession={handleDeleteSession}
                            data-testid="session-notes"
                        />
                    </div>

                    {/* Right Column - Session Prep and Chat */}
                    {isCurrentSession && patientPrepData && showPrepChat && (
                        <div className="lg:col-span-5 bg-primary/20 rounded-lg flex flex-col h-[calc(100vh-24rem)] 
                                      border border-gray-200 shadow-sm">
                            <SessionPrepChat
                                patientID={sessionData.patient_id}
                                aiAnalysis={patientPrepData.aiAnalysis}
                                chatMessages={chatMessages}
                                setChatMessages={setChatMessages}
                            />
                        </div>
                    )}
                </div>
            </div>

            <SessionPrepNotesModal
                isOpen={showPrepNotesModal}
                onClose={() => setShowPrepNotesModal(false)}
                prepNotes={prepNotes}
                isLoading={isLoadingPrepNotes}
                isStreaming={isStreamingPrepNotes}
            />
        </div>
    );
};

export default SessionContainer;
