// PatientDashboard.tsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { useMediaQuery } from 'react-responsive';
import PatientDashboardProfile from '../components/PatientDashboard/PatientDashboardProfile';
import TherapistList from '../components/PatientDashboard/TherapistList';
import PatientOverview from '../components/PatientDashboard/Overview/PatientOverview';
import PatientHealthDashboard from '../components/PatientDashboard/PatientHealthDashboard/PatientHealthDashboard';
import {
  Home,
  Users,
  BarChart2,
  Plug2,
  UserCog,
  User,
  ChevronLeft,
  ChevronRight,
  Flower2,
  Loader,
  LucideIcon,
  RefreshCw
} from 'lucide-react';
import usePatientDashboard from '../hooks/usePatientDashboard';
import { formatDistanceToNow } from 'date-fns';
import DataIntegration from '../components/PatientDashboard/DataIntegration';
import WellnessTab from '../components/PatientDashboard/WellnessTab/WellnessTab';

interface SectionIcons {
  [key: string]: LucideIcon;
}

interface AuthMessage {
  message: string;
  type: 'success' | 'error';
}



interface PatientDashboardProps {
  userEmail?: string;
}

const PatientDashboard: React.FC<PatientDashboardProps> = ({ userEmail }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    fullName,
    dashboardData,
    activeSection,
    setActiveSection,
    isSidebarCollapsed,
    authMessage,
    setAuthMessage,
    userTherapist,
    handleConnectHealth,
    handleDisconnectProvider,
    toggleSidebar,
    handleTherapistSelection,
    lastFetchTime,
    isHealthDataLoading,
    isProvidersLoading,
    healthSummary,
    isHealthSummaryLoading,
    fetchHealthDataUpdated,
  } = usePatientDashboard(user, location);


  const sectionIcons: SectionIcons = {
    overview: Home,
    dashboard: BarChart2,
    wellness: Flower2,
    connectHealth: Plug2,
    myTherapist: UserCog,
    profile: User,
  };

  const renderSidebarButton = (section: string): JSX.Element => {
    const Icon = sectionIcons[section];
    return (
      <button
        key={section}
        className={`w-full h-10 rounded-md transition-colors duration-150 ease-in-out flex items-center font-light
          ${isSidebarCollapsed ? 'justify-center' : 'justify-start pl-2'} 
          ${activeSection === section ? 'bg-primary text-white' : 'text-gray-600 hover:bg-gray-100'}`}
        onClick={() => setActiveSection(section)}
      >
        <Icon className={`w-4 h-4 flex-shrink-0 ${isSidebarCollapsed ? '' : 'mr-2'}`} />
        {!isSidebarCollapsed && (
          <span>
            {section === 'myTherapist' ? 'My Therapist' :
              section === 'connectHealth' ? 'Data Integration' :
                section === 'wellness' ? 'Wellness' :
                  section === 'overview' ? 'Home' :
                    section === 'dashboard' ? 'Health Stats' :
                      section.charAt(0).toUpperCase() + section.slice(1)}
          </span>
        )}
      </button>
    );
  };

  const renderAuthMessage = (): JSX.Element | null => {
    if (!authMessage) return null;
    const isError = authMessage.startsWith('Failed');
    return (
      <div className={`border px-4 py-3 rounded relative mb-4 font-light
        ${isError ? 'bg-red-100 border-red-400 text-red-700' : 'bg-green-100 border-green-400 text-green-700'}`}>
        <span className="block sm:inline">{authMessage}</span>
        <span onClick={() => setAuthMessage(null)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg className={`fill-current h-6 w-6 ${isError ? 'text-red-500' : 'text-green-500'}`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <title>Close</title>
            <path d="M14.348 5.652a1 1 0 10-1.414-1.414L10 7.172 7.066 4.238a1 1 0 00-1.414 1.414L8.828 10l-3.176 3.176a1 1 0 101.414 1.414L10 12.828l3.176 3.176a1 1 0 001.414-1.414L11.172 10l3.176-3.176z" />
          </svg>
        </span>
      </div>
    );
  };

  const renderContent = (): JSX.Element => {
    switch (activeSection) {
      case 'overview':
        return (
          <PatientOverview
            patientName={fullName}
            connectedProviders={dashboardData.connectedProviders}
            upcomingSessions={[]}
            onTabChange={setActiveSection}
            isProvidersLoading={isProvidersLoading}
            healthSummary={healthSummary}
            isHealthSummaryLoading={isHealthSummaryLoading}
          />
        );
      case 'dashboard':
        if (isHealthDataLoading) {
          return (
            <div className="flex justify-center items-center h-64">
              <Loader className="animate-spin w-8 h-8 text-primary" />
            </div>
          );
        }
        return (
          <>
            <PatientHealthDashboard
              healthData={dashboardData.healthData}
              nutritionData={dashboardData.nutritionData}
              sleepData={dashboardData.sleepData}
              activityData={dashboardData.activityData}
              menstruationData={dashboardData.menstruationData}
              dailyData={dashboardData.dailyData}
              bodyData={dashboardData.bodyData}
              connectedProviders={dashboardData.connectedProviders}
              creativeInsights={dashboardData.creativeInsights}
              healthInsights={dashboardData.healthInsights}
              isLoading={isHealthDataLoading}
            />
            {lastFetchTime && (
              <div className="flex items-center gap-2 mt-2">
                <button
                  onClick={() => fetchHealthDataUpdated()}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200 group relative"
                  title="Refresh Data"
                >
                  <RefreshCw className="w-5 h-5 text-primary" />
                  <span className="absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                    Refresh Data
                  </span>
                </button>
                <p className="text-sm text-gray-500 font-light">
                  Last updated: {formatDistanceToNow(lastFetchTime, { addSuffix: true })}
                </p>
              </div>
            )}
          </>
        );
      case 'connectHealth':
        return (
          <DataIntegration
            connectedProviders={dashboardData.connectedProviders}
            handleConnectHealth={handleConnectHealth}
            handleDisconnectProvider={handleDisconnectProvider}
          />
        );
      case 'myTherapist':
        return (
          <TherapistList
            onTherapistSelect={handleTherapistSelection}
            userTherapist={userTherapist}
          />
        );
      case 'profile':
        return (
          <PatientDashboardProfile userEmail={user?.email || ''} />
        );
      case 'wellness':
        return <WellnessTab userEmail={user?.email || ''} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className={`flex flex-grow mt-20 ${isMobile ? 'flex-col' : ''}`}>
        {!isMobile && (
          <div className={`bg-white shadow-md transition-all duration-300 
            ${isSidebarCollapsed ? 'w-16' : 'w-64'} 
            sticky top-20 h-[calc(100vh-5rem)]`}>
            <div className="p-4 flex-grow overflow-y-auto">
              <button
                className="mb-4 p-2 w-full flex justify-center items-center bg-white 
                  text-primary border border-primary rounded-md 
                  hover:bg-gray-100 transition-colors duration-150"
                onClick={toggleSidebar}
              >
                {isSidebarCollapsed ? <ChevronRight /> : <ChevronLeft />}
              </button>
              {!isSidebarCollapsed && (
                <h2 className="text-2xl font-normal text-primary mb-4">Dashboard</h2>
              )}
              <nav className="space-y-2">
                {Object.keys(sectionIcons).map(renderSidebarButton)}
              </nav>
            </div>
          </div>
        )}

        <div className={`flex-1 overflow-auto ${isMobile ? 'p-3 pb-20' : 'p-8'}`}>
          <div className="max-w-full mx-auto">
            {renderAuthMessage()}
            <div className="bg-white shadow-md rounded-lg p-6 mb-8">
              {renderContent()}
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md">
            <nav className="flex justify-around items-center h-16">
              {Object.keys(sectionIcons).map((section) => {
                const Icon = sectionIcons[section];
                return (
                  <button
                    key={section}
                    className={`p-2 rounded-full ${activeSection === section
                      ? 'bg-primary text-white'
                      : 'text-gray-600'
                      }`}
                    onClick={() => setActiveSection(section)}
                  >
                    <Icon className="text-xl" />
                  </button>
                );
              })}
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDashboard;
