import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../services/supabaseClient';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ProfileModal from '../components/AdminDashboard/ProfileModal';
import { api } from '../services/api';
import { Therapist } from '../types';
import { DashboardData, GrowthData } from '../types/adminTypes';


// Props interfaces
interface StatCardProps {
  title: string;
  value: number;
  onClick?: () => void;
  additionalInfo?: string;
}

interface GrowthChartProps {
  title: string;
  data: GrowthData[];
}

// Add this interface for the growth data accumulator
interface GrowthDataAccumulator {
  [key: string]: number;
}

// Main component
const AdminDashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [pendingTherapists, setPendingTherapists] = useState<Therapist[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [displayData, setDisplayData] = useState<'users' | 'therapists' | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [userGrowthData, setUserGrowthData] = useState<GrowthData[]>([]);
  const [therapistGrowthData, setTherapistGrowthData] = useState<GrowthData[]>([]);
  const [cumulativeUserGrowthData, setCumulativeUserGrowthData] = useState<GrowthData[]>([]);
  const [cumulativeTherapistGrowthData, setCumulativeTherapistGrowthData] = useState<GrowthData[]>([]);
  const [showGraphs, setShowGraphs] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const itemsPerPage = 10;
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<any | null>(null);

  const fetchDashboardData = useCallback(async () => {
    try {
      // Fetch users count
      const { count: usersCount, error: usersCountError } = await supabase
        .from('users')
        .select('*', { count: 'exact', head: true });

      if (usersCountError) throw usersCountError;

      // Fetch therapists count and status
      const { data: therapistsData, error: therapistsError } = await supabase
        .from('therapists')
        .select('status');

      if (therapistsError) throw therapistsError;

      const therapistsCount = therapistsData.length;
      const approvedTherapists = therapistsData.filter(t => t.status === 'approved').length;
      const rejectedTherapists = therapistsData.filter(t => t.status === 'rejected').length;

      setDashboardData({
        totalUsers: usersCount || 0,
        totalTherapists: therapistsCount,
        approvedTherapists,
        rejectedTherapists
      });

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data');
    }
  }, []);

  const fetchPendingTherapists = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('therapists')
        .select('*')
        .or('status.eq.pending,status.eq.pending_review')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setPendingTherapists(data);
    } catch (error) {
      console.error('Error fetching pending therapists:', error);
      setError('Failed to fetch pending therapists');
    }
  }, []);

  const fetchTableData = useCallback(async () => {
    try {
      let query = supabase
        .from(displayData === 'users' ? 'users' : 'therapists')
        .select('*', { count: 'exact' })
        .order('created_at', { ascending: false });

      if (searchTerm) {
        query = query.or(`full_name.ilike.%${searchTerm}%,email.ilike.%${searchTerm}%`);
      }

      const { data, error, count } = await query
        .range((page - 1) * itemsPerPage, page * itemsPerPage - 1);

      if (error) throw error;

      setDashboardData((prevData): DashboardData => ({
        totalUsers: prevData?.totalUsers || 0,
        totalTherapists: prevData?.totalTherapists || 0,
        approvedTherapists: prevData?.approvedTherapists || 0,
        rejectedTherapists: prevData?.rejectedTherapists || 0,
        tableData: data
      }));

      setTotalPages(Math.ceil((count || 0) / itemsPerPage));
    } catch (error) {
      console.error(`Error fetching ${displayData} data:`, error);
      setError(`Failed to fetch ${displayData} data`);
    }
  }, [displayData, page, itemsPerPage, searchTerm]);

  const fetchGrowthData = useCallback(async () => {
    const fetchData = async (table: string) => {
      const { data, error } = await supabase
        .from(table)
        .select('created_at')
        .order('created_at', { ascending: true });

      if (error) throw error;

      const growthData: GrowthDataAccumulator = data.reduce((acc: GrowthDataAccumulator, item) => {
        const date = new Date(item.created_at).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      const allDates = Object.keys(growthData).sort();
      const startDate = new Date(allDates[0]);
      const endDate = new Date(allDates[allDates.length - 1]);

      const dailyGrowth: GrowthData[] = [];
      let cumulativeCount = 0;
      const cumulativeGrowth: GrowthData[] = [];

      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const date = d.toISOString().split('T')[0];
        const count = growthData[date] || 0;
        cumulativeCount += count;
        dailyGrowth.push({ date, count });
        cumulativeGrowth.push({ date, count: cumulativeCount });
      }

      return { dailyGrowth, cumulativeGrowth };
    };

    try {
      const userData = await fetchData('users');
      const therapistData = await fetchData('therapists');
      setUserGrowthData(userData.dailyGrowth);
      setTherapistGrowthData(therapistData.dailyGrowth);
      setCumulativeUserGrowthData(userData.cumulativeGrowth);
      setCumulativeTherapistGrowthData(therapistData.cumulativeGrowth);
    } catch (error) {
      console.error('Error fetching growth data:', error);
      setError('Failed to fetch growth data');
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
    fetchPendingTherapists();
    fetchGrowthData();
  }, [fetchDashboardData, fetchPendingTherapists, fetchGrowthData]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData, displayData, page, searchTerm]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleCardClick = (dataType: 'users' | 'therapists') => {
    setDisplayData(dataType);
    setPage(1);
    setSearchTerm('');
    setShowTable(true);
  };

  const toggleGraphs = () => {
    setShowGraphs(!showGraphs);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleApproval = async (therapistId: string, isApproved: boolean) => {
    try {
      const newStatus = isApproved ? 'approved' : 'rejected';

      // Send the status update email
      await api.admin.sendTherapistStatusUpdate(therapistId, newStatus);

      // Update the therapist status in the database
      const { error } = await supabase
        .from('therapists')
        .update({ status: newStatus })
        .eq('id', therapistId);

      if (error) throw error;


      fetchPendingTherapists();
      fetchDashboardData();
    } catch (error) {
      console.error('Error updating therapist status:', error);
      setError('Failed to update therapist status');
    }
  };

  const handleProfileClick = async (id: string, isTherapist: boolean) => {
    try {
      const { data, error } = await supabase
        .from(isTherapist ? 'therapists' : 'users')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      setSelectedProfile({ ...data, isTherapist });
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to fetch profile');
    }
  };

  const closeProfileModal = () => {
    setSelectedProfile(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-16 sm:py-20 md:py-24">
      <h1 className="text-3xl font-normal text-primary mb-8">Admin Dashboard</h1>
      <p className="text-sm font-light text-gray-600 mb-4 text-center">Click to view user or therapist registries</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <StatCard
          title="Total Users"
          value={dashboardData.totalUsers}
          onClick={() => handleCardClick('users')}
        />
        <StatCard
          title="Total Therapists"
          value={dashboardData.totalTherapists}
          onClick={() => handleCardClick('therapists')}
          additionalInfo={`(Approved: ${dashboardData.approvedTherapists}, Rejected: ${dashboardData.rejectedTherapists})`}
        />
      </div>
      <button
        onClick={toggleGraphs}
        className="mb-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-300"
      >
        {showGraphs ? 'Hide Trends' : 'Show Trends'}
      </button>
      {showGraphs && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <GrowthChart title="Daily User Growth" data={userGrowthData} />
            <GrowthChart title="Daily Therapist Growth" data={therapistGrowthData} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <GrowthChart title="Cumulative User Growth" data={cumulativeUserGrowthData} />
            <GrowthChart title="Cumulative Therapist Growth" data={cumulativeTherapistGrowthData} />
          </div>
        </>
      )}
      {showTable && displayData && (
        <div className="bg-card rounded-lg shadow-md p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4">
            {displayData === 'users' ? 'Users' : 'Therapists'}
          </h3>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={handleSearch}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-muted">
                  <th className="p-3 text-left">Full Name</th>
                  <th className="p-3 text-left">Email</th>
                  <th className="p-3 text-left">Signup Date</th>
                  {displayData === 'therapists' && <th className="p-3 text-left">Status</th>}
                </tr>
              </thead>
              <tbody>
                {dashboardData.tableData && dashboardData.tableData.map((item) => (
                  <tr key={item.id} className="border-b border-border">
                    <td className="p-3">
                      <button
                        onClick={() => handleProfileClick(item.id, displayData === 'therapists')}
                        className="text-primary hover:underline"
                      >
                        {item.full_name}
                      </button>
                    </td>
                    <td className="p-3">{item.email}</td>
                    <td className="p-3">{new Date(item.created_at).toLocaleDateString()}</td>
                    {displayData === 'therapists' && <td className="p-3">{item.status}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-center items-center space-x-4">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="text-primary hover:text-primary-dark transition-colors duration-300 disabled:opacity-50"
            >
              Previous
            </button>
            <span>Page {page} of {totalPages}</span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              className="text-primary hover:text-primary-dark transition-colors duration-300 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
      <div className="bg-card rounded-lg shadow-md p-6">
        <h3 className="text-xl font-normal mb-4">Pending Therapist Approvals</h3>
        {pendingTherapists.length === 0 ? (
          <p className="font-light">No pending therapist approvals.</p>
        ) : (
          <ul className="space-y-4">
            {pendingTherapists.map((therapist) => (
              <li key={therapist.id} className="bg-white shadow rounded-lg p-4">
                <h4 className="text-lg font-normal">
                  <button
                    onClick={() => handleProfileClick(therapist.id, true)}
                    className="text-primary hover:underline"
                  >
                    {therapist.full_name}
                  </button>
                </h4>
                <p className="font-light">Email: {therapist.email}</p>
                <p className="font-light">License: {therapist.license}</p>
                <div className="mt-2 space-x-2">
                  <button
                    onClick={() => handleApproval(therapist.id, true)}
                    className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 font-normal transition-colors duration-300"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleApproval(therapist.id, false)}
                    className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 font-normal transition-colors duration-300"
                  >
                    Reject
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {selectedProfile && (
        <ProfileModal
          profile={selectedProfile}
          onClose={closeProfileModal}
          isTherapist={selectedProfile.isTherapist}
        />
      )}
    </div>
  );
};

// Subcomponents with TypeScript
const StatCard: React.FC<StatCardProps> = ({ title, value, onClick, additionalInfo }) => (
  <div
    className={`bg-white rounded-lg shadow-md p-6 ${onClick ? 'cursor-pointer hover:shadow-lg transition-shadow duration-300' : ''}`}
    onClick={onClick}
  >
    <h3 className="text-lg font-normal text-gray-800 mb-2">{title}</h3>
    <div className="flex items-center">
      <p className="text-3xl font-light text-primary">{value}</p>
      {additionalInfo && <p className="ml-2 text-sm font-light text-gray-500">{additionalInfo}</p>}
    </div>
  </div>
);

const GrowthChart: React.FC<GrowthChartProps> = ({ title, data }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h3 className="text-lg font-normal text-gray-800 mb-4">{title}</h3>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="category"
          tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
          interval="preserveStartEnd"
          tick={{ fontSize: 12, fontWeight: 300 }}
        />
        <YAxis tick={{ fontSize: 12, fontWeight: 300 }} />
        <Tooltip
          labelFormatter={(label) => new Date(label).toLocaleDateString()}
          contentStyle={{ fontSize: '12px', fontWeight: 300 }}
        />
        <Legend wrapperStyle={{ fontSize: '12px', fontWeight: 300 }} />
        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default AdminDashboard;
