import React from 'react';
import { usePatientTab } from '../../../../contexts/PatientTabContext';
import {
  Pagination as ShadcnPagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { cn } from "@/lib/utils";

const Pagination: React.FC = () => {
    const { currentPage, totalPages, handlePageChange } = usePatientTab();

    return (
        <ShadcnPagination className="mt-4">
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious
                        onClick={() => handlePageChange(currentPage - 1)}
                        className={cn(
                            "font-light",
                            currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"
                        )}
                    />
                </PaginationItem>
                
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                    <PaginationItem key={pageNum}>
                        <PaginationLink
                            onClick={() => handlePageChange(pageNum)}
                            isActive={currentPage === pageNum}
                            className={cn(
                                "cursor-pointer font-light",
                                currentPage === pageNum ? "font-normal" : "font-light"
                            )}
                        >
                            {pageNum}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem>
                    <PaginationNext
                        onClick={() => handlePageChange(currentPage + 1)}
                        className={cn(
                            "font-light",
                            currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"
                        )}
                    />
                </PaginationItem>
            </PaginationContent>
        </ShadcnPagination>
    );
};

export default Pagination;

