// frontend/src/contexts/SessionContext.tsx

import React, { createContext, useState, useCallback, ReactNode, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { api } from '../services/api';
import {
    Session,
    SessionCreate,
    SessionState,
    SessionContextType,
    PatientPrepData,
    Patient
} from '../types';
import { User } from '../types/usersTypes';

interface SessionProviderProps {
    children: ReactNode;
    currentUser: User;
    patients: Patient[];
}

export const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider: React.FC<SessionProviderProps> = ({ children, currentUser, patients }) => {
    const [sessionState, setSessionState] = useState<SessionState>({
        activeView: 'default',
        sessionData: null,
        successMessage: '',
        patientPrepData: null,
        isLoading: false,
    });

    const changeActiveView = (view: string) => {
        setSessionState((prevState) => ({
            ...prevState,
            activeView: view,
            successMessage: '',
        }));
    };

    const handleStartCurrentSession = async (data: SessionCreate) => {
        try {
            setSessionState(prev => ({ ...prev, isLoading: true }));

            const isDemoPatient = data.patient_id === "demo-patient-id";
            let prepData: PatientPrepData | null = null;

            try {
                const response = await api.sessions.getPatientSessionPrep(data.patient_id!);
                const patientDetails = await api.sessions.getPatientDetails(data.patient_id!);
                prepData = {
                    lastSession: null,
                    patientInfo: patientDetails,
                    aiAnalysis: response.aiAnalysis
                };
            } catch (error) {
                console.warn('Could not fetch patient prep data:', error);
                prepData = {
                    lastSession: null,
                    patientInfo: null,
                    aiAnalysis: "No previous session data available."
                };
            }

            const roomName = `session-${uuidv4()}`;
            const sessionData = {
                ...data,
                therapist_id: currentUser.id,
                date: new Date().toISOString().split('T')[0],
                start_time: data.start_time,
                format: 'SOAP',
                status: 'in_progress',
                videoRoomName: roomName,
                session_number: data.session_number
            } as SessionCreate;

            try {
                const createdSession = isDemoPatient
                    ? await api.sessions.createDemoSession(sessionData)
                    : await api.sessions.createSession(sessionData);

                setSessionState((prevState) => ({
                    ...prevState,
                    sessionData: createdSession,
                    patientPrepData: prepData,
                    activeView: 'currentSession',
                    isDemoSession: isDemoPatient
                }));
            } catch (error) {
                console.error('Error creating session:', error);
                setSessionState(prev => ({
                    ...prev,
                    successMessage: 'Failed to create session. Please try again.',
                }));
            }
        } catch (error) {
            console.error('Error in session flow:', error);
            setSessionState(prev => ({
                ...prev,
                successMessage: 'An unexpected error occurred. Please try again.',
            }));
        } finally {
            setSessionState(prev => ({ ...prev, isLoading: false }));
        }
    };

    const handleUpdateSession = async (updatedSessionData: Partial<Session>) => {
        try {
            if (!sessionState.sessionData?.id) return;

            const isDemoSession = sessionState.isDemoSession;
            const updateData = {
                ...updatedSessionData,
                session_number: sessionState.sessionData.session_number,
                date: sessionState.sessionData.date,
                format: sessionState.sessionData.format,
                start_time: sessionState.sessionData.start_time
            } as SessionCreate;

            setSessionState((prevState) => ({
                ...prevState,
                sessionData: prevState.sessionData ? {
                    ...prevState.sessionData,
                    ...updatedSessionData,
                } : null,
            }));

            if (!isDemoSession) {
                await api.sessions.updateSession(sessionState.sessionData.id, updateData);
            }
        } catch (error) {
            console.error('Error updating session:', error);
        }
    };

    const setSuccessMessage = useCallback((message: string) => {
        setSessionState(prevState => ({
            ...prevState,
            successMessage: message
        }));

        setTimeout(() => {
            setSessionState(prevState => ({
                ...prevState,
                successMessage: ''
            }));
        }, 10000);
    }, []);

    const handleEndSession = async (updatedSessionData: Partial<Session>) => {
        try {
            if (!sessionState.sessionData?.id) return;
            const isDemoSession = sessionState.isDemoSession;

            const endTime = new Date().toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).substring(0, 5);

            const finalSessionData = {
                ...updatedSessionData,
                status: 'completed',
                end_time: endTime,
                session_number: sessionState.sessionData.session_number,
                date: sessionState.sessionData.date,
                format: sessionState.sessionData.format,
                start_time: sessionState.sessionData.start_time
            } as SessionCreate;

            if (!isDemoSession) {
                await api.sessions.updateSession(sessionState.sessionData.id, finalSessionData);
            }

            const patient = patients.find(p => p.id === finalSessionData.patient_id);
            const patientName = patient ? patient.full_name : isDemoSession ? 'Demo Patient' : 'Patient';

            setSessionState({
                activeView: 'default',
                sessionData: null,
                successMessage: '',
                patientPrepData: null,
                isLoading: false
            });

            const message = isDemoSession
                ? `Demo session completed successfully. Note: Demo sessions are not saved.`
                : `Session with ${patientName} saved successfully. Head to the Patient tab to view the session's details.`;

            setSuccessMessage(message);
        } catch (error) {
            console.error('Error ending session:', error);
        }
    };

    const handleDeleteSession = async () => {
        const isDemoSession = sessionState.isDemoSession;
        const confirmMessage = isDemoSession
            ? 'Are you sure you want to end this demo session?'
            : 'Are you sure you want to delete this session?';

        if (window.confirm(confirmMessage)) {
            try {
                if (!isDemoSession && sessionState.sessionData?.id) {
                    await api.sessions.deleteSession(sessionState.sessionData.id);
                }

                const message = isDemoSession
                    ? 'Demo session ended'
                    : 'Session deleted successfully';

                setSessionState({
                    activeView: 'default',
                    sessionData: null,
                    successMessage: message,
                    patientPrepData: null,
                    isLoading: false
                });
            } catch (error) {
                console.error('Error deleting session:', error);
                setSessionState((prevState) => ({
                    ...prevState,
                    successMessage: `Error deleting session: ${error instanceof Error ? error.message : 'Unknown error'}`,
                }));
            }
        }
    };

    const handleLogPreviousSession = async (data: SessionCreate) => {
        try {
            setSessionState(prev => ({ ...prev, isLoading: true }));

            const isDemoPatient = data.patient_id === "demo-patient-id";
            const sessionData = {
                ...data,
                therapist_id: currentUser.id,
                status: 'completed',
                format: 'SOAP',
            };

            const createdSession = isDemoPatient
                ? await api.sessions.createDemoSession(sessionData)
                : await api.sessions.createSession(sessionData);

            setSessionState((prevState) => ({
                ...prevState,
                sessionData: createdSession,
                activeView: 'completedSession',
                isDemoSession: isDemoPatient,
                isLoading: false,
            }));
        } catch (error) {
            console.error('Error logging previous session:', error);
            throw error;
        }
    };

    const handleCancelSession = () => {
        const confirmCancel = window.confirm('Are you sure you want to cancel this session? All unsaved changes will be lost.');
        if (!confirmCancel) {
            return;
        }

        setSessionState({
            activeView: 'default',
            sessionData: null,
            successMessage: '',
            patientPrepData: null,
            isLoading: false
        });
    };

    const getLastSessionNumber = useCallback(async (patientId: string) => {
        try {
            if (patientId === "demo-patient-id") {
                return 6;
            }
            const lastSessionNumber = await api.sessions.getLastSessionNumber(currentUser.id, patientId);
            return lastSessionNumber + 1;
        } catch (error) {
            console.error('Error fetching last session number:', error);
            return 1; // Default to 1 if there's an error
        }
    }, [currentUser.id]);

    const contextValue: SessionContextType = {
        sessionState,
        setSessionState,
        changeActiveView,
        handleStartCurrentSession,
        handleUpdateSession,
        handleEndSession,
        handleDeleteSession,
        handleLogPreviousSession,
        currentUser,
        patients,
        setSuccessMessage,
        handleCancelSession,
        getLastSessionNumber,
    };

    return (
        <SessionContext.Provider value={contextValue}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSessionContext must be used within a SessionProvider');
    }
    return context;
};
