import React from 'react';
import { Link } from 'react-router-dom';
const oasysLogo = require('../../assets/oasys-logo2.png');
const hipaaLogo = require('../../assets/Homepage/compliance-badge.png');

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary text-white py-12 mt-16 relative z-10">
            <div className="container mx-auto px-6 sm:px-8 max-w-7xl">
                {/* Logo Section */}
                <div className="w-16 h-16 mb-4">
                    <img src={oasysLogo} alt="Oasys Health Logo" className="w-full h-full object-contain" />
                </div>

                <div className="flex flex-col lg:flex-row justify-between gap-12">
                    {/* Left Side */}
                    <div className="flex flex-col gap-8 lg:w-2/3">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <h3 className="text-xl font-normal mb-4">Our Vision</h3>
                                <p className="text-sm font-light max-w-lg">
                                    We believe in a future where mental healthcare is predictive, proactive, and highly personalized.
                                </p>
                            </div>

                            <div>
                                <h3 className="text-xl font-normal mb-4">Join the Movement</h3>
                                <div className="space-y-2">
                                    <p>
                                        <a href="https://x.com/oasys_health" target="_blank" rel="noopener noreferrer" className="hover:underline font-light !text-white">
                                            Twitter / X
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://www.linkedin.com/company/oasyshealth" target="_blank" rel="noopener noreferrer" className="hover:underline font-light !text-white">
                                            LinkedIn
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-auto">
                            <div className="space-y-2">
                                <p className="text-sm font-light">&copy; 2024 Oasys Health, Inc.</p>
                                <p className="text-xs font-light">
                                    <Link to="/privacy-policy" className="underline hover:text-gray-300">Privacy Policy</Link>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Right Side */}
                    <div className="flex flex-col items-end lg:w-1/3 justify-between">
                        <p className="text-2xl font-light">
                            Have a question? <a href="/contact" className="underline">Contact Us</a>
                        </p>
                        <img 
                            src={hipaaLogo} 
                            alt="HIPAA Compliance Badge" 
                            className="w-48 h-auto object-contain mt-auto mb-2" 
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 