import React, { useState, useEffect, useMemo } from 'react';
import { FiBell, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { FaMoon, FaRunning, FaAppleAlt, FaInfoCircle } from 'react-icons/fa';
import { InsightFilter, AlertProps, HealthInsight } from '../../../types/patientDataTypes';
import { Loader } from 'lucide-react';
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import Tooltip from "../../../components/Shared/Tooltip";
import { Badge } from "../../../components/ui/badge";

const Alert: React.FC<AlertProps> = ({ type, message, category, priority }) => {
    const colors = {
        sleep: 'bg-blue-50 text-blue-800 border-blue-200',
        activity: 'bg-green-50 text-green-800 border-green-200',
        nutrition: 'bg-yellow-50 text-yellow-800 border-yellow-200',
        general: 'bg-purple-50 text-purple-800 border-purple-200'
    };

    const categoryIcons = {
        sleep: <FaMoon className="mr-2" />,
        activity: <FaRunning className="mr-2" />,
        nutrition: <FaAppleAlt className="mr-2" />,
        general: <FaInfoCircle className="mr-2" />
    };

    const getPriorityLabel = (priority: number) => {
        switch (priority) {
            case 1: return { text: 'High', color: 'text-red-600' };
            case 2: return { text: 'Medium', color: 'text-orange-600' };
            case 3: return { text: 'Low', color: 'text-yellow-600' };
            case 4: return { text: 'Info', color: 'text-gray-600' };
            default: return { text: 'Normal', color: 'text-gray-600' };
        }
    };

    const priorityInfo = getPriorityLabel(priority);

    return (
        <div className={`${colors[category]} p-3 sm:p-4 rounded-md border flex flex-col sm:flex-row items-start space-y-2 sm:space-y-0 sm:space-x-3 hover:shadow-sm transition-shadow`}>
            <div className="flex-shrink-0">
                {categoryIcons[category]}
            </div>
            <div className="flex-1">
                <p className="font-light">{message}</p>
                <div className="mt-2 flex flex-wrap items-center text-sm gap-2">
                    <span className="font-medium">{category}</span>
                    <span className={`${priorityInfo.color} font-medium text-xs px-2 py-0.5 rounded-full bg-white/50`}>
                        {priorityInfo.text} Priority
                    </span>
                </div>
            </div>
        </div>
    );
};

interface InsightsTabProps {
    healthInsights: HealthInsight[];
    isLoading?: boolean;
}

export const InsightsTab: React.FC<InsightsTabProps> = ({
    healthInsights,
    isLoading = false
}) => {
    const [insightFilter, setInsightFilter] = useState<InsightFilter>({
        category: 'all',
        priority: 'all'
    });
    const [showAllInsights, setShowAllInsights] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [openPriority, setOpenPriority] = useState(false);

    // Add debug logging
    useEffect(() => {
        console.debug('Health Insights received:', healthInsights);
    }, [healthInsights]);

    // Filter out duplicate insights while preserving default insights
    const uniqueInsights = useMemo(() => {
        if (!healthInsights || healthInsights.length === 0) {
            return [];
        }

        // If we only have default insights (2 general insights), return them directly
        if (healthInsights.length === 2 &&
            healthInsights.every(insight => insight.category === 'general')) {
            return healthInsights;
        }

        // Otherwise, filter duplicates
        const seen = new Set();
        return healthInsights.filter(insight => {
            const key = `${insight.category}-${insight.message}`;
            if (seen.has(key)) return false;
            seen.add(key);
            return true;
        });
    }, [healthInsights]);

    // Apply filters while preserving default insights
    const filteredInsights = useMemo(() => {
        // If we have default insights, don't apply filters
        if (uniqueInsights.length === 2 &&
            uniqueInsights.every(insight => insight.category === 'general')) {
            return uniqueInsights;
        }

        return uniqueInsights
            .filter(insight => {
                if (insightFilter.category !== 'all' && insight.category !== insightFilter.category) {
                    return false;
                }
                if (insightFilter.priority !== 'all') {
                    switch (insightFilter.priority) {
                        case 'high': return insight.priority <= 2;
                        case 'medium': return insight.priority === 3;
                        case 'low': return insight.priority >= 4;
                    }
                }
                return true;
            })
            .sort((a, b) => a.priority - b.priority);
    }, [uniqueInsights, insightFilter]);

    const displayedInsights = showAllInsights
        ? filteredInsights
        : filteredInsights.slice(0, 3);

    const categoryOptions = [
        { value: 'all', label: 'All Categories' },
        { value: 'sleep', label: 'Sleep' },
        { value: 'activity', label: 'Activity' },
        { value: 'nutrition', label: 'Nutrition' },
        { value: 'general', label: 'General' }
    ];

    const priorityOptions = [
        { value: 'all', label: 'All Priority' },
        { value: 'high', label: 'High Priority' },
        { value: 'medium', label: 'Medium Priority' },
        { value: 'low', label: 'Low Priority' }
    ];

    const activeFiltersCount = Object.values(insightFilter).filter(value => value !== 'all').length;

    // Add category counts for the summary
    const categoryCounts = useMemo(() => {
        return {
            sleep: filteredInsights.filter(i => i.category === 'sleep').length,
            activity: filteredInsights.filter(i => i.category === 'activity').length,
            nutrition: filteredInsights.filter(i => i.category === 'nutrition').length,
            general: filteredInsights.filter(i => i.category === 'general').length,
        };
    }, [filteredInsights]);

    return (
        <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md">
            <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-normal text-primary flex items-center">
                    <FiBell className="inline-block mr-2" />
                    Health Insights
                    {isLoading && (
                        <Loader className="ml-2 w-4 h-4 animate-spin text-primary" />
                    )}
                </h3>

                {activeFiltersCount > 0 && (
                    <Badge variant="secondary">
                        {filteredInsights.length} matching insights
                    </Badge>
                )}
            </div>

            <div className="flex justify-end mb-6 space-x-2">
                <div className="w-[150px]">
                    <Tooltip text="Filter insights by their category">
                        <Popover open={openCategory} onOpenChange={setOpenCategory}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={insightFilter.category !== 'all' ? "default" : "outline"}
                                    role="combobox"
                                    aria-expanded={openCategory}
                                    className="w-full justify-between font-light"
                                    disabled={isLoading}
                                >
                                    {categoryOptions.find((option) => option.value === insightFilter.category)?.label || "Category"}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[150px] p-0">
                                <Command>
                                    <CommandInput placeholder="Search categories..." />
                                    <CommandList>
                                        <CommandEmpty>No category found.</CommandEmpty>
                                        <CommandGroup>
                                            {categoryOptions.map((option) => (
                                                <CommandItem
                                                    key={option.value}
                                                    value={option.value}
                                                    onSelect={() => {
                                                        setInsightFilter(prev => ({
                                                            ...prev,
                                                            category: option.value as InsightFilter['category']
                                                        }));
                                                        setOpenCategory(false);
                                                    }}
                                                >
                                                    <Check
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            insightFilter.category === option.value ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {option.label}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </Tooltip>
                </div>

                <div className="w-[150px]">
                    <Tooltip text="Filter insights by importance level">
                        <Popover open={openPriority} onOpenChange={setOpenPriority}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={insightFilter.priority !== 'all' ? "default" : "outline"}
                                    role="combobox"
                                    aria-expanded={openPriority}
                                    className="w-full justify-between font-light"
                                    disabled={isLoading}
                                >
                                    {priorityOptions.find((option) => option.value === insightFilter.priority)?.label || "Priority"}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[150px] p-0">
                                <Command>
                                    <CommandInput placeholder="Search priority levels..." />
                                    <CommandList>
                                        <CommandEmpty>No priority found.</CommandEmpty>
                                        <CommandGroup>
                                            {priorityOptions.map((option) => (
                                                <CommandItem
                                                    key={option.value}
                                                    value={option.value}
                                                    onSelect={() => {
                                                        setInsightFilter(prev => ({
                                                            ...prev,
                                                            priority: option.value as InsightFilter['priority']
                                                        }));
                                                        setOpenPriority(false);
                                                    }}
                                                >
                                                    <Check
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            insightFilter.priority === option.value ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {option.label}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </Tooltip>
                </div>
            </div>

            <div className="space-y-3">
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center py-8 space-y-2">
                        <Loader className="w-8 h-8 animate-spin text-primary" />
                        <p className="text-sm text-gray-500">Generating insights...</p>
                    </div>
                ) : healthInsights && healthInsights.length > 0 ? (
                    filteredInsights.length > 0 ? (
                        <>
                            {displayedInsights.map((insight, index) => (
                                <Alert
                                    key={index}
                                    type={insight.type}
                                    message={insight.message}
                                    category={insight.category}
                                    priority={insight.priority}
                                />
                            ))}
                            {filteredInsights.length > 3 && (
                                <button
                                    onClick={() => setShowAllInsights(!showAllInsights)}
                                    className="w-full mt-4 text-primary hover:text-primary-dark text-sm font-medium"
                                >
                                    {showAllInsights ? (
                                        <span className="flex items-center justify-center">
                                            <FiChevronUp className="mr-1" /> Show Less
                                        </span>
                                    ) : (
                                        <span className="flex items-center justify-center">
                                            <FiChevronDown className="mr-1" />
                                            Show {filteredInsights.length - 3} More Insights
                                        </span>
                                    )}
                                </button>
                            )}
                        </>
                    ) : (
                        <div className="text-center py-8">
                            <p className="text-gray-500 font-light mb-2">
                                No insights found for the selected filters
                            </p>
                            <Button
                                variant="outline"
                                onClick={() => setInsightFilter({ category: 'all', priority: 'all' })}
                                className="text-sm"
                            >
                                Clear Filters
                            </Button>
                        </div>
                    )
                ) : (
                    <div className="text-center py-8">
                        <p className="text-gray-500 font-light mb-2">
                            No insights available at the moment
                        </p>
                        <p className="text-sm text-muted-foreground">
                            Check back later for updates on your health journey
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InsightsTab;