import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import type { ButtonProps } from "@/components/ui/button"

// Primary Buttons
export function PrimaryButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={cn(
        "inline-flex items-center justify-center px-6 border border-transparent",
        "rounded-full text-base font-light text-white",
        "bg-primary hover:bg-secondary transition-colors duration-300",
        "h-12 py-3.5",
        className
      )}
      {...props}
    />
  )
}

// New Large Primary Button
export function PrimaryLargeButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={cn(
        "inline-flex items-center justify-center px-8 py-4 border border-transparent",
        "rounded-full text-lg font-light text-white h-14",
        "bg-primary hover:bg-secondary transition-colors duration-300",
        className
      )}
      {...props}
    />
  )
}

// Primary Small - for smaller contexts
export function PrimarySmallButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={cn(
        "inline-flex items-center justify-center px-4 py-2 border border-transparent",
        "rounded-full text-sm font-light text-white",
        "bg-primary hover:bg-secondary transition-colors duration-300",
        className
      )}
      size="sm"
      {...props}
    />
  )
}

// Secondary Buttons - for less prominent actions
export function SecondaryButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="secondary"
      className={cn(
        "font-normal text-base",
        className
      )}
      {...props}
    />
  )
}

// Outline Buttons - for optional actions
export function OutlineButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="outline"
      className={cn(
        "inline-flex items-center justify-center px-6 border border-primary",
        "rounded-full text-base font-light",
        "text-primary hover:bg-primary hover:text-white transition-colors duration-300",
        "h-12 py-3.5",
        className
      )}
      {...props}
    />
  )
}

// Ghost Buttons - for subtle actions
export function GhostButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="ghost"
      className={cn(
        "text-primary hover:bg-primary/10",
        className
      )}
      {...props}
    />
  )
}

// Destructive Buttons - for dangerous actions
export function DestructiveButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="destructive"
      className={cn(
        "font-normal",
        className
      )}
      {...props}
    />
  )
}

// Link Buttons - for navigation-like actions
export function LinkButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="link"
      className={cn(
        "text-primary underline-offset-4 p-0 h-auto",
        className
      )}
      {...props}
    />
  )
}

// Icon Button - for actions with just an icon
export function IconButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      variant="ghost"
      className={cn(
        "h-9 w-9 p-0",
        className
      )}
      {...props}
    />
  )
}

// Form Submit Button - full width, slightly different style for forms
export function SubmitButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={cn(
        "w-full bg-primary text-white font-light text-base",
        "hover:bg-primary/90 transition-colors duration-300",
        "h-12 py-3.5", // Match height with other buttons
        "rounded-lg", // Different radius for form contexts
        className
      )}
      {...props}
    />
  )
}