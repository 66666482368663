import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { FaUpload, FaExpandArrowsAlt, FaRedo } from 'react-icons/fa';
import { HiSparkles } from 'react-icons/hi';
import { api } from '../../../../services/api';
import { SessionContext } from '../../../../contexts/SessionContext';
import { useMediaQuery } from 'react-responsive';
import { Session, NoteData, SessionCreate } from '../../../../types/sessionsTypes';
import Tooltip from '../../../Shared/Tooltip';
import FileUploadModal from './FileUploadModal';
import SessionSummaryModal from './SessionSummaryModal';
import FloatingNotes from './FloatingNotes';
import { Textarea } from "@/components/ui/textarea";
import { Calendar } from "@/components/ui/calendar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

interface ExtendedSessionCreate extends Partial<SessionCreate> {
  id?: string;
}

interface NoteTakingProps {
  initialData: ExtendedSessionCreate;
  isPreviousSession: boolean;
  onEndSession: (data: Partial<SessionCreate>) => Promise<void>;
  handleDeleteSession: () => void;
  showSessionDetails?: boolean;
}

const NoteTaking: React.FC<NoteTakingProps> = ({
  initialData,
  isPreviousSession,
  onEndSession,
  handleDeleteSession,
  showSessionDetails = false
}) => {
  const sessionContext = useContext(SessionContext);
  if (!sessionContext) throw new Error('SessionContext must be used within SessionProvider');
  const { currentUser, patients, handleCancelSession, getLastSessionNumber } = sessionContext;

  const [noteData, setNoteData] = useState<NoteData>({
    patient_id: '',
    session_number: 0,
    date: initialData?.date || new Date().toISOString().split('T')[0],
    start_time: '',
    end_time: '',
    content: '',
    format: 'SOAP',
    summary: '',
    ...(initialData as Partial<NoteData>),
  });

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSummarizing, setIsSummarizing] = useState<boolean>(false);
  const [isEditingSummary, setIsEditingSummary] = useState<boolean>(false);
  const [editedSummary, setEditedSummary] = useState<string>('');
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showFloatingNotes, setShowFloatingNotes] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Use a ref to keep track of the previous session ID
  const prevSessionIdRef = useRef(initialData?.id);

  useEffect(() => {
    if (initialData && initialData.id !== prevSessionIdRef.current) {
      // Add null check and type assertion for date
      const dateString = initialData.date || new Date().toISOString();
      const adjustedDate = new Date(dateString);
      adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());

      // Format the times to ensure they're in the correct format
      const formattedStartTime = initialData.start_time ? 
        format(new Date(`2000-01-01T${initialData.start_time}`), 'HH:mm') : '';
      const formattedEndTime = initialData.end_time ? 
        format(new Date(`2000-01-01T${initialData.end_time}`), 'HH:mm') : '';

      setNoteData((prevData) => ({
        ...prevData,
        ...initialData,
        date: adjustedDate.toISOString().split('T')[0],
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        content: initialData.content || '',
        format: (initialData.format as NoteData['format']) || prevData.format,
      }));
      prevSessionIdRef.current = initialData.id;
    }
  }, [initialData]);

  useEffect(() => {
    if (!isPreviousSession) {
      const timer = setInterval(() => {
        setElapsedTime(prev => prev + 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isPreviousSession]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Special handling for session_number
    if (name === 'session_number') {
      setNoteData((prevData) => ({
        ...prevData,
        [name]: value === '' ? 0 : Number(value),
      }));
    } else {
      setNoteData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Add check for empty notes
    if (!noteData.content?.trim() && !noteData.summary?.trim()) {
      if (!window.confirm('Are you sure you want to save this session without any notes?')) {
        return;
      }
    }

    setError('');
    setIsSaving(true);

    try {
      const now = new Date();
      const userTimezoneOffset = now.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(now.getTime() + userTimezoneOffset);
      const endTime = adjustedDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).substring(0, 5);

      const sessionPayload: Partial<Session> = {
        ...noteData,
        session_number: Number(noteData.session_number),
        start_time: noteData.start_time.substring(0, 5),
        end_time: endTime,
        status: 'completed'
      };

      await onEndSession(sessionPayload);
      setIsSaving(false);
    } catch (err) {
      const errorMessage = err instanceof Error
        ? err.message
        : 'Unknown error occurred';
      console.error('Error saving session:', err);
      setError(`Error saving session: ${errorMessage}`);
      setIsSaving(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (!noteData.content && !uploadedFile) {
      setError('Please enter note content or upload a file before enhancing');
      return;
    }

    setError('');
    setIsSummarizing(true);
    try {
      let fileUrl = '';
      if (uploadedFile) {
        fileUrl = await api.files.uploadFile(currentUser.id, uploadedFile);
      }

      if (!noteData.content) {
        noteData.content = ' ';
      }

      const response = await api.sessions.summarize({
        content: noteData.content,
        fileUrl: fileUrl,
        format: noteData.format
      });

      if (response && response.summary) {
        setNoteData((prevData) => ({
          ...prevData,
          summary: response.summary,
        }));
        setShowSummaryModal(true);
      } else {
        throw new Error('Failed to generate summary');
      }
    } catch (err) {
      const errorMessage = err instanceof Error
        ? err.message
        : 'Unknown error occurred';
      console.error('Error generating summary:', err);
      setError(`Error enhancing notes: ${errorMessage}`);
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleEditSummary = () => {
    setIsEditingSummary(true);
    setEditedSummary(noteData.summary);
  };

  const handleSaveSummary = () => {
    setNoteData(prevData => ({
      ...prevData,
      summary: editedSummary
    }));
    setIsEditingSummary(false);
  };

  const handleSummaryChange = (newMarkdown: string) => {
    setEditedSummary(newMarkdown);
  };

  const handleRegenerateSummary = async () => {
    setError('');
    setIsSummarizing(true);
    try {
      let fileUrl = '';
      if (uploadedFile) {
        fileUrl = await api.files.uploadFile(currentUser.id, uploadedFile);
      }

      const isDemoSession = sessionContext.sessionState.isDemoSession;
      let newSummary: string | undefined;

      if (isDemoSession) {
        const response = await api.sessions.summarize({
          content: noteData.content,
          fileUrl: fileUrl,
          format: noteData.format
        });
        newSummary = response?.summary;
      } else {
        const response = await api.sessions.summarize({
          content: noteData.content,
          fileUrl: fileUrl,
          format: noteData.format
        });
        newSummary = response?.summary;
      }

      if (newSummary) {
        setNoteData((prevData) => ({
          ...prevData,
          summary: newSummary as string,
        }));
        setShowSummaryModal(true);
      } else {
        throw new Error('Failed to regenerate summary');
      }
    } catch (err) {
      const errorMessage = err instanceof Error
        ? err.message
        : 'Unknown error occurred';
      console.error('Error regenerating summary:', err);
      setError(`Error regenerating summary: ${errorMessage}`);
    } finally {
      setIsSummarizing(false);
    }
  };

  // Add helper function for time options
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const date = new Date();
        date.setHours(hour, minute);
        options.push({
          value: format(date, 'HH:mm'),
          label: format(date, 'h:mm a')
        });
      }
    }
    return options;
  };

  return (
    <>
      <FileUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onDrop={onDrop}
        uploadedFile={uploadedFile}
      />

      <SessionSummaryModal
        isOpen={showSummaryModal}
        onClose={() => setShowSummaryModal(false)}
        summary={noteData.summary}
        isEditing={isEditingSummary}
        onEdit={handleEditSummary}
        onSave={handleSaveSummary}
        editedSummary={editedSummary}
        onSummaryChange={handleSummaryChange}
        wellbeingScore={noteData.wellbeing_score}
      />

      <form onSubmit={handleSubmit} className="space-y-6">
        {isPreviousSession && (
          <div className={`${isMobile ? 'space-y-4' : 'grid grid-cols-2 gap-4'}`}>
            <div>
              <Label className="text-sm font-normal text-gray-700">
                Date
              </Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className={cn(
                      "w-full mt-1 justify-start text-left font-light",
                      !noteData.date && "text-muted-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {noteData.date ? format(new Date(noteData.date), "PPP") : <span>Pick a date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={noteData.date ? new Date(noteData.date) : undefined}
                    onSelect={(date) => {
                      if (date) {
                        handleInputChange({
                          target: {
                            name: 'date',
                            value: format(date, 'yyyy-MM-dd')
                          }
                        } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    initialFocus
                    disabled={(date) =>
                      date > new Date() || date < new Date("1900-01-01")
                    }
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="text-sm font-normal text-gray-700">
                  Start Time
                </Label>
                <Select
                  value={noteData.start_time || undefined}
                  onValueChange={(value) => 
                    handleInputChange({
                      target: { name: 'start_time', value }
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  <SelectTrigger className="w-full mt-1 font-light">
                    <SelectValue placeholder="Select start time">
                      {noteData.start_time ? 
                        format(new Date(`2000-01-01T${noteData.start_time}`), 'h:mm a')
                        : "Select start time"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <div className="max-h-[300px] overflow-y-auto">
                      {generateTimeOptions().map(({ value, label }) => (
                        <SelectItem key={value} value={value} className="font-light">
                          {label}
                        </SelectItem>
                      ))}
                    </div>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label className="text-sm font-normal text-gray-700">
                  End Time
                </Label>
                <Select
                  value={noteData.end_time || undefined}
                  onValueChange={(value) => 
                    handleInputChange({
                      target: { name: 'end_time', value }
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  <SelectTrigger className="w-full mt-1 font-light">
                    <SelectValue placeholder="Select end time">
                      {noteData.end_time ? 
                        format(new Date(`2000-01-01T${noteData.end_time}`), 'h:mm a')
                        : "Select end time"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <div className="max-h-[300px] overflow-y-auto">
                      {generateTimeOptions().map(({ value, label }) => (
                        <SelectItem 
                          key={value} 
                          value={value} 
                          className={cn(
                            "font-light",
                            noteData.start_time && value <= noteData.start_time ? "text-muted-foreground" : ""
                          )}
                        >
                          {label}
                        </SelectItem>
                      ))}
                    </div>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        )}

        <div>
          <Label htmlFor="content" className="block text-sm font-normal text-gray-700">
            Session Notes
          </Label>
          <div className="mt-1 relative">
            <Textarea
              id="content"
              name="content"
              value={noteData.content || ''}
              onChange={handleInputChange}
              className="min-h-[150px] font-light"
              placeholder="Enter your session notes here..."
            />
            <Tooltip text="Pop out notes">
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFloatingNotes(true);
                }}
                variant="ghost"
                size="sm"
                className="absolute top-2 right-2 p-1.5 text-gray-400 hover:text-gray-600 
                      hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                <FaExpandArrowsAlt size={14} />
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="flex items-center gap-4">
          {uploadedFile ? (
            <div className="flex items-center gap-2 px-3 py-1.5 bg-gray-50 rounded-lg text-sm text-gray-600 font-light">
              <FaUpload className="text-gray-400" />
              {uploadedFile.name}
            </div>
          ) : null}

          <button
            type="button"
            onClick={() => setIsUploadModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-light 
                      text-gray-600 hover:bg-gray-50 rounded-lg border border-gray-200"
          >
            <FaUpload className="text-gray-400" />
            {uploadedFile ? 'Change File' : 'Upload File'}
          </button>
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
            <div className="flex-grow">
              <Label className="block text-sm font-normal text-gray-600 mb-2">
                Note Format
              </Label>
              <Select
                value={noteData.format || undefined}
                onValueChange={(value) => 
                  handleInputChange({
                    target: { name: 'format', value }
                  } as React.ChangeEvent<HTMLSelectElement>)
                }
              >
                <SelectTrigger className="w-full md:w-48 font-light">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="SOAP" className="font-light">SOAP Format</SelectItem>
                  <SelectItem value="DAP" className="font-light">DAP Format</SelectItem>
                  <SelectItem value="BIRP" className="font-light">BIRP Format</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="flex-shrink-0 flex gap-2">
              <button
                type="button"
                onClick={noteData.summary ? () => setShowSummaryModal(true) : handleGenerateSummary}
                disabled={isSummarizing || (!noteData.content && !uploadedFile)}
                className="flex items-center gap-2 px-6 py-2.5 bg-secondary/10 text-secondary
                              rounded-lg text-sm font-light hover:bg-secondary/20
                              disabled:opacity-50 transition-all duration-200 w-full md:w-auto"
              >
                {isSummarizing ? (
                  <>
                    <span className="animate-spin">⟳</span>
                    <span>Processing...</span>
                  </>
                ) : (
                  <>
                    <HiSparkles className="text-lg" />
                    <span>{noteData.summary ? 'View Enhanced Summary' : 'Enhance Notes'}</span>
                  </>
                )}
              </button>

              {noteData.summary && (
                <Tooltip text="Regenerate Summary">
                  <button
                    type="button"
                    onClick={handleRegenerateSummary}
                    disabled={isSummarizing}
                    className="flex items-center justify-center w-10 h-10 bg-secondary/10 text-secondary
                              rounded-lg hover:bg-secondary/20 disabled:opacity-50 transition-all duration-200"
                  >
                    <FaRedo className={`${isSummarizing ? 'animate-spin' : ''}`} />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>

          <div className="mt-3">
            <p className="text-xs text-gray-500">
              Select a note format and click "Enhance Notes" to create a structured summary
              of your session notes.
            </p>
          </div>
        </div>

        {error && <p className="text-red-500 text-sm font-light">{error}</p>}

        <div className="sticky bottom-0 bg-white p-4 border-t border-gray-200 mt-6">
          <div className="flex flex-col sm:flex-row gap-3 justify-end">
            <button
              type="button"
              onClick={handleDeleteSession}
              className="order-2 sm:order-1 px-4 py-2 text-sm font-light text-red-600 
                      hover:bg-red-50 rounded-lg border border-red-200 
                      transition-all duration-200"
            >
              Delete Session
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="order-1 sm:order-2 px-6 py-2 text-sm font-light text-white 
                      bg-primary hover:bg-primary/90 rounded-lg disabled:opacity-50 
                      transition-all duration-200"
            >
              {isSaving ? 'Saving...' : 'Save Session'}
            </button>
          </div>
        </div>
      </form>

      {showFloatingNotes && (
        <FloatingNotes
          content={noteData.content}
          onChange={(newContent) => {
            setNoteData(prev => ({ ...prev, content: newContent }));
            setHasUnsavedChanges(true);
          }}
          onClose={() => setShowFloatingNotes(false)}
        />
      )}
    </>
  );
};

export default NoteTaking;
