import React, { useState } from 'react';
import { format, parseISO, differenceInDays, eachDayOfInterval, isWithinInterval } from 'date-fns';
import { Droplet, Calendar, Activity, Heart, Sprout, Sun, Moon } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import { MenstruationData, Stats } from '../../../types';

interface MenstruationTabProps {
    menstruationData: MenstruationData[];
}

const MenstruationTab: React.FC<MenstruationTabProps> = ({ menstruationData }) => {

    const NoDataDisplay: React.FC = () => (
        <div className="h-48 sm:h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    const latestData = menstruationData[0]?.menstruation_data;

    if (!menstruationData || menstruationData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No menstruation data available.</p>;
    }

    // Calculate averages and stats
    const calculateStats = (): Stats => {
        const validCycles = menstruationData.filter(entry => entry.menstruation_data.cycle_length_days);
        const validPeriods = menstruationData.filter(entry => entry.menstruation_data.period_length_days);
        const latestData = menstruationData[0].menstruation_data;

        return {
            avgCycleLength: validCycles.length > 0
                ? Math.round(validCycles.reduce((sum, entry) =>
                    sum + (entry.menstruation_data.cycle_length_days || 0), 0) / validCycles.length)
                : null,
            avgPeriodLength: validPeriods.length > 0
                ? Math.round(validPeriods.reduce((sum, entry) =>
                    sum + (entry.menstruation_data.period_length_days || 0), 0) / validPeriods.length)
                : null,
            totalCycles: validCycles.length,
            daysUntilNext: latestData.period_start_date && latestData.predicted_cycle_length_days
                ? differenceInDays(
                    new Date(parseISO(latestData.period_start_date).setDate(
                        parseISO(latestData.period_start_date).getDate() + latestData.predicted_cycle_length_days
                    )),
                    new Date()
                )
                : null
        };
    };

    const stats = calculateStats();

    const CyclePhaseTimeline: React.FC<{ menstruationData: MenstruationData[] }> = ({ menstruationData }) => {
        if (!menstruationData || menstruationData.length === 0) {
            return <NoDataDisplay />;
        }

        const entry = menstruationData[0];
        const cycleStartDate = parseISO(entry.menstruation_data.period_start_date || entry.metadata.start_time);
        const cycleEndDate = new Date(cycleStartDate);
        cycleEndDate.setDate(cycleStartDate.getDate() + (entry.menstruation_data.cycle_length_days || 28));

        const days = eachDayOfInterval({ start: cycleStartDate, end: cycleEndDate });

        const phaseConfig = {
            menstruation: {
                color: 'bg-pink-500',
                hoverColor: 'hover:bg-pink-600',
                label: 'Menstruation',
                height: 'h-16'
            },
            fertility: {
                color: 'bg-blue-400',
                hoverColor: 'hover:bg-blue-500',
                label: 'Fertility Window',
                height: 'h-12'
            },
            ovulation: {
                color: 'bg-emerald-400',
                hoverColor: 'hover:bg-emerald-500',
                label: 'Ovulation Day',
                height: 'h-20'
            },
            regular: {
                color: 'bg-gray-200',
                hoverColor: 'hover:bg-gray-300',
                label: 'Regular Phase',
                height: 'h-8'
            }
        };

        return (
            <div className="space-y-4">
                {/* Legend */}
                <div className="flex flex-wrap gap-4 justify-center">
                    {Object.entries(phaseConfig).map(([phase, config]) => (
                        <div key={phase} className="flex items-center gap-2">
                            <div className={`w-3 h-3 rounded-full ${config.color}`} />
                            <span className="text-sm text-gray-600 font-light">{config.label}</span>
                        </div>
                    ))}
                </div>

                {/* Timeline - Updated container and positioning */}
                <div className="relative px-4">
                    <div className="grid grid-cols-[repeat(28,1fr)] gap-1 min-h-[200px]">
                        {days.map((date, index) => {
                            let phase: keyof typeof phaseConfig = 'regular';

                            // Determine phase
                            if (entry.menstruation_data.period_end_date &&
                                isWithinInterval(date, {
                                    start: cycleStartDate,
                                    end: parseISO(entry.menstruation_data.period_end_date)
                                })) {
                                phase = 'menstruation';
                            }

                            if (entry.menstruation_data.fertility_window_start &&
                                entry.menstruation_data.fertility_window_end &&
                                isWithinInterval(date, {
                                    start: parseISO(entry.menstruation_data.fertility_window_start),
                                    end: parseISO(entry.menstruation_data.fertility_window_end)
                                })) {
                                phase = 'fertility';
                            }

                            if (entry.menstruation_data.ovulation_day &&
                                format(date, 'yyyy-MM-dd') === format(parseISO(entry.menstruation_data.ovulation_day), 'yyyy-MM-dd')) {
                                phase = 'ovulation';
                            }

                            const config = phaseConfig[phase];

                            return (
                                <div key={index} className="relative group">
                                    <div className={`
                                        ${config.color} 
                                        ${config.hoverColor} 
                                        h-full min-h-[2rem]
                                        rounded-md 
                                        transition-all 
                                        duration-200
                                        transform
                                        group-hover:scale-105
                                    `}>
                                        <div className="opacity-0 group-hover:opacity-100 absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-white text-gray-800 text-xs rounded-lg py-2 px-3 shadow-lg border border-gray-100 whitespace-nowrap z-10">
                                            <p className="font-medium">{format(date, 'MMM dd')}</p>
                                            <p className="text-gray-500">{config.label}</p>
                                        </div>
                                    </div>
                                    {/* Updated date label positioning */}
                                    {index % 7 === 0 && (
                                        <div className="absolute top-full mt-1 left-0 text-xs text-gray-500 transform -translate-x-1/2">
                                            {format(date, 'MMM dd')}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    // Add new component for cycle insights
    const CycleInsightsCard: React.FC<{ menstruationData: MenstruationData[] }> = ({ menstruationData }) => {
        const entry = menstruationData[0];
        const currentPhase = entry.menstruation_data.current_phase || null;
        const daysUntilNextPhase = entry.menstruation_data.days_until_next_phase;

        const getPhaseIcon = (phase: string | null) => {
            switch (phase) {
                case 'fertile': return <Sprout className="w-6 h-6 text-blue-500" />;
                case 'menstruation': return <Droplet className="w-6 h-6 text-pink-500" />;
                case 'ovulation': return <Sun className="w-6 h-6 text-emerald-500" />;
                default: return <Moon className="w-6 h-6 text-gray-500" />;
            }
        };

        return (
            <DashboardCard
                title="Cycle Insights"
                titleClass="text-lg font-light"
                icon={<Activity className="text-pink-500" />}
                testId="dashboard-card-cycle-insights"
            >
                <div className="space-y-4">
                    {/* Current Phase Status */}
                    <div className="text-center p-4 bg-gray-50 rounded-lg">
                        <div className="flex justify-center mb-2">
                            {getPhaseIcon(currentPhase)}
                        </div>
                        <p className="text-gray-600 font-light">
                            {currentPhase ? (
                                <>
                                    You are in your <span className="font-medium text-pink-500">{currentPhase}</span> phase
                                    {daysUntilNextPhase !== null && (
                                        <> ({daysUntilNextPhase} days until next phase)</>
                                    )}
                                </>
                            ) : 'Regular phase'}
                        </p>
                    </div>

                    {/* Cycle Statistics */}
                    <div className="grid grid-cols-2 gap-4">
                        <div className="text-center p-3 bg-pink-50 rounded-lg">
                            <p className="text-sm text-gray-500 mb-1">Cycle Length</p>
                            <p className="text-xl font-light text-pink-500">
                                {entry.menstruation_data.cycle_length_days || 'N/A'} days
                            </p>
                        </div>
                        <div className="text-center p-3 bg-blue-50 rounded-lg">
                            <p className="text-sm text-gray-500 mb-1">Current Day</p>
                            <p className="text-xl font-light text-blue-500">
                                Day {entry.menstruation_data.day_in_cycle || 'N/A'}
                            </p>
                        </div>
                    </div>

                    {/* Upcoming Events */}
                    <div className="space-y-2">
                        {entry.menstruation_data.ovulation_day && (
                            <div className="flex items-center justify-between p-2 bg-emerald-50 rounded-lg">
                                <span className="text-sm text-gray-600">Ovulation Day</span>
                                <span className="text-sm font-medium text-emerald-500">
                                    {format(parseISO(entry.menstruation_data.ovulation_day), 'MMM dd')}
                                </span>
                            </div>
                        )}
                        {entry.menstruation_data.fertility_window_start && (
                            <div className="flex items-center justify-between p-2 bg-blue-50 rounded-lg">
                                <span className="text-sm text-gray-600">Fertility Window</span>
                                <span className="text-sm font-medium text-blue-500">
                                    {format(parseISO(entry.menstruation_data.fertility_window_start), 'MMM dd')} - {
                                        format(parseISO(entry.menstruation_data.fertility_window_end || ''), 'MMM dd')
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </DashboardCard>
        );
    };

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 gap-4">
                {/* Cycle Insights - Moved to top */}
                <CycleInsightsCard menstruationData={menstruationData} />

                {/* Cycle Timeline - Reduced height */}
                <DashboardCard
                    title="Cycle Phases"
                    titleClass="text-lg font-light"
                    icon={<Calendar className="text-pink-500" />}
                    testId="dashboard-card-cycle-phases"
                >
                    <div className="h-[300px]">
                        <CyclePhaseTimeline menstruationData={menstruationData} />
                    </div>
                </DashboardCard>

                {/* Cycle Information Summary */}
                <DashboardCard
                    title="Cycle Information"
                    titleClass="text-lg font-light"
                    icon={<Heart className="text-pink-500" />}
                >
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                        <div className="text-center p-2" data-testid="current-cycle-day">
                            <p className="text-sm text-gray-500 font-light mb-1">Current Cycle Day</p>
                            <p className="text-xl sm:text-2xl font-light text-pink-500">
                                {latestData?.day_in_cycle || 'N/A'}
                            </p>
                        </div>
                        <div className="text-center p-2">
                            <p className="text-sm text-gray-500 font-light mb-1">Avg. Cycle Length</p>
                            <p className="text-xl sm:text-2xl font-light text-pink-500">
                                {stats.avgCycleLength ? `${stats.avgCycleLength}d` : 'N/A'}
                            </p>
                        </div>
                        <div className="text-center p-2">
                            <p className="text-sm text-gray-500 font-light mb-1">Avg. Period Length</p>
                            <p className="text-xl sm:text-2xl font-light text-pink-500">
                                {stats.avgPeriodLength ? `${stats.avgPeriodLength}d` : 'N/A'}
                            </p>
                        </div>
                        <div className="text-center p-2">
                            <p className="text-sm text-gray-500 font-light mb-1">Days Until Next</p>
                            <p className="text-xl sm:text-2xl font-light text-pink-500">
                                {stats.daysUntilNext ? `${stats.daysUntilNext}d` : 'N/A'}
                            </p>
                        </div>
                        <div className="text-center p-2">
                            <p className="text-sm text-gray-500 font-light mb-1">Total Cycles</p>
                            <p className="text-xl sm:text-2xl font-light text-pink-500">
                                {stats.totalCycles || 'N/A'}
                            </p>
                        </div>
                    </div>
                </DashboardCard>
            </div>
        </div>
    );
};

export default MenstruationTab;