import React, { useState } from 'react';
import SessionDetailsModal from './SessionDetailsModal';
import WellbeingScoreBar from './WellbeingScoreDesktop/WellbeingScoreBar';
import { Session } from '../../../../types/sessionsTypes';

interface SessionCardProps {
    session: Session;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const isDemo = session.id.startsWith('demo-session');

    const formatDate = (dateString: string, startTime?: string): string => {
        const date = new Date(dateString);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.getTime() + userTimezoneOffset);
        
        const formattedDate = adjustedDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        return startTime
            ? `${formattedDate} at ${new Date(`2000-01-01T${startTime}`).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            })}`
            : formattedDate;
    };

    return (
        <>
            <div
                onClick={() => setShowModal(true)}
                className="bg-gray-50 rounded-lg px-6 py-4 mb-4 transition-all duration-200 
                          hover:shadow-lg hover:scale-[1.02] hover:bg-white border-l-4 
                          border-primary max-w-2xl cursor-pointer relative h-[98px]"
            >
                <div className="flex justify-between items-center mb-3">
                    <span className="font-normal text-lg text-primary">
                        Session {session.session_number}
                    </span>
                    <span className="text-sm text-gray-500 font-light">
                        {formatDate(session.date, session.time)}
                    </span>
                </div>
                <div className="flex justify-end items-center min-h-[24px]">
                    {session.wellbeing_score !== null ? (
                        <WellbeingScoreBar score={session.wellbeing_score} isMobile={false} />
                    ) : (
                        <div className="h-[24px]" />
                    )}
                </div>

                <div className="absolute bottom-4 left-4 text-gray-400">
                    <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                        />
                    </svg>
                </div>
            </div>

            {showModal && (
                <SessionDetailsModal
                    session={session}
                    onClose={() => setShowModal(false)}
                />
            )}
        </>
    );
};

export default SessionCard; 