export const timePromise = async <T>(
    name: string, 
    promise: Promise<T>
): Promise<T> => {
    const start = performance.now();
    try {
        const result = await promise;
        const end = performance.now();
        console.log(`${name} took ${(end - start).toFixed(2)}ms`);
        return result;
    } catch (error) {
        const end = performance.now();
        console.log(`${name} failed after ${(end - start).toFixed(2)}ms`);
        throw error;
    }
}; 