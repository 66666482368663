import React, { useState, useEffect } from 'react';
import { useSessionContext } from '../../../../contexts/SessionContext';
import { SessionCreate, Patient, FormData } from '../../../../types';
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2 } from "lucide-react";

const NewSessionForm: React.FC = () => {
    const { handleStartCurrentSession, changeActiveView, sessionState, patients, getLastSessionNumber } = useSessionContext();
    const [error, setError] = useState<string>('');
    const [formData, setFormData] = useState<FormData>(() => {
        const now = new Date();
        const userTimezoneOffset = now.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(now.getTime() + userTimezoneOffset);
        return {
            patient_id: '',
            session_number: '',
            date: adjustedDate.toISOString().split('T')[0],
            start_time: new Date().toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }),
            end_time: '',
        };
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (formData.patient_id) {
            getLastSessionNumber(formData.patient_id).then((nextSessionNumber: number) => {
                setFormData(prevData => ({ ...prevData, session_number: nextSessionNumber }));
            });
        }
    }, [formData.patient_id, getLastSessionNumber]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (!formData.patient_id || !formData.session_number) {
            setError('Please fill in all required fields.');
            return;
        }

        const now = new Date();
        const endDate = new Date(now);
        endDate.setHours(endDate.getHours() + 1);
        const endTime = endDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        const currentData: SessionCreate = {
            ...formData,
            end_time: endTime
        } as SessionCreate;

        handleStartCurrentSession(currentData);
    };

    const allPatients = patients.length > 0 ? patients : [{
        id: "demo-patient-id",
        full_name: "Jane Doe (Demo Patient)",
        email: "demo.patient@example.com",
        role: "user"
    }];

    return (
        <form onSubmit={handleSubmit} className="space-y-6" data-testid="session-form">
            {error && (
                <Alert variant="destructive">
                    <AlertDescription>
                        {error}
                    </AlertDescription>
                </Alert>
            )}

            <div className="space-y-2">
                <Label htmlFor="patient_id" className="text-sm text-gray-700 font-normal">
                    Patient
                </Label>
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className="w-full justify-between font-light"
                            data-testid="patient-select"
                        >
                            {formData.patient_id
                                ? allPatients.find((patient) => patient.id === formData.patient_id)?.full_name
                                : "Select a patient"}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0" align="start">
                        <Command>
                            <CommandInput placeholder="Search patients..." />
                            <CommandList>
                                <CommandEmpty>No patient found.</CommandEmpty>
                                <CommandGroup>
                                    {allPatients.map((patient) => (
                                        <CommandItem
                                            key={patient.id}
                                            value={patient.full_name}
                                            onSelect={() => {
                                                handleInputChange({
                                                    target: { name: 'patient_id', value: patient.id }
                                                } as React.ChangeEvent<HTMLSelectElement>);
                                                setOpen(false);
                                            }}
                                            className="font-light"
                                        >
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4",
                                                    formData.patient_id === patient.id ? "opacity-100" : "opacity-0"
                                                )}
                                            />
                                            <span className={cn(
                                                "font-light",
                                                patient.id === "demo-patient-id" ? "text-blue-600" : ""
                                            )}>
                                                {patient.full_name}
                                            </span>
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>

            <div className="space-y-2">
                <Label htmlFor="session_number" className="text-sm text-gray-700 font-normal">
                    Session Number
                </Label>
                <Input
                    type="number"
                    id="session_number"
                    name="session_number"
                    value={formData.session_number}
                    onChange={handleInputChange}
                    required
                    className="font-light"
                    data-testid="session-number"
                    placeholder="Enter session number"
                />
            </div>

            <div className="flex justify-end space-x-4">
                <Button
                    type="button"
                    variant="outline"
                    onClick={() => changeActiveView('default')}
                    disabled={sessionState.isLoading}
                    className="font-light rounded-full"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={sessionState.isLoading}
                    className="font-light rounded-full"
                >
                    {sessionState.isLoading ? (
                        <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Starting...
                        </>
                    ) : (
                        'Start Session'
                    )}
                </Button>
            </div>
        </form>
    );
};

export default NewSessionForm;