import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import TherapistOnboardingProgress from './TherapistOnboardingProgress';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import useTherapistSignUp from '../../hooks/useTherapistSignUp';
import { useAuthContext } from '../../contexts/AuthContext';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

interface FormData {
  professionalBio: string;
  therapyApproach: string;
  profilePicture?: File;
  profilePictureUrl?: string | null;
}

const BioAndApproach: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    professionalBio: '',
    therapyApproach: '',
    profilePictureUrl: null
  });
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { error: hookError, handleBioAndApproach } = useTherapistSignUp();

  useEffect(() => {
    const fetchTherapistData = async () => {
      if (!user) {
        navigate('/login');
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          setFormData({
            professionalBio: data.professional_bio || '',
            therapyApproach: data.therapy_approach || '',
            profilePictureUrl: data.profile_picture_url || null
          });
        }
      }
    };
    fetchTherapistData();
  }, [navigate, user]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFormData(prevState => ({
      ...prevState,
      profilePicture: file
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user) {
      const submissionData = {
        ...formData,
        profilePicture: formData.profilePicture,
        profilePictureUrl: formData.profilePictureUrl || undefined
      };
      await handleBioAndApproach(user.id, submissionData);
    }
  };

  const handleBack = () => {
    navigate('/therapist-onboarding/practice-details');
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex items-center justify-center p-4 pt-24 pb-24 md:pt-32 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md mx-auto p-8 md:p-12">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-normal text-primary">Bio and Approach</h2>
              <p className="text-gray-400 mt-2 font-light">Tell us about yourself and your practice.</p>
            </div>

            <TherapistOnboardingProgress currentStep="/therapist-onboarding/bio-and-approach" />

            <form onSubmit={handleSubmit} className="space-y-8 mt-8">
              <div>
                <Label htmlFor="professionalBio" className="text-sm text-gray-500 font-light">
                  Professional Bio (250 words max) *
                </Label>
                <Textarea
                  id="professionalBio"
                  name="professionalBio"
                  value={formData.professionalBio}
                  onChange={handleChange}
                  maxLength={1500}
                  rows={4}
                  required
                  placeholder="Share your professional background and experience..."
                  className="mt-2 font-light resize-none"
                />
              </div>

              <div>
                <Label htmlFor="therapyApproach" className="text-sm text-gray-500 font-light">
                  How do you approach therapy? (50 words max) *
                </Label>
                <Textarea
                  id="therapyApproach"
                  name="therapyApproach"
                  value={formData.therapyApproach}
                  onChange={handleChange}
                  maxLength={300}
                  rows={4}
                  required
                  placeholder="Describe your therapeutic approach and methodology..."
                  className="mt-2 font-light resize-none"
                />
              </div>

              <div>
                <Label htmlFor="profilePicture" className="text-sm text-gray-500 font-light">
                  Profile Picture (Optional)
                </Label>
                <div className="mt-2">
                  <Input
                    type="file"
                    id="profilePicture"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="font-light h-12 py-1.5 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-light file:bg-primary file:text-white hover:file:bg-primary-dark file:cursor-pointer cursor-pointer"
                  />
                </div>
              </div>

              <div className="flex justify-between space-x-4 mt-20">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-4 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors font-normal"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-normal"
                >
                  Next
                </button>
              </div>
            </form>

            {hookError && (
              <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light mt-6">
                <span className="block sm:inline">{hookError}</span>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default BioAndApproach;
