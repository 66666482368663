import React, { useState } from 'react';
import { Activity, Dumbbell, Moon, Utensils, Droplet, Weight, MessageSquare, ChevronDown, Bell } from 'lucide-react';
import { TabButton } from '../HealthDashboardUtils';

import OverviewTab from './PatientHealthOverviewTab';
import ActivityTab from './PatientActivityTab';
import SleepTab from './PatientSleepTab';
import NutritionTab from './PatientNutritionTab';
import MenstruationTab from './PatientMenstruationTab';
import BodyTab from './PatientBodyTab';
import AIChatbot from '../AIChatbot';
import InsightsTab from './PatientInsightsTab';

import { useAuthContext } from '../../../contexts/AuthContext';

import {
    HealthAnalysis,
    ActivityData,
    SleepData,
    NutritionEntry,
    MenstruationData,
    BodyData,
    ChatMessage,
    DailyData,
    HealthInsight,
    CreativeInsight,
} from '../../../types';

interface DesktopHealthDashboardProps {
    healthData: HealthAnalysis | null;
    nutritionData: NutritionEntry[] | null;
    sleepData: SleepData[] | null;
    activityData: ActivityData[] | null;
    menstruationData: MenstruationData[] | null;
    bodyData: BodyData[] | null;
    dailyData: DailyData[] | null;
    connectedProviders: string[] | null;
    creativeInsights: CreativeInsight[];
    healthInsights: HealthInsight[];
    isLoading?: boolean;
}

type TabType = 'overview' | 'insights' | 'activity' | 'sleep' | 'nutrition' | 'menstruation' | 'body' | 'assistant';

const PatientHealthDashboard: React.FC<DesktopHealthDashboardProps> = ({
    healthData,
    nutritionData,
    sleepData,
    activityData,
    menstruationData,
    bodyData,
    dailyData,
    connectedProviders,
    creativeInsights,
    healthInsights,
    isLoading,
}) => {
    const [activeTab, setActiveTab] = useState<TabType>('overview');
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([
        {
            role: 'assistant',
            content: "Hi! I'm here to help analyze your health data. What would you like to know?",
        },
    ]);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const { user } = useAuthContext();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const mainTabs = [
        { id: 'overview', label: 'Overview', icon: <Activity className="w-5 h-5 md:w-4 md:h-4" />, show: true },
        { id: 'insights', label: 'Insights', icon: <Bell className="w-5 h-5 md:w-4 md:h-4" />, show: true },
        { id: 'activity', label: 'Activity', icon: <Dumbbell className="w-5 h-5 md:w-4 md:h-4" />, show: activityData && activityData.length > 0 },
        { id: 'sleep', label: 'Sleep', icon: <Moon className="w-5 h-5 md:w-4 md:h-4" />, show: sleepData && sleepData.length > 0 },
        { id: 'nutrition', label: 'Nutrition', icon: <Utensils className="w-5 h-5 md:w-4 md:h-4" />, show: nutritionData && nutritionData.length > 0 },
        { id: 'menstruation', label: 'Menstruation', icon: <Droplet className="w-5 h-5 md:w-4 md:h-4" />, show: menstruationData && menstruationData.length > 0 },
        { id: 'body', label: 'Body', icon: <Weight className="w-5 h-5 md:w-4 md:h-4" />, show: bodyData && bodyData.length > 0 },
    ] as const;

    const assistantTab = { id: 'assistant', label: 'Assistant', icon: <MessageSquare className="w-5 h-5 md:w-4 md:h-4" />, show: true };

    return (
        <div className="space-y-4">
            <h3 className="text-2xl font-normal text-primary mb-4">Health Dashboard</h3>
            {/* Tab Selection */}
            <div className="bg-gray-100/50 p-2 rounded-xl">
                {/* Desktop View */}
                <div className="hidden md:flex md:flex-row gap-2 items-center justify-between">
                    <div className="flex flex-wrap gap-2">
                        {mainTabs.map(tab => (
                            tab.show && (
                                <TabButton
                                    key={tab.id}
                                    selected={activeTab === tab.id}
                                    onClick={() => setActiveTab(tab.id as TabType)}
                                    icon={tab.icon}
                                >
                                    {tab.label}
                                </TabButton>
                            )
                        ))}
                    </div>
                    <TabButton
                        selected={activeTab === assistantTab.id}
                        onClick={() => setActiveTab(assistantTab.id as TabType)}
                        icon={assistantTab.icon}
                    >
                        {assistantTab.label}
                    </TabButton>
                </div>

                {/* Mobile View */}
                <div className="md:hidden relative">
                    <div className="flex gap-2">
                        <button
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="flex items-center justify-between w-full px-4 py-2 bg-white rounded-lg text-primary shadow-sm"
                        >
                            <div className="flex items-center">
                                {[...mainTabs, assistantTab].find(tab => tab.id === activeTab)?.icon}
                                <span className="ml-2">{[...mainTabs, assistantTab].find(tab => tab.id === activeTab)?.label}</span>
                            </div>
                            <ChevronDown className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
                        </button>
                    </div>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                        <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-lg z-50">
                            {[...mainTabs, assistantTab].map(tab => (
                                tab.show && tab.id !== activeTab && (
                                    <button
                                        key={tab.id}
                                        onClick={() => {
                                            setActiveTab(tab.id as TabType);
                                            setIsDropdownOpen(false);
                                        }}
                                        className="flex items-center w-full px-4 py-2 hover:bg-gray-50 text-gray-700"
                                    >
                                        {tab.icon}
                                        <span className="ml-2">{tab.label}</span>
                                    </button>
                                )
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Content Area */}
            <div className="py-5 bg-primary/5 rounded-lg p-2 sm:p-6">
                <div className="space-y-4">
                    {activeTab === 'overview' && (
                        <OverviewTab
                            email={user?.email || ''}
                            healthAnalysis={healthData}
                            dailyDataOverview={dailyData}
                            connectedProviders={connectedProviders}
                            creativeInsights={creativeInsights}
                        />
                    )}
                    {activeTab === 'insights' && (
                        <InsightsTab
                            healthInsights={healthInsights}
                            isLoading={isLoading}
                        />
                    )}
                    {activeTab === 'activity' && activityData && (
                        <ActivityTab activityData={activityData} />
                    )}
                    {activeTab === 'sleep' && sleepData && (
                        <SleepTab sleepData={sleepData} />
                    )}
                    {activeTab === 'nutrition' && nutritionData && (
                        <NutritionTab nutritionData={nutritionData} />
                    )}
                    {activeTab === 'menstruation' && menstruationData && (
                        <MenstruationTab menstruationData={menstruationData} />
                    )}
                    {activeTab === 'body' && bodyData && (
                        <BodyTab bodyData={bodyData} />
                    )}
                    {activeTab === 'assistant' && (
                        <div className="h-[calc(100vh-20rem)]">
                            <AIChatbot
                                userEmail={user?.email || ''}
                                embedded={true}
                                messages={chatMessages}
                                setMessages={setChatMessages}
                                isTyping={isTyping}
                                setIsTyping={setIsTyping}
                                onMessageSent={() => {
                                    setActiveTab('assistant');
                                }}
                            />
                        </div>
                    )}

                    {/* Chat Input - Only show if not on assistant tab */}
                    {activeTab !== 'assistant' && (
                        <div className="bg-white rounded-lg border border-gray-100">
                            <AIChatbot
                                userEmail={user?.email || ''}
                                embedded={true}
                                inputOnly={true}
                                messages={chatMessages}
                                setMessages={setChatMessages}
                                isTyping={isTyping}
                                setIsTyping={setIsTyping}
                                onMessageSent={() => setActiveTab('assistant')}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PatientHealthDashboard;
