import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Utensils, Apple, PieChart as PieChartIcon } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
import { NutritionEntry, NutritionSummaryData, CustomTooltipProps } from '../../../types';


interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-2 sm:px-3 py-1 text-xs sm:text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);



interface NutritionTabProps {
    nutritionData: NutritionEntry[];
}

const NutritionTab: React.FC<NutritionTabProps> = ({ nutritionData }) => {
    const [timeframe, setTimeframe] = useState<'week' | 'month'>('week');

    if (!nutritionData || nutritionData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No nutrition data available.</p>;
    }

    const timeframeDays = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = () => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process nutrition data with proper date range
    const nutritionSummaryData: NutritionSummaryData[] = generateDateRange().map(date => {
        const matchingNutritionData = nutritionData.find(entry =>
            format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );

        const calories = matchingNutritionData?.summary.macros.calories
            ? Math.round(matchingNutritionData.summary.macros.calories)
            : null;
        const protein = matchingNutritionData?.summary.macros.protein_g
            ? Math.round(matchingNutritionData.summary.macros.protein_g)
            : null;
        const carbs = matchingNutritionData?.summary.macros.carbohydrates_g
            ? Math.round(matchingNutritionData.summary.macros.carbohydrates_g)
            : null;
        const fat = matchingNutritionData?.summary.macros.fat_g
            ? Math.round(matchingNutritionData.summary.macros.fat_g)
            : null;

        // Calculate macro percentages
        const totalMacroGrams = (protein || 0) + (carbs || 0) + (fat || 0);

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            calories,
            mealCount: matchingNutritionData?.meals?.length || null,
            protein,
            carbs,
            fat,
            proteinPercentage: protein && totalMacroGrams ? Math.round((protein / totalMacroGrams) * 100) : null,
            carbsPercentage: carbs && totalMacroGrams ? Math.round((carbs / totalMacroGrams) * 100) : null,
            fatPercentage: fat && totalMacroGrams ? Math.round((fat / totalMacroGrams) * 100) : null
        };
    });

    const calculateAverages = (data: {
        date: string;
        rawDate: Date;
        calories: number | null;
        mealCount: number | null;
        protein: number | null;
        carbs: number | null;
        fat: number | null;
        proteinPercentage: number | null;
    }[]) => {
        const validCalories = data.filter(d => d.calories !== null);
        const validMeals = data.filter(d => d.mealCount !== null);
        const validMacros = data.filter(d => d.proteinPercentage !== null);

        return {
            avgCalories: validCalories.length > 0
                ? Math.round(validCalories.reduce((acc, curr) =>
                    acc + (curr.calories || 0), 0) / validCalories.length)
                : null,
            totalMeals: validMeals.length > 0
                ? validMeals.reduce((acc, curr) =>
                    acc + (curr.mealCount || 0), 0)
                : null,
            avgProtein: validMacros.length > 0
                ? Math.round(validMacros.reduce((acc, curr) =>
                    acc + (curr.proteinPercentage || 0), 0) / validMacros.length)
                : null
        };
    };

    const averages = calculateAverages(nutritionSummaryData);

    interface NutritionTooltipProps extends CustomTooltipProps<NutritionSummaryData> {
        chartType?: 'calories' | 'protein';
    }

    const CustomTooltip: React.FC<NutritionTooltipProps> = ({ active, payload, label, chartType }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const matchingNutritionData = nutritionData.find(entry =>
                format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(data.rawDate, 'yyyy-MM-dd')
            );

            if (chartType === 'calories') {
                return (
                    <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="font-light text-gray-600 mb-2">{label}</p>
                        {data.calories ? (
                            <div className="space-y-2">
                                <p className="text-sm font-light text-center text-green-600 mb-3">
                                    Total Calories: {data.calories}
                                </p>
                                <div className="space-y-2 max-h-48 overflow-y-auto">
                                    {matchingNutritionData?.meals.map((meal, index) => (
                                        <div key={index} className="text-xs border-b border-gray-100 pb-2">
                                            <div className="flex justify-between text-gray-600">
                                                <span className="font-medium">
                                                    {meal.name?.split(' - ')[0] || 'Meal'}
                                                </span>
                                                <span>{meal.macros.calories || 0} cal</span>
                                            </div>
                                            <div className="text-gray-400">
                                                {meal.timestamp ?
                                                    format(parseISO(meal.timestamp), 'h:mm a') :
                                                    'Time not specified'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p className="text-sm font-light text-gray-500">No nutrition data for this day</p>
                        )}
                    </div>
                );
            }

            // For macro distribution chart tooltip
            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                    <p className="font-light text-gray-600 mb-2">{label}</p>
                    <div className="space-y-1">
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#15803d' }}>Protein:</span>
                            <span style={{ color: '#15803d' }}>{data.proteinPercentage}%</span>
                        </div>
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#16a34a' }}>Carbs:</span>
                            <span style={{ color: '#16a34a' }}>{data.carbsPercentage}%</span>
                        </div>
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#22c55e' }}>Fat:</span>
                            <span style={{ color: '#22c55e' }}>{data.fatPercentage}%</span>
                        </div>
                        <div className="mt-2 pt-1 border-t border-gray-100">
                            <p className="text-xs text-gray-500">
                                Total Macros: {((data.protein || 0) + (data.carbs || 0) + (data.fat || 0))}g
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-4">
            <div className="flex justify-end space-x-1 sm:space-x-2 px-1 sm:px-0" data-testid="timeframe-selector">
                <TimeframeButton active={timeframe === 'week'} onClick={() => setTimeframe('week')}>Week</TimeframeButton>
                <TimeframeButton active={timeframe === 'month'} onClick={() => setTimeframe('month')}>Month</TimeframeButton>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-2 sm:gap-4">
                {/* Calorie Intake Chart */}
                <div className="col-span-1 md:col-span-8">
                    <DashboardCard
                        title="Calorie Intake"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<Utensils className="text-green-500" />}
                        testId="dashboard-card-calorie-intake"
                    >
                        {nutritionSummaryData.some(d => d.calories !== null) ? (
                            <div className="h-48 sm:h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={nutritionSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip chartType="calories" />} />
                                        <Bar dataKey="calories" name="Calories" fill="#22c55e" radius={[4, 4, 0, 0]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Macro Distribution */}
                <div className="col-span-1 md:col-span-4">
                    <DashboardCard
                        title="Macro Distribution"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<PieChartIcon className="text-green-500" />}
                        testId="dashboard-card-macro-distribution"
                    >
                        {nutritionSummaryData.some(d => d.protein !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={nutritionSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="proteinPercentage" name="Protein %" stackId="a" fill="#15803d" />
                                        <Bar dataKey="carbsPercentage" name="Carbs %" stackId="a" fill="#16a34a" />
                                        <Bar dataKey="fatPercentage" name="Fat %" stackId="a" fill="#22c55e" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Summary Stats */}
                <div className="col-span-1 md:col-span-12">
                    <DashboardCard
                        title="Summary Statistics"
                        titleClass="text-base sm:text-lg font-light"
                        icon={<Apple className="text-green-500" />}
                        testId="summary-stats-card"
                    >
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4" data-testid="summary-stats">
                            <div className="text-center p-2">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Avg. Calories</p>
                                <p className="text-xl sm:text-2xl font-light text-green-500">
                                    {averages.avgCalories ?
                                        `${averages.avgCalories}` :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Total Meals</p>
                                <p className="text-xl sm:text-2xl font-light text-green-500">
                                    {averages.totalMeals ?
                                        `${averages.totalMeals}` :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Avg. Protein Ratio</p>
                                <p className="text-xl sm:text-2xl font-light text-green-500">
                                    {averages.avgProtein ?
                                        `${averages.avgProtein}%` :
                                        'N/A'}
                                </p>
                            </div>
                            <div className="text-center p-2">
                                <p className="text-xs sm:text-sm text-gray-500 font-light mb-1">Latest Calories</p>
                                <p className="text-xl sm:text-2xl font-light text-green-500">
                                    {nutritionSummaryData[nutritionSummaryData.length - 1]?.calories ?
                                        `${nutritionSummaryData[nutritionSummaryData.length - 1].calories}` :
                                        'N/A'}
                                </p>
                            </div>
                        </div>
                    </DashboardCard>
                </div>
            </div>
        </div>
    );
};

export default NutritionTab;