import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DashboardCard } from '../TherapistHealthDashboard/TherapistHealthDashboardUtils';
import { TrendingUp } from 'lucide-react';
import { Session } from '../../../../../types/sessionsTypes';

interface WellbeingScoreGraphProps {
  sessions: Session[];
}

interface DataPoint {
  date: string;
  score: number | null | undefined;
}

interface TooltipProps {
  active?: boolean;
  payload?: {
    value: number;
    color: string;
  }[];
  label?: string;
}

const WellbeingScoreGraph: React.FC<WellbeingScoreGraphProps> = ({ sessions }) => {
  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
          <p className="font-light text-gray-600 mb-1">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm font-light" style={{ color: entry.color }}>
              Notes Sentiment Score: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const data: DataPoint[] = sessions
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(session => ({
      date: new Date(session.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      score: session.wellbeing_score
    }));

  return (
    <DashboardCard
      title="Notes Sentiment Analysis"
      titleClass="text-base font-light text-gray-800"
      icon={<TrendingUp className="text-primary" />}
    >
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 11, fontFamily: 'Inter' }}
              style={{ fontFamily: 'Inter' }}
            />
            <YAxis
              tick={{ fontSize: 11, fontFamily: 'Inter' }}
              style={{ fontFamily: 'Inter' }}
              domain={[0, 100]}
              tickFormatter={(value: number) => `${value}`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="score"
              name="Notes Sentiment Score"
              stroke="#3b82f6"
              strokeWidth={2}
              dot={{ fill: '#3b82f6', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </DashboardCard>
  );
};

export default WellbeingScoreGraph;