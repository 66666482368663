import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../services/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { User, Session } from '@supabase/supabase-js';

interface UserData {
  id: string;
  email: string;
  [key: string]: any;
}

interface AuthResponse<T> {
  data: T | null;
  error: Error | null;
}

interface SignInResponse {
  user: User;
  session: Session;
}

interface SignUpResponse {
  user: User;
  session: Session | null;
}

const useAuth = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      setUser(session?.user ?? null);
      setIsLoading(false);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
      setIsLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchUserData = useCallback(async () => {
    if (user) {
      const table = user.user_metadata?.role === 'therapist' ? 'therapists' : 'users';
      const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq('id', user.id)
        .single();

      if (!error && data) {
        setUserData(data);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchUserData();
    } else {
      setUserData(null);
    }
  }, [user, fetchUserData]);

  const signIn = async (email: string, password: string): Promise<AuthResponse<SignInResponse>> => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      if (!data.user.email_confirmed_at) {
        throw new Error('Email not confirmed');
      }
      if (!data.user || !data.session) {
        throw new Error('Invalid response from server');
      }
      await handleAuthStateChange(data.user);
      return { 
        data: {
          user: data.user,
          session: data.session
        }, 
        error: null 
      };
    } catch (error) {
      return { data: null, error: error as Error };
    }
  };

  const signUp = async (email: string, password: string, role: 'therapist' | 'patient'): Promise<AuthResponse<SignUpResponse>> => {
    try {
      // Check if email already exists
      const { data: existingUser } = await supabase
        .from('users')
        .select('id')
        .eq('email', email)
        .single();

      const { data: existingTherapist } = await supabase
        .from('therapists')
        .select('id')
        .eq('email', email)
        .single();

      if (existingUser || existingTherapist) {
        throw new Error("An account with this email already exists. If you've forgotten your password, please reset it.");
      }

      const origin = window.location.origin;
      const redirectTo = `${origin}/auth/callback`;
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: redirectTo,
          data: { role, profile_completed: false }
        }
      });

      if (error) throw error;
      if (!data.user) {
        throw new Error('No user returned from signup');
      }

      if (role === 'therapist') {
        await supabase.from('therapists').insert({
          id: data.user.id,
          email: email,
          status: 'pending_signup_email_verification'
        });
      } else if (role === 'patient') {
        await supabase.from('users').insert({
          id: data.user.id,
          email: email
        });
      }

      return { 
        data: {
          user: data.user,
          session: data.session
        }, 
        error: null 
      };
    } catch (error) {
      setSession(null);
      setUser(null);
      return { data: null, error: error as Error };
    }
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setSession(null);
    setUser(null);
  };

  const getSession = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session;
  };

  const getUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return user;
  };

  const handleAuthStateChange = async (user: User): Promise<void> => {
    if (user) {
      const userMetadata = user.user_metadata;
      if (!userMetadata.profile_completed) {
        if (userMetadata.role === 'therapist') {
          navigate('/therapist-onboarding/professional-info');
        } else {
          navigate('/complete-profile');
        }
      } else if (userMetadata.role === 'therapist') {
        const { data, error } = await supabase
          .from('therapists')
          .select('status')
          .eq('id', user.id)
          .single();
        if (!error && data) {
          if (data.status === 'approved') {
            navigate('/therapist');
          } else if (data.status === 'pending_review') {
            navigate('/therapist-onboarding/pending-approval');
          }
        }
      } else if (userMetadata.role === 'admin') {
        navigate('/admin-dashboard');
      } else {
        navigate('/dashboard');
      }
    }
  };

  const resendConfirmationEmail = async (email: string): Promise<{ error: Error | null }> => {
    try {
      const origin = window.location.origin;
      const redirectTo = `${origin}/auth/callback`;
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: email,
        options: {
          emailRedirectTo: redirectTo
        }
      });
      if (error) throw error;
      return { error: null };
    } catch (error) {
      return { error: error as Error };
    }
  };

  const resetPasswordForEmail = async (email: string): Promise<{ error: Error | null }> => {
    try {
      // Check both tables without using .single()
      const { data: users, error: usersError } = await supabase
        .from('users')
        .select('id')
        .eq('email', email);

      const { data: therapists, error: therapistsError } = await supabase
        .from('therapists')
        .select('id')
        .eq('email', email);

      if (usersError || therapistsError) {
        throw new Error('Error checking user existence');
      }

      // Check if user exists in either table
      if ((!users || users.length === 0) && (!therapists || therapists.length === 0)) {
        throw new Error('No account found with this email address.');
      }
      const { error } = await supabase.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/reset-password`
        }
      );
      if (error) throw error;
      return { error: null };
    } catch (error) {
      return { error: error as Error };
    }
  };

  const updatePassword = async (newPassword: string): Promise<{ error: Error | null }> => {
    try {
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      return { error: null };
    } catch (error) {
      return { error: error as Error };
    }
  };

  const verifyOtp = async (tokenHash: string): Promise<{ error: Error | null }> => {
    try {
      const { error } = await supabase.auth.verifyOtp({ token_hash: tokenHash, type: 'recovery' });
      if (error) throw error;
      return { error: null };
    } catch (error) {
      return { error: error as Error };
    }
  };

  const isAdmin = user?.user_metadata?.role === 'admin';
  const isTherapist = user?.user_metadata?.role === 'therapist';
  const isProfileCompleted = user?.user_metadata?.profile_completed;

  return {
    session,
    user,
    isLoading,
    signIn,
    signUp,
    signOut,
    isAdmin,
    isTherapist,
    isProfileCompleted,
    resendConfirmationEmail,
    userData,
    fetchUserData,
    resetPasswordForEmail,
    updatePassword,
    getSession,
    getUser,
    verifyOtp,
    handleAuthStateChange
  };
};

export default useAuth;
