import React from 'react';
import { FiUser, FiCalendar, FiPhone, FiHeart, FiCoffee } from 'react-icons/fi';
import { BiWine } from 'react-icons/bi';
import { PatientDetailsSessions } from '../../../../types/sessionsTypes';
const defaultAvatar = require('../../../../assets/default-avatar.png');

interface PatientProfileModalProps {
    profile: PatientDetailsSessions;
    onClose: () => void;
}

interface ProfileItemProps {
    icon: React.ReactNode;
    label: string;
    value?: string | number;
}

const formatPhoneNumber = (value?: string): string => {
    if (!value) return '';
    
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    // North American format (XXX) XXX-XXXX
    if (phoneNumberLength === 10) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
    // International format with country code +X XXX XXX XXXX
    else if (phoneNumberLength > 10) {
        // Format: +[country code] XXX XXX XXXX
        const countryCode = phoneNumber.slice(0, phoneNumberLength - 10);
        const areaCode = phoneNumber.slice(phoneNumberLength - 10, phoneNumberLength - 7);
        const middle = phoneNumber.slice(phoneNumberLength - 7, phoneNumberLength - 4);
        const last = phoneNumber.slice(phoneNumberLength - 4);
        return `+${countryCode} ${areaCode} ${middle} ${last}`;
    }
    // Partial number - just add spaces every 3 digits
    else {
        return phoneNumber.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
    }
};

const capitalizeFirstLetter = (value?: string): string => {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
};

const ProfileItem: React.FC<ProfileItemProps> = ({ icon, label, value }) => (
    <div className="flex items-start bg-gray-50 p-3 rounded-lg">
        <div className="text-primary mr-3 text-xl flex-shrink-0 mt-1">{icon}</div>
        <div className="flex-grow min-w-0">
            <span className="font-normal text-gray-700">{label}</span>
            <p className="mt-1 text-gray-900 break-words max-h-20 overflow-y-auto font-light">
                {label === "Phone" 
                    ? formatPhoneNumber(value?.toString()) || 'N/A' 
                    : capitalizeFirstLetter(value?.toString()) || 'N/A'}
            </p>
        </div>
    </div>
);

const PatientProfileModal: React.FC<PatientProfileModalProps> = ({ profile, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-normal text-primary">Patient Profile</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 font-light"
                    >
                        Close
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Left Column - Basic Info and Avatar */}
                    <div className="md:col-span-1">
                        <div className="text-center mb-6">
                            <h3 className="text-xl font-normal text-gray-800">
                                {profile.full_name}
                            </h3>
                            <p className="text-gray-600 font-light mt-1">
                                {profile.email}
                            </p>
                        </div>

                        <div className="w-40 h-40 mx-auto mb-4">
                            <img
                                src={profile.profile_picture_url || defaultAvatar}
                                alt="Profile"
                                className="rounded-full w-full h-full object-cover border-4 border-primary"
                                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = defaultAvatar;
                                }}
                            />
                        </div>
                    </div>

                    {/* Right Column - Profile Details */}
                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <ProfileItem icon={<FiCalendar />} label="Date of Birth" value={profile.dob} />
                            <ProfileItem icon={<FiUser />} label="Gender" value={profile.gender} />
                            <ProfileItem icon={<FiPhone />} label="Phone" value={profile.phone} />
                            <ProfileItem icon={<FiHeart />} label="Primary Reason" value={profile.primary_reason} />
                            <ProfileItem icon={<BiWine className="text-xl" />} label="Alcohol Consumption" value={profile.alcohol_consumption} />
                            <ProfileItem icon={<FiCoffee />} label="Caffeine Consumption" value={profile.caffeine_consumption} />
                        </div>
                    </div>
                </div>

                {/* Mental Health Information */}
                <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                    <h3 className="text-xl font-normal text-primary mb-2">Mental Health Information</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <h4 className="font-normal mb-2">Mental Health History</h4>
                            <p className="text-gray-700 font-light">
                                {capitalizeFirstLetter(profile.mental_health_history) || 'Not provided'}
                            </p>
                        </div>
                        <div>
                            <h4 className="font-normal mb-2">Current Medications</h4>
                            <p className="text-gray-700 font-light">
                                {capitalizeFirstLetter(profile.current_medications) || 'Not provided'}
                            </p>
                        </div>
                        <div>
                            <h4 className="font-normal mb-2">Stress Triggers</h4>
                            <p className="text-gray-700 font-light">
                                {capitalizeFirstLetter(profile.stress_triggers) || 'Not provided'}
                            </p>
                        </div>
                        <div>
                            <h4 className="font-normal mb-2">Relaxation Habits</h4>
                            <p className="text-gray-700 font-light">
                                {capitalizeFirstLetter(profile.relaxation_habits) || 'Not provided'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientProfileModal;