import React, { useState, FormEvent } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import useAuth from '../hooks/useAuth';
import BackgroundAnimation from '../components/HomePageMobile/BackgroundAnimationMobile';
import Footer from '../components/HomePageMobile/FooterMobile';
import { SubmitButton, LinkButton } from "@/components/Shared/Buttons"

interface ErrorMap {
    [key: string]: string;
}

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { signIn, resendConfirmationEmail } = useAuth();
    const [showResendOption, setShowResendOption] = useState<boolean>(false);

    const getUserFriendlyErrorMessage = (error: string): string => {
        const errorMap: ErrorMap = {
            'Invalid login credentials': 'The email or password you entered is incorrect. Please try again.',
            'User not found': 'We couldn\'t find an account with that email. Please check your email or sign up.',
            'Email not confirmed': 'Please confirm your email address before logging in.',
        };

        return errorMap[error] || 'An unexpected error occurred. Please try again later or contact support.';
    };

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        setShowResendOption(false);
        try {
            const { data, error } = await signIn(email, password);

            if (error) {
                if (error.message === 'Email not confirmed') {
                    setShowResendOption(true);
                }
                throw error;
            }

            if (!data) {
                throw new Error('No data received from sign in');
            }

            const user = data.user;
            const userMetadata = user.user_metadata;

            if (userMetadata.role === 'therapist') {
                const { data: therapistData, error: therapistError } = await supabase
                    .from('therapists')
                    .select('status')
                    .eq('id', user.id)
                    .single();

                if (therapistError) throw therapistError;

                if (therapistData.status === 'approved') {
                    navigate('/therapist');
                } else if (therapistData.status === 'pending_review' || therapistData.status === 'pending' || therapistData.status === 'rejected') {
                    navigate('/therapist-onboarding/pending-approval');
                } else {
                    navigate('/therapist-onboarding/professional-info');
                }
            } else {
                if (!userMetadata.profile_completed) {
                    navigate('/complete-profile');
                } else if (userMetadata.role === 'admin') {
                    navigate('/admin-dashboard');
                } else {
                    navigate('/dashboard');
                }
            }
        } catch (error: any) {
            console.error('Error logging in:', error);
            setError(getUserFriendlyErrorMessage(error.message));
        }
    };

    const handleResendConfirmation = async (): Promise<void> => {
        try {
            const { error } = await resendConfirmationEmail(email);
            if (error) throw error;
            setError('Confirmation email resent. Please check your inbox.');
        } catch (error: any) {
            console.error('Error resending confirmation email:', error);
            setError('Failed to resend confirmation email. Please try again later.');
        }
    };

    const OasysLogo = require('../assets/oasys-logo1.png');

    return (
        <div className="min-h-screen flex flex-col">
            <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                <BackgroundAnimation />
                
                <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
                    <div className="bg-white rounded-3xl shadow-2xl w-full max-w-5xl flex overflow-hidden min-h-[600px]">
                        <div className="hidden md:flex md:w-1/2 bg-white relative border-8 border-primary rounded-l-3xl">
                            <img 
                                src={OasysLogo} 
                                alt="Oasys Logo" 
                                className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55%] h-auto"
                            />
                            <div className="absolute bottom-8 right-8 max-w-[80%]">
                                <blockquote className="text-primary font-light text-lg">
                                    "Medicine is a science of uncertainty and an art of probability."
                                </blockquote>
                                <cite className="text-primary/80 text-sm block mt-2 text-right">
                                    – William Osler
                                </cite>
                            </div>
                        </div>

                        <div className="w-full md:w-1/2 p-8 md:p-12 flex flex-col justify-center bg-primary">
                            <div className="text-center mb-8">
                                <h2 className="text-3xl font-normal text-white">Log In</h2>
                                <p className="text-white/80 mt-2 font-light">Welcome back! Please enter your details.</p>
                            </div>

                            <form onSubmit={handleLogin} className="space-y-6">
                                <div className="space-y-4">
                                    <div>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="w-full px-4 py-3 border border-white/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-200 font-light bg-primary/50 text-white placeholder-white/70"
                                        />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="w-full px-4 py-3 border border-white/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent transition-all duration-200 font-light bg-primary/50 text-white placeholder-white/70"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-end text-sm">
                                    <LinkButton asChild className="p-0 text-white hover:text-white/80">
                                        <Link to="/forgot-password">
                                            Forgot password?
                                        </Link>
                                    </LinkButton>
                                </div>

                                {error && (
                                    <div className="p-3 bg-red-100/10 border border-red-400 text-red-200 rounded-lg font-light">
                                        <p>{error}</p>
                                        {showResendOption && (
                                            <button
                                                onClick={handleResendConfirmation}
                                                className="mt-2 text-white hover:underline font-light"
                                            >
                                                Resend confirmation email
                                            </button>
                                        )}
                                    </div>
                                )}

                                <SubmitButton type="submit" className="bg-white text-primary hover:bg-white/90">
                                    Log In
                                </SubmitButton>

                                <div className="text-center text-sm text-white/80 font-light">
                                    Don't have an account?{' '}
                                    <LinkButton asChild className="p-0 text-white hover:text-white/80">
                                        <Link to="/get-started">
                                            Sign up
                                        </Link>
                                    </LinkButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default Login;
