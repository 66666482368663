import React from 'react';

interface DashboardCardProps {
    title: string;
    titleClass?: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    testId?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({ title, titleClass, icon, children, testId }) => (
    <div
        className="bg-white rounded-lg shadow-sm p-3 sm:p-6 mb-4"
        data-testid={testId || `dashboard-card-${title.toLowerCase().replace(/\s+/g, '-')}`}
    >
        <div className="flex items-center mb-3 sm:mb-4">
            {icon}
            <h3 className={`ml-2 text-base sm:text-lg font-light ${titleClass || ''}`}>{title}</h3>
        </div>
        {children}
    </div>
);

interface TabButtonProps {
    selected: boolean;
    onClick: () => void;
    icon: React.ReactNode;
    children: React.ReactNode;
}

export const TabButton: React.FC<TabButtonProps> = ({ selected, onClick, icon, children }) => (
    <button
        onClick={onClick}
        className={`flex items-center px-2 sm:px-4 py-2 sm:py-2 rounded-lg text-sm transition-all duration-200
            md:text-sm w-full md:w-auto justify-center md:justify-start
            ${selected
                ? 'bg-white text-primary shadow-sm font-medium'
                : 'text-gray-500 hover:bg-white/50 font-light'}`}
    >
        {icon}
        <span className="ml-2">{children}</span>
    </button>
);

interface Thresholds {
    good: number;
    average: number;
}

export const getStatusColor = (value: number, thresholds: Thresholds, reverse: boolean = false): string => {
    if (reverse) {
        if (value <= thresholds.good) return 'text-green-500';
        if (value <= thresholds.average) return 'text-yellow-500';
        return 'text-red-500';
    }
    if (value >= thresholds.good) return 'text-green-500';
    if (value >= thresholds.average) return 'text-yellow-500';
    return 'text-red-500';
};

export const round = (num: number, decimals: number = 2): string =>
    Number(num).toFixed(decimals);