import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import ProfileForm from './ProfileForm';
import { usePatientProfile } from '../../hooks/usePatientProfile';
import { UserProfileData } from '../../types/patientOnboardingTypes';
import { SubmitButton } from "@/components/Shared/Buttons"

const CompleteProfile: React.FC = () => {
    const [userData, setUserData] = useState<UserProfileData>({
        full_name: '',
        gender: '',
        dob: null,
        primary_reason: '',
        therapy_before: '',
        mental_health_rating: '',
    });
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const { getUser } = useAuthContext();
    const { completeProfile } = usePatientProfile(undefined);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | { target: { name: string; value: any } }
    ): void => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: name === 'mental_health_rating' ? parseInt(value) : value
        }));
    };

    const handleDateChange = (date: Date | null): void => {
        setUserData({ ...userData, dob: date });
    };

    const handleSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        setError('');

        try {
            const currentUser = await getUser();

            if (!currentUser) throw new Error('No user found');

            const isTherapist = currentUser.user_metadata.role === 'therapist';

            if (isTherapist) {
                throw new Error('Sorry, therapists cannot complete the profile. This is a patient-only feature.');
            } else {
                const formattedUserData = {
                    ...userData,
                    dob: userData.dob?.toISOString().split('T')[0] || undefined
                };
                await completeProfile(currentUser.id, formattedUserData);
            }

            navigate(isTherapist ? '/therapist' : '/dashboard');
        } catch (err) {
            console.error('Error completing profile:', err);
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while completing your profile. Please try again.';
            setError(errorMessage);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                <BackgroundAnimation />
                
                <div className="flex items-center justify-center p-4 pt-32 md:pt-40 relative z-10">
                    <div className="bg-white rounded-3xl shadow-2xl w-full max-w-xl mx-auto p-8 md:p-12">
                        <div className="text-center mb-8">
                            <h2 className="text-3xl font-normal text-primary">Complete Your Profile</h2>
                            <p className="text-gray-400 mt-2 font-light">Please fill in your details below.</p>
                        </div>

                        <ProfileForm
                            userData={userData}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                        />

                        {error && (
                            <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light my-6">
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )}

                        <div className="mt-8">
                            <SubmitButton 
                                onClick={handleSubmit}
                                className="w-full"
                            >
                                Complete Profile
                            </SubmitButton>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default CompleteProfile;
