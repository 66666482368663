import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { supabase } from '../../services/supabaseClient';
import TherapistOnboardingProgress from './TherapistOnboardingProgress';
import BackgroundAnimation from '../HomePageMobile/BackgroundAnimationMobile';
import Footer from '../HomePageMobile/FooterMobile';
import useTherapistSignUp from '../../hooks/useTherapistSignUp';
import { useAuthContext } from '../../contexts/AuthContext';
import { therapyTypes, ageGroups, insurancePlans } from './Constants';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

interface FormData {
  therapyTypes: string[];
  ageGroups: string[];
  telehealthAvailable: boolean;
  insurancePlans: string[];
  address: string;
  specializations: string;
  [key: string]: string | string[] | boolean;
}

interface SelectOption {
  value: string;
  label: string;
}

const PracticeDetails: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    therapyTypes: [],
    ageGroups: [],
    telehealthAvailable: false,
    insurancePlans: [],
    address: '',
    specializations: ''
  });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { error: hookError, handlePracticeDetails } = useTherapistSignUp();

  useEffect(() => {
    const fetchTherapistData = async () => {
      if (!user) {
        navigate('/login');
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          console.log('Fetched data:', data);
          
          const specializations = data.specializations;
          const formattedSpecializations = Array.isArray(specializations)
            ? specializations.join(', ')
            : specializations || '';

          console.log('Formatted specializations:', formattedSpecializations);

          setFormData({
            therapyTypes: data.therapy_types || [],
            ageGroups: data.age_groups_served || [],
            telehealthAvailable: data.telehealth_available || false,
            insurancePlans: data.insurance_accepted || [],
            address: data.address || '',
            specializations: formattedSpecializations
          });
        }
      }
    };
    fetchTherapistData();
  }, [navigate, user]);

  const handleSelectChange = (
    name: string,
    selectedOptions: MultiValue<SelectOption>
  ) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOptions ? selectedOptions.map(option => option.value) : []
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!user) throw new Error('No user found');
      
      console.log('Form data before processing:', formData);

      const processedFormData = {
        ...formData,
        specializations: formData.specializations
          ? formData.specializations
              .split(',')
              .map(item => item.trim())
              .filter(item => item !== '')
          : []
      };

      console.log('Processed form data:', processedFormData);
      
      await handlePracticeDetails(user.id, processedFormData);
    } catch (error) {
      console.error('Submit error:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const handleBack = () => {
    navigate('/therapist-onboarding/professional-info');
  };

  const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
        <BackgroundAnimation />
        
        <div className="flex items-center justify-center p-4 pt-24 md:pt-32 relative z-10">
          <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md mx-auto p-8 md:p-12">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-normal text-primary">Practice Details</h2>
              <p className="text-gray-400 mt-2 font-light">Please fill in your practice details.</p>
            </div>

            <TherapistOnboardingProgress currentStep="/therapist-onboarding/practice-details" />

            <form onSubmit={handleSubmit} className="space-y-6 mt-8">
              <div>
                <Label htmlFor="specializations" className="text-sm text-gray-500 font-light">
                  Specializations (comma-separated) *
                </Label>
                <Input
                  type="text"
                  id="specializations"
                  name="specializations"
                  value={formData.specializations}
                  placeholder="e.g. ADHD, Anxiety, PTSD"
                  onChange={handleArrayChange}
                  required
                  className="mt-2 font-light"
                />
              </div>

              <div>
                <Label className="text-sm text-gray-500 font-light">
                  Types of Therapy Offered *
                </Label>
                <div className="mt-2">
                  <Select
                    isMulti
                    name="therapyTypes"
                    options={therapyTypes.map(type => ({ value: type, label: type }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue) => handleSelectChange('therapyTypes', newValue)}
                    value={formData.therapyTypes.map(type => ({ value: type, label: type }))}
                    required
                    styles={{
                      control: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      option: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                        color: '#6B7280',
                      }),
                      singleValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                      multiValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                    }}
                  />
                </div>
              </div>

              <div>
                <Label className="text-sm text-gray-500 font-light">
                  Age Groups Served *
                </Label>
                <div className="mt-2">
                  <Select
                    isMulti
                    name="ageGroups"
                    options={ageGroups.map(group => ({ value: group, label: group }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue) => handleSelectChange('ageGroups', newValue)}
                    value={formData.ageGroups.map(group => ({ value: group, label: group }))}
                    required
                    styles={{
                      control: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      option: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                        color: '#6B7280',
                      }),
                      singleValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                      multiValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="telehealthAvailable"
                  checked={formData.telehealthAvailable}
                  onCheckedChange={(checked) => 
                    setFormData(prev => ({ ...prev, telehealthAvailable: checked as boolean }))
                  }
                />
                <Label htmlFor="telehealthAvailable" className="text-sm text-gray-500 font-light">
                  Telehealth Available
                </Label>
              </div>

              <div>
                <Label className="text-sm text-gray-500 font-light">
                  Accepted Insurance Plans
                </Label>
                <div className="mt-2">
                  <Select
                    isMulti
                    name="insurancePlans"
                    options={insurancePlans.map(plan => ({ value: plan, label: plan }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue) => handleSelectChange('insurancePlans', newValue)}
                    value={formData.insurancePlans.map(plan => ({ value: plan, label: plan }))}
                    styles={{
                      control: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      option: (base) => ({
                        ...base,
                        fontWeight: 300,
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                        color: '#6B7280',
                      }),
                      singleValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                      multiValue: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        fontWeight: 300,
                      }),
                    }}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="address" className="text-sm text-gray-500 font-light">
                  Office Address
                </Label>
                <Input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                  className="mt-2 font-light"
                  placeholder="Enter your office address"
                />
              </div>

              <div className="flex justify-between space-x-4">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-4 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors font-normal"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors font-normal"
                >
                  Next
                </button>
              </div>
            </form>

            {(error || hookError) && (
              <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg font-light mt-6">
                <span className="block sm:inline">{error || hookError}</span>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PracticeDetails;
