import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FiX, FiLock } from 'react-icons/fi';
import { healthProviders, HealthProvider } from '../TherapistOnboarding/Constants';
import AppleHealthModal from './AppleHealthModal';

interface DataIntegrationProps {
  connectedProviders: string[];
  handleConnectHealth: (providerId: string) => Promise<string>;
  handleDisconnectProvider: (providerId: string) => void;
}

const DataIntegration: React.FC<DataIntegrationProps> = ({
  connectedProviders,
  handleConnectHealth,
  handleDisconnectProvider
}) => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const connectedProvidersList = healthProviders.filter(provider =>
    connectedProviders?.some(cp =>
      cp.toLowerCase() === provider.id.toLowerCase() ||
      cp.toLowerCase() === provider.name.toLowerCase()
    )
  );

  const availableProviders = healthProviders.filter(provider =>
    !connectedProviders?.some(cp =>
      cp.toLowerCase() === provider.id.toLowerCase() ||
      cp.toLowerCase() === provider.name.toLowerCase()
    )
  );

  const [isAppleModalOpen, setIsAppleModalOpen] = useState(false);
  const [widgetUrl, setWidgetUrl] = useState('');

  const handleProviderClick = async (provider: HealthProvider) => {
    if (!provider.available) return;

    try {
      if (provider.id === 'APPLE') {
        const url = await handleConnectHealth('APPLE');
        setWidgetUrl(url);
        setIsAppleModalOpen(true);
      } else {
        await handleConnectHealth(provider.id);
      }
    } catch (error) {
      console.error('Error handling provider click:', error);
    }
  };

  return (
    <div>
      <h3 className="text-2xl font-normal text-primary mb-4">Health Data Integration</h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Available Providers */}
        <div className="bg-gray-100 p-6 rounded-lg">
          <div className="mb-4">
            <h4 className="text-lg font-normal mb-2">Available Providers</h4>
            <p className="text-sm text-gray-600 font-light">
              Connect your health data from these providers
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {availableProviders.map((provider) => (
              <div
                key={provider.id}
                onClick={() => handleProviderClick(provider)}
                className={`relative bg-white p-4 rounded-lg shadow-sm 
                         transition-all duration-200 
                         ${provider.available ? 'cursor-pointer hover:shadow-md hover:ring-2 hover:ring-primary hover:ring-opacity-50' : 'opacity-75 cursor-not-allowed'}`}
              >
                {/* Show "Coming Soon" for unavailable providers */}
                {!provider.available && (
                  <div className="absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-10 
                                flex items-center justify-center rounded-lg transition-all duration-200 group">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                                  bg-gray-900 text-white px-3 py-1 rounded-full text-xs font-light">
                      <span>Coming Soon</span>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-center mb-3 h-12">
                  <img
                    src={provider.logo}
                    alt={provider.name}
                    className="h-8 w-auto object-contain"
                  />
                </div>

                <div className="text-center">
                  <h5 className="text-sm font-light text-gray-700">
                    {provider.name}
                  </h5>
                  <span className="text-xs font-light text-primary hover:text-primary/80">
                    {!provider.available ? 'Coming Soon' :
                      provider.id === 'APPLE' ? 'Connect via Mobile Device' :
                        'Click to connect'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Connected Providers */}
        <div className="bg-gray-100 p-6 rounded-lg">
          <div className="mb-4">
            <h4 className="text-lg font-normal mb-2">Connected Providers</h4>
            <p className="text-sm text-gray-600 font-light">
              Manage your connected health data sources
            </p>
          </div>

          {connectedProvidersList.length > 0 ? (
            <div className="grid grid-cols-2 gap-4">
              {connectedProvidersList.map((provider) => (
                <div
                  key={provider.id}
                  className="relative bg-white p-4 rounded-lg shadow-sm"
                >
                  {/* Connected Status Indicator */}
                  <div className="absolute top-2 right-2 flex space-x-2">
                    <button
                      aria-label="Disconnect provider"
                      onClick={() => handleDisconnectProvider(provider.id)}
                      className="bg-red-100 text-red-500 p-1 rounded-full hover:bg-red-200 transition-colors duration-200"
                      title="Disconnect provider"
                    >
                      <FiX className="h-4 w-4" />
                    </button>
                  </div>

                  {/* Provider Logo */}
                  <div className="flex items-center justify-center mb-3 h-12">
                    <img
                      src={provider.logo}
                      alt={provider.name}
                      className="h-8 w-auto object-contain"
                    />
                  </div>

                  {/* Provider Name */}
                  <div className="text-center">
                    <h5 className="text-sm font-light text-gray-700">
                      {provider.name}
                    </h5>
                    <span className="text-xs font-light text-green-500">
                      Connected
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bg-white p-4 rounded-lg">
              <p className="text-center text-gray-500 font-light">
                No providers connected yet
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Apple Health Modal */}
      <AppleHealthModal
        isOpen={isAppleModalOpen}
        onClose={() => setIsAppleModalOpen(false)}
        widgetUrl={widgetUrl}
      />
    </div>
  );
};

export default DataIntegration;
