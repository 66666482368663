import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Utensils, Apple, PieChart as PieChartIcon } from 'lucide-react';
import { DashboardCard } from './TherapistHealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { NutritionEntry, NutritionSummaryData, CustomTooltipProps } from '../../../../../types';

interface TimeframeButtonProps {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const TimeframeButton: React.FC<TimeframeButtonProps> = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={`px-3 py-1 text-sm font-light rounded-full transition-colors duration-200 
                   ${active ? 'bg-primary text-white' : 'text-gray-500 hover:bg-gray-100'}`}
    >
        {children}
    </button>
);

interface NutritionTabProps {
    nutritionData: NutritionEntry[];
}

const NutritionTab: React.FC<NutritionTabProps> = ({ nutritionData }) => {
    const [timeframe, setTimeframe] = useState<'week' | 'month'>('week');

    if (!nutritionData || nutritionData.length === 0) {
        return <p className="text-gray-500 text-center py-4 font-light">No nutrition data available.</p>;
    }

    const timeframeDays = {
        week: 7,
        month: 30
    };

    // Generate date range for the selected timeframe
    const generateDateRange = () => {
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        const dates = [];

        for (let i = 0; i < timeframeDays[timeframe]; i++) {
            const date = new Date(endDate);
            date.setDate(date.getDate() - i);
            dates.push(date);
        }

        return dates.reverse();
    };

    // Process nutrition data with proper date range
    const nutritionSummaryData: NutritionSummaryData[] = generateDateRange().map(date => {
        const matchingNutritionData = nutritionData.find(entry =>
            format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );

        const calories = matchingNutritionData?.summary.macros.calories
            ? Math.round(matchingNutritionData.summary.macros.calories)
            : null;
        const protein = matchingNutritionData?.summary.macros.protein_g
            ? Math.round(matchingNutritionData.summary.macros.protein_g)
            : null;
        const carbs = matchingNutritionData?.summary.macros.carbohydrates_g
            ? Math.round(matchingNutritionData.summary.macros.carbohydrates_g)
            : null;
        const fat = matchingNutritionData?.summary.macros.fat_g
            ? Math.round(matchingNutritionData.summary.macros.fat_g)
            : null;

        // Calculate total macros for percentage calculations
        const totalMacroGrams = (protein || 0) + (carbs || 0) + (fat || 0);

        return {
            date: format(date, 'MMM dd'),
            rawDate: date,
            calories,
            mealCount: matchingNutritionData?.meals?.length || null,
            protein,
            carbs,
            fat,
            proteinPercentage: protein && totalMacroGrams ? Math.round((protein / totalMacroGrams) * 100) : null,
            carbsPercentage: carbs && totalMacroGrams ? Math.round((carbs / totalMacroGrams) * 100) : null,
            fatPercentage: fat && totalMacroGrams ? Math.round((fat / totalMacroGrams) * 100) : null
        };
    });

    const calculateAverages = (data: NutritionSummaryData[]) => {
        const validCalories = data.filter(d => d.calories !== null);
        const validMeals = data.filter(d => d.mealCount !== null);
        const validProtein = data.filter(d => d.proteinPercentage !== null);

        return {
            avgCalories: validCalories.length > 0
                ? Math.round(validCalories.reduce((acc, curr) =>
                    acc + (curr.calories || 0), 0) / validCalories.length)
                : null,
            totalMeals: validMeals.length > 0
                ? validMeals.reduce((acc, curr) =>
                    acc + (curr.mealCount || 0), 0)
                : null,
            avgProteinPercentage: validProtein.length > 0
                ? Math.round(validProtein.reduce((acc, curr) =>
                    acc + (curr.proteinPercentage || 0), 0) / validProtein.length)
                : null
        };
    };

    const averages = calculateAverages(nutritionSummaryData);

    interface NutritionTooltipProps extends CustomTooltipProps<NutritionSummaryData> {
        chartType?: 'calories' | 'protein';
    }

    const CustomTooltip: React.FC<NutritionTooltipProps> = ({ active, payload, label, chartType }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const matchingNutritionData = nutritionData.find(entry =>
                format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd') === format(data.rawDate, 'yyyy-MM-dd')
            );

            if (chartType === 'calories') {
                return (
                    <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                        <p className="font-light text-gray-600 mb-2">{label}</p>
                        {data.calories ? (
                            <div className="space-y-2">
                                <p className="text-sm font-light text-center text-green-600 mb-3">
                                    Total Calories: {data.calories}
                                </p>
                                <div className="space-y-2 max-h-48 overflow-y-auto">
                                    {matchingNutritionData?.meals.map((meal, index) => (
                                        <div key={index} className="text-xs border-b border-gray-100 pb-2">
                                            <div className="flex justify-between text-gray-600">
                                                <span className="font-medium">
                                                    {meal.name?.split(' - ')[0] || 'Meal'}
                                                </span>
                                                <span>{meal.macros.calories || 0} cal</span>
                                            </div>
                                            <div className="text-gray-400">
                                                {meal.timestamp ?
                                                    format(parseISO(meal.timestamp), 'h:mm a') :
                                                    'Time not specified'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p className="text-sm font-light text-gray-500">No nutrition data for this day</p>
                        )}
                    </div>
                );
            }

            // For macro distribution chart tooltip
            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
                    <p className="font-light text-gray-600 mb-2">{label}</p>
                    <div className="space-y-1">
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#15803d' }}>Protein:</span>
                            <span style={{ color: '#15803d' }}>{data.proteinPercentage}%</span>
                        </div>
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#16a34a' }}>Carbs:</span>
                            <span style={{ color: '#16a34a' }}>{data.carbsPercentage}%</span>
                        </div>
                        <div className="flex justify-between text-xs">
                            <span style={{ color: '#22c55e' }}>Fat:</span>
                            <span style={{ color: '#22c55e' }}>{data.fatPercentage}%</span>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const NoDataDisplay: React.FC = () => (
        <div className="h-64 flex items-center justify-center text-gray-500 font-light">
            No data available
        </div>
    );

    return (
        <div className="space-y-4">
            <div className="flex justify-end space-x-2">
                <TimeframeButton active={timeframe === 'week'} onClick={() => setTimeframe('week')}>Week</TimeframeButton>
                <TimeframeButton active={timeframe === 'month'} onClick={() => setTimeframe('month')}>Month</TimeframeButton>
            </div>

            <div className="grid grid-cols-12 gap-4">
                {/* Calorie Intake Chart */}
                <div className="col-span-4">
                    <DashboardCard title="Calorie Intake" titleClass="text-lg font-light" icon={<Utensils className="text-green-500" />}>
                        {nutritionSummaryData.some(d => d.calories !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={nutritionSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip chartType="calories" />} />
                                        <Bar dataKey="calories" name="Calories" fill="#22c55e" radius={[4, 4, 0, 0]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Macro Distribution Chart - Replace Protein Ratio */}
                <div className="col-span-4">
                    <DashboardCard title="Macro Distribution" titleClass="text-lg font-light" icon={<PieChartIcon className="text-green-500" />}>
                        {nutritionSummaryData.some(d => d.protein !== null) ? (
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={nutritionSummaryData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                        <XAxis dataKey="date" tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <YAxis tick={{ fontSize: 11, fontFamily: 'Inter' }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="proteinPercentage" name="Protein %" stackId="a" fill="#15803d" />
                                        <Bar dataKey="carbsPercentage" name="Carbs %" stackId="a" fill="#16a34a" />
                                        <Bar dataKey="fatPercentage" name="Fat %" stackId="a" fill="#22c55e" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        ) : <NoDataDisplay />}
                    </DashboardCard>
                </div>

                {/* Summary Stats Cards */}
                <div className="col-span-4 grid grid-cols-2 gap-4 h-64">
                    {[
                        ['Daily Calories', 'Total Meals'],
                        ['Protein Ratio', 'Avg Meal Size']
                    ].map((row, rowIndex) => (
                        row.map((metric, colIndex) => {
                            let value = metric === 'Daily Calories' ? averages.avgCalories :
                                metric === 'Total Meals' ? averages.totalMeals :
                                    metric === 'Protein Ratio' ? averages.avgProteinPercentage :
                                        (averages.avgCalories && averages.totalMeals) ?
                                            Math.round(averages.avgCalories / averages.totalMeals) : null;

                            const displayValue = value !== null ?
                                `${value}${metric === 'Daily Calories' ? ' cal' :
                                    metric === 'Total Meals' ? ' meals' :
                                        metric === 'Protein Ratio' ? '%' :
                                            ' cal/meal'}` :
                                'N/A';

                            const periodLabel = timeframe === 'week' ?
                                (metric === 'Total Meals' ? '7-day total' : '7-day avg') :
                                (metric === 'Total Meals' ? '30-day total' : '30-day avg');

                            return (
                                <div key={metric}
                                    className="bg-white rounded-lg p-4 shadow-sm border border-gray-100 
                                              flex flex-col justify-center">
                                    <h3 className="text-gray-500 text-sm font-light">{metric}</h3>
                                    <p className={`text-xl font-light mt-1 ${value === null ? 'text-gray-400' : 'text-gray-900'}`}>
                                        {displayValue}
                                    </p>
                                    <p className="text-xs text-gray-400 mt-1">{periodLabel}</p>
                                </div>
                            );
                        })
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NutritionTab;