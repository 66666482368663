import React, { useState, useEffect } from 'react';
import { FiEdit, FiSave, FiX, FiUser, FiCalendar, FiPhone, FiUpload, FiTrash, FiHeart, FiCoffee } from 'react-icons/fi';
import { BiWine } from 'react-icons/bi';
import { usePatientProfile } from '../../hooks/usePatientProfile';
import { PatientDashboardProfileData } from '../../types/usersTypes';
import { toast } from 'react-hot-toast';
import { format } from "date-fns"
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";

interface PatientDashboardProfileProps {
  userEmail: string | undefined;
}

interface Option {
  value: string;
  label: string;
}

interface ProfileItemProps {
  icon: React.ReactNode;
  label: string;
  value: string | undefined;
  isEditing: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  type?: string;
  options?: Option[];
}


const defaultAvatar = require('../../assets/default-avatar-2.png');

const formatPhoneNumber = (value: string | undefined) => {
  if (!value) return value;

  // Remove all non-numeric characters
  const phoneNumber = value.replace(/[^\d]/g, '');

  // Handle different phone number lengths
  const phoneNumberLength = phoneNumber.length;

  // North American format (XXX) XXX-XXXX
  if (phoneNumberLength === 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  // International format with country code +X XXX XXX XXXX
  else if (phoneNumberLength > 10) {
    // Format: +[country code] XXX XXX XXXX
    const countryCode = phoneNumber.slice(0, phoneNumberLength - 10);
    const areaCode = phoneNumber.slice(phoneNumberLength - 10, phoneNumberLength - 7);
    const middle = phoneNumber.slice(phoneNumberLength - 7, phoneNumberLength - 4);
    const last = phoneNumber.slice(phoneNumberLength - 4);
    return `+${countryCode} ${areaCode} ${middle} ${last}`;
  }
  // Partial number - just add spaces every 3 digits
  else {
    return phoneNumber.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
  }
};

const PatientDashboardProfile: React.FC<PatientDashboardProfileProps> = ({ userEmail }) => {
  const { profile, loading, error, updateProfile, uploadProfilePicture } = usePatientProfile(userEmail);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<PatientDashboardProfileData>({});
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setFormData(profile);
    }
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Only allow numbers and format phone input
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prev => ({ ...prev, [name]: formattedPhone }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProfile(formData);
      setIsEditing(false);
    } catch (error) {
      // Error is already handled in the hook
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploadingPhoto(true);
      const publicUrl = await uploadProfilePicture(file);
      
      // Update local state
      setFormData(prev => ({ ...prev, profile_picture_url: publicUrl }));
      
      // Show success message
      toast.success('Profile photo updated successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload profile photo. Please try again.');
    } finally {
      setIsUploadingPhoto(false);
    }
  };

  const handleRemoveImage = async () => {
    try {
      setIsDeletingPhoto(true);
      
      // Update formData and profile data
      const updatedData = {
        ...formData,
        profile_picture_url: null
      };
      
      await updateProfile(updatedData);
      setFormData(updatedData);
      
      toast.success('Profile photo removed successfully');
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove profile photo. Please try again.');
    } finally {
      setIsDeletingPhoto(false);
    }
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = defaultAvatar;
  };

  if (loading) return <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
  </div>;

  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" },
    { value: "prefer-not-to-say", label: "Prefer not to say" }
  ];

  const primaryReasonOptions = [
    { value: "anxiety", label: "Anxiety" },
    { value: "depression", label: "Depression" },
    { value: "stress", label: "Stress management" },
    { value: "growth", label: "Personal growth" },
    { value: "other", label: "Other" }
  ];

  const consumptionOptions = [
    { value: "none", label: "None" },
    { value: "light", label: "Light" },
    { value: "moderate", label: "Moderate" },
    { value: "heavy", label: "Heavy" }
  ];

  return (
    <>
      <h2 className="text-2xl font-normal text-primary mb-6">My Profile</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6" data-testid="patient-profile">
        <div className="md:col-span-1">
          {/* Added: Name and Email section above photo */}
          <div className="text-center mb-6">
            <h3 className="text-xl font-normal text-gray-800">
              {profile?.full_name || 'N/A'}
            </h3>
            <p className="text-gray-600 font-light mt-1">
              {profile?.email || 'N/A'}
            </p>
          </div>

          {/* Profile Photo Section */}
          <div className="relative w-44 h-44 mx-auto mb-4">
            <img
              src={formData.profile_picture_url || defaultAvatar}
              alt="Profile"
              className={`rounded-full w-full h-full object-cover border-4 
                ${isUploadingPhoto || isDeletingPhoto ? 'opacity-50' : ''} 
                ${formData.profile_picture_url ? 'border-primary' : 'border-gray-200'}`}
              onError={handleImageError}
            />
            
            {/* Loading Overlay */}
            {(isUploadingPhoto || isDeletingPhoto) && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary border-t-transparent"></div>
              </div>
            )}
            
            {/* Edit Controls */}
            {isEditing && !isUploadingPhoto && !isDeletingPhoto && (
              <div className="absolute bottom-0 right-0 flex space-x-2">
                <label 
                  htmlFor="profile-picture-upload" 
                  className="cursor-pointer bg-primary text-white rounded-full p-2 hover:bg-primary/90 
                           transition-colors duration-200 shadow-sm"
                >
                  <FiUpload className="w-4 h-4" />
                </label>
                <input
                  id="profile-picture-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                  disabled={isUploadingPhoto}
                />
                
                {formData.profile_picture_url && (
                  <button 
                    onClick={handleRemoveImage}
                    disabled={isDeletingPhoto}
                    className="bg-red-500 text-white rounded-full p-2 hover:bg-red-600 
                             transition-colors duration-200 shadow-sm"
                  >
                    <FiTrash className="w-4 h-4" />
                  </button>
                )}
              </div>
            )}
          </div>
          
          {/* Loading Status Messages */}
          {isUploadingPhoto && (
            <p className="text-center text-sm text-primary font-light">
              Uploading photo...
            </p>
          )}
          {isDeletingPhoto && (
            <p className="text-center text-sm text-primary font-light">
              Removing photo...
            </p>
          )}
        </div>

        {/* Right Column - Other Profile Information */}
        <div className="md:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Removed name and email from here */}
            <ProfileItem
              icon={<FiCalendar />}
              label="Date of Birth"
              value={isEditing ? formData.dob : profile?.dob}
              isEditing={isEditing}
              name="dob"
              onChange={handleChange}
              type="date"
            />
            <ProfileItem
              icon={<FiUser />}
              label="Gender"
              value={isEditing ? formData.gender : profile?.gender}
              isEditing={isEditing}
              name="gender"
              onChange={handleChange}
              options={genderOptions}
            />
            <ProfileItem
              icon={<FiPhone />}
              label="Phone"
              value={isEditing ? formData.phone : profile?.phone}
              isEditing={isEditing}
              name="phone"
              onChange={handleChange}
            />
            <ProfileItem
              icon={<FiHeart />}
              label="Reason for Therapy"
              value={isEditing ? formData.primary_reason : profile?.primary_reason}
              isEditing={isEditing}
              name="primary_reason"
              onChange={handleChange}
              options={primaryReasonOptions}
            />
            <ProfileItem
              icon={<BiWine className="text-xl" />}
              label="Alcohol Consumption"
              value={isEditing ? formData.alcohol_consumption : profile?.alcohol_consumption}
              isEditing={isEditing}
              name="alcohol_consumption"
              onChange={handleChange}
              options={consumptionOptions}
            />
            <ProfileItem
              icon={<FiCoffee />}
              label="Caffeine Consumption"
              value={isEditing ? formData.caffeine_consumption : profile?.caffeine_consumption}
              isEditing={isEditing}
              name="caffeine_consumption"
              onChange={handleChange}
              options={consumptionOptions}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 bg-gray-100 p-4 rounded-lg">
        <h3 className="text-xl font-normal text-primary mb-2">Mental Health Information</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h4 className="font-normal mb-2">Mental Health History</h4>
            {isEditing ? (
              <Textarea
                name="mental_health_history"
                value={formData.mental_health_history || ''}
                onChange={handleChange}
                className="font-light"
                rows={4}
              />
            ) : (
              <p className="text-gray-700 font-light">
                {profile?.mental_health_history || 'No mental health history.'}
              </p>
            )}
          </div>
          <div>
            <h4 className="font-normal mb-2">Current Medications</h4>
            {isEditing ? (
              <Textarea
                name="current_medications"
                value={formData.current_medications || ''}
                onChange={handleChange}
                className="font-light"
                rows={4}
              />
            ) : (
              <p className="text-gray-700 font-light">
                {profile?.current_medications || 'No current medications.'}
              </p>
            )}
          </div>
          <div>
            <h4 className="font-normal mb-2">Stress Triggers</h4>
            {isEditing ? (
              <Textarea
                name="stress_triggers"
                value={formData.stress_triggers || ''}
                onChange={handleChange}
                className="font-light"
                rows={4}
                placeholder="What typically triggers your stress?"
              />
            ) : (
              <p className="text-gray-700 font-light">
                {profile?.stress_triggers || 'No stress triggers.'}
              </p>
            )}
          </div>
          <div>
            <h4 className="font-normal mb-2">Relaxation Habits</h4>
            {isEditing ? (
              <Textarea
                name="relaxation_habits"
                value={formData.relaxation_habits || ''}
                onChange={handleChange}
                className="font-light"
                rows={4}
                placeholder="What activities help you relax?"
              />
            ) : (
              <p className="text-gray-700 font-light">
                {profile?.relaxation_habits || 'No relaxation habits.'}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        {isEditing ? (
          <>
            <Button
              variant="outline"
              onClick={() => setIsEditing(false)}
              className="flex items-center font-light hover:bg-gray-100/80 transition-colors rounded-full px-4"
              size="sm"
            >
              <FiX className="mr-2 h-4 w-4" />
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className="flex items-center font-light bg-primary hover:bg-primary/90 text-white transition-colors rounded-full px-4"
              size="sm"
            >
              <FiSave className="mr-2 h-4 w-4" />
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            variant="outline"
            onClick={() => setIsEditing(true)}
            className="flex items-center font-light hover:bg-gray-100/80 transition-colors rounded-full px-4"
            size="sm"
          >
            <FiEdit className="mr-2 h-4 w-4" />
            Edit Profile
          </Button>
        )}
      </div>
    </>
  );
};

const ProfileItem: React.FC<ProfileItemProps> = ({ icon, label, value, isEditing, name, onChange, type = "text", options }) => {
  const [open, setOpen] = useState(false);
  
  // Update how we initialize the date
  const [date, setDate] = React.useState<Date | undefined>(() => {
    if (!value) return undefined;
    // Adjust for timezone offset when creating the date
    const dateValue = new Date(value);
    const userTimezoneOffset = dateValue.getTimezoneOffset() * 60000;
    return new Date(dateValue.getTime() + userTimezoneOffset);
  });

  const getDisplayValue = () => {
    if (name === 'phone') {
      return formatPhoneNumber(value) || 'N/A';
    }
    if (name === 'dob' && value) {
      // Adjust for timezone offset when displaying the date
      const dateValue = new Date(value);
      const userTimezoneOffset = dateValue.getTimezoneOffset() * 60000;
      return format(new Date(dateValue.getTime() + userTimezoneOffset), 'PPP');
    }
    if (options) {
      const option = options.find(opt => opt.value === value);
      return option ? option.label : 'N/A';
    }
    return value || 'N/A';
  };

  if (isEditing && type === 'date') {
    return (
      <div className="flex items-start bg-gray-50 p-3 rounded-lg">
        <div className="text-primary mr-3 text-xl flex-shrink-0 mt-1">{icon}</div>
        <div className="flex-grow min-w-0">
          <span className="font-normal text-gray-700">{label}</span>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-light mt-1",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? format(date, "PPP") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(newDate) => {
                  setDate(newDate);
                  if (newDate) {
                    // Adjust for timezone when saving the date
                    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
                    const adjustedDate = new Date(newDate.getTime() - userTimezoneOffset);
                    onChange({
                      target: {
                        name,
                        value: format(adjustedDate, 'yyyy-MM-dd')
                      }
                    } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
                disabled={(date) =>
                  date > new Date() || date < new Date("1900-01-01")
                }
                initialFocus
                captionLayout="dropdown-buttons"
                fromYear={1900}
                toYear={new Date().getFullYear()}
                ISOWeek
                showOutsideDays={false}
                classNames={{
                  caption_dropdowns: "flex justify-between w-full px-2",
                  dropdown: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                  dropdown_month: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                  dropdown_year: "w-[100%] bg-transparent text-sm focus:outline-none focus:ring-0 [&>span]:line-clamp-1 [&>span]:font-medium",
                  caption: "flex justify-center pt-1 relative items-center",
                  caption_label: "hidden",
                  nav: "hidden",
                  nav_button: "hidden",
                  nav_button_previous: "hidden",
                  nav_button_next: "hidden"
                }}
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-start bg-gray-50 p-3 rounded-lg">
      <div className="text-primary mr-3 text-xl flex-shrink-0 mt-1">{icon}</div>
      <div className="flex-grow min-w-0">
        <span className="font-normal text-gray-700">{label}</span>
        {isEditing ? (
          options ? (
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between mt-1 font-light"
                >
                  {value
                    ? options.find((option) => option.value === value)?.label
                    : `Select ${label}`}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0">
                <Command>
                  <CommandInput placeholder={`Search ${label.toLowerCase()}...`} />
                  <CommandList>
                    <CommandEmpty>No option found.</CommandEmpty>
                    <CommandGroup>
                      {options.map((option) => (
                        <CommandItem
                          key={option.value}
                          value={option.value}
                          onSelect={() => {
                            onChange({
                              target: { name, value: option.value }
                            } as React.ChangeEvent<HTMLSelectElement>);
                            setOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              value === option.value ? "opacity-100" : "opacity-0"
                            )}
                          />
                          {option.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          ) : (
            <input
              type={type}
              name={name}
              value={value || ''}
              onChange={onChange}
              maxLength={name === 'phone' ? 20 : undefined}
              className="w-full p-1 mt-1 border rounded focus:ring-2 focus:ring-primary focus:border-transparent font-light"
            />
          )
        ) : (
          <p className="mt-1 text-gray-900 break-words max-h-20 overflow-y-auto font-light">
            {getDisplayValue()}
          </p>
        )}
      </div>
    </div>
  );
};

export default PatientDashboardProfile;
