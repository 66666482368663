import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../services/supabaseClient';
import { useAuthContext } from '../contexts/AuthContext';

interface UserProfile {
  id: string;
  email: string;
  full_name?: string;
  dob?: string;
  [key: string]: any; 
}

interface ProfileFormData {
  id?: string;
  email?: string;
  full_name?: string;
  dob?: string | Date;
  gender?: string;
  primary_reason?: string;
  therapy_before?: string;
  mental_health_rating?: number | string;
  [key: string]: any;
}

export const usePatientProfile = (userEmail: string | undefined) => {
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { getUser } = useAuthContext();

    const fetchProfile = useCallback(async (): Promise<void> => {
        if (!userEmail) {
            return;
        }

        try {
            setLoading(true);
            const { data: userData, error: userError } = await supabase.auth.getUser();

            if (userError) throw userError;

            if (!userData.user) {
                throw new Error('No authenticated user found');
            }

            const { data, error } = await supabase
                .from('users')
                .select('*')
                .eq('id', userData.user.id)
                .single();

            if (error) throw error;

            setProfile(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching profile:', error);
            setError('Failed to load profile. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [userEmail]);

    const updateProfile = async (formData: ProfileFormData): Promise<void> => {
        try {
            setLoading(true);
            const { error } = await supabase
                .from('users')
                .update(formData)
                .eq('email', userEmail);

            if (error) throw error;

            setProfile(prev => ({ ...prev, ...formData } as UserProfile));
            setError(null);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile. Please try again.');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const uploadProfilePicture = async (file: File): Promise<string> => {
        try {
            if (!profile) throw new Error('No profile found');
            
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            const filePath = `${profile.id}/${fileName}`;

            let { error: uploadError } = await supabase.storage
                .from('user_profile_pictures')
                .upload(filePath, file);

            if (uploadError) {
                throw uploadError;
            }

            const { data } = supabase.storage
                .from('user_profile_pictures')
                .getPublicUrl(filePath);

            return data.publicUrl;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const completeProfile = async (userId: string, userData: ProfileFormData): Promise<void> => {
        try {
            setLoading(true);
            const user = await getUser();
            
            if (!user) {
                throw new Error('No authenticated user found');
            }

            if (userId !== user.id) {
                throw new Error('User ID does not match authenticated user');
            }

            const dob = userData.dob instanceof Date 
                ? userData.dob.toISOString().split('T')[0] 
                : userData.dob;

            const { error: updateUserError } = await supabase.auth.updateUser({
                data: { 
                    profile_completed: true,
                    full_name: userData.full_name
                }
            });

            if (updateUserError) throw updateUserError;

            const { error: insertError } = await supabase
                .from('users')
                .upsert({
                    id: user.id,
                    email: user.email,
                    ...userData,
                    dob,
                });

            if (insertError) throw insertError;

            setProfile({ id: user.id, email: user.email, ...userData, dob } as UserProfile);
            setError(null);
        } catch (error) {
            console.error('Error completing profile:', error);
            setError('Failed to complete profile. Please try again.');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    return { 
        profile, 
        loading, 
        error, 
        fetchProfile, 
        updateProfile, 
        uploadProfilePicture, 
        completeProfile 
    } as const;
};
