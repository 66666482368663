import { createClient } from '@supabase/supabase-js'

// Ensure these environment variables are correctly set in your `.env` file
const supabaseUrl: string = process.env.REACT_APP_SUPABASE_URL as string
const supabaseAnonKey: string = process.env.REACT_APP_SUPABASE_ANON_KEY as string

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase URL or anon key. Check your .env file.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: false
  }
})
