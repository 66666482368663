import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BackgroundAnimation from './BackgroundAnimation';
import Footer from './Footer';
import { api } from '../../services/api';
import { ContactFormData } from '../../types/feedbackTypes';
import { SubmitButton } from "@/components/Shared/Buttons"

const OasysLogo = require('../../assets/oasys-logo2.png');

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState<ContactFormData>({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        try {
            await api.contact.submitForm(formData);
            setIsSubmitted(true);
            setFormData({ name: '', email: '', message: '' });
            setTimeout(() => setIsSubmitted(false), 5000);
        } catch (error: any) {
            console.error('Error submitting form:', error);
            setError(`An error occurred while submitting the form: ${error.response?.data?.detail || error.message}`);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <main className="flex-grow flex flex-col bg-gradient-to-br from-background to-lightGray">
                <BackgroundAnimation />
                
                <div className="flex-grow flex items-center justify-center p-4 pt-32 md:pt-40 pb-20 md:pb-32 relative z-10">
                    <div className="bg-white rounded-3xl shadow-2xl w-full max-w-5xl flex overflow-hidden min-h-[600px]">
                        {/* Left Half - Quote Section */}
                        <div className="hidden md:flex md:w-1/2 bg-primary relative border-8 border-primary rounded-l-3xl">
                            <img 
                                src={OasysLogo} 
                                alt="Oasys Logo" 
                                className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[55%] h-auto"
                            />
                            <div className="absolute bottom-8 right-8 max-w-[80%]">
                                <blockquote className="text-white font-light text-lg">
                                    "The greatest wealth is health."
                                </blockquote>
                                <cite className="text-white/80 text-sm block mt-2 text-right">
                                    – Virgil
                                </cite>
                            </div>
                        </div>

                        {/* Right Half - Contact Form */}
                        <div className="w-full md:w-1/2 p-8 md:p-12 flex flex-col justify-center bg-white border-8 border-primary rounded-r-3xl">
                            <div className="text-center mb-8">
                                <h2 className="text-3xl font-normal text-primary">Contact Us</h2>
                                <p className="text-gray-500 mt-2 font-light">We'd love to hear from you.</p>
                            </div>

                            <AnimatePresence>
                                {isSubmitted && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -50 }}
                                        className="mb-4 p-3 bg-green-50 border border-green-400 text-green-700 rounded-lg font-light"
                                    >
                                        Thank you! Your message has been submitted.
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            {error && (
                                <div className="mb-4 p-3 bg-red-50 border border-red-400 text-red-700 rounded-lg font-light">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-light text-gray-700 mb-1">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-light text-gray-700 mb-1">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="message" className="block text-sm font-light text-gray-700 mb-1">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        rows={4}
                                        className="w-full px-4 py-3 border border-primary/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all duration-200 font-light bg-white text-primary placeholder-primary/70"
                                    ></textarea>
                                </div>

                                <SubmitButton type="submit" className="bg-primary text-white hover:bg-primary/90 w-full">
                                    Send Message
                                </SubmitButton>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default ContactUs; 