import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../services/supabaseClient';
import { api } from '../services/api';
import { Location } from 'react-router-dom';
import {
    HealthAnalysis,
    NutritionEntry,
    SleepData,
    ActivityData,
    MenstruationData,
    DailyData,
    BodyData,
} from '../types/healthDataTypes';
import { HealthInsight, CreativeInsight, HealthSummary } from '../types/patientDataTypes';
import { Therapist } from '../types';
import { User } from '@supabase/supabase-js';
import { timePromise } from '../utils/timing';

interface DashboardData {
    userData: HealthAnalysis | null;
    healthData: HealthAnalysis | null;
    nutritionData: NutritionEntry[] | null;
    sleepData: SleepData[] | null;
    activityData: ActivityData[] | null;
    menstruationData: MenstruationData[] | null;
    connectedProviders: any[];
    dailyData: DailyData[] | null;
    bodyData: BodyData[] | null;
    healthInsights: HealthInsight[];
    creativeInsights: CreativeInsight[];
    healthSummary: HealthSummary | null;
}

interface LocationState {
    showProfile?: boolean;
}


interface PatientDashboardReturn {
    fullName: string;
    dashboardData: DashboardData;
    isLoading: boolean;
    activeSection: string;
    setActiveSection: (section: string) => void;
    isSidebarCollapsed: boolean;
    authMessage: string | null;
    setAuthMessage: (message: string | null) => void;
    userTherapist: Therapist | null;
    handleConnectHealth: (providerId: string) => Promise<string>;
    handleDisconnectProvider: (providerId: string) => Promise<void>;
    toggleSidebar: () => void;
    handleTherapistSelection: (therapist: Therapist | null) => void;
    fetchAllDashboardData: (forceRefresh?: boolean) => Promise<void>;
    lastFetchTime: number | null;
    refreshInsights: () => Promise<void>;
    fetchHealthInsights: () => Promise<void>;
    isInsightsLoading: boolean;
    isHealthDataLoading: boolean;
    isProvidersLoading: boolean;
    fetchHealthData: (forceRefresh?: boolean) => Promise<void>;
    healthSummary: HealthSummary | null;
    isHealthSummaryLoading: boolean;
    fetchHealthDataUpdated: () => Promise<void>;
}


const usePatientDashboard = (user: User | null, location: Location<LocationState>): PatientDashboardReturn => {
    const [fullName, setFullName] = useState<string>('');
    const [dashboardData, setDashboardData] = useState<DashboardData>({
        userData: null,
        healthData: null,
        nutritionData: null,
        sleepData: null,
        activityData: null,
        menstruationData: null,
        connectedProviders: [],
        dailyData: null,
        bodyData: null,
        healthInsights: [],
        creativeInsights: [],
        healthSummary: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeSection, setActiveSection] = useState<string>('overview');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
    const [authMessage, setAuthMessage] = useState<string | null>(null);
    const [userTherapist, setUserTherapist] = useState<Therapist | null>(null);
    const [lastFetchTime, setLastFetchTime] = useState<number | null>(null);
    const [isInsightsLoading, setIsInsightsLoading] = useState<boolean>(false);
    const [isHealthDataLoading, setIsHealthDataLoading] = useState<boolean>(false);
    const [isProvidersLoading, setIsProvidersLoading] = useState<boolean>(false);
    const [healthSummary, setHealthSummary] = useState<HealthSummary | null>(null);
    const [isHealthSummaryLoading, setIsHealthSummaryLoading] = useState(false);

    const fetchUserData = useCallback(async (): Promise<void> => {
        if (!user || !user.email) {
            console.error('No user email provided');
            return;
        }

        const { data, error } = await supabase
            .from('users')
            .select('full_name')
            .eq('email', user.email)
            .single();

        if (error) {
            console.error('Error fetching user data:', error);
        } else if (data) {
            setFullName(data.full_name);
        } else {
            console.error('No user data found');
        }
    }, [user]);

    const fetchHealthInsights = useCallback(async (): Promise<void> => {
        if (!user?.email) return;

        try {
            setIsInsightsLoading(true);
            const insights = await api.patients.getHealthInsights(user.email);
            
            setDashboardData(prev => ({
                ...prev,
                healthInsights: insights.insights || [{
                    type: "info",
                    message: "No insights available at the moment.",
                    category: "general",
                    priority: 1
                }]
            }));
        } catch (error) {
            console.error('Error fetching health insights:', error);
            setDashboardData(prev => ({
                ...prev,
                healthInsights: [{
                    type: "info",
                    message: "Unable to load insights at the moment. Please try again later.",
                    category: "general",
                    priority: 1
                }]
            }));
        } finally {
            setIsInsightsLoading(false);
        }
    }, [user]);

    const fetchCreativeInsights = useCallback(async (): Promise<void> => {
        if (!user?.email) return;
        
        try {
            const response = await api.patients.getCreativeInsights(user.email);
            setDashboardData(prev => ({
                ...prev,
                creativeInsights: response.insights || []
            }));
        } catch (error) {
            console.error('Error fetching creative insights:', error);
            setDashboardData(prev => ({
                ...prev,
                creativeInsights: []
            }));
        }
    }, [user]);

    const fetchConnectedProviders = useCallback(async (): Promise<void> => {
        if (!user?.email) return;
        
        try {
            setIsProvidersLoading(true);
            const providers = await api.terra.getConnectedProviders(user.email);
            setDashboardData(prev => ({
                ...prev,
                connectedProviders: providers
            }));
        } catch (error) {
            console.error('Error fetching connected providers:', error);
        } finally {
            setIsProvidersLoading(false);
        }
    }, [user]);

    const fetchHealthData = useCallback(async (forceRefresh: boolean = false): Promise<void> => {
        if (!user?.email) return;
        
        try {
            setIsHealthDataLoading(true);
            const combinedData = await timePromise(
                'Combined Health Data Request',
                api.terra.getCombinedHealthData(user.email, forceRefresh)
            );

            setDashboardData(prev => ({
                ...prev,
                userData: combinedData.health_analysis,
                healthData: combinedData.health_analysis,
                nutritionData: combinedData.nutrition_data,
                sleepData: combinedData.sleep_data,
                activityData: combinedData.activity_data,
                menstruationData: combinedData.menstruation_data,
                dailyData: combinedData.daily_data,
                bodyData: combinedData.body_data,
            }));
            setLastFetchTime(new Date().getTime());
        } catch (error) {
            console.error('Error fetching health data:', error);
        } finally {
            setIsHealthDataLoading(false);
        }
    }, [user]);

    const fetchHealthSummary = useCallback(async () => {
        if (!user?.email) return;
        
        setIsHealthSummaryLoading(true);
        try {
            const summary = await api.patients.getHealthSummary(user.email);
            setHealthSummary(summary);
        } catch (error) {
            console.error('Error fetching health summary:', error);
        } finally {
            setIsHealthSummaryLoading(false);
        }
    }, [user]);

    const fetchHealthDataUpdated = useCallback(async (): Promise<void> => {
        if (!user?.email) return;
        
        try {
            setIsHealthDataLoading(true);
            const combinedData = await timePromise(
                'Combined Health Data Updated Request',
                api.terra.getCombinedHealthDataUpdated(user.email)
            );

            setDashboardData(prev => ({
                ...prev,
                userData: combinedData.health_analysis,
                healthData: combinedData.health_analysis,
                nutritionData: combinedData.nutrition_data,
                sleepData: combinedData.sleep_data,
                activityData: combinedData.activity_data,
                menstruationData: combinedData.menstruation_data,
                dailyData: combinedData.daily_data,
                bodyData: combinedData.body_data,
            }));
            setLastFetchTime(new Date().getTime());
        } catch (error) {
            console.error('Error fetching updated health data:', error);
        } finally {
            setIsHealthDataLoading(false);
        }
    }, [user]);

    const fetchAllDashboardData = useCallback(async (forceRefresh: boolean = false): Promise<void> => {
        if (!user?.email) return;
        
        setIsLoading(true);
        try {
            await Promise.all([
                fetchConnectedProviders(),
                fetchHealthInsights(),
                fetchCreativeInsights(),
                fetchHealthSummary(),
            ]);

            if (activeSection === 'dashboard') {
                await fetchHealthData(forceRefresh);
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [user, fetchConnectedProviders, fetchHealthInsights, fetchHealthData, fetchCreativeInsights, fetchHealthSummary]);

    // Add effect to load health data when switching to dashboard tab
    useEffect(() => {
        if (activeSection === 'dashboard' && !dashboardData.healthData) {
            fetchHealthData();
        }
    }, [activeSection, dashboardData.healthInsights, fetchHealthData]);

    const refreshInsights = useCallback(async (): Promise<void> => {
        if (!user?.email) return;
        
        try {
            setIsLoading(true);
            await fetchHealthInsights();
        } catch (error) {
            console.error('Error refreshing insights:', error);
        } finally {
            setIsLoading(false);
        }
    }, [user, fetchHealthInsights]);

    const handleConnectHealth = useCallback(async (providerId: string): Promise<string> => {
        try {
            if (!user?.email) throw new Error('No user email available');
            
            const { url } = await api.terra.generateWidgetSession(user.email, providerId === 'APPLE' ? undefined : providerId);
            
            if (providerId !== 'APPLE') {
                window.open(url, '_blank', 'noopener,noreferrer');
            }
            
            return url;
        } catch (error) {
            console.error('Error generating Terra widget session:', error);
            setAuthMessage('Failed to connect health provider. Please try again.');
            setTimeout(() => setAuthMessage(null), 5000);
            throw error;
        }
    }, [user]);

    const handleDisconnectProvider = useCallback(async (providerId: string): Promise<void> => {
        if (!user?.email) return;
        
        if (window.confirm(`Warning: Disconnecting this provider will delete all associated data. Are you sure you want to proceed?`)) {
            try {
                await api.terra.disconnectProvider(user.email, providerId);
                console.log(`Provider ${providerId} disconnected successfully`);
                fetchConnectedProviders();
            } catch (error) {
                console.error('Error disconnecting provider:', error);
            }
        }
    }, [user, fetchConnectedProviders]);

    const toggleSidebar = useCallback((): void => {
        setIsSidebarCollapsed(prev => !prev);
    }, []);

    const handleTherapistSelection = useCallback((therapist: Therapist | null): void => {
        setUserTherapist(therapist);
        if (!therapist || therapist.approved === false) {
            setActiveSection('myTherapist');
        }
    }, []);

    useEffect(() => {
        fetchAllDashboardData();
        fetchUserData();
    }, [fetchAllDashboardData, fetchUserData]);

    useEffect(() => {
        if (location.state && (location.state as LocationState).showProfile) {
            setActiveSection('profile');
        }

        if (location.search) {
            const params = new URLSearchParams(location.search);
            const authStatus = params.get('auth');
            
            if (authStatus === 'success') {
                setAuthMessage('Health data provider connected successfully!');
                setTimeout(() => setAuthMessage(null), 5000);
            } else if (authStatus === 'failure') {
                const error = params.get('error');
                const errorDescription = params.get('error_description');
                // Only show error message if there's an actual error description
                if (errorDescription && errorDescription !== 'None') {
                    setAuthMessage(`Failed to connect health data provider: ${errorDescription}`);
                    setTimeout(() => setAuthMessage(null), 5000);
                }
            }
        }
    }, [location]);

    return {
        fullName,
        dashboardData,
        isLoading,
        activeSection,
        setActiveSection,
        isSidebarCollapsed,
        authMessage,
        setAuthMessage,
        userTherapist,
        handleConnectHealth,
        handleDisconnectProvider,
        toggleSidebar,
        handleTherapistSelection,
        fetchAllDashboardData,
        lastFetchTime,
        refreshInsights,
        fetchHealthInsights,
        isInsightsLoading,
        isHealthDataLoading,
        isProvidersLoading,
        fetchHealthData,
        healthSummary,
        isHealthSummaryLoading,
        fetchHealthDataUpdated,
    };
};

export default usePatientDashboard;
