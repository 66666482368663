import React from 'react';
import { useTherapistDashboard } from '../hooks/useTherapistDashboard';
import TherapistProfile from '../components/TherapistDashboard/TherapistProfile';
import PatientTab from '../components/TherapistDashboard/Patients/Desktop/PatientsTab';
import SessionTab from '../components/TherapistDashboard/Sessions/SessionTab';
import { Home, Users, Calendar, UserCircle, MessageSquare, ChevronLeft, ChevronRight, LucideIcon } from 'lucide-react';
import FeedbackForm from '../components/Shared/FeedbackForm';
import TherapistOverview from '../components/TherapistDashboard/Overview/TherapistOverview';
import { TherapistProfileData } from '../types/usersTypes';
import Tooltip from '../components/Shared/Tooltip';

// Add type for section icons mapping
interface SectionIcons {
  [key: string]: LucideIcon;
}

// Add this type guard function at the top level
const isTherapistProfile = (user: any): user is TherapistProfileData => {
  return user && typeof user.email === 'string' && typeof user.status !== 'undefined';
};

const TherapistDashboard: React.FC = () => {
  const {
    user,
    userData,
    fullName,
    activeSection,
    patients,
    approvedPatients,
    isSidebarCollapsed,
    isMobile,
    handleTabChange,
    handlePatientAction,
    toggleSidebar,
  } = useTherapistDashboard();

  // Create a therapist profile object from user data
  const therapistProfile: TherapistProfileData = {
    id: user?.id || '',
    email: user?.email || '',
    full_name: fullName,
    status: 'approved', // You might want to get this from userData
    ...userData
  };

  if (!user) {
    return <div>Loading user profile...</div>;
  }

  const sectionIcons: SectionIcons = {
    home: Home,
    patients: Users,
    sessions: Calendar,
    profile: UserCircle,
    feedback: MessageSquare,
  };

  const sectionLabels: { [key: string]: string } = {
    home: 'Home',
    patients: 'Patients',
    sessions: 'Sessions',
    profile: 'Profile',
    feedback: 'Feedback',
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className={`flex flex-grow mt-20 ${isMobile ? 'flex-col' : ''}`}>
        {!isMobile && (
          <div 
            className={`bg-white shadow-md transition-all duration-300 ${
              isSidebarCollapsed ? 'w-16' : 'w-64'
            } flex flex-col sticky top-20 h-[calc(100vh-5rem)]`} 
            data-testid="sidebar"
          >
            <div className="p-4 flex-grow flex flex-col">
              <button
                className="mb-4 p-2 w-full flex justify-center items-center bg-white text-primary border border-primary rounded-md hover:bg-gray-100 transition-colors duration-150 font-light"
                onClick={toggleSidebar}
              >
                {isSidebarCollapsed ? <ChevronRight /> : <ChevronLeft />}
              </button>
              {!isSidebarCollapsed && (
                <h2 className="text-2xl font-normal text-primary mb-4">Dashboard</h2>
              )}
              <nav className="space-y-2 flex-grow flex flex-col">
                <div className="space-y-2">
                  {['home', 'patients', 'sessions', 'profile'].map((section) => {
                    const Icon = sectionIcons[section];
                    return (
                      <Tooltip
                        key={section}
                        text={isSidebarCollapsed ? sectionLabels[section] : ""}
                        position="right"
                      >
                        <button
                          className={`w-full h-10 rounded-md transition-colors duration-150 ease-in-out flex items-center font-light
                              ${isSidebarCollapsed ? 'justify-center' : 'justify-start pl-2'}
                              ${activeSection === section
                              ? 'bg-primary text-white'
                              : 'text-gray-600 hover:bg-gray-100'
                            }`}
                          onClick={() => handleTabChange(section)}
                        >
                          <Icon className={`w-4 h-4 flex-shrink-0 ${isSidebarCollapsed ? '' : 'mr-2'}`} />
                          {!isSidebarCollapsed && (
                            <span>{section.charAt(0).toUpperCase() + section.slice(1)}</span>
                          )}
                        </button>
                      </Tooltip>
                    );
                  })}
                </div>
                <div className="mt-auto">
                  <Tooltip
                    text={isSidebarCollapsed ? sectionLabels.feedback : ""}
                    position="right"
                  >
                    <button
                      className={`w-full h-10 rounded-md transition-colors duration-150 ease-in-out flex items-center font-light
                          ${isSidebarCollapsed ? 'justify-center' : 'justify-start pl-2'}
                          ${activeSection === 'feedback'
                          ? 'bg-primary text-white'
                          : 'text-gray-600 hover:bg-gray-100'
                        }`}
                      onClick={() => handleTabChange('feedback')}
                    >
                      <MessageSquare className="w-4 h-4 flex-shrink-0" />
                      {!isSidebarCollapsed && <span className="ml-2">Feedback</span>}
                    </button>
                  </Tooltip>
                </div>
              </nav>
            </div>
          </div>
        )}
        <div className={`flex-1 overflow-auto ${isMobile ? 'p-3 pb-20' : 'p-8'}`}>
          <div className="max-w-full mx-auto h-full">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 h-full flex flex-col">
              {activeSection === 'home' && (
                <TherapistOverview
                  patients={patients}
                  approvedPatients={approvedPatients}
                  user={user}
                  therapistProfile={therapistProfile}
                  onTabChange={handleTabChange}
                  onPatientAction={handlePatientAction}
                />
              )}
              {activeSection === 'patients' && (
                isMobile ? (
                  <div className="text-center py-8">
                    <h3 className="text-xl font-medium text-gray-700">Experience the Full Power on Desktop!</h3>
                    <p className="text-gray-500 mt-2">
                      Switch to desktop to unlock comprehensive patient monitoring with wearables data,
                      detailed treatment plans, and an intuitive dashboard for managing all your patients' health journeys.
                    </p>
                  </div>
                ) : (
                  <PatientTab patients={patients} onPatientAction={handlePatientAction} />
                )
              )}
              {activeSection === 'sessions' && (
                isMobile ? (
                  <div className="text-center py-8">
                    <h3 className="text-xl font-medium text-gray-700">Unlock Advanced Session Tools on Desktop!</h3>
                    <p className="text-gray-500 mt-2">
                      Access our full suite of session management tools on desktop, including video calls,
                      AI-powered note enhancement, and real-time AI assistance to make your therapy sessions more effective.
                    </p>
                  </div>
                ) : (
                  <SessionTab user={user} patients={approvedPatients} />
                )
              )}
              {activeSection === 'profile' && (
                <TherapistProfile therapistProfile={therapistProfile} />
              )}
              {activeSection === 'feedback' && (
                <FeedbackForm isTherapist={true} />
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md">
          <nav className="flex justify-around items-center h-16">
            {['home', 'patients', 'sessions', 'profile', 'feedback'].map((section) => {
              const Icon = sectionIcons[section];
              return (
                <button
                  key={section}
                  data-testid={`mobile-nav-${section}`}
                  className={`p-2 rounded-full font-light ${activeSection === section
                    ? 'bg-primary text-white'
                    : 'text-gray-600'
                    }`}
                  onClick={() => handleTabChange(section)}
                >
                  <Icon className="text-xl" />
                </button>
              );
            })}
          </nav>
        </div>
      )}
    </div>
  );
};

export default TherapistDashboard;
