import React from 'react';
import { Link } from 'react-router-dom';
const oasysLogo = require('../../assets/oasys-logo2.png');
const hipaaLogo = require('../../assets/Homepage/compliance-badge.png');

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary text-white py-12 mt-16 relative z-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-start mb-12">
                    <div className="w-16 h-16 mx-auto md:mx-0">
                        <img src={oasysLogo} alt="Oasys Health Logo" className="w-full h-full object-contain" />
                    </div>
                    <div className="text-center md:text-right mt-4 md:mt-0">
                        <p className="text-xl md:text-2xl font-light">
                            Have a question? <a href="/contact" className="underline">Contact Us</a>
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-xl font-normal mb-4">Our Vision</h3>
                        <p className="text-sm font-light w-3/4">
                            We believe in a future where mental healthcare is predictive, proactive, and highly personalized.
                        </p>
                        <p className="text-sm font-light mt-4">&copy; 2024 Oasys Health, Inc.</p>
                        <p className="text-xs font-light mt-12">
                            <Link to="/privacy-policy" className="underline hover:text-gray-300">Privacy Policy</Link>
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-normal mb-4">Join the Movement</h3>
                        <ul className="space-y-2">
                            <p>
                                <a
                                    href="https://x.com/oasys_health"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:underline font-light !text-white"
                                >
                                    Twitter / X
                                </a>
                            </p>
                            <p>
                                <a
                                    href="https://www.linkedin.com/company/oasyshealth"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:underline font-light !text-white"
                                >
                                    LinkedIn
                                </a>
                            </p>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="absolute bottom-12 right-1/2 transform translate-x-1/2 md:translate-x-0 md:right-24">
                <img src={hipaaLogo} alt="HIPAA Compliance Badge" className="w-32 md:w-48 h-auto object-contain" />
            </div>
        </footer>
    );
};

export default Footer; 