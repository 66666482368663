import React from 'react';

const BackgroundAnimation: React.FC = () => {
    return (
        <>
            {/* Background with dots and radial gradient */}
            <div className="absolute inset-0 dark:bg-black bg-white dark:bg-dot-white/[0.5] bg-dot-black/[0.5]">
                <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)] md:[mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
            </div>
        </>
    );
};

export default BackgroundAnimation;