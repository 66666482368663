import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FiSend } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import { ChatMessage } from '../../types/chatDataTypes';
import { chatApi } from '../../services/chatApi';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import breaks from 'remark-breaks';

interface AIChatbotProps {
  userEmail: string;
  embedded?: boolean;
  inputOnly?: boolean;
  messages?: ChatMessage[];
  setMessages?: (messages: ChatMessage[] | ((prev: ChatMessage[]) => ChatMessage[])) => void;
  isTyping?: boolean;
  setIsTyping?: (isTyping: boolean) => void;
  onMessageSent?: (messages?: ChatMessage[]) => void;
}

const AIChatbot: React.FC<AIChatbotProps> = ({
  userEmail,
  embedded = false,
  inputOnly = false,
  messages = [],
  setMessages = () => { },
  isTyping = false,
  setIsTyping = () => { },
  onMessageSent = () => { }
}) => {
  const [chatInput, setChatInput] = useState('');
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const [currentPlaceholder, setCurrentPlaceholder] = useState('');
  const [displayedPlaceholder, setDisplayedPlaceholder] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isPlaceholderTyping, setIsPlaceholderTyping] = useState(true);

  const placeholderPrompts = [
    "How has my sleep quality been trending?",
    "What patterns do you notice in my sleep schedule?",
    "Analyze my sleep duration variations",
    "What's my weekly activity pattern?",
    "How consistent am I with exercise?",
    "Compare my activity levels month over month",
    "Analyze my eating patterns",
    "What nutritional improvements could you suggest?",
    "How balanced is my diet?",
    "What trends do you see in my health data?",
    "How does my activity affect my sleep?",
    "What factors seem to affect my wellbeing most?",
    "Provide a comprehensive health analysis",
    "What are my key health metrics?",
    "Suggest potential lifestyle improvements"
  ];

  // Placeholder typing animation effect
  useEffect(() => {
    if (!isPlaceholderTyping) return;

    if (displayedPlaceholder.length < currentPlaceholder.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedPlaceholder(currentPlaceholder.slice(0, displayedPlaceholder.length + 1));
      }, 50);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setIsPlaceholderTyping(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [displayedPlaceholder, currentPlaceholder, isPlaceholderTyping]);

  // Placeholder erasing animation effect
  useEffect(() => {
    if (isPlaceholderTyping) return;

    if (displayedPlaceholder.length > 0) {
      const timeoutId = setTimeout(() => {
        setDisplayedPlaceholder(displayedPlaceholder.slice(0, -1));
      }, 30);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * placeholderPrompts.length);
        setCurrentPlaceholder(placeholderPrompts[randomIndex]);
        setIsPlaceholderTyping(true);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [displayedPlaceholder, isPlaceholderTyping, placeholderPrompts]);

  // Initialize first placeholder
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * placeholderPrompts.length);
    setCurrentPlaceholder(placeholderPrompts[randomIndex]);
  }, []);

  // Add scroll to bottom effect
  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  const handleSendMessage = async () => {
    if (!chatInput.trim()) return;

    const newUserMessage: ChatMessage = {
      role: 'user',
      content: chatInput
    };
    const newMessages: ChatMessage[] = [...messages, newUserMessage];

    const placeholderAssistantMessage: ChatMessage = {
      role: 'assistant',
      content: ''
    };
    const messagesWithPlaceholder: ChatMessage[] = [...newMessages, placeholderAssistantMessage];

    setMessages(messagesWithPlaceholder);
    setChatInput('');
    setIsTyping(true);

    onMessageSent?.(messagesWithPlaceholder);

    try {
      const response = await chatApi.streamChat(userEmail, chatInput, messages);
      if (!response) throw new Error('No response available');

      const reader = response.body?.getReader();
      if (!reader) throw new Error('No reader available');

      let accumulatedResponse = '';
      let isFirstChunk = true;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        accumulatedResponse += chunk;

        if (isFirstChunk) {
          setIsTyping(false);
          isFirstChunk = false;
        }

        const updatedMessages = messagesWithPlaceholder.map((msg, index) => {
          if (index === messagesWithPlaceholder.length - 1) {
            return {
              ...msg,
              content: accumulatedResponse.trim()
            };
          }
          return msg;
        });
        setMessages(updatedMessages);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage: ChatMessage = {
        role: 'assistant',
        content: 'Sorry, I encountered an error. Please try again.'
      };
      setMessages([...newMessages, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // Add responsive breakpoint check
  const isMobile = useMediaQuery({ maxWidth: 640 });

  // Update inputOnly render with mobile-friendly classes
  if (inputOnly) {
    return (
      <div className="flex items-center space-x-2 bg-white p-2 sm:p-4 rounded-lg w-full">
        <input
          type="text"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={isFocused ? '' : displayedPlaceholder}
          className="flex-grow px-3 sm:px-6 py-2 border border-gray-200 rounded-full 
                    focus:outline-none focus:ring-2 focus:ring-primary/50 
                    focus:border-transparent font-light text-sm sm:text-base"
        />
        <button
          onClick={handleSendMessage}
          className="p-1.5 sm:p-2 text-primary hover:text-primary/80 transition-colors duration-200"
        >
          <FiSend className="w-4 h-4 sm:w-5 sm:h-5" />
        </button>
      </div>
    );
  }

  // Render embedded chat interface
  return (
    <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm h-full flex flex-col" data-testid="ai-chatbot">
      <div className="flex justify-end mb-2">
        <button
          onClick={() => setMessages([{
            role: 'assistant' as const,
            content: "Hi! I'm here to help analyze your health data. What would you like to know?"
          }])}
          className="text-xs text-gray-500 hover:text-primary transition-colors duration-200 font-light"
          data-testid="clear-chat"
        >
          Clear chat
        </button>
      </div>
      <div
        ref={chatMessagesRef}
        className="flex-1 overflow-y-auto px-2 sm:px-4 mb-4"
        data-testid="chat-messages"
      >
        <div className="space-y-3 sm:space-y-4 pb-4">
          {Array.isArray(messages) && messages.map((message, index) => (
            message.content && (
              <div key={index}
                className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                <div className={`max-w-[85%] sm:max-w-[70%] p-2 sm:p-3 rounded-lg ${message.role === 'assistant'
                  ? 'bg-gray-50 text-gray-800'
                  : 'bg-primary text-white'
                  } font-light text-sm sm:text-base`}>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm, breaks]}
                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                    components={{
                      p: ({ children }) => (
                        <p className="mb-3 font-light whitespace-pre-wrap leading-7">{children}</p>
                      ),
                      h1: ({ children }) => (
                        <h1 className="text-2xl font-normal my-3 text-primary">{children}</h1>
                      ),
                      h2: ({ children }) => (
                        <h2 className="text-xl font-normal my-2.5 text-primary">{children}</h2>
                      ),
                      h3: ({ children }) => (
                        <h3 className="text-lg font-normal my-2 text-primary">{children}</h3>
                      ),
                      ul: ({ children }) => (
                        <ul className="list-disc pl-5 my-2 space-y-1 font-light">{children}</ul>
                      ),
                      ol: ({ children }) => (
                        <ol className="list-decimal pl-5 my-2 space-y-1 font-light">{children}</ol>
                      ),
                      li: ({ children }) => (
                        <li className="font-light leading-relaxed">{children}</li>
                      ),
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                </div>
              </div>
            )
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="max-w-3/4 p-3 rounded-lg bg-gray-100">
                <div className="flex space-x-2">
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '0ms' }}></div>
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '150ms' }}></div>
                  <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: '300ms' }}></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pt-3 sm:pt-4 border-t border-gray-100">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? '' : displayedPlaceholder}
            className="flex-grow px-3 sm:px-6 py-2 border border-gray-200 rounded-full 
                      focus:outline-none focus:ring-2 focus:ring-primary/50 
                      focus:border-transparent font-light text-sm sm:text-base"
            data-testid="chat-input"
          />
          <button
            onClick={handleSendMessage}
            className="p-1.5 sm:p-2 text-primary hover:text-primary/80 transition-colors duration-200"
            data-testid="send-message"
          >
            <FiSend className="w-4 h-4 sm:w-5 sm:h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AIChatbot;