import React from 'react';
import { format } from 'date-fns';
import { Trash2, Edit2, ChevronLeft, ChevronRight } from 'lucide-react';
import { MoodEntry } from '../../../types/patientDataTypes';

interface MoodListProps {
    entries: MoodEntry[];
    onDelete: (entryId: string) => Promise<void>;
    onEdit: (entry: MoodEntry) => void;
    total: number;
    page: number;
    perPage: number;
    onPageChange: (page: number) => void;
    isLoading: boolean;
    renderPagination: (currentPage: number, totalPages: number) => React.ReactNode;
}

const getMoodLabel = (score: number): string => {
    switch (score) {
        case 1: return 'Very Low';
        case 2: return 'Low';
        case 3: return 'Neutral';
        case 4: return 'Good';
        case 5: return 'Excellent';
        default: return 'Unknown';
    }
};

const getMoodColor = (score: number): string => {
    switch (score) {
        case 1: return 'text-rose-600';
        case 2: return 'text-orange-600';
        case 3: return 'text-amber-600';
        case 4: return 'text-emerald-600';
        case 5: return 'text-green-600';
        default: return 'text-gray-600';
    }
};

const MoodList: React.FC<MoodListProps> = ({
    entries,
    onDelete,
    onEdit,
    total,
    page,
    perPage,
    onPageChange,
    isLoading,
    renderPagination
}) => {
    const totalPages = Math.ceil(total / perPage);

    const handleDelete = (id: string) => {
        if (window.confirm('Are you sure you want to delete this entry? This action cannot be undone.')) {
            onDelete(id);
        }
    };

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {entries.map((entry) => (
                    <div
                        key={entry.id}
                        className="bg-white p-4 rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-shadow duration-200 md:min-h-[210px]"
                    >
                        <div className="flex justify-between items-start mb-2">
                            <span className="text-sm text-gray-500">
                                {format(new Date(entry.created_at), 'MMM d, h:mm a')}
                            </span>
                            <div className="flex gap-2">
                                <button
                                    onClick={() => onEdit(entry)}
                                    className="text-gray-400 hover:text-primary"
                                >
                                    <Edit2 aria-label="Edit Mood Entry" className="w-4 h-4" />
                                </button>
                                <button
                                    onClick={() => handleDelete(entry.id)}
                                    className="text-gray-400 hover:text-red-500"
                                >
                                    <Trash2 className="w-4 h-4" />
                                </button>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="flex items-center gap-2">
                                <span className={`text-2xl ${getMoodColor(entry.mood_score)}`}>
                                    {getMoodLabel(entry.mood_score)}
                                </span>
                            </div>
                            {entry.activities && entry.activities.length > 0 && (
                                <div className="flex flex-wrap gap-1">
                                    {entry.activities.map((activity, index) => (
                                        <span
                                            key={index}
                                            className="text-xs bg-gray-50 text-gray-600 px-2 py-1 rounded-full"
                                        >
                                            {activity}
                                        </span>
                                    ))}
                                </div>
                            )}
                            {entry.notes && (
                                <p className="text-sm text-gray-600 font-light line-clamp-2">
                                    {entry.notes}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {totalPages > 1 && renderPagination(page, totalPages)}
        </div>
    );
};

export default MoodList; 